import { Col, Form, Row, Typography, Switch } from 'antd';
import SelectClub from './select';
import SelectTag from '../../../common/createEdit/selectTag';

const { Text } = Typography;

export default ({ organisationId, setClubCount }) => {


    return (
        <div>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label="Available only to selected clubs"
                        name="isClubLevelVisibility"
                        valuePropName="checked"
                        wrapperCol={{ span: 16 }}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item shouldUpdate={(prev, cur) =>
                    (
                        prev.challengeClubs !== cur.challengeClubs || 
                        prev.isClubLevelVisibility !== cur.isClubLevelVisibility
                    )}>
                        {(props) => {

                             const isEnabled = props.getFieldValue('isClubLevelVisibility');

                             // console.log('isClubLevelVisibility', props.getFieldValue('isClubLevelVisibility'))

                            return (
                                <div style={{ paddingTop: 12 }}>
                                    <SelectClub organisationId={organisationId} isEnabled={isEnabled} />
                                </div>)

                        }}
                    </Form.Item >
                </Col>
            </Row>
            <Row>
                <SelectTag 
                field='challengeClubs' 
                onRemoveCallback={(itemId, items) => {
                    try {

                        setClubCount(items && items.length || 0)
                        
                    } catch (error) {
                        console.log('error setting club length', error);
                    }
                }} />
            </Row>
        </div>
    )

}