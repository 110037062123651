import { Spin, Row, Button, Breadcrumb } from 'antd';
import { useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';

import useHttpHelper from '../../../common/hooks/useHttpHelper';
import appConfig from '../../../config/config';
import { appLinks, queryKeys, appRoutes } from '../../../config/constants';
import SessionLogTable from './table';

import { ArrowLeftOutlined } from '@ant-design/icons';

import './index.scss';


const UserLogs = () => {

    const navigate = useNavigate();

    let { sessionId } = useParams();

    const [resetKey, setResetKey] = useState(0);

    const {
        httpPostAsync,
    } = useHttpHelper();

    const fetchUserLogsAsync = async (params) => {
        // console.log('call api:page param', pageParam, searchCriteria);

        const { pageParam } = params

        console.log('fetchUserLogsAsync: params', params)

        let postData = {
            timezone: appConfig.appTimeZone,
            sessionId,
        }

        if (pageParam !== undefined) {
            postData = {
                ...postData,
                LastEvaluatedKey: pageParam
            }
        }

        //console.log('session post data', postData)

        const response = await httpPostAsync(
            appLinks.userLogs,
            postData
        );

        const data = response?.data ?? []

        console.log('session logs response', data?.items)

        return data
    }

    const queryKey = [queryKeys.sessionLogs]

    const {
        isLoading,
        data = {},
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery(
        queryKey,
        fetchUserLogsAsync,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            getNextPageParam: (currentResponse) => {
                //const { LastEvaluatedKey } = currentResponse ?? {};
                return currentResponse?.LastEvaluatedKey;
            },
        })

    const onLoadMoreClick = () => {
        if (hasNextPage) {
            fetchNextPage()
        }
    }

    const logs = useMemo(() => {

        const items = data?.pages?.reduce((prev, cur) => {

            const items = cur.items ?? []

            return [
                ...prev,
                ...items,
            ]

        }, [])

        return items

    }, [data])

    // console.log('data', data)

    const hasMore = hasNextPage

    const onBack = () => {
        try {
    
          navigate(`/${appRoutes.dashboard}/${appRoutes.sessionLogs}`);
    
        } catch (error) {
          console.log('error on back click', error);
        }
      }

    return (
        <div className='user-log-list'>
            <Spin spinning={isLoading || isFetching}>
            <Row align="middle" style={{ paddingBottom: 8 }}>
          <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
          <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
            <Breadcrumb.Item><a onClick={onBack}>Session Logs</a></Breadcrumb.Item>
          </Breadcrumb>
        </Row>
                <SessionLogTable
                    key={resetKey}
                    logs={logs}
                    hasMore={hasMore}
                    onLoadMoreClick={onLoadMoreClick}
                    isFetchingNextPage={isFetchingNextPage}
                />
            </Spin>
        </div>
    )
}

export default UserLogs;