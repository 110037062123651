import { httpPostAsync } from '../../helpers/httpHelpers';
import { appLinks, appRoutes } from "../../config/constants";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Row, Space, Spin, Table, Typography, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { debounce, filter, get, isEmpty, join, last, map, toLower } from 'lodash';

const { Text } = Typography
const { Search } = Input;

const useStyles = createUseStyles({
    Input: {
        borderRadius: '40px',
        margin: '8px 0px ',
        //backgroundColor: '#F4EEEE'
    },
    btn: {
        margin: '8px 0px'
    },
    RightSection: {
        backgroundImage: 'url(/static/media/ActivationSvg-01.76363784.png)',
        height: '100vh',
        backgroundSize: 'contain',
        backgroundRepeatX: 'no-repeat',
        backgroundRepeatY: 'no-repeat',
        backgroundColor: ' #f4eeee',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center'
        //backgroundColor: 'red'
    },
    para: {
        color: '#000',
        fontSize: 16
    },
    paraStyle2: {
        fontWeight: 'bolder'
    },
    paraLight: {
        color: 'grey'
    }
})


//const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

const userTypeMapper = {
    SA: 'Super Admin',
    Admin: 'Admin',
    Youth: 'Youth'
}

const Users = props => {

    let navigate = useNavigate();

    const classes = useStyles();


    const [initialUsers, setInitialUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});

    const fetchUserAsync = async (searchParam = '') => {
        // console.log('call api:page param', pageParam, searchCriteria);

        const response = await httpPostAsync(
            appLinks.users,
            {
                itemPerPage: 100,
                query: searchParam,
                //pageNo: pageParam,
                //...searchCriteria,
            }
        );

        //console.log('fetch response', response)

        //const { nextPage } = response;

        //nextPageRef.current = nextPage;
        //console.log('challenges fetch data to be returned', data)
        return response;
    };



    useEffect(() => {

        let isValid = true;

        const fetch = async () => {
            try {
                setIsLoading(true);
                const response = await fetchUserAsync();

                console.log('users response', response);
                if (isValid) {
                    setUsers(response.data);
                    // setIsLoading(false);
                    setInitialUsers(response.data);
                }
            } catch (error) {
                console.log('error loading user list', error);
            }

            if (isValid) {
                // setUsers(response.data);
                setIsLoading(false);
            }

            //console.log('data2', users)
        }

        fetch();


        return () => {
            isValid = false;
        }

    }, [])

    const reloadUserAsync = async () => {
        try {
            setIsLoading(true);
            const response = await fetchUserAsync();
            console.log('reload user resonse', response);
            setUsers(response.data);

        } catch (error) {
            console.log('error reloading user list', error);
        }
        setIsLoading(false);
    }

    const indexOf = (search, start) => {
        'use strict';

        if (search instanceof RegExp) {
            throw TypeError('first argument must not be a RegExp');
        }
        if (start === undefined) { start = 0; }
        return this.indexOf(search, start) !== -1;
    };

    const searchUsers = async (text) => {
        try {

            console.log('search users', text);

            setIsLoading(true);
            const response = await fetchUserAsync(text);
            console.log('search user resonse', response);
            setUsers(response.data);

        } catch (error) {
            console.log('error searching users', error);
        }
    }

    const searchUsersRef = useRef(searchUsers);
    searchUsersRef.current = searchUsers;

    const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), [])


    const columns = [
        {
            title: 'Name',
            dataIndex: 'firstName',
            key: 'id',
            render: (text, record) => <span>{record.firstName} {record.lastName}</span>
            // render: (text, record) => <a onClick={() => {
            //     console.log('on click', record)
            //     navigate(`/${appRoutes.userDetails}/${record.id}`)
            // }}>
            //     {text}
            // </a>,
        },
        {
            title: 'Email address',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'status',
            render: (text, record) => {
                const type = get(userTypeMapper, record.type, record.type);
                return (<span>{type}</span>)
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        // {
        //     title: 'Info',
        //     dataIndex: 'firstName',
        //     key: 'id',
        //     render: (text, record) => {

        //         const logins = get(record, 'userLogins', []);

        //         //const lastLogin = last(logins);

        //         let devices = map(logins, login => get(login, 'device'));
        //         devices = join(devices, ', ');

        //         let challenges = get(record, 'enrollments', []);

        //         challenges = map(challenges, challenge => {

        //             return `${get(challenge, 'activity.title')}: ${get(challenge, 'status')}`

        //         });

        //         challenges = join(challenges, ' | ')

        //         const posts = get(record, 'userStatistics[0].posts', 0)


        //         return (
        //             <div>
        //                 <div>
        //                     <span>Logged in devices: {devices}</span>
        //                 </div>
        //                 <div>
        //                     <span>Challenges:  {challenges}</span>
        //                 </div>
        //                 <div>
        //                     <span>Total Posts:  {posts}</span>
        //                 </div>
        //             </div>)
        //     }
        //     // render: (text, record) => <a onClick={() => {
        //     //     console.log('on click', record)
        //     //     navigate(`/${appRoutes.userDetails}/${record.id}`)
        //     // }}>
        //     //     {text}
        //     // </a>,
        // },

        // {
        //     title: 'Action',
        //     key: 'action',
        //     render: (text, record) => (
        //         <Space size="middle">
        //             {
        //                 record.status === "active"
        //                     ? <div>
        //                         <Spin indicator={antIcon} spinning={isBusy} style={{ marginRight: 8 }} />
        //                         <a >Deactivate</a>
        //                     </div>
        //                     : <div>
        //                         <Spin indicator={antIcon} spinning={isBusy} style={{ marginRight: 8 }} />
        //                         <a  >Activate</a>
        //                     </div>
        //             }
        //         </Space>
        //     ),
        // },
    ];

    // const onSearch = value => console.log(value);


    return (
        <div>
            <Row justify='end' style={{ paddingBottom: '18px' }} gutter={24}>
                <Col>
                    <Search placeholder="Search users" onChange={async e => debouncedSearch(e.target.value)} style={{ width: 200 }} />
                </Col>
                <Col style={{
                       // marginLeft: '24px'
                }}
                >
                    <Button type="primary" block 
            // style={{
            //     margin:'12px'
            // }}
            >
                New User 
            </Button>
                </Col>
            </Row>
            <Row>
                <Col justify='flex-start' span={24} style={{
                    //padding: '12px',
                    paddingTop: '0px'
                }}>
                    <Table
                        columns={columns}
                        dataSource={users}
                        pagination={{
                            defaultPageSize: 20
                        }}

                    // onRow={(record, rowIndex) => {

                    //     console.log('record', rowIndex, record);

                    // }}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default Users;