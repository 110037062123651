import Unauthorized from "../../../common/components/unauthorized";


const UnauthorizedComponent = (props) => {

    const { 
        isAuthorized,
        children
     } = props

    return isAuthorized ? children :  (<Unauthorized />) 

}

export default UnauthorizedComponent;