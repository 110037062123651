import { Select } from "antd";

import useLookupsSearch from "../../common/components/lookupSelect/useLookupsSearch";
import { appLinks, organisationTypes, queryKeys } from "../../config/constants";
import { useEffect, useRef, useState } from "react";

const { Option } = Select

const oneOrganisations = 'one'

const defaultValue = {
    "value": 16,
    "label": "ONE Learning_EN",
    "type": "foundation"
  }

const OrganisationsLookup = (props) => {

    const {
        onSelect,
        ...rest
    } = props

    const { 
        data = [],
        isLoading,
        onSearch,
        onBlur,
    } = useLookupsSearch(
        appLinks.adminOrganisationsV2,
        [queryKeys.foundationOrganisations, organisationTypes.foundation, oneOrganisations],
        { 
            type: organisationTypes.foundation,
            oneOrganisations: true
        }
    )

    const [selectedValue, setSelectedValue] = useState()
    const isFirstLoad = useRef(false) 

    const onSelectOrganisation = (e) => {
        console.log('selected value', e)
        setSelectedValue(e)
        props?.onSelect(e)
    }

    useEffect(() => {

        if (!isFirstLoad.current && !!data?.length ) {

            isFirstLoad.current = true
            console.log('set selected value')
            
            onSelectOrganisation(data?.[0])
        }

    }, [data])



    return (
        <Select
            //mode="multiple"
            labelInValue
            //tagRender={EmptyDisplayComponent}
            loading={isLoading}
            // search related
            filterOption={false} // needed to pick items updated after search
            showSearch
            //showArrow
            onSearch={onSearch}
            onBlur={onBlur}
            value={selectedValue}
            //defaultValue={defaultValue}
            onSelect={onSelectOrganisation}
            {...rest}
        >
            {
                data?.map(item => (<Option key={item.value} value={item.value}>{item.label}</Option>))
            }
        </Select>
    )

}

export default OrganisationsLookup;
