import React from 'react';
import { Form, Divider, Typography, Modal, Select
} from 'antd';
import { map, get, filter } from 'lodash';
import TagChip from '../../../../common/components/tagchip';
import useHttpHelper from '../../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../../../config/constants';
import { useQuery } from 'react-query';
import Empty from '../../../../common/components/Empty';

const { confirm } = Modal;
const { Text } = Typography;
const { Option } = Select;

const styles = {
    stepRow: { paddingBottom: 12, }
}

const shouldUpdate = () => (prev, cur) => prev.roles !== cur.roles

const OrganisationRolesSelect = (props) => {

    const { index, form } = props;

    const items = form.getFieldValue('roles');

    const organisationId = get(items, `[${index}].organisationId`)

    const { httpPostAsync } = useHttpHelper();

    const fetchRolesAsync = async () => {
        try {

            return await httpPostAsync(appLinks.organisationRoles.replace('{organisationId}', organisationId))
        } catch (error) {
            console.log('error getting user roles for organisations', error);
        }
    };

    const queryKey = [queryKeys.organisationRoles, organisationId];

    const {
        data: roles = [],
        isLoading,
    } = useQuery(
        queryKey,
        fetchRolesAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });

    //console.log('roles', roles);

    return (
        <Form.Item
            //label="Roles"
            name={[index, "roles"]}
            //rules={[{ required: true, message: 'Please choose an Type.' }]}
            wrapperCol={{ span: 16 }}
        >
            <Select
                placeholder="Select roles"
                mode="multiple"
                labelInValue
                tagRender={Empty}
                //notFoundContent={e => Empty}
                loading={isLoading}
            // disabled={disableSkills}
            //allowClear
            >
                {
                    map(roles, item => {
                        return (
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        )
                    })
                }
            </Select>
        </Form.Item>
    )


}

export default ({ form }) => {

    return (
        <Form.List name='roles'>
            {(fields, { add, remove }) => {
                return (
                    <div>
                        <div style={{ minHeight: 300 }}>
                            {map(fields, (field, index) => (
                                <div key={field.key}>
                                    {index > 0 ? <div><Divider /></div> : null}
                                    <Form.Item shouldUpdate={shouldUpdate}>
                                        {(props) => {

                                            const items = props.getFieldValue('roles');

                                            const organisation = get(items, `[${index}].organisation`)

                                            //console.log('items', items);

                                            return (<div>Roles for: {organisation}</div>)
                                        }}
                                    </Form.Item>
                                    <OrganisationRolesSelect index={index} form={form} />
                                    <Form.Item shouldUpdate={shouldUpdate}>
                                        {(props) => {

                                            // roles grouped by organisation
                                            const items = props.getFieldValue('roles');

                                            const organisationRoles = get(items, `[${index}].roles`);

                                            //console.log('items', items);

                                            return (
                                                <React.Fragment>
                                                    {map(organisationRoles, role => {

                                                        return (
                                                            <TagChip
                                                                key={role.value}
                                                                closable
                                                                onClose={() => {

                                                                    const filtered = filter(organisationRoles, o => o.value !== role.value);

                                                                    console.log('filtered roles', filtered)

                                                                    console.log('filtered themes', filtered)

                                                                    items[index].roles = [...filtered ]
                            
                                                                    props.setFieldsValue({ roles: items })

                                                                }}
                                                            >
                                                                {role.label}
                                                            </TagChip>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        }}
                                    </Form.Item>
                                </div>))}
                        </div>
                    </div>
                )
            }}
        </Form.List>)
}