import { Col, Row, Form, Input, message, Upload } from "antd"
import FormUploader from "../../../challenge/common/formUploader"
import { includes } from "lodash";
import { appLinks, imageFilesExenstions } from "../../../../config/constants";
import useHttpHelper from "../../../../common/hooks/useHttpHelper";
import { useEffect, useState } from "react";
import { normFile } from "../../../../helpers/utils";

import { ReactComponent as CoverImageDefault } from '../../../../assets/coverPlaceholder.svg';
import { ReactComponent as UserProfileDefault } from '../../../../assets/userProfile.svg';


const ThumbImagePreview = (props) => {

    const { previewUrl } = props;


    return (
        previewUrl
            ? (
                <Row style={{ paddingBottom: '15px' }} >
                    <img src={previewUrl} alt="creator avatar" style={{ width: 120, height: 130, borderRadius: '8px' }} />
                </Row>
            )
            : (
                <Row justify="start" style={{ paddingBottom: 12 }}>
                      <UserProfileDefault height={120} width={120} style={{ borderRadius: 8 }} />
                </Row>
            )
    )

}


const CoverImagePreview = (props) => {

    const { previewUrl } = props;

    return (
        previewUrl
            ? (
                <Row style={{ paddingBottom: '15px' }} >
                    <img src={previewUrl} alt="creator cover image" style={{ width: 280, height: 158, borderRadius: '8px' }} />
                </Row>
            )
            : (
                <Row justify="start" style={{ paddingBottom: 12 }}>
                       <CoverImageDefault width={280} height={158} style={{ borderRadius: 8 }} />
                </Row>
            )
    )

}


export default (props) => {

    const { 
        creatorProfileImageId,
        creatorProfileImageUrl,
        creatorCoverImageId,
        creatorCoverImageUrl,
        setCreatorsData,
    } = props;

    const { httpPostAsync } = useHttpHelper();

    //#region  profile image upload
    const [profileImageId, setProfileImageId] = useState();
    const [profileImageUrl, setProfileImageUrl] = useState();

    const getProfileImageUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.getCreatorPersonUploadImageUrl, { ...UploadFile })

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload challenge thumb response', uploadLinkResponse)

            setProfileImageId(documentId);

            setCreatorsData(d => ({
                ...d,
                creatorProfileImageId: documentId,
            }));

            return uploadUrl;

        } catch (error) {
            console.log('error getting getProfileImageUploadUrlAsync', error)
        }
    }

    const onProfileImageUploadSuccess = ({ url }) => {
        try {

            console.log('on file upload success', profileImageId)

            //setThumbId(challengeThumbDocumentIdRef.current);
            setProfileImageUrl(url);
            //form.setFieldsValue({ thumbId: thumbId })

   


        } catch (error) {
            console.log('error in onProfileImageUploadSuccess', error);
        }
    }

    //#endregion

    //#region cover image upload
    const [coverImageId, setCoverImageId] = useState();
    const [coverImageUrl, setCoverImageUrl] = useState();

    const getcoverUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.getCreatorPersonUploadImageUrl, { ...UploadFile })

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload challenge thumb response', uploadLinkResponse)

            setCoverImageId(documentId);

            setCreatorsData(d => ({
                ...d,
                creatorCoverImageId: documentId,
            }))

            return uploadUrl;

        } catch (error) {
            console.log('error getcoverUploadUrlAsync', error)
        }
    }

    const onCoverImageUploadSuccess = ({ url }) => {
        try {

            console.log('on file upload success', coverImageId)

            //setThumbId(challengeThumbDocumentIdRef.current);
            setCoverImageUrl(url);
            //form.setFieldsValue({ thumbId: thumbId })


        } catch (error) {
            console.log('error in onCoverImageUploadSuccess', error);
        }
    }

    //#endregion

    useEffect(() => {

        setProfileImageId(creatorProfileImageId);
        setProfileImageUrl(creatorProfileImageUrl);
        setCoverImageId(creatorCoverImageId);
        setCoverImageUrl(creatorCoverImageUrl);

        setCreatorsData({
            creatorProfileImageId,
            creatorCoverImageId,
        })

        console.log('setting image urls')

    }, [ creatorProfileImageId, creatorProfileImageUrl, creatorCoverImageId, creatorCoverImageUrl,])

    const beforeImageUpload = (file) => {

        const extenstion = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

        console.log('extension ', extenstion);

        const isImage = includes(imageFilesExenstions, extenstion);
        if (!isImage) {
            message.error(`Only image files can be uploaded`);
        }

        if (file.size > 3 * 1024 * 1024) {
            message.error(`File size needs to be less then 3MB`);
        }

        return isImage || Upload.LIST_IGNORE;
    }


    return (
        <div>
            <Row>
                <Col span={12}>
                    <Form.Item
                       label="Profile Image (1080 X 1080)"
                       name="creatorProfileImageFiles"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        //wrapperCol={{ span: 22 }}
                        //rules={[{ required: true, message: 'Please choose an listing image' }]}
                    >
                        <FormUploader
                            getUploadUrlAsync={getProfileImageUploadUrlAsync}
                            onUploadSuccess={onProfileImageUploadSuccess}
                            beforeUpload={beforeImageUpload}
                            previewComponent={
                                <Form.Item shouldUpdate={(prev, cur) => prev.listingImage !== cur.listingImage}>
                                    {() => {
                                        return (<ThumbImagePreview previewUrl={profileImageUrl} />)
                                    }}
                                </Form.Item>
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label="Display name"
                        name="creatorName"
                        //rules={[{ required: true, message: 'First name is required.' }]}
                        wrapperCol={{ span: 16 }}
                    >
                        <Input placeholder="Display name" />
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="creatorTitle"
                        wrapperCol={{ span: 16 }}
                    >
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                        label="Website"
                        name="creatorWebsite"
                        wrapperCol={{ span: 16 }}
                    >
                        <Input placeholder="Website" />
                    </Form.Item>
                    <Form.Item
                        label="Short Bio"
                        name="creatorShortBio"
                        wrapperCol={{ span: 16 }}
                    >
                        <Input.TextArea placeholder="Short Bio" rows={4} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Cover Image (1920 X 1080)"
                        name="creatorCoverImageFiles"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        //wrapperCol={{ span: 22 }}
                        //rules={[{ required: true, message: 'Please choose an listing image' }]}
                    >
                        <FormUploader
                            getUploadUrlAsync={getcoverUploadUrlAsync}
                            onUploadSuccess={onCoverImageUploadSuccess}
                            beforeUpload={beforeImageUpload}
                            previewComponent={
                                <Form.Item shouldUpdate={(prev, cur) => prev.listingImage !== cur.listingImage}>
                                    {() => {
                                        return (<CoverImagePreview previewUrl={coverImageUrl} />)
                                    }}
                                </Form.Item>
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )

}