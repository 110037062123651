import React, { useMemo } from "react";
import { get } from "lodash";
import Empty from "../../../../../../common/components/Empty";
import { CreatorOrganisationView, CreatorPersonView } from '../../../creators/creatorViews';
import { creatorTypes } from "../../../../../../config/constants";


const PersonView = (props) => {

    const { form } = props;

    const personInfo = form.getFieldValue('creatorPerson')

    console.log('creator person', personInfo);

    return (personInfo ? <CreatorPersonView {...personInfo} showBio={false} /> : null)

}

const CreatorOrganisation = (props) => {

    const { form } = props;

    const creatorOrganisation = form.getFieldValue('creatorOrganisation')

    return (creatorOrganisation ? <CreatorOrganisationView  {...creatorOrganisation} /> : null)

}

const viewFactory = {
    [creatorTypes.creator]: PersonView,
    [creatorTypes.organisation]: CreatorOrganisation,
}

export default ({ type, ...rest }) => {

    const CreatorComponent = useMemo(() => {
        return get(viewFactory, type, Empty);
    }, [type])

    return (<CreatorComponent {...rest} />);

}