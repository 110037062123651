import { Form } from "antd";
import React from "react";
import { selectedOrganisationFieldName, } from "./SendView";
import UsersLookups from "./UsersLookups";


const UserSelect = (props) => {

    const { form , ...rest } = props; 

    const selectedOrganisation = Form.useWatch(selectedOrganisationFieldName, form);

    return (
        <UsersLookups
            organisationId={selectedOrganisation?.value}
            {...rest}
        />
    )
}

export default UserSelect