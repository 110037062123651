import { Breadcrumb, Button, Row, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import ClubView from './view';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Dashboard from '../../../common/components/dashboard';


export default () => {

    let { clubId } = useParams();

    //console.log('params', params);

    const navigate = useNavigate();

    const { httpGetAsync } = useHttpHelper();

    const fetchClubDetailsAsync = async () => {
        try {

            const challenge = await httpGetAsync(appLinks.clubDetails.replace('{clubId}', clubId));

            console.log('club details', challenge);

            return challenge;

        } catch (error) {
            console.log('error fetching club details', error);
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.clubDetailsView, clubId],
        fetchClubDetailsAsync,
        {
            enabled: clubId > 0,
            refetchOnWindowFocus: false
        }
    );

    const {
        name,
        //organisationId,
        organisation,
        status,
    } = data ?? {}

    const onBack = () => {
        try {

            navigate(`/${appRoutes.clubs}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }


    return (
        <Dashboard subrouting={false} activeItem='clubs'>
            <Spin spinning={isLoading}>
                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Clubs</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <ClubView
                    name={name}
                    status={status}
                    organisation={organisation}
                />
            </Spin>
        </Dashboard>
    );

}