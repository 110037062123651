import React from 'react';
import styled from 'styled-components';
import { Typography} from 'antd';

const { Text } = Typography;

const TitleDiv = styled.div`
padding-top: 8px
`;


export default ({ title, value }) => {


    return (
        <TitleDiv>
            <div>
                <Text type="secondary">{title}</Text>
            </div>
            <Text>{value}</Text>
        </TitleDiv>
    )

}