import { Form, Modal, Radio, Space } from "antd"
import useLookupsSearch from "../../../common/components/lookupSelect/useLookupsSearch"
import { appLinks, organisationTypes, queryKeys } from "../../../config/constants"

//const lookupStyle = { width: 200 }

const oneOrganisations = 'one'

const CreateChallengeModal = (props) => {

    const {
        isModalVisible,
        onCancel,
        onSubmit
    } = props

    const [form] = Form.useForm();

    const {
        data = [],
        isLoading,
        // onSearch,
        // onBlur,
    } = useLookupsSearch(
        appLinks.adminOrganisationsV2,
        [queryKeys.foundationOrganisations, organisationTypes.foundation, oneOrganisations],
        {
            type: organisationTypes.foundation,
            oneOrganisations: true
        }
    )

    const onCreateModalOk = () => {
        try {

            form.submit();

        } catch (error) {
            console.log('error on ok create challenge modal', error);
        }
    }

    const onFinish = (values) => {
        try {

            const { organisationId } = values;

            organisationId && typeof onSubmit === 'function' && onSubmit(organisationId)

        } catch (error) {
            console.log('error on create challenge on finish', error);
        }
    }

    return (
        <Modal
            title="New Challenge"
            open={isModalVisible}
            onOk={onCreateModalOk}
            onCancel={onCancel}
            okButtonProps={{
                ['data-testid']: "create-challenge-ok-button",
            }}
        >
            <Form
                layout='vertical'
                form={form}
                scrollToFirstError
                name='create-challenge-modal'
                onFinish={onFinish}
            >
                <Form.Item
                    name="organisationId"
                    label="Organisation"
                    rules={[{ required: true, message: 'Please select an organisation.' }]}
                    wrapperCol={{ span: 16 }}
                >
                    {/* <OneOrganisationsLookup
                        style={lookupStyle}
                        placeholder="Select Organisation"
                        mode='single'
                        labelInValue={false}
                    /> */}
                    <Radio.Group >
                        <Space direction="vertical">
                            {
                                data?.map(e => (<Radio key={e.value} value={e.value}>{e.label}</Radio>))
                            }
                        </Space>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    )

}

export default CreateChallengeModal