import { Spin } from 'antd';
import { useInfiniteQuery } from 'react-query';

import { useMemo, useState } from 'react';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import appConfig from '../../../config/config';
import { appLinks, queryKeys } from '../../../config/constants';
import SessionLogTable from './table';

import './index.scss';

const SessionLogs = (props) => {

    const [resetKey, setResetKey] = useState(0);

    const {
        httpPostAsync,
    } = useHttpHelper();

    const fetchSessionLogsAsync = async (params) => {
        // console.log('call api:page param', pageParam, searchCriteria);

        const { pageParam } = params

        console.log('fetchSessionLogsAsync: params', params)

        let postData = {
            timezone: appConfig.appTimeZone
        }

        if (pageParam !== undefined) {
            postData = {
                ...postData,
                LastEvaluatedKey: pageParam
            }
        }

        console.log('session post data', postData)

        const response = await httpPostAsync(
            appLinks.sessionLogs,
            postData
            //   {
            //     query,
            //     sortBy,
            //     sortOrder
            //   }
        );

        const data = response?.data ?? []

        console.log('session logs response', data?.items)

        return data

    }

    const queryKey = [queryKeys.sessionLogs]

    const {
        isLoading,
        //isError,
        //error,
        data = {},
        isFetching,
        //isPreviousData,
        isFetchingNextPage,
        //refetch,
        fetchNextPage,
        hasNextPage
    } = useInfiniteQuery(
        queryKey,
        fetchSessionLogsAsync,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            getNextPageParam: (currentResponse) => {
                const { LastEvaluatedKey } = currentResponse ?? {};
                //console.log('getNextPageParam', nextPage);
                return LastEvaluatedKey;
            },
        })

    const onLoadMoreClick = () => {
        if (hasNextPage) {
            fetchNextPage()
        }
    }

    const logs = useMemo(() => {

        const items = data?.pages?.reduce((prev, cur) => {

            const items = cur.items ?? []

            return [
                ...prev,
                ...items,
            ]

        }, [])

        return items

    }, [data])

    console.log('data', data)

    const hasMore = hasNextPage

    return (
        <div className='session-log-list'>
            <Spin spinning={isLoading || isFetching}>
                <SessionLogTable
                    key={resetKey}
                    logs={logs}
                    hasMore={hasMore}
                    onLoadMoreClick={onLoadMoreClick}
                    isFetchingNextPage={isFetchingNextPage}
                //onSearch={debouncedSearch}
                //onPagniationChange={onPagniationChange}
                //onSortChange={onSortChange}
                />
            </Spin>
        </div>
    )
}

export default SessionLogs;