import axios from 'axios';

export async function httpPostAsync(link, payload = {}, config = {}) {
  try {
    const response = await axios.post(link, payload, config);

    const { data = {} } = response || {};

    return data;
  } catch (error) {
    console.log('error in http helper, when making post call', error);
  }
}