import { createContext, useContext, useMemo, useRef, useState } from "react";
import axios from "axios";
import { removeStoredValueAsync } from "../../../helpers/storageHelpers";
import config from '../../../config/config';

const initialValue = {
  isAuthenticated: false,
  user: {},
  token: null
}

const AppContext = createContext({ ...initialValue });


export const WithAppContext = (props) => {

  //const { children, ...rest } = props;
  const { initValue = {} } = props;

  const [appInfo, setAppInfo] = useState({ ...initialValue, ...initValue })

  const axiosInstance = useMemo(() => {
    console.log('axios instance created')
    return axios.create();
  }, [])

  const setAuthenticated = ({ user, token }) => {
    try {

      setAppInfo(a => ({ ...a, isAuthenticated: true, user, token }))

    } catch (error) {
      console.log('error setting authenticated user', error);
    }
  }

  const setAuthenticatedRef = useRef(setAuthenticated);
  setAuthenticatedRef.current = setAuthenticated;

  const resetState = () => {
    try {
      setAppInfo({ ...initialValue });
    } catch (error) {
      console.log(
        'error while setting application state to authenticated',
        error
      );
    }
  }

  async function onLogoutAsync() {
    try {
      console.log('logout async');

      await removeStoredValueAsync(config.refreshTokenStorageKey);
      resetState();

    } catch (error) {
      console.log('error when logging out', error);
    }
  }

  const logoutAsyncRef = useRef(onLogoutAsync);
  logoutAsyncRef.current = onLogoutAsync;

  const appContextValue = useMemo(() => {

    return {
      ...appInfo,
      axiosInstance,
      setAuthenticated: setAuthenticatedRef.current,
      onLogoutAsync: logoutAsyncRef.current,
    }

  }, [appInfo, axiosInstance])

  return (<AppContext.Provider {...props} value={appContextValue} />)

}


export const useAppContext = () => {

  const value = useContext(AppContext);

  return { ...value }

}


export default AppContext;