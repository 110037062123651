import { trim } from 'lodash'; 



export function isNullOrWhitespace( input ) {

    if (typeof input === 'undefined' || input == null) return true;

    return trim(input) === '';
}

// required for antd form uploader component
export function normFile(e) {

    if (Array.isArray(e)) {
        return e;
    }

    return e && e.fileList;
};

export function getFileName(imagePath) {
    try {

        let fileName = '';

        const fileNameIndex = imagePath.lastIndexOf('/');


        if (fileNameIndex >= 0) {
            //console.log('image url', imagePath)
            console.log('file name', imagePath.substring(fileNameIndex + 1, imagePath.length))
            fileName = imagePath.substring(fileNameIndex + 1, imagePath.length);
        }

        return fileName;

    } catch (err) {
        console.log('error getting filename', err);
    }
}


export function getYoutubeVideoId(videoUrl = ''){
    try {

        var matches = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        if (matches != null) {
            //console.log("video id = ", matches[1]);
            return matches[1];
        }

    } catch (error) {
        console.log('error getting video id', error)
    }
  }