import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Mentions, Row, Typography } from "antd";
import { debounce, difference, filter, find, findIndex, get, intersection, isEmpty, join, map, split, startsWith, trim } from "lodash";
import LookupSelect from "../../../common/components/lookupSelect";
import { appLinks, organisationTypes, queryKeys } from "../../../config/constants";
import useOrganisationsLookups from "../../challenge/common/hooks/useOrganisationsLookups";
import OrganisationItems from "./OrganisationContainer";
import useHttpHelper from "../../../common/hooks/useHttpHelper";
import { useQuery } from "react-query";
import { validateEmail } from "../../../helpers/validators";
import TagChip from "../../../common/components/tagchip";
import { useAppContext } from "../../../common/components/appContext";

const { Option } = Mentions;
const { Text } = Typography;

// const borderStyle = {
//     borderWidth: 1,
//     borderStyle: 'solid',
//     borderColor: 'black'
// }

const shouldUpdateOrganisations = () => (prev, cur) => prev.organisations !== cur.organisations ||
    prev.organisationItems !== cur.organisationItems

const shouldUpdateUnmatched = () => (prev, cur) => prev.emails !== cur.emails ||
    prev.organisationItems !== cur.organisationItems

const getValidEmailsFromText = (commaDelimiatedEmailString = '') => {
    try {

        let emails = [];

        const splitEmails = split(commaDelimiatedEmailString, ',');

        for (let i = 0; i < splitEmails.length; i++) {
            let email = trim(splitEmails[i]);
            if (startsWith(email, '@')) {
                email = email.substring(1);
            }
            email && validateEmail(email) && emails.push(email);
        }

        return emails;

    } catch (error) {
        console.log('error getting valid emails from text', error);
    }
}

export default (props) => {

    const { sentNotification, formValues, isSending } = props;

    const [form] = Form.useForm();

    const [organisationId, setOrganisationId] = useState();

    useEffect(() => {

        //console.log('set field values', formValues);

        form.setFieldsValue(formValues);

        const initialOrganisationId = get(formValues, 'organisation.value');

        if (initialOrganisationId > 0) {
            setOrganisationId(initialOrganisationId);
        }

    }, [formValues])

    const {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    } = useOrganisationsLookups(undefined, organisationTypes.foundation, false);

    const onSubmitHandler = async (values) => {
        try {

            console.log('on submit', values);

            const { 
                message, 
                organisationItems = [], 
                organisation, 
                sendAll = false, 
                sendPushNotifications = false
            } = values;

            const orgId = organisation.value;

            const organisationItem = find(organisationItems, org => org.value === orgId);

            if (organisationItem) {

                let receipients = get(organisationItem, 'receipients') || [];

                // const recipentInfo = [{
                //     organisationId: orgId,
                //     type: organisationItem.sendAll ? 'organisation_all_users' : 'organisation_users',
                //     userIds: map(receipients, user => user.value),
                // }];

                const payload = {
                    message,
                    organisationId: orgId,
                    //type: sendAll ? 'organisation_all_users' : 'organisation_users',
                    sendAll,
                    sendPushNotifications,
                    userIds: map(receipients, user => user.value),
                }

                return await sentNotification(payload)

            }

            // for (let i = 0; i < organisations.length; i++) {
            //     const item = find(organisationItems, org => org.value === organisations[i].value);
            //     if (item) {
            //         let receipients = get(item, 'receipients') || [];
            //         recipentInfo.push({
            //             organisationId: item.value,
            //             type: item.sendAll ? 'all_users_app_organisation' : 'users_app_organisation',
            //             userIds: map(receipients, user => user.value),
            //         })
            //     }
            // }

            //

        } catch (error) {
            console.log('error on submit', error);
        }
    };

    const onValuesChange = (values) => {
        try {

            // console.log('onValuesChange', values);

        } catch (error) {
            console.log('error handling on values change', error);
        }
    }

    const onFinishFailed = (e) => {
        console.log('finish failed', e);
    }

    const onSendClick = async () => {
        try {

            const organisation = form.getFieldValue('organisation');
            const sendAll = form.getFieldValue('sendAll')

            if (!organisation) {
                await form.validateFields();
                console.log('validate and return');
                return;
            }

            const organisationItems = form.getFieldValue('organisationItems') || [];

            const item = find(organisationItems, o => o.value === organisation.value);

            if (sendAll !== true) {

                const recipients = get(item, 'receipients');

                if (isEmpty(recipients)) {
                    console.log('no recipients')
                    form.setFields([{
                        name: 'message',
                        errors: ['Please select some recipients']
                    }])
                    return
                }

            }

            form.submit();


        } catch (error) {
            console.log('error on send click', error);
        }

    }

    //#region mentions

    const { httpPostAsync } = useHttpHelper();

    const [searchParams, setSearchParams] = useState({ query: '', itemsPerPage: 15 });

    const { query = '' } = searchParams;

    const fetchUsersAsync =
        useCallback(async () => {

            console.log('fetch users with params', searchParams);

            const response = await httpPostAsync(appLinks.userLookups, { ...searchParams });

            console.log('users response', response)

            return get(response, 'data') || [];
        }, [httpPostAsync, searchParams]);

    const deboundedSearch = useCallback(debounce(q => setSearchParams(p => ({ ...p, query: q })), 300), [])

    const onSearch = (e) => {
        // console.log('on search', e);
        deboundedSearch(e);
    }

    const { data = [], isLoading } = useQuery([queryKeys.userLookups, query], fetchUsersAsync, { refetchOnWindowFocus: false });

    const onEmailsChange = (e) => {
        try {

            //console.log('emails change', e)

        } catch (error) {
            console.log('on emails change', error);
        }
    }

    //#endregion

    const onOrganisationChangeHandler = (e) => {
        try {

            if (e && !Array.isArray(e)) {

                setOrganisationId(e.value);
                // fetch users for mentions, for emails list text
                setSearchParams(p => ({ ...p, organisations: [e.value] }));

                const organisationItems = form.getFieldValue('organisationItems') || [];

                // console.log('organisation items', organisationItems)

                const item = find(organisationItems, o => o.value === e.value);

                let list = organisationItems;

                if (!item) {
                    console.log('new item')
                    list.push({ ...e, sendAll: false })
                }

                //console.log('updated list', list);
                //updating challenge view list
                form.setFieldsValue({ organisationItems: list });

                // update email comma delimited list,
                const recipients = get(item, 'receipients');
                let recipientEmails = filter(map(recipients, r => r.email), e => !!e);

                if (!isEmpty(recipientEmails)) {
                    // let emails = filter(map(recipients, r => r.email), e => !!e);
                    const delimietedEmails = join(recipientEmails, ', ') || ''
                    form.setFieldsValue({ emails: delimietedEmails });
                } else {
                    form.setFieldsValue({ emails: '' });
                }

                console.log('emails', recipientEmails)
            }

        } catch (error) {
            console.log('error in on change organisations in create edit', error)
        }
    }

    const parsedEmailArrayRef = useRef([]);

    //  const [userEmailsKey, setUsersEmailKey] = useState([queryKeys.userEmails, organisationId, ''])

    const [recipientsEmailsList, setRecipientEmailsList] = useState([]);

    const deboundedUsersEmailsSearch = useCallback(debounce(async emails => {


        const postData = {
            recipients: parsedEmailArrayRef.current
        }

        const link = appLinks.getRecipientsFromEmail.replace('{organisationId}', organisationId)

        const response = await httpPostAsync(link, postData);

        console.log('users from email response', response)

        const recipients = get(response, 'data') || [];

        setRecipientEmailsList(recipients)

        //  console.log('debouned serach', organisationId, emails)
        // return setUsersEmailKey([queryKeys.userEmails, organisationId, join(emails, '.')])

    }, 500), [organisationId])

    // const getRecipientsFromEmailAsync = async () => {
    //     try {

    //         //console.log('fetching users with emails', emails);

    //         const postData = {
    //             recipients: parsedEmailArrayRef.current
    //         }

    //         const link = appLinks.getRecipientsFromEmail.replace('{organisationId}', organisationId)

    //         const response = await httpPostAsync(link, postData);

    //         console.log('users from email response', response)

    //         return get(response, 'data') || [];

    //     } catch (error) {
    //         console.log('error getting recipients from email', error);
    //     }
    // }

    // const { data: emailUsers = [], isLoadingEmails } = useQuery(
    //     userEmailsKey,
    //     getRecipientsFromEmailAsync,
    //     {
    //         refetchOnWindowFocus: false,
    //         enabled: organisationId > 0
    //     }
    // );

    const onEmailTextChange = async (e) => {
        try {

            //console.log('on email text change', e);
            let parsedEmailArray = getValidEmailsFromText(e);

            let organisationItems = form.getFieldValue('organisationItems') || [];

            const itemIndex = findIndex(organisationItems, o => o.value === organisationId);

            if (itemIndex >= 0) {

                const recipients = get(organisationItems[itemIndex], 'receipients');
                let recipientEmails = filter(map(recipients, r => r.email), e => !!e);

                if (parsedEmailArray.length !== recipientEmails.length) {
                    console.log('emails not equal,  trigger validation')
                    parsedEmailArrayRef.current = parsedEmailArray;
                    await deboundedUsersEmailsSearch(parsedEmailArray)
                    return;
                }

                const emailsUnion = intersection(parsedEmailArray, recipientEmails);

                console.log('recipient emails', emailsUnion.length, parsedEmailArray.length, recipientEmails.length);

                if (emailsUnion.length !== parsedEmailArray.length) {
                    await deboundedUsersEmailsSearch(parsedEmailArray)
                    console.log(' union not equal, need to validate entered emails')
                }

            }

            console.log('emails lists from text', parsedEmailArray)

        } catch (error) {
            console.log('error on email text change', error);
        }
    }

    useEffect(() => {
        try {

            // console.log('set user from reciepient list', setRecipientsFromEmailList.current)

            console.log('setting receipients from email text list', recipientsEmailsList);

            //organisations
            const organisation = form.getFieldValue('organisation');
            const orgId = organisation.value;

            const organisationItems = form.getFieldValue('organisationItems') || [];
            // console.log('organisationItems', organisationItems)
            //const item = find(organisationItems, o => o.value === orgId);
            const itemIndex = findIndex(organisationItems, o => o.value === orgId);
            console.log('found index', itemIndex)
            if (itemIndex >= 0) {
                organisationItems[itemIndex] = {
                    ...organisationItems[itemIndex],
                    receipients: map(recipientsEmailsList, u => ({ ...u, key: `${u.value}.${u.email}` })),
                }

                form.setFieldsValue({ organisationItems: [...organisationItems] });
                //     setRecipientsFromEmailList.current = false;
            }

            // find unmatched emails




        } catch (error) {
            console.log('error setting recipients', error);
        }
    }, [recipientsEmailsList])

    const messageCursorPosnRef = useRef(0);
    const messageInputRef = useRef();

    return (
        <div>
            <Card>
                <Form
                    layout='vertical'
                    form={form}
                    scrollToFirstError
                    onFinish={onSubmitHandler}
                    onValuesChange={onValuesChange}
                    onFinishFailed={onFinishFailed}
                    name='send-app-notifications'
                >
                    <Form.Item name="organisationItems" hidden>
                        <Input type="hidden" />
                    </Form.Item>
                    <Row>
                        <Col span={12} style={{ paddingRight: 24 }}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Notification message"
                                        name="message"
                                        rules={[
                                            { required: true, message: 'Notificaiton message is required.', whitespace: true },
                                            { max: 500, message: 'Maximum 500 characters allowed.' },
                                        ]}
                                    //wrapperCol={{ span: 16 }}
                                    >
                                        <Input.TextArea 
                                        ref={messageInputRef} 
                                        rows={5} 
                                        placeholder="Notifiation message" 
                                        data-testid="notification-message-input"
                                        onBlur={e => {
                                            try {
                                                console.log('on blur', e.target.selectionStart);
                                                messageCursorPosnRef.current = e.target.selectionStart;
                                            } catch (error) {
                                                console.log('error getting cursor posn', error)
                                            }
                                          
                                        }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Button onClick={e => {
                                    try {

                                        const existingMessage = form.getFieldValue('message');

                                       // console.log('exising message', existingMessage);

                                        let updatedMessage = ''

                                        if (existingMessage && existingMessage.length > 0) {

                                            const beforeCursor = existingMessage.substring(0, messageCursorPosnRef.current || 0);

                                            const afterCursor = existingMessage.substring(messageCursorPosnRef.current || 0);

                                            //console.log('before cursor', beforeCursor, afterCursor)

                                            updatedMessage = `${beforeCursor || ''}{userName}${afterCursor || ''}`

                                        } else {
                                            updatedMessage = `${existingMessage || ''} {userName}`
                                        }


                                        messageInputRef.current.focus({ start: messageCursorPosnRef.current || 0 });

                                        form.setFieldsValue({ message: updatedMessage })
                                        
                                    } catch (error) {
                                        console.log('error setting userName merge field', error)
                                    }


                                }} type="ghost" style={{ marginBottom: 24 }}>User name</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={`Emails ${!organisationId > 0 ? ' (Select Organisation to enable)' : ''}`}
                                        name="emails"
                                    >
                                        <Mentions
                                            disabled={!(organisationId > 0)}
                                            rows={5}
                                            loading={isLoading}
                                            onSearch={onSearch}
                                            filterOption={false}
                                            prefix={['@']}
                                            split=","
                                            onSelect={onEmailsChange}
                                            onChange={onEmailTextChange}
                                        >
                                            {map(data, ({ value, label, email }) => (
                                                <Option key={value} value={email}
                                                //className="antd-demo-dynamic-option"
                                                >
                                                    {`${label} (${email})`}
                                                </Option>
                                            ))}
                                        </Mentions>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row>
                                <Form.Item shouldUpdate={shouldUpdateUnmatched}>
                                    {props => {

                                        const emailText = props.getFieldValue('emails');
                                        let parsedEmailArray = getValidEmailsFromText(emailText);

                                        let nonMatched = []

                                        const organisation = props.getFieldValue('organisation');
                                        if (organisation) {
                                            try {
                                                const orgId = organisation.value;

                                                const organisationItems = props.getFieldValue('organisationItems') || [];
                                                // console.log('organisationItems', organisationItems)
                                                //const item = find(organisationItems, o => o.value === orgId);
                                                const item = find(organisationItems, o => o.value === orgId);
    
                                                console.log('found organisation', item)
        
                                                let recipients = get(item, 'receipients') || [];
                                                recipients = map(recipients, r => r.email);
    
                                                nonMatched = difference(parsedEmailArray, recipients);
    
                                                console.log('non matched', nonMatched)
                                            } catch (error) {
                                                console.log('error getting email recipient different', error);
                                            }
                                       
                                        }

                                        return (
                                        <div>
                                            {
                                                nonMatched.length ?
                                                <div><Text>Recipients not found for:</Text></div>
                                                : null
                                            }
                                            
                                            {
                                                map(nonMatched, email => {

                                                    return (
                                                        <TagChip>{email}</TagChip>
                                                    )

                                                })
                                            }
                                        </div>)

                                    }}
                                </Form.Item>
                            </Row>
                            <Row>
                                <Col>
                                    <Button 
                                    data-testid="notification-send-btn"
                                    onClick={onSendClick}
                                    >Send</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="Organisations"
                                        name="organisation"
                                        rules={[{ required: true, message: 'Organisations are required' }]}
                                    >
                                        <LookupSelect
                                            placeholder="Select Organisation"
                                            //disabled={organisationsDisabled}
                                            data-testid="notification-org-select"
                                            queryParams={organisationParams}
                                            fetchAsync={fetchOrganisationsAsync}
                                            queryKeyFn={organisationQueryKeyFn}
                                            lookupId='organisation'
                                            labelInValue
                                            //mode="multiple"
                                            // tagRender={Empty}
                                            onChange={onOrganisationChangeHandler}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Item name="sendAll"  valuePropName="checked" >
                                        <Checkbox style={{ paddingBottom: 12 }}>Send all users</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="sendPushNotifications" valuePropName="checked" >
                                        <Checkbox style={{ paddingBottom: 12 }}>Send push notifications</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item shouldUpdate={shouldUpdateOrganisations}>
                                        {props => <OrganisationItems {...props} />}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    )

}

