import React, { useCallback, useState } from 'react';
import { Input } from 'antd';
import { debounce } from 'lodash';

const { Search } = Input;


export default (props) => {

    const { onSearch, initialValue, ...rest } = props;

    const [searchValue, setSearchValue] = useState(initialValue);

    const onSearchChange = async (e) => {
        //console.log('debounded on change', e.target.value);
        setSearchValue(e.target.value);
        debouncedSearch(e.target.value);
    }

    const onSearchDebounced = (e) => {
        onSearch && onSearch(e);
    }

    const debouncedSearch = useCallback(debounce(onSearchDebounced, 300), []);

    return (
        <Search
            value={searchValue}
            onChange={onSearchChange}
            {...rest}
        />
    );

}