import { Select } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

const { Option } = Select;

const enabledDefault = () => true;

function defaultItemPropMapper({ value, label }) {
    return {
        key: value,
        value,
        children: label,
    }
}

export default (props) => {

    const { 
        queryParams = {}, 
        fetchAsync, 
        queryKeyFn, 
        enabledFn = enabledDefault, 
        lookupId, 
        itemPropsMapperFn,
        setCurrentSelectItems,
        ...rest 
    } = props;

    const fetchDataAsync = async () => {
        try {
            const data = await fetchAsync(queryParams);
            //console.log(`lookup response ${lookupId}`, data);
            return data;
        } catch (error) {
            console.log('error calling fetch for lookup', lookupId, error);
        }
    }

    const queryKey = useMemo(() => queryKeyFn(queryParams), [queryParams]);

    const queryOptions = useMemo(() => {
        try {
            let enabled = false

        if (typeof enabledFn === 'function') {
            enabled = enabledFn && enabledFn(queryParams) || false;
        } 

        return {  refetchOnWindowFocus: false, enabled }
        } catch (error) {
            console.log('error in getting query options')
            return false;
        }
      

    }, [queryParams]);

    const { data = [], isLoading } = useQuery(queryKey, fetchDataAsync, queryOptions);

    typeof setCurrentSelectItems === 'function' && setCurrentSelectItems(data)

    const itemPropsMapper = useCallback((props) => {

        return  typeof itemPropsMapperFn === 'function' 
        ? itemPropsMapperFn(props)
        : defaultItemPropMapper(props);

    }, [itemPropsMapperFn])

    return (
        <div>
            <Select loading={isLoading} { ...rest } >
                {
                    Array.isArray(data) && data?.map((item) => {
                        return (
                            <Option { ...itemPropsMapper(item) } />
                        )
                    })
                }
            </Select>
        </div>)

}