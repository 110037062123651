import RelatedActivities from './RelatedActivities';


export const PrimaryRelatedToFieldName = 'isPrimaryRelatedActivity'
export const PrimaryRelatedToActivityFieldName = 'primaryRelatedActivity'

export const RelatedToActivitiesFieldName = 'relatedTo'
export const RelatedToListFieldName = 'relatedToList'

const RelatedTo = (props) => {

    const {
        organisationId,
        form,
    } = props;

    return (
        <div>
            {/* <div>
                <PrimaryActivity
                    organisationId={organisationId}
                    challengeId={challengeId}
                    form={form}
                />
            </div>
            <Divider /> */}
            <div>
                <RelatedActivities 
                 organisationId={organisationId}
                 form={form}
                />
            </div>
        </div>
    )

}

export default RelatedTo;