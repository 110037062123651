import React from 'react';
import { Col, Form, Radio, Row, Typography, Divider, Modal, Button } from 'antd';
import { filter, map } from 'lodash';
import { creatorTypes } from '../../../../../config/constants';
import { PoweredByItem } from '../../creators/poweredBy';
import CreatorEditView from './editViews';
import CreateView from './views';
import PoweredBy from './editViews/poweredBy';

import { DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography;

const creatorTypeOptions = [
    { label: 'Creator', value: creatorTypes.creator },
    { label: 'Organisation', value: creatorTypes.organisation },
    { label: 'Club', value: creatorTypes.club },
    { label: 'Text', value: creatorTypes.text },
];

export default ({ organisationId, form }) => {

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Text>Creator info</Text>
                    <Form.Item
                        name="creatorType"
                        label=""
                    >
                        <Radio.Group
                            defaultValue='creator'
                            options={creatorTypeOptions}
                            buttonStyle="solid"
                            optionType="button"
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate={(prev, cur) => prev.creatorType !== cur.creatorType}>
                        {(props) => {

                            const type = props.getFieldValue('creatorType');

                            return (<CreatorEditView type={type} organisationId={organisationId} form={form} />)

                        }}
                    </Form.Item >
                </Col>
                <Col span={12}>
                    <Form.Item shouldUpdate={(prev, cur) =>
                    (
                        prev.creatorType !== cur.creatorType ||
                        prev.creatorOrganisationId !== cur.creatorOrganisationId ||
                        prev.creatorId !== cur.creatorId
                    )}>
                        {(props) => {

                            const type = props.getFieldValue('creatorType');

                            return (
                                <div style={{ paddingTop: 12 }}>
                                    <CreateView type={type} form={form} />
                                </div>)

                        }}
                    </Form.Item >
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col span={12}>
                    <PoweredBy organisationId={organisationId} form={form} />
                </Col>
                <Col span={12}>
                    <Form.Item shouldUpdate={(prev, cur) => prev.poweredByOrganisations !== cur.poweredByOrganisations}>
                        {(props) => {

                            const items = props.getFieldValue('poweredByOrganisations');

                            const onClose = (itemId, label) => {

                                Modal.confirm({
                                    title: `Are you sure you want to remove ${label}`,
                                    okText: 'Remove',
                                    closable: true,
                                    onOk: () => {
                                        console.log('on delete click');

                                        const items = props.getFieldValue('poweredByOrganisations');

                                        //console.log('powered by', itemId, items);

                                        const filtered = filter(items, o => o.value !== itemId);

                                        //console.log('filtered themes', filtered)

                                        props.setFieldsValue({ poweredByOrganisations: filtered })

                                    }

                                });

                            }

                            return (
                                map(items, (item, index) => {
                                    //console.log('powered by item', index, item);
                                    return (
                                        <div key={item.value} >
                                            {index > 0 ? (<Divider />) : null}
                                            <Row justify='space-between'>
                                                <Col span={12}>
                                                    <PoweredByItem {...item} />
                                                </Col>
                                                <Col>
                                                    <Button
                                                        //type='primary'
                                                        shape='circle'
                                                        onClick={() => {

                                                            onClose(item.value, item.label)
                                                        }}
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                    />
                                                </Col>
                                            </Row>

                                        </div>)
                                })
                            )
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </div>)
}