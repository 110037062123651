import { List, Skeleton } from "antd";
import styles from './RelatedToView.module.scss'

const RelatedToView = (props) => {

    const {
        primaryRelatedActivity,
        relatedActivities,
    } = props;

    return (
        <div>
            <p>Related Activitie(s)</p>
            <List
                dataSource={relatedActivities}
                renderItem={(item) => {

                    return (
                        <List.Item>
                            <Skeleton loading={false}>
                                <List.Item.Meta

                                    title={<p>{item?.label}</p>}
                                    description={
                                        <div className={styles.itemPrimaryContainer}>
                                            <p>{item.organisation}</p>
                                            <p>{item.isPrimary ? 'Primary' : null}</p>

                                        </div>
                                    }
                                />
                            </Skeleton>
                        </List.Item>
                    )

                }}
            />
        </div>
    )
}

export default RelatedToView;