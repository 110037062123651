import React, { useState, useMemo } from 'react';
import {
    Button, Form, Input, Select, Divider, Typography, Modal, Row, Col
} from 'antd';
import { get, map, max, maxBy } from 'lodash';

import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { resourceType } from '../../../../../config/constants';
import ResourceFactoryView from './views';
import './style.css';

const { confirm } = Modal;
const { Text } = Typography;
const { Option } = Select;




const resourceTypes = [
    { key: resourceType.link, label: 'Link' },
    { key: resourceType.pdf, label: 'PDF' },
    { key: resourceType.youtubeVideo, label: 'Youtube Link' },
    { key: resourceType.image, label: 'Image' },
    // { key: resourceType.viewDownload, label: 'Doc link & download' },
]

const ResourceItem = (props) => {

    const { index, field, remove, form } = props;

    const [resourceType, setResourceType] = useState();

    // const type = useMemo(() => {
    //     try {

    //         const resources = form.getFieldValue('resources');
    //         const _resourceType = get(resources, `[${index}].type`)
    //         console.log('resource type', _resourceType)

    //         return _resourceType;

    //     } catch (error) {
    //         console.log('error getting resource type', error);
    //         return null;
    //     }


    // }, [field, index, form, resourceType])


    return (
        <React.Fragment>
            {index > 0 ? <Divider /> : null}
            <Form.Item
                name={[index, "id"]}
                hidden
            >
                <Input hidden />
            </Form.Item>
            <Row justify='space-between' >
                <Col span={12}>
                    <Text>{`Resource ${index + 1}`}</Text>
                </Col>
                <Col>
                    <Button
                        //type='primary'
                        shape='circle'
                        onClick={() => {

                            confirm({
                                title: `Are you sure remove this resource ${index + 1}?`,
                                icon: <ExclamationCircleOutlined />,
                                content: 'This resource will be removed permently after save.',
                                okText: 'Remove',
                                okType: 'danger',
                                cancelText: 'No',
                                onOk() {

                                    console.log('Remove', index,);
                                    remove(index);
                                },
                                onCancel() {
                                    console.log('Cancel');
                                },
                            });

                        }}
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Col>
            </Row>
            <Form.Item
                name={[index, "type"]}
                label='Type'
                rules={[{ required: true, message: 'Please choose an resource type.' }]}
            >
                <Select
                    //mode="multiple"
                    //allowClear
                    style={{ width: 200 }}
                    placeholder="Type"
                    onChange={(value) => {
                        console.log('on type select', value)
                        setResourceType(value);
                    }}
                >
                    {
                        map(resourceTypes, s => {
                            return (
                                <Option key={s.key} value={s.key}>{s.label}</Option>
                            )
                        })
                    }
                </Select>
            </Form.Item>
            <Form.Item
                name={[index, "name"]}
                label='Display Name'
                tooltip='This is the name/description of the resource'
                rules={[{ required: true, message: 'Resource name is required.' }]}
            >
                <Input placeholder='Display name' max={100} showCount />
            </Form.Item>
            <Form.Item shouldUpdate={(prev, cur) => {

                // const resources = form.getFieldValue('resources');
                // const _resourceType = get(resources, `[${index}].type`)
                // console.log('resource type', _resourceType)

                //prev.resources !== cur.resources

                const prevResourceType = get(prev.resources, `[${index}].type`);
                const curResourceType = get(cur.resources, `[${index}].type`);

                //console.log('prev, cur', prev.resources, cur.resources);

                return prevResourceType !== curResourceType;

            }}>
                {(props) => {

                    const resources = props.getFieldValue('resources');
                    const type = get(resources, `[${index}].type`)
                    // console.log('resource type', _resourceType)

                    return (<ResourceFactoryView index={index} form={form} type={type} />)

                }}
            </Form.Item>

        </React.Fragment>)

}
//style={{ borderColor: 'red', borderWidth: 1, borderStyle: 'solid' }}
export default ({ form }) => {

    return (
        <div className="edit-resources-container">
            <Form.List name='resources'>
                {(fields, { add, remove }) => {

                    return (
                        <div>
                            <div style={{ minHeight: 300 }}>
                                {
                                    map(fields, (field, index) => {
                                        return (
                                            <ResourceItem
                                                key={index}
                                                index={index}
                                                field={field}
                                                remove={remove}
                                                form={form}
                                            />)
                                    })
                                }
                            </div>
                            <Row justify='center' >
                                <Button
                                    type="primary"
                                    //shape="circle"
                                    onClick={() => {

                                        const resources = form.getFieldValue('resources') || [];
                                        let sortOrders = map(resources, i => i.sortOrder || 0);

                                        const maxSortOrder = max(sortOrders);

                                        console.log('max sortorder', maxSortOrder || resources.length);

                                        add({ type: resourceType.link, sortOrder: maxSortOrder || resources.length })
                                    }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Resource
                                </Button>
                            </Row>
                        </div>
                    )
                }}
            </Form.List>
        </div>
    )
}