import { Button, Card, Form, Input, Modal } from "antd"
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export const linkFieldName = 'sectionLinks'

const LinkItems = (props) => {

    const {
        getLinks,
        field,
        form,
    } = props

    const getUniqueId = () => {
        try {

            //const items = form.getFieldValue('emailSections')

            //console.log('sectins', items)

            const items = getLinks()

            console.log('links', items)

            let timeStamp = new Date().getTime();
            let index = 0
            for (; index < 100; index++) {
                const elem = items?.find(e => e.uid === timeStamp)
                if (!elem) {
                    break;
                }
                timeStamp = new Date().getTime();
            }

            console.log('paras', items)

            return timeStamp;

        } catch (error) {
            console.log('error getting unique id', error)
        }
    }

    return (
        <div style={{
            marginBottom: 16
        }}>
            <Form.List name={[field.name, linkFieldName]}>
                {(linkFields, { add, remove, move }) => {


                    // console.log('para items', fields?.length)

                    return (
                        <div>
                            {
                                linkFields?.map((paraField, index) => {

                                   // console.log('para', paraField.name, index)

                                    const onRemove = () => {
                                        try {

                                            confirm({
                                                title: `Are you sure remove this link ${index + 1}?`,
                                                icon: <ExclamationCircleOutlined />,
                                                content: 'This link will be removed permently after save.',
                                                okText: 'Remove',
                                                okType: 'danger',
                                                cancelText: 'No',
                                                onOk() {

                                                    console.log('Remove', paraField.name,);
                                                    remove(paraField.name);
                                                },
                                                onCancel() {
                                                    console.log('Cancel');
                                                },
                                            });


                                        } catch (error) {
                                            console.log('error removing step', error);
                                        }
                                    }

                                    return (
                                        <div key={index}
                                            className="link-item"
                                        >
                                            <Button
                                                className="delete-btn"
                                                shape='circle'
                                                onClick={() => {
                                                    onRemove && onRemove();
                                                }}
                                                danger
                                                icon={<DeleteOutlined />}
                                            />
                                            <Card className="link-card">
                                            <Form.Item
                                                label={`Link Title ${index + 1}`}
                                                {...paraField}
                                                name={[paraField.name, "title"]}
                                                key={1}

                                            >
                                                <Input placeholder="Title" />
                                            </Form.Item>
                                            <Form.Item
                                                className="para-text-input"
                                                label={`Link`}
                                                {...paraField}
                                                name={[paraField.name, "link"]}
                                                key={2}

                                            >
                                                <Input placeholder="Link" />
                                            </Form.Item>
                                            </Card>
                                        </div>
                                    )

                                })
                            }
                            <Button
                                type="primary"
                                onClick={() => {

                                    const uid = getUniqueId()

                                    console.log('uid')

                                    add({
                                        uid,
                                        link: '',
                                        title: '',
                                    })

                                }}
                                icon={<PlusOutlined />}
                            >
                                Add Links
                            </Button>
                        </div>
                    )

                }}
            </Form.List>
        </div>
    )

}


export default LinkItems