import { Select } from "antd";

import { appLinks, queryKeys } from "../../../config/constants";
import EmptyDisplayComponent from "../Empty";
import useLookupsSearch from "./useLookupsSearch";
import { useMemo } from "react";

const { Option } = Select

const ThemesLookup = (props) => {

    const {
        organisationId,
        ...rest
    } = props

    const { 
        extraQueryParams, 
        queryKey 
    } = useMemo(() => {

        const queryKey = [queryKeys.themes]

        let extraQueryParams = {}

       if (organisationId > 0) {
        queryKey.push(organisationId)
        extraQueryParams = {
            ...extraQueryParams,
            organisationId
        }
       }

       return {
        extraQueryParams,
        queryKey
       };

    }, [organisationId])
 
    const { 
        data = [],
        isLoading,
        onSearch,
        onBlur,
    } = useLookupsSearch(
        appLinks.themes,
        queryKey,
        extraQueryParams
    )

    return (
        <Select
            mode="multiple"
            labelInValue
            tagRender={EmptyDisplayComponent}
            loading={isLoading}
            // search related
            filterOption={false} // needed to pick items updated after search
            showArrow
            onSearch={onSearch}
            onBlur={onBlur}
            {...rest}
        >
            {
                data?.map(item => (<Option key={item.value} value={item.value}>{item.label}</Option>))
            }
        </Select>
    )

}

export default ThemesLookup;