import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const usersRolesFilterConfig = {
    key: 'userRoles',
    isActiveProp: 'isUserRolesSearchActive',
    setAction: 'user-roles',
    resetAction: 'reset-user-roles',
    setFilterProp: 'setUserRolesFilters',
    resetFilterProp: 'resetUserRolesFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useUserRolesFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, usersRolesFilterConfig)
}

export default useUserRolesFilterContext;
