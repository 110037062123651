import { Col, Row, Table, Tag } from 'antd';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


export default (props) => {

    const {
        skills = [],
        onPagniationChange,
        onSortChange,
        onDelete,
        pageNo,
        total,
        defaultPageSize,
        showEditModal,
        sortBy,
        sortOrder,
        hasEditAccess = false,
        hasDeleteAccess = false,
    } = props;

    //let navigate = useNavigate();

    const columns = useMemo(() => {

        let titleCol = { defaultSortOrder: undefined }

        const defaultSortOrder = sortOrder === 'ascend' ? 'ascend' : 'descend';

        if (sortBy === 'title') {
            titleCol = { defaultSortOrder }
        }

        return [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                sorter: true,
                ...titleCol,
            },
            {
                title: 'Color',
                dataIndex: 'color',
                key: 'color',
                render: (text, record) => {

                    return (
                        <div>
                            {record.color ? <Tag color={record.color}>{record.color}</Tag> : null}
                        </div>
                    )
                },
            },
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                //render: (text, record) => <span>{record.name}</span>
                render: (text, record) => {

                    return (
                        <a
                        data-testid='edit-skill'
                        style={hasEditAccess && record.canEdit  ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                        data-can-edit={hasEditAccess && record.canEdit ? 'yes' : ''}
                            onClick={() => {
                                hasEditAccess && record.canEdit && showEditModal && showEditModal({
                                    id: record.id,
                                    title: record.title,
                                    color: record.color,
                                });
                            }}>
                            Edit
                        </a>
                    )
                },
            },
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                //render: (text, record) => <span>{record.name}</span>
                render: (text, record) => {

                    return (
                        <a
                        data-testid='delete-skill'
                        style={hasDeleteAccess && record.canDelete ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                        data-can-delete={hasDeleteAccess && record.canDelete ? 'yes' : ''} 
                        onClick={() => {
                            hasDeleteAccess && record.canDelete && onDelete && onDelete(record.id, record.title)
                        }}>
                            Delete
                        </a>
                    )
                },
            },
        ]
    }, [sortBy, sortOrder, hasEditAccess, hasDeleteAccess, showEditModal]);


    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }

    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{
                    //padding: '12px',
                    paddingTop: '0px'
                }}>
                    <Table
                        columns={columns}
                        dataSource={skills}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}