import { useContext } from "react";
import { get } from "lodash";
import PermissionContext, { childPermission, parentPermissions, permissionValues, settingsChildPermissions } from ".";


export default function useClubPermissionContext() {

    const { permissions = {} } = useContext(PermissionContext);

    console.log('club challenges contex: challenges', permissions.clubs)

    const hasClubsAccess = get(permissions, `${parentPermissions.clubs}`) === permissionValues.yes;

    const listPermissionKey = `${parentPermissions.clubs}.${childPermission.list}`;
    const hasListAccess = hasClubsAccess &&
    get(permissions, listPermissionKey, permissionValues.no) !== permissionValues.no;

    const detailsPermissionKey = `${parentPermissions.clubs}.${childPermission.details}`;
    const hasDetailAccess = hasClubsAccess &&
    get(permissions, detailsPermissionKey, permissionValues.no) !== permissionValues.no;

    const createPermissionKey = `${parentPermissions.clubs}.${childPermission.create}`;
    const hasCreateAccess = hasClubsAccess &&
    get(permissions, createPermissionKey, permissionValues.no) !== permissionValues.no;

    const editPermisssionKey = `${parentPermissions.clubs}.${childPermission.edit}`;
    const hasEditAccess = hasClubsAccess &&
    get(permissions, editPermisssionKey, permissionValues.no) !== permissionValues.no;

    const deletePermissionKey =  `${parentPermissions.clubs}.${childPermission.delete}`;
    const hasDeleteAccess = hasClubsAccess &&
    get(permissions, deletePermissionKey, permissionValues.no) !== permissionValues.no;

    return {
        hasListAccess,
        hasDetailAccess,
        hasCreateAccess,
        hasEditAccess,
        hasDeleteAccess,
    }

}