import { Form } from "antd"
import useCreatorsLookups from '../useCreatorsLookups';
import LookupSelect from "../../../../../../common/components/lookupSelect";


function defaultItemPropMapper({ value, label, logoLink, website, title }) {
    return {
        key: value,
        value,
        link: logoLink,
        website,
        name: label,
        children: label,
        title,
    }
}

export default ({ organisationId, form }) => {

    console.log(' creator organisation id', organisationId);

    const {
        creatorsParams,
        fetchCreatorsAsync,
        enableCretorsFetchFn,
        creatorsQueryKeyFn
    } = useCreatorsLookups(organisationId, true);

    const onChange = (e, params) => {
        try {

            console.log('on creator select', e, params)

            if (params) {
            const creatorPerson = {
                key: params.key,
                id: params.value,
                name: params.name,
                logoLink: params.link,
                title: params.title,
                website: params.website,
            }

             form.setFieldsValue({ creatorPerson })

            } else {
                form.setFieldsValue({ creatorPerson: undefined })
            }


        } catch (error) {
            console.log('error on creator person on change', error)
        }

    }

    return (
        <Form.Item
            name="creatorId"
            label="Creator"
            //rules={[{ required: true, message: 'Please choose an creator.' }]}
            wrapperCol={{ span: 16 }}
        >
            <LookupSelect
                placeholder="Select Creator"
                queryParams={creatorsParams}
                fetchAsync={fetchCreatorsAsync}
                queryKeyFn={creatorsQueryKeyFn}
                enabledFn={enableCretorsFetchFn}
                lookupId='creators-creator'
                allowClear
                onChange={onChange}
                itemPropsMapperFn={defaultItemPropMapper}
            />
        </Form.Item>
    )


}