import { List } from "antd";
import { ListBanner, MainBanner } from "../common/createEdit/banner";



const BannerView = ({ oneValues, oneAreas, title }) => {

   console.log('one values', oneValues);
   console.log('one oneAreas', oneAreas);

    const character = oneValues?.[0]?.character;
    const imageUrl = oneAreas?.[0]?.imageUrl;
    const listImageUrl = oneAreas?.[0]?.listImageUrl;

    return (
        <div className="banner-view">
            <div className="values-container">
                <MainBanner character={character} title={title} imageUrl={imageUrl} />
                <span className="values-title">Values</span>
                {
                    oneValues?.map((e, index) => {
                        return (
                            <div key={e.id} className="value-item">
                                <List.Item>
                                    <List.Item.Meta
                                        // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={<span>{e.title} {index === 0 ? '(Primary)' : ''}</span>}
                                        description={e.character}
                                    />

                                </List.Item>
                                <span>Score: {e.score ?? ''}</span>
                            </div>)
                    })
                }
            </div>
            <div className="areas-container">
               <ListBanner character={character} title={title} imageUrl={listImageUrl} />
               <span className="areas-title">Areas of develoment</span>
                {
                    oneAreas?.map((e, index) => {
                        return (
                            <div key={e.id} className="areas-item">
                                <List.Item>
                                    <List.Item.Meta
                                        // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={<span>{e.title} {index === 0 ? '(Primary)' : ''}</span>}
                                    />

                                </List.Item>
                                <span>Score: {e.score ?? ''}</span>
                            </div>)
                    })
                }
            </div>
        </div>
    )

}

export default BannerView;