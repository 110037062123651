import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, message, Row, Spin } from 'antd';
import styled from 'styled-components';
import AppLayout from '../../../common/components/layout';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import Organisation from '../common/createEdit';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { get } from 'lodash';

const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;

const initialValues = { isActive: true, showPopular: true }

export default () => {

  const navigate = useNavigate();

  const { httpPostAsync } = useHttpHelper();

  const createOrganisationAsync = async (data) => {
    //const link = appLinks.createNewPerons
    // console.log('accept challenge link', link);
    console.log('create organisation post data', data);
    const createOrganisationData = { ...data };
    const response = await httpPostAsync(appLinks.createNewOranisation, createOrganisationData);
    console.log('create organisation response', response);

    return response;
  };

  const mutationQueryKey = [queryKeys.createNewOranisation]

  const {
    //data: createPersonResponse,
    isLoading: isCreatingOrganisation,
    mutateAsync: onCreateOrganisation,
  } = useMutation(
    createOrganisationAsync,
    { mutationKey: mutationQueryKey, }
  );


  const onSubmit = async (postData, redirect = false) => {
    try {

      console.log('postData', postData);

      const response = await onCreateOrganisation(postData);

      if (response && response.code === 0) {
        message.success('Organisation was created successfully');
     
        if (redirect) {
          // save and continue, => redirect to challenges listing
          navigate(`/${appRoutes.organisations}`);
        } else {
           //save/create new => created new and redirect to edit
          const userId = get(response, 'data.id');
          if (userId > 0) {
             navigate(`/${appRoutes.editOrganisation}/${userId}`)
         
          }
        }

      } else if (response && response.code === 12) {
        message.error('Organisation with the email address already exists')
      }

    } catch (error) {
      console.log('error submitting', error);
    }
  }

  const onBack = () => {
    try {

      navigate(`/${appRoutes.organisations}`);

    } catch (error) {
      console.log('error on back click', error);
    }
  }


  return (
    <AppLayout activeItem='org'>
      <Spin spinning={isCreatingOrganisation}>
        <Row align="middle" style={{paddingBottom:8}} >
          <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
          <Breadcrumb style={{ margin: 8, marginLeft: 12 }} >
            <Breadcrumb.Item><a onClick={onBack}>Organisations</a></Breadcrumb.Item>
            <Breadcrumb.Item>New Organisation</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <ContainerDiv>
          <Organisation
            formValues={initialValues}
            onSubmit={onSubmit}
            submitTitle='Save'
          />
        </ContainerDiv>
      </Spin>
    </AppLayout>
  );

}