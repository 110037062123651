import { Button, Col, Input, Row, Spin } from 'antd';
import { debounce, filter, get, join, map } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import TagChip from '../../../common/components/tagchip';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import SentNotificationsTable from './Table';
import useNotificationsFilterContext from './useNotificationsFilterContext';
import Dashboard from '../../../common/components/dashboard';

const { Search } = Input;

const pageSize = 10;

export default () => {

  const navigate = useNavigate();

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useNotificationsFilterContext();

  const {
    pageNo,
    query,
    sortBy,
    sortOrder,
    organisations = [],
  } = searchParams;

  const [searchValue, setSearchValue] = useState(query);

  const { httpPostAsync } = useHttpHelper();

  const getSentNotifications = async () => {
    // console.log('call api:page param', pageParam, searchCriteria);

    const response = await httpPostAsync(
      appLinks.sentNotifications,
      {
        itemPerPage: pageSize,
        query,
        pageNo,
        sortBy,
        sortOrder,
        filterOrganisationIds: map(organisations, s => s.value),
      }
    );

    let notifications = get(response, 'data');
    notifications = map(notifications, n => {

      return {
        ...n,
        appZoneCreatedAt: getAppZoneMomentFromUTC(n.createdAt),
      }

    })

    console.log('notifications', response)

    return {
      ...response,
      data: notifications,
    };
  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.sentNotifications, pageNo, query, sortBy, sortOrder];

    if (organisations.length) {
      const organisationValues = map(organisations, s => s.value);
      const organisationList = join(organisationValues, '.');
      key.push(organisationList)
    }

    console.log('filter key', key)

    return key;

  }, [pageNo, query, sortBy, sortOrder, organisations]);


  const {
    isLoading,
    //isError,
    //error,
    data = {},
    //isFetching,
    //isPreviousData,
    //refetch,
  } = useQuery(
    queryKey,
    getSentNotifications,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })

  let { data: notifications = [], total = 0, } = data || {}

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));

    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  const onSearch = async (text) => {
    try {

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }));

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onSearch);
  searchUsersRef.current = onSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const navigateToDetails = (id) => {
    try {

      navigate(`/${appRoutes.notificationDetails}/${id}`)

    } catch (error) {
      console.log('error navigating to notification details', error);
    }
  }

  let hasDetailAccess = true;

  const navigateToCreateNew = () => {
    try {

      navigate(`/${appRoutes.notificationSend}`);

    } catch (error) {
      console.log('error navigating to send notifications', error);
    }
  }

  const onOrganisationChange = (values) => {
    try {

      console.log('values', values)

      setSearchParams(p => ({ ...p, organisations: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on organisation chamge', error)
    }
  }

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  const [tagsVisible, setTagsVisible] = useState(false);

  const styles = useMemo(() => ({
    itemStyle: {
      paddingRight: 16,
      paddingBottom: 18,
    },
    tagsContainer: {
      paddingTop: tagsVisible ? 16 : 0,
      paddingBottom: tagsVisible ? 8 : 0

    },
    challengeTableContainer: {
      paddingTop: tagsVisible ? 0 : 17
    }
  }), [tagsVisible])

  useEffect(() => {

    const searchTagsLen = organisations.length;

    setTagsVisible(searchTagsLen > 0)

  }, [organisations])

  return (
    <Dashboard subrouting={false} activeItem='notifications'>
      <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
        <Col>
          <Row>
            <Col style={{ paddingRight: 15 }} >
            <OrganisationsLookup
                onChange={onOrganisationChange}
                value={mapKeyValues(organisations)}
                style={{ width: 200 }}
                placeholder="Organisation filter"
              />
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Search
                placeholder="Search template"
                value={searchValue}
                onChange={onSearchChange}
                allowClear
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
        <Col >
          <Button
            type="primary"
            block
            //disabled={!hasCreateAccess}
            data-testid="send-notifications"
            onClick={navigateToCreateNew}
          >
            Send app notifications
          </Button>
        </Col>
      </Row>
      <Row style={styles.tagsContainer} justify="space-between">
        <Col>
          <div style={{ marginBottom: 8 }}>
            {map(organisations, org => {

              return (
                <TagChip key={org.key} closable onClose={() => {
                  setSearchParams(p => {

                    //console.log('key, organisations', org.key, typeof org.key, p.organisations)

                    const filtered = filter(p.organisations, o => o.key !== org.key);

                    //console.log('filtered organisations', filtered)

                    return {
                      ...p,
                      organisations: filtered,
                    }

                  })
                }}>
                  {org.label}
                </TagChip>
              )

            })}
          </div>
        </Col>
        <Col>
          {
            isSearchActive
              ? (
                <Button type="link" size='small' onClick={onReset}>
                  Reset
                </Button>
              )
              : null
          }
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <SentNotificationsTable
          key={resetKey}
          notifications={notifications}
          onPagniationChange={onPagniationChange}
          onSortChange={onSortChange}
          navigateToDetails={navigateToDetails}
          pageNo={pageNo}
          total={total}
          defaultPageSize={pageSize}
          hasDetailAccess={hasDetailAccess}
        />
      </Spin>
    </Dashboard>
  );

}