import { Row, Col, Image, Card, Typography, Badge } from 'antd';
import styled from 'styled-components';
import React from 'react';

import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import { ReactComponent as ProfileImageDefault } from '../../../assets/logoPlaceholder.svg';



const TitleComponent = styled.span`
font-size: 16px;
font-weight: bold;
`

const TitleDiv = styled.div`
padding-top: 8px
`;

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    }
}

const { Text } = Typography;

const ItemTitle = ({ title, value }) => {

    return (
        <TitleDiv>
            <div>
                <Text type="secondary">{title}</Text>
            </div>
            <Text>{value}</Text>
        </TitleDiv>
    )
}

export default (props) => {

    const {
        name,
        organisation,
        status,
    } = props;

    const isActive = status === 'active';

    return (
        <div>
            <Card>
                <Row>
                    <Col md={24} sm={24} xs={24} lg={18} xl={12} xxl={12}>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: '12px' }}>
                                    <Col>
                                        <TitleComponent>{name}</TitleComponent>
                                    </Col>
                                    <Col>
                                        <Badge style={{ paddingLeft: 32 }}
                                            status={isActive ? "success" : 'default'}
                                            text={isActive ? 'Active' : 'Inactive'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div style={styles.statsContainer}>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col span={12}>
                                    <ItemTitle title="Organisation" value={organisation} />
                                </Col>
                                <Col span={12}>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={24} sm={24} xs={24} lg={12} xl={12} xxl={12}>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}