import { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from "react-query";
import { Layout, Menu, Row, Avatar, Col, Typography } from 'antd';
import Icon, {
    // DesktopOutlined,
    PieChartOutlined,
    UserOutlined,
    LogoutOutlined,
    DashboardOutlined,
    TeamOutlined,
    MessageOutlined,
    NotificationOutlined,
    StopOutlined,
    MailOutlined,
    CalendarOutlined
} from '@ant-design/icons';
import { ReactComponent as Globe } from '../../../assets/Globe_iconnew.svg';
import { ReactComponent as Group } from '../../../assets/group-1-512-_1_.svg';
import { ReactComponent as Achievment } from '../../../assets/Achievement-512.svg';
import { ReactComponent as OneAppLogo } from '../../../assets/adminlogobeta.svg';
import { useAppContext } from '../appContext';
import { appRoutes } from '../../../config/constants';
import { useMenuPermissionsContext, usePermssionContext } from '../permissionContext';
import { useFilterContext } from '../filterStoreContext';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Text } = Typography;

const logoContainerHeight = '60px';
const styles = {
    logo: {
        minHeight: logoContainerHeight,
        paddingLeft: '24px'
    }
}


export default function AppLayout(props) {

    const { isAuthenticated, onLogoutAsync, user } = useAppContext();

    let location = useLocation();

    const {
        hasSettingsMenuAccess = false,
        hasUsersMenuAccess = false,
        hasChallengesMenuAccess = false,
        // hasCreatorsMenuAccess = false,
        hasOrganisationsMenuAccess = false,
        hasClubsMenuAccess = false,
        hasThemeSubmenuAccess = false,
        hasSkillsSubmenuAccess = false,
        hasDifficultySubmenuAccess = false,
        // hasDurationSubmenuAccess = false,
        hasRolePermissionsSubmenuAccess = false,
        hasPostsMenuAccess = false,
        hasOneKidsMenuAccess = false,
        hasNotificationsPermission = true,
        hasUserMessagesPermission = true,
        hasReportedItemsPermission = true,
        hasAgesPermission = true,
        hasMaterialsPermission = true,
        hasEmailPermission = true,
        hasCalendarPermission = false,
        hasDashboardAccess = true,
    } = useMenuPermissionsContext();

    const navigate = useNavigate();

    const [collapsed, setCollapsed] = useState(false);

    // const [saveBtnDisplay, setSaveBtnDisplay] = useState(true);

    // using hooks before the return;
    const { resetPermissions } = usePermssionContext();
    const { resetFilterState } = useFilterContext();
    const queryClient = useQueryClient();

    if (!isAuthenticated) {
        console.log('unauthenticated redirect back to login')
        return (<Navigate to="/login" state={{ from: location }} replace />);
    }

    const { children, activeItem = '', openKeys = [], footerElement = "submit" } = props;

    const onLogoutClick = () => {
        try {
            onLogoutAsync();
            resetPermissions && resetPermissions();
            resetFilterState && resetFilterState();
            queryClient.clear();
            navigate('/login', { state: {}, replace: true })
        } catch (error) {
            console.log('error logging out', error);
        }
    }

    const onCollapse = collapsed => {
        console.log(collapsed);
        setCollapsed(collapsed);
    };

    // const naviagateToPersonCreators = () => {
    //     try {

    //         navigate(`/${appRoutes.creatorPersons}`);

    //     } catch (error) {
    //         console.log('error navigating to person creator', error);
    //     }
    // }

    const naviagateToUsers = () => {
        try {

            navigate(`/${appRoutes.dashboard}/${appRoutes.users}`);

        } catch (error) {
            console.log('error navigating to Users', error);
        }
    }

    const naviagateToOrganisations = () => {
        try {

            navigate(`/${appRoutes.organisations}`);

        } catch (error) {
            console.log('error navigating to organisations', error);
        }
    }


    const naviagateToClubs = () => {
        try {

            navigate(`/${appRoutes.clubs}`);

        } catch (error) {
            console.log('error navigating to clubs', error);
        }
    }

    const naviagateToChallenges = () => {
        try {

            navigate(`/${appRoutes.challenges}`);

        } catch (error) {
            console.log('error navigating to challenges', error);
        }
    }

    const naviagateToCalendar = () => {
        try {

            navigate(`/${appRoutes.adminArea}/${appRoutes.calender}`);

        } catch (error) {
            console.log('error navigating to challenges', error);
        }
    }

    const navigateToThemes = () => {
        try {

            navigate(`/${appRoutes.themes}`);

        } catch (error) {
            console.log('navigating to theme', error);
        }
    }


    const navigateToSkills = () => {
        try {

            navigate(`/${appRoutes.skills}`);

        } catch (error) {
            console.log('navigating to skills', error);
        }
    }

    const navigateToDifficulty = () => {
        try {

            navigate(`/${appRoutes.difficulty}`);

        } catch (error) {
            console.log('navigating to difficulty', error);
        }
    }

    // const navigateToDurations = () => {
    //     try {

    //         navigate(`/${appRoutes.durations}`);

    //     } catch (error) {
    //         console.log('navigating to activity durations', error);
    //     }
    // }

    const naviagetToRolePermissions = () => {
        try {
            navigate(`/${appRoutes.rolePermissions}`);
        } catch (error) {
            console.log('error navigating to permissions', error);
        }

    }

    const naviagateToPosts = () => {
        try {
            navigate(`/${appRoutes.posts}`);
        } catch (error) {
            console.log('error navigating to posts', error)
        }
    }

    const naviagateToOneKidsPosts = () => {
        try {
            navigate(`/${appRoutes.oneKidsPosts}`);
        } catch (error) {
            console.log('error navigating to oneKidsPosts', error)
        }
    }

    const navigateToNotifications = () => {
        try {

            navigate(`/${appRoutes.notifications}`);

        } catch (error) {
            console.log('navigating to notifications', error);
        }
    }

    const naviagateToMessages = () => {
        try {

            navigate(`/${appRoutes.messages}`);

        } catch (error) {
            console.log('error navigating to messages', error);
        }
    }

    const naviagateToreportedItems = () => {
        try {

            navigate(`/${appRoutes.reported}`);

        } catch (error) {
            console.log('error navigating to reported posts and users', error);
        }
    }

    const naviagateToSendEmails = () => {
        try {

            navigate(`/${appRoutes.emails}`);

        } catch (error) {
            console.log('error navigating to send emails', error);
        }
    }

    const naviagateToAges = () => {
        try {

            navigate(`/${appRoutes.ages}`);

        } catch (error) {
            console.log('error navigating to age settings', error);
        }
    }

    const naviagateToMaterials = () => {
        try {

            navigate(`/${appRoutes.materials}`);

        } catch (error) {
            console.log('error navigating to age materials', error);
        }
    }

    const naviagateToDashboard = () => navigate(`/${appRoutes.dashboard}/${appRoutes.dashHome}`);
    

    //console.log('user info all  ', user)

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={onCollapse}
                breakpoint="lg"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{
                    borderBottomRightRadius: 22,
                    backgroundColor: '#002140'
                }}
            >
                <div style={styles.logo} >
                    <Row align="middle" style={{ minHeight: logoContainerHeight }} >
                        <OneAppLogo fill="#fff" height='38px' width="78px" />
                        {/* <Text>{get(user, 'username') || ''}</Text> */}
                    </Row>
                </div>
                <Menu theme="dark" defaultSelectedKeys={[activeItem]} defaultOpenKeys={openKeys} mode="inline">
                    <Menu.Item
                        disabled={!hasDashboardAccess}
                        key="dashboard"
                        data-testid='dashboard-menu'
                        icon={<DashboardOutlined />}
                        onClick={naviagateToDashboard}
                    >
                        Dashboard
                    </Menu.Item>
                    {
                        hasChallengesMenuAccess
                            ?
                            (<Menu.Item
                                onClick={naviagateToChallenges}
                                key="challenges"
                                data-testid='challenge-menu'
                                icon={<Icon component={Achievment} />}>
                                Challenges
                            </Menu.Item>)
                            : null
                    }
                    {
                        hasCalendarPermission
                            ?
                            (<Menu.Item
                                onClick={naviagateToCalendar}
                                key={appRoutes.calender}
                                data-testid='calendar-menu'
                                icon={<Icon component={CalendarOutlined} />}>
                                Calendar
                            </Menu.Item>)
                            : null
                    }
                    {
                        hasUsersMenuAccess
                            ?
                            (
                                <Menu.Item
                                    key="users"
                                    data-testid='users-menu'
                                    icon={<UserOutlined />}
                                    onClick={naviagateToUsers}
                                >
                                    Users
                                </Menu.Item>
                            )
                            : null
                    }
                    {
                        hasUserMessagesPermission
                            ?
                            (
                                <Menu.Item
                                    key="messages"
                                    data-testid='users-messages'
                                    icon={<MessageOutlined />}
                                    onClick={naviagateToMessages}
                                >
                                    Messages
                                </Menu.Item>
                            )
                            : null
                    }
                    {
                        hasPostsMenuAccess
                            ? (
                                <Menu.Item
                                    key="posts"
                                    data-testid='posts-menu'
                                    onClick={naviagateToPosts}
                                    icon={<Icon component={Group} />}
                                >
                                    Posts
                                </Menu.Item>
                            )
                            : null
                    }
                    {
                        hasOneKidsMenuAccess
                            ? (
                                <Menu.Item
                                    key="onekids-posts"
                                    data-testid='onekids-posts-menu'
                                    onClick={naviagateToOneKidsPosts}
                                    icon={<Icon component={Group} />}
                                >
                                    One Kids Posts
                                </Menu.Item>
                            )
                            : null
                    }
                    {/* {
                        hasCreatorsMenuAccess
                            ?
                            (<Menu.Item
                                key="creatorPerson"
                                data-testid='creators-menu'
                                icon={<DesktopOutlined />}
                                onClick={naviagateToPersonCreators}
                            >
                                Creators
                            </Menu.Item>)
                            : null
                    } */}
                    {
                        hasOrganisationsMenuAccess
                            ?
                            (
                                <Menu.Item
                                    onClick={naviagateToOrganisations}
                                    key="org"
                                    data-testid='organisations-menu'
                                    icon={<Icon component={Globe} />}
                                >
                                    Organisations
                                </Menu.Item>
                            )
                            : null
                    }
                    {
                        hasClubsMenuAccess
                            ?
                            (<Menu.Item
                                onClick={naviagateToClubs}
                                key="clubs"
                                data-testid='clubs-menu'
                                icon={<TeamOutlined />}
                            >
                                Clubs
                            </Menu.Item>)
                            : null
                    }
                    {
                        hasNotificationsPermission
                            ?
                            (<Menu.Item
                                onClick={navigateToNotifications}
                                key="notifications"
                                data-testid='notifications-menu'
                                icon={<NotificationOutlined />}
                            >
                                Notifications
                            </Menu.Item>)
                            : null
                    }
                    {
                        hasEmailPermission
                            ?
                            (<Menu.Item
                                onClick={naviagateToSendEmails}
                                key="emails"
                                data-testid='emails-menu'
                                icon={<MailOutlined />}
                            >
                                Emails
                            </Menu.Item>)
                            : null
                    }
                    {
                        hasReportedItemsPermission ?
                            (
                                <Menu.Item
                                    onClick={naviagateToreportedItems}
                                    key="reportedItems"
                                    data-testid='reported-items-menu'
                                    icon={<StopOutlined />}
                                >
                                    Reported
                                </Menu.Item>
                            ) : null
                    }
                    {
                        hasSettingsMenuAccess
                            ? (
                                <SubMenu
                                    key="settings"
                                    data-testid='settings-sub-menu'
                                    icon={<PieChartOutlined />}
                                    title="Configurations"
                                >
                                    {
                                        hasThemeSubmenuAccess
                                            ? (
                                                <Menu.Item
                                                    key="settings-themes"
                                                    data-testid='settings-themes-sub-menu'
                                                    onClick={navigateToThemes}>Themes</Menu.Item>)
                                            : null
                                    }
                                    {
                                        hasSkillsSubmenuAccess
                                            ? (
                                                <Menu.Item
                                                    data-testid='settings-skills-sub-menu'
                                                    key="settings-skills"
                                                    onClick={navigateToSkills}>Skills</Menu.Item>)
                                            : null
                                    }
                                    {
                                        hasDifficultySubmenuAccess
                                            ? (
                                                <Menu.Item
                                                    data-testid='settings-difficulty-sub-menu'
                                                    key="settings-difficulty"
                                                    onClick={navigateToDifficulty}>Difficulty Levels</Menu.Item>)
                                            : null
                                    }
                                    {/* {
                                        hasDurationSubmenuAccess
                                            ? (
                                                <Menu.Item
                                                    data-testid='settings-duration-sub-menu'
                                                    key="settings-durations"
                                                    onClick={navigateToDurations}>Durations</Menu.Item>)
                                            : null
                                    } */}
                                    {
                                        hasRolePermissionsSubmenuAccess
                                            ? (
                                                <Menu.Item
                                                    data-testid='settings-role-permissions-sub-menu'
                                                    key="settings-role-permissions"
                                                    onClick={naviagetToRolePermissions}>Role Permissions</Menu.Item>
                                            )
                                            : null
                                    }
                                    {
                                        hasAgesPermission
                                            ? (
                                                <Menu.Item
                                                    data-testid='settings-age-sub-menu'
                                                    key="settings-ages"
                                                    onClick={naviagateToAges}>Ages</Menu.Item>
                                            )
                                            : null
                                    }
                                    {
                                        hasMaterialsPermission
                                            ? (
                                                <Menu.Item
                                                    data-testid='settings-materials-sub-menu'
                                                    key="settings-materials"
                                                    onClick={naviagateToMaterials}>Materials</Menu.Item>
                                            )
                                            : null
                                    }
                                </SubMenu>)
                            : null
                    }
                    <Menu.Item
                        key="logout"
                        data-testid='logout-menu'
                        icon={<LogoutOutlined />}
                        onClick={onLogoutClick}
                    >
                        Logout
                    </Menu.Item>
                </Menu>
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{ padding: 0 }} >
                    <Row justify="end">
                        <Col>
                            <Row align="middle" style={{ marginRight: 28 }}>
                                <Avatar size={32} style={{ marginRight: 8 }} icon={<UserOutlined />} />
                                <Text style={{
                                    color: '#fff'
                                }}>{user.username}</Text>
                            </Row>
                        </Col>
                    </Row>
                </Header>
                <Content style={{ margin: '0 16px', paddingTop: 20 }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        {children}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    &copy; 2024 ONE Learning
                </Footer>
                {/* {
                    (saveBtnDisplay) ?
                        <div style={{
                            backgroundColor: '#fff',
                            width: '100%',
                            padding: 12
                        }}>
                        </div>
                        : null
                } */}
            </Layout>
        </Layout>
    );
}