import { Form, Input } from 'antd';
import React, { useCallback } from 'react';
import { HexColorPicker } from "react-colorful";


export default ({ form, onSubmitCallback }) => {

    const onSubmit = (values) => {
        try {

            let postData = {
                title: values.title,
                color: values.color,
            }

            onSubmitCallback && onSubmitCallback(postData);

        } catch (error) {
            console.log('error in edit skill submit form', error);
        }
    }

    const shouldUpdate = useCallback(() => (prev, cur) => prev.color !== cur.color, []);

    const onColorPickerChange = (e) => {
        try {

            console.log('color picker', e);

            form.setFieldsValue({ hexColor: e });

        } catch (error) {
            console.log('error on color picker change', error);
        }
    }

    const onColorInputChange = (e) => {
        try {

            console.log('on color input change', e.target.value);
            form.setFieldsValue({ color: e.target.value });

        } catch (error) {
            console.log('on color input change', error);
        }
    }

    return (
        <Form
            layout='vertical'
            form={form}
            scrollToFirstError
            name='create-edit-theme'
            //initialValues={initialValues}
            onFinish={onSubmit}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Title is required.' }]}
                wrapperCol={{ span: 16 }}
            >
                <Input placeholder="title" showCount maxLength={50} />
            </Form.Item>
            <Form.Item
                label="Color"
                name="color"
                //wrapperCol={{ span: 22 }}
                valuePropName="color"
                //getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please choose an color' }]}
            >
                <HexColorPicker onChange={onColorPickerChange} />
            </Form.Item>
            <Form.Item shouldUpdate={shouldUpdate}>
                {(props) => {

                    return (
                        <Form.Item
                            label="Color"
                            name="hexColor"
                            rules={[
                                { required: true, message: 'Please enter an color' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        var reg = /^#([0-9a-f]{3}){1,2}$/i;
                                        if (reg.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Invalid hex color code'));
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Color" onChange={onColorInputChange} />
                        </Form.Item>
                    )

                }}
            </Form.Item>
        </Form>
    )

}