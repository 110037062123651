import { Breadcrumb, Button, Row, Spin, message } from 'antd';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import CreateEdit from '../common/createEdit/index';

import { ArrowLeftOutlined } from '@ant-design/icons';
import useUsersPermissionContext from '../../../common/components/permissionContext/useUsersPermissions';


const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;


export default ({ }) => {

    let { userId } = useParams();

    const navigate = useNavigate();

    //console.log('params', params);

    const { httpGetAsync } = useHttpHelper();

    const fetchUserDetailsAsync = async () => {
        try {

            const response = await httpGetAsync(appLinks.usersDetails.replace('{userId}', userId));

            console.log('user', response);

            return response;

        } catch (error) {
            console.log('error fetching user details', error);
        }
    }

    const {
        data,
        isLoading,
       // isSuccess
    } = useQuery(
        [queryKeys.userDetails, userId],
        fetchUserDetailsAsync,
        {
            enabled: userId > 0,
            refetchOnWindowFocus: false
        }
    );

    const initialValues = useMemo(() => {

        // const organisationId = get(data, 'data.organisationId');
        const firstName = get(data, 'data.firstName');
        const lastName = get(data, 'data.lastName');
        const email = get(data, 'data.email');
        const roles = get(data, 'data.roles') || [];
        const clubs = get(data, 'data.clubs') || [];
        const organisations = get(data, 'data.organisations') || [];
        const hasAdminAccess = get(data, 'data.hasAdminAccess') || false;
        //const isActive = get(data, 'isActive');

        // creator info
        const creatorName = get(data, 'data.creatorName');
        const creatorShortBio = get(data, 'data.creatorShortBio');
        const creatorTitle = get(data, 'data.creatorTitle');
        const creatorWebsite = get(data, 'data.creatorWebsite');

        const creatorProfileImageId = get(data, 'data.creatorProfileImageId');
        const creatorProfileImageUrl = get(data, 'data.creatorProfileImageUrl');

        let creatorProfileImageFiles = [];

        if (creatorProfileImageId && creatorProfileImageUrl) {
            creatorProfileImageFiles.push({
                uid: creatorProfileImageId,
                name: 'iconimage',
                status: 'done',
                url: creatorProfileImageUrl,
            })
        }

        const creatorCoverImageId = get(data, 'data.creatorCoverImageId');
        const creatorCoverImageUrl = get(data, 'data.creatorCoverImageUrl');


        let creatorCoverImageFiles = [];

        if (creatorCoverImageId && creatorCoverImageUrl) {
            creatorCoverImageFiles.push({
                uid: creatorCoverImageId,
                name: 'iconimage',
                status: 'done',
                url: creatorCoverImageUrl,
            })
        }

        return {
            firstName,
            lastName,
            organisations,
            roles,
            clubs,
            hasAdminAccess,
            email,
            // isActive,
            // creator info
            creatorName,
            creatorShortBio,
            creatorTitle,
            creatorWebsite,
            creatorProfileImageFiles,
            creatorCoverImageFiles,
        }

    }, [data]);

    const { httpPutAsync } = useHttpHelper();

    const udpateUserAsync = async (data) => {
        const link = appLinks.updateUser.replace('{userId}', userId)
        // console.log('accept challenge link', link);
        console.log('udpate post data', data);
        const createPersonData = { ...data };
        const response = await httpPutAsync(link, createPersonData);
        console.log('update user async', response);

        return response;
    };

    const mutationQueryKey = [queryKeys.updateUser, userId]

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateUserAsync,
    } = useMutation(
        udpateUserAsync,
        { mutationKey: mutationQueryKey, }
    );

    const onSubmit = async (postData, redirect = false) => {
        try {

            console.log('on submit', postData);

             const response = await onUpdateUserAsync(postData);

            if (response && response.code === 0) {
                message.success('User was updated successfully');

                if (redirect) {
                    // save and continue, => redirect to challenges listing
                    navigate(`/${appRoutes.dashboard}/${appRoutes.users}`);
                } 

            } else if (response && response.code !== 0) {
                message.error('User could not be updated')
            }

        } catch (error) {
            console.log('error on submit', error);
        }
    }

    const onBack = () => {
        try {

            navigate(`/${appRoutes.dashboard}/${appRoutes.users}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const name = get(data, 'data.name');

    const { hasEditRolesAccess = false, hasEditOrganisationsAccess = false } = useUsersPermissionContext();

    const creatorProfileImageId = get(data, 'data.creatorProfileImageId');
    const creatorProfileImageUrl = get(data, 'data.creatorProfileImageUrl');
    const creatorCoverImageId = get(data, 'data.creatorCoverImageId');
    const creatorCoverImageUrl = get(data, 'data.creatorCoverImageUrl');
    return (
            <Spin spinning={isLoading || isUpdating}>
                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Users</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <ContainerDiv className='form-container-one'>
                    <CreateEdit
                        showRolesTab={hasEditRolesAccess}
                        showOrganisationsTab={hasEditOrganisationsAccess}
                        showClubsTab={true}
                        formValues={initialValues}
                        creatorProfileImageId={creatorProfileImageId}
                        creatorProfileImageUrl={creatorProfileImageUrl}
                        creatorCoverImageId={creatorCoverImageId}
                        creatorCoverImageUrl={creatorCoverImageUrl}
                        onSubmit={onSubmit}
                        submitTitle='Save' />
                </ContainerDiv>
            </Spin>
    );

}