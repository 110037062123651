import { useMemo } from "react"
import { AudioUpload, DefaultUpload } from "."
import { uploadFileTypes } from "../../../../../config/constants"


const uploadComponentConfig = {
    [uploadFileTypes.audio]: AudioUpload,
}

export default function UploadItem(props) {

    const { fileType } = props;

    const UploadComponent = useMemo(() =>  uploadComponentConfig[fileType]?? DefaultUpload, [fileType])

    return (<UploadComponent {...props} />)

}