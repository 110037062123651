import React, { useMemo } from "react";
import { get, includes, map } from "lodash";
import { Row, Space, Segmented, Checkbox, Col, Card, Typography, Select, Divider } from 'antd';

const excludeChildrenMappins = ['settings']
const { Title, Text } = Typography;
const { Option } = Select;

const ownOptions = [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }, { value: 'Own', label: 'Own' }]

const permissionConfigurations = {
    'challenges.edit': ownOptions,
    'challenges.delete': ownOptions,
    'clubs.edit': ownOptions,
    'clubs.delete': ownOptions,
    default: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
}


const PermissionCard = (props) => {

    const { children, onPermissionSelect } = props;

    return (
        <div>
            <Row>
                {
                    map(children, child => {

                        const items = get(permissionConfigurations, child.permission, permissionConfigurations.default);

                        const onChange = (e) => {
                            try {

                                console.log(' setting on change', e);
                                onPermissionSelect && onPermissionSelect(e, child.permission, child.parent);

                            } catch (error) {
                                console.log('error on segment change', error);
                            }
                        }

                        return (
                            <Col>
                                <Card style={{
                                    marginRight: 12,
                                    marginBottom: 12
                                }}>
                                    <div>
                                        <Text type="secondary">{child.label}</Text>
                                    </div>
                                    <br />
                                    <Segmented value={child.value} options={items} onChange={onChange} />
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </div>
    )

}

export default (props) => {

    const {
        children = [],
        permission,
        indeterminate,
        checkAll,
        onPermissionSelect,
        onCheckUncheckAll,
    } = props;

    //const options = map(children, child => ({ label: child.label, value: child.permission }));

    const includeChildrens = useMemo(() => includes(excludeChildrenMappins, permission), [permission]);

    return (
        <React.Fragment>
            {
                !includes(excludeChildrenMappins, permission)
                    ?
                    <div style={{ marginBottom: 12 }}>
                        <div>

                            <Checkbox
                                indeterminate={indeterminate}
                                onChange={e => onCheckUncheckAll(e.target.checked, permission)}
                                checked={checkAll}
                                data-testid='all-check'
                            >
                                 All permissions
                            </Checkbox>

                        </div>
                    </div>
                    : null
            }
            {
                !includeChildrens ?
                    (<PermissionCard {...props} />)
                    : null
            }
            {
                includeChildrens && children.length ?
                    map(children, perm => (
                        <ParentPermissionView
                            key={perm.id}
                            {...perm}
                            onPermissionSelect={onPermissionSelect}
                            onCheckUncheckAll={onCheckUncheckAll}
                        />))
                    : null
            }
        </React.Fragment>
    )

}


export const ParentPermissionView = (props) => {

    const {
        label,
        children = [],
        permission,
        indeterminate = false,
        checkAll = false,
        onPermissionSelect,
        onCheckUncheckAll,
    } = props;

    return (
        <React.Fragment>
            <Row style={{ marginTop: 24 }}>
                <Title level={5}>{label}</Title>
                <Divider style={{ marginTop: 0 }} />
            </Row>
            <Row>
                <Col>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={e => {
                            onCheckUncheckAll && onCheckUncheckAll(e.target.checked, permission)
                        }}
                        checked={checkAll}
                        style={{
                            marginBottom: 12
                        }}
                    >
                        All permissions
                    </Checkbox>
                    <div>
                        <Row>

                            {
                                map(children, child => {

                                    const items = get(permissionConfigurations, child.permission, permissionConfigurations.default);

                                    const onChange = (e) => {
                                        try {

                                            console.log(' setting on change', e);
                                            onPermissionSelect && onPermissionSelect(e, child.permission, child.parent);

                                        } catch (error) {
                                            console.log('error on segment change', error);
                                        }
                                    }

                                    return (
                                        <Col>
                                            <Card style={{ marginRight: 12, marginBottom: 12 }}>
                                                <div>
                                                    <div>
                                                        <Text type="secondary">{child.label}</Text>
                                                    </div>
                                                    <br />
                                                    <Segmented value={child.value} options={items} onChange={onChange} />
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}