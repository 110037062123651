import { useMemo } from 'react';
import { Col, Row, Table, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;


export default props => {

    const {
        listItems,
        onPagniationChange,
        onSortChange,
        pageNo,
        total,
        defaultPageSize,
        navigateToDetails,
        navigateWithCopy,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Created on',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, { appZoneCreatedAt, id }) => {

                //console.log('add created at', appZoneCreatedAt)

                return (
                    <a
                        onClick={() => {
                            //console.log('on click', record)
                            navigateToDetails && navigateToDetails(id)
                        }}>
                        {appZoneCreatedAt && appZoneCreatedAt.isValid() && appZoneCreatedAt.format('Do MMMM, YYYY HH:mm')}
                    </a>)

                // return (
                //     <a
                //     onClick={() => {
                //         //console.log('on click', record)
                //         navigate(`/${appRoutes.organisations}/${record.id}`)
                //     }}>
                //         {record.name}
                //     </a>
                // )
            },
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
            render: (text, record) => {

                return (
                    <div style={{ maxWidth: 300 }}>
                        <Tooltip title={record.subject}>
                            <Text ellipsis={{
                                rows: 1,
                                expandable: false,
                            }}>
                                {record.subject}
                            </Text>
                        </Tooltip>
                    </div>
                )

            }
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => {

                return (
                    <div style={{ maxWidth: 300 }}>
                        <Tooltip title={record.title}>
                            <Text ellipsis={{
                                rows: 1,
                                expandable: false,
                            }}>
                                {record.title}
                            </Text>
                        </Tooltip>
                    </div>
                )

            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            {record.status}
                        </Text>
                        {
                            record.canEdit? 
                            (
                                <a
                                    onClick={() => navigateToDetails(record.id)}
                                    className='edit-email-draft'
                                    >
                                    Edit
                                </a>
                            ) : (
                                <a
                                    onClick={() => navigateWithCopy(record.id)}
                                    className='edit-email-draft'
                                    >
                                    Copy
                                </a>
                            )
                        }
                    </div>
                )
            }
        },
        {
            title: 'Recipient(s)',
            dataIndex: 'count',
            key: 'count',
            render: (text, record) => {
                return (
                    <div>
                        <Text>
                            {record.count ? record.count : ''}
                        </Text>
                    </div>
                )
            }
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }

    return (
        <div className='send-email-list-table'>
            <Row>
                <Col justify='flex-start' span={24} style={{
                    //padding: '12px',
                    paddingTop: '0px'
                }}>
                    <Table
                        columns={columns}
                        dataSource={listItems}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                            pageSizeOptions: []
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}