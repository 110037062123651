import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Spin, Row, Button, Breadcrumb, message } from 'antd';
import styled from 'styled-components';
import { find, get, map } from 'lodash';
import AppLayout from '../../../common/components/layout';
import CreateEdit from '../common/createEdit';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, creatorTypes, queryKeys, resourceType } from '../../../config/constants';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import { useChallengesPermissionContext, useMenuPermissionsContext } from '../../../common/components/permissionContext';
import { PrimaryRelatedToFieldName, RelatedToActivitiesFieldName, RelatedToListFieldName } from '../common/createEdit/RelatedTo';
import Dashboard from '../../../common/components/dashboard';


const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;



export default () => {

    let { challengeId } = useParams();

    // move to a hook
    const [currentTab, setCurrentTab] = useQueryParam('tab', withDefault(StringParam, 'intro'));

    const setTabParam = (key) => {
        key && setCurrentTab(key)
    }

    const navigate = useNavigate();


    const { httpGetAsync } = useHttpHelper();

    const fetchChallengeDetailsAsync = async () => {
        try {

            const creator = await httpGetAsync(appLinks.challengeDetails.replace('{challengeId}', challengeId));

            console.log('challenge details', creator);

            return creator;

        } catch (error) {
            console.log('error fetching challenge details', error);
        }
    }

    const {
        data,
        isLoading,
        isSuccess,
        refetch: refetchChallengeDetailsAsync,
    } = useQuery(
        [queryKeys.challengeDetailsView, challengeId],
        fetchChallengeDetailsAsync,
        {
            enabled: challengeId > 0,
            refetchOnWindowFocus: false
        }
    );

    const initialValues = useMemo(() => {

        const organisationId = get(data, 'organisationId');
        const title = get(data, 'title');
        const xp = get(data, 'xp');
        const difficultyId = get(data, 'difficultyId');
        const durationMins = get(data, 'durationMins') || 0;
        const durationId = get(data, 'durationId');
        const isFacilitationNeeded = get(data, 'isFacilitationNeeded');
        const themes = get(data, 'themes', []);
        const skills = get(data, 'skills', []);
        const ages = data?.ages ?? [];
        const materials = data?.materials ?? [];
        const customIntro = get(data, 'customIntro');
        const introId = get(data, 'introId');
        const description = get(data, 'description');
        const locationId = data?.locationId
        const stepSection = find(get(data, 'sections'), section => section.type === 'steps');
        //console.log('steps section', stepSection)
        let steps = get(stepSection, 'items', []);
        steps = map(steps, step => ({ 
            id: step.id, 
            description: step.title, 
            sortOrder: step.sortOrder,
            uniqueId: `${step.id}`, 
        }));
        const seasons = data?.seasons ?? []

        //console.log('steps', steps);

        let resources = get(data, 'resources', []);
        resources = map(resources, res => {

            let extraProps = {};
            if (res.type === resourceType.image && res.downloadLink) {
                extraProps = {
                    imageFile: [{
                        uid: res.id,
                        name: 'iconimage',
                        status: 'done',
                        url: res.downloadLink,
                    }],
                }
            }

            if (res.type === resourceType.pdf && res.downloadLink) {
                extraProps = {
                    pdfFile: [{
                        uid: res.id,
                        name: 'iconimage',
                        status: 'done',
                        url: res.downloadLink,
                    }],
                }
            }

            if (res.fileName) {
                extraProps = {
                    ...extraProps,
                    fileName: res.fileName
                }
            }

            return {
                ...res,
                ...extraProps
            }

        });

        const thumbId = get(data, 'thumbId');
        const thumbUrl = get(data, 'thumbUrl');
        const imageId = get(data, 'imageId');
        const imageUrl = get(data, 'imageUrl')

        let thumbFile = [];

        if (thumbId && thumbUrl) {
            thumbFile.push({
                uid: thumbId,
                name: 'iconimage',
                status: 'done',
                url: thumbUrl,
            })
        }

        let imageFile = [];

        if (imageId && imageUrl) {
            imageFile.push({
                uid: imageId,
                name: 'coverImage',
                status: 'done',
                url: imageUrl,
            })
        }

        const creatorType = get(data, 'creators.creator.creatorType', 'creator');

        let creatorProps = {};
        const creatorId = get(data, 'creators.creator.value');

        const creator = get(data, 'creators.creator') || {}

        if (creatorType === creatorTypes.creator) {

            creatorProps = { 
                creatorId,
                creatorPerson: {
                    name: creator.name,
                    logoLink: creator.logoLink,
                    title: creator.title,
                    website: creator.website,
                } 
            }

        } else if (creatorType === creatorTypes.organisation) {
            
            creatorProps = { 
                creatorOrganisationId: creatorId,
                creatorOrganisation: {
                    id: creatorId,
                    name: creator.name,
                    logoLink: creator.logoLink,
                    website: creator.website,
                }
             }

        } else if (creatorType === creatorTypes.club) {
            creatorProps = { creatorClubId: creatorId }
        } 

        const creatorText = get(data, 'creators.creator.creatorText');

        const poweredByItems = get(data, 'creators.poweredBy', []);

        const poweredByOrganisations = map(
            poweredByItems, 
            item => ({ 
                value: item.value, 
                label: item.label,
                logoLink: item.logoLink,
                name: item.name,
                website: item.website,
            }));

        //const duration = moment().startOf('day').add(durationMins, 'minutes');

        const coverImageType = get(data, 'coverImageType');
        const listImageType = get(data, 'listImageType');
        const videoLink = get(data, 'videoLink');

        const isClubLevelVisibility = !!get(data, 'isClubLevelVisibility');

        //console.log('isClubLevelVisibility', isClubLevelVisibility)

        const challengeClubs = get(data, 'clubs', []);

        const prerequisites = get(data, 'prerequisites', []);

        const oneValues = data?.oneValues?.map(e => ({
            key: e.id,
            value: e.id,
            label: e.title,
            character: e.character,
            score: e.score,
        })) ?? [];

        const oneAreas = data?.areas?.map(e => ({
            key: e.id,
            value: e.id,
            label: e.title,
            score: e.score,
            imageUrl: e.imageUrl,
            listImageUrl: e.listImageUrl,
        })) ?? [];

        //console.log('oneAreas', oneAreas)

        // related to
        let relatedToInfo = {
            [PrimaryRelatedToFieldName]: data?.primaryRelatedActivity,
           
        }

        if (Array.isArray(data?.relatedActivities) && !!data?.relatedActivities?.length) {
            //relatedToInfo[RelatedToActivitiesFieldName] = data?.relatedActivities
            relatedToInfo[RelatedToListFieldName] = data?.relatedActivities;
        }

        return {
            organisationId,
            title,
            xp,
            difficultyId,
            duration: durationMins,
            durationId,
            isFacilitationNeeded,
            customIntro, 
            introId, 
            description,
            themes,
            skills,
            ages,
            materials,
            steps,
            resources,
            thumbFile,
            imageFile,
            creatorType,
            creatorText,
            poweredByOrganisations,
            coverImageType,
            listImageType,
            videoLink,
            isClubLevelVisibility,
            challengeClubs,
            prerequisites,
            oneValues,
            oneAreas,
            ...creatorProps,
            locationId,
            ...relatedToInfo,
            challengeId: data?.id,
            seasons,
            dailyActivity: data?.dailyActivity ?? false,
        }

    }, [data]);

    const thumbId = get(data, 'thumbId');
    const thumbUrl = get(data, 'thumbUrl');
    const imageId = get(data, 'imageId');
    const imageUrl = get(data, 'imageUrl')
    const introText = get(data, 'introText');

    const { httpPutAsync } = useHttpHelper();

    const udpateChallengeAsync = async (data) => {
        const link = appLinks.updateChallenge.replace('{challengeId}', challengeId)
        console.log('udpate post data', data);
        const organisationData = { ...data };
        const response = await httpPutAsync(link, organisationData);
        console.log('update challenge async', response);

        return response;
    };

    const mutationQueryKey = [queryKeys.updateChallenge, challengeId]

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateChallengeAsync,
    } = useMutation(
        udpateChallengeAsync,
        { mutationKey: mutationQueryKey, }
    );


    const onSubmit = async (postData, redirect = false) => {
        try {

            console.log('on submit', postData);

             const response = await onUpdateChallengeAsync(postData);

              console.log('response', response);

            if (response && response.code === 0) {
                message.success('Challenge was updated successfully');
                await refetchChallengeDetailsAsync();
                redirect && navigate(`/${appRoutes.challenges}`);
            } if (response && response.code !== 0) {
                message.error('Challenge could not be updated')
            }

        } catch (error) {
            console.log(' on submit', error);
        }
    }

    const onBack = () => {
        try {

            navigate(`/${appRoutes.challenges}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const title = get(data, 'title');
    const organisationId = get(data, 'organisationId');

    const {
        hasAgesPermission = false,
        hasMaterialsPermission = false
    } = useMenuPermissionsContext();

    const { 
        hasCreateEditBannersAccess,
        hasLocationAccess, 
    } = useChallengesPermissionContext()

    return (
        <Dashboard subrouting={false} activeItem='challenges'>
            <Spin spinning={isLoading || isUpdating}>

                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Challenges</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <ContainerDiv className='form-container-one'>
                    <CreateEdit
                        formValues={initialValues}
                        isDataLoaded={isSuccess}
                        organisationId={organisationId}
                        thumbId={thumbId}
                        thumbUrl={thumbUrl}
                        imageId={imageId}
                        imageUrl={imageUrl}
                        introText={introText}
                        onSubmit={onSubmit}
                        submitTitle='Save'
                        activeTab={currentTab} 
                        setActiveTabParam={setTabParam}
                        showAges={hasAgesPermission}
                        showMaterials={hasMaterialsPermission}
                        showBannersTab={hasCreateEditBannersAccess}
                        hasLocationAccess={hasLocationAccess}
                        showRelatedToTab
                        />
                </ContainerDiv>
            </Spin>
        </Dashboard>
    );

}