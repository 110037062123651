import { useEffect, useState } from "react";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { toInteger, get } from "lodash";
import { httpPostAsync as httpPostHelper } from "../helpers/httpHelpers";
import { getStoredDataAsync, getStoredJsonDataAsync, storeDataAsync, storeJsonDataAsync } from "../helpers/storageHelpers";
import { useAppContext } from "../common/components/appContext";
import config from '../config/config';
import { appLinks } from "../config/constants";
import useHttpHelper from "../common/hooks/useHttpHelper";
import { usePermssionContext } from "../common/components/permissionContext";
import axios from "axios";


export default function useAppSetup() {

    const { 
        setAuthenticated, 
        axiosInstance, 
        onLogoutAsync, 
        //token 
    } = useAppContext();

    const { httpPostAsync  } = useHttpHelper();

    const { setPermissions } = usePermssionContext();

    const [isSettingUp, setIsSettingUp] = useState(false);
    useEffect(() => {

        let isValidScope = true;

        const getNewAccessTokenAsync = async (failedRequest) => {
            try {
      
              console.log('getting new token using refresh token')
      
              const { refreshToken }  = await getTokenAsync();
      
              const tokenLoginResponse = await httpPostHelper(
                appLinks.refreshTokenLogin,
                { token: refreshToken }
              );

              if (!isValidScope) {
                return Promise.resolve();
              }
      
      
              // refresh token failed
              if (!tokenLoginResponse || tokenLoginResponse.code !== 0) {
                //set unauthorized
                await onLogoutAsync();
                return Promise.resolve();
              }
      
              const { user, token, permissions = {} } = tokenLoginResponse;

              console.log('token refresh login user resonse', tokenLoginResponse)
      
              failedRequest.response.config.headers['Authorization'] = 'Bearer ' + token;


              const loggedInUser = {
                id: toInteger(user.id),
                username: user.username,
                email: user.email,
                langauge: user.language,
                profileImageUrl: user.profileImageUrl,
                profileViewUrl: user.profileViewUrl,
                defaultOrganisationId: user?.defaultOrganisationId,
                activeOrganisationsIds: user?.activeOrganisations,
                organisation: user?.organisation,
            }
      
              setAuthenticated({ user: loggedInUser, token });
              setPermissions(permissions);
      
              return Promise.resolve();
      
            } catch (error) {
              console.log('error refreshing token', error)
            }
          }

        const setupHttpTokenRefreshAsync = async () => {
            try {
      
              createAuthRefreshInterceptor(axiosInstance, getNewAccessTokenAsync);
              return await Promise.resolve();
      
            } catch (error) {
              console.log('error setting http', error);
            }
          }
      

        const getLoggedInUserInfoAsync = async () => {
            try {

                // this is the refresh token
                const { token } = await getTokenAsync();

                console.log('get bearer token', !!token)

                if (!token) {
                    return;
                }

               // console.log('get logged in user info');

                const requestConfig = {
                    headers: { Authorization: `Bearer ${token}` },
                }

                //console.log('get logged in user info');
                const { data = {} } = await axios.post(appLinks.loggedInUserInfo, {}, requestConfig);

                const { user, permissions = {}} = data;

                console.log('logged in user info', user, permissions);
                if (!user|| !user.id) {
                    onLogoutAsync();
                    return;
                }

               // const { user, permissions = {} } = userInfo || {}

                setAuthenticated({ user, token });
                setPermissions(permissions)

            } catch (error) {
                console.log('error getting logged in user info', error);
               
            }
        }

        const getTokenAsync = async () => {
            try {

                const savedInfo = await getStoredJsonDataAsync(config.refreshTokenStorageKey);

                console.log('saved info', savedInfo);

                return { token: savedInfo.token, refreshToken: savedInfo.ref };
                
            } catch (error) {
                console.log('error setting auth token', error);
            }
        }

        const setupAsync = async () => {

            setIsSettingUp(true);
            // set up axios instance, auto set bearer token and auth refresh
            await setupHttpTokenRefreshAsync();
          
            // fetch bearer token from the local storage and set it in context
            // make api call to fetch user info
            // if token is expired it will make new token refresh call      
            await getLoggedInUserInfoAsync();
            setIsSettingUp(false);

        }

        setupAsync();

    }, [])

    const loginAsync = async (email, password, rememberMe = false) => {
        try {

            const data = {
                email,
                password,
                device: 'browser',
                ip: 'local',
            }

            const loginResponse = await httpPostAsync(appLinks.login, data);

            console.log('login resopnse', loginResponse);

            const { code, refreshToken, token, user, useLanguage } = loginResponse || {};

            if (code && code > 0) {
                console.log('login error code', loginResponse)
                return loginResponse
                //set login errors
                // if (code === 1) {
                //   return 'Invalid email or password';
                // } else if (code === 2) {
                //   return 'User not active, please contact your admin'
                // }
            }

            const loggedInUser = {
                id: toInteger(user.id),
                username: user.username,
                email: user.email,
                langauge: user.language,
                profileImageUrl: user.profileImageUrl,
                profileViewUrl: user.profileViewUrl,
                defaultOrganisationId: user?.defaultOrganisationId,
                activeOrganisationsIds: user?.activeOrganisations,
                organisation: user?.organisation,
            }

            let storageData = { token, }

            if (refreshToken && rememberMe) {
                storageData = {
                    ...storageData,
                    ref: refreshToken,
                }
            }

            await storeJsonDataAsync(config.refreshTokenStorageKey, storageData);
            //console.log('saving refresh token in storage', storageData)

            setAuthenticated({ user: loggedInUser, token });
            setPermissions(get(loginResponse, 'permissions', {}));

            return loginResponse;


        } catch (error) {
            console.log('error logging in', error);
        }
    }

    return {
        loginAsync,
        isSettingUp,
    }


}