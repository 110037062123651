import { Avatar, List } from "antd";
import {
    PlayCircleOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { upperFirst } from "lodash";


export function AudioUpload(props) {

    const { name, url } = props;

    return (
        <List.Item>
            <List.Item.Meta
                title={`Audio - ${name || ''}`}
                avatar={<Avatar size={32} style={{ marginRight: 8 }} icon={<PlayCircleOutlined />} />}
            />
            <audio
                controls
                src={url}>
                <a href={url} target="_blank">
                    Download
                </a>
            </audio>
        </List.Item>
    )
}


export function DefaultUpload(props) {

    const { fileType, url } = props

    const typeText = upperFirst(fileType) || ''

    return (
        <List.Item>
            <List.Item.Meta
                title={`Uploaded ${typeText}`}
                avatar={<Avatar size={32} style={{ marginRight: 8 }} icon={<DownloadOutlined />} />}
                description={
                    <a href={url} target="_blank">
                        Download
                    </a>
                }
            />
        </List.Item>
    )
}

