import { useContext } from "react";
import { get } from "lodash";
import PermissionContext, { childPermission, parentPermissions, permissionValues } from ".";


export default function useUsersPermissionContext() {

    const { permissions = {} } = useContext(PermissionContext);

    // console.log('users challenges contex: challenges', permissions)

    const userKey = parentPermissions.user;
    const hasUsersAccess = get(permissions, userKey) === permissionValues.yes;

    const listPermissionKey = `${userKey}.${childPermission.list}`;
    const hasListAccess = hasUsersAccess &&
    get(permissions, listPermissionKey, permissionValues.no) !== permissionValues.no;

    const detailsPermissionKey = `${userKey}.${childPermission.details}`;
    const hasDetailAccess = hasUsersAccess &&
    get(permissions, detailsPermissionKey, permissionValues.no) !== permissionValues.no;

    const createPermissionKey = `${userKey}.${childPermission.create}`;
    const hasCreateAccess = hasUsersAccess &&
    get(permissions, createPermissionKey, permissionValues.no) !== permissionValues.no;

    const editPermisssionKey = `${userKey}.${childPermission.edit}`;
    const hasEditAccess = hasUsersAccess &&
    get(permissions, editPermisssionKey, permissionValues.no) !== permissionValues.no;

    const deletePermissionKey =  `${userKey}.${childPermission.delete}`;
    const hasDeleteAccess = hasUsersAccess &&
    get(permissions, deletePermissionKey, permissionValues.no) !== permissionValues.no;

    const editRolesPermissionKey =  `${userKey}.${childPermission.editRoles}`;
    const hasEditRolesAccess = hasUsersAccess &&
    get(permissions, editRolesPermissionKey, permissionValues.no) !== permissionValues.no;


    // const editOrganisationsPermissionKey =  `${userKey}.${childPermission.editOrganisations}`;
    // const hasEditOrganisationsAccess = hasUsersAccess &&
    // get(permissions, editOrganisationsPermissionKey, permissionValues.no) !== permissionValues.no;
    const hasEditOrganisationsAccess = hasEditAccess;

    // 'users.editRoles': RolePermissionValue.Yes,

    return {
        hasListAccess,
        hasDetailAccess,
        hasCreateAccess,
        hasEditAccess,
        hasDeleteAccess,
        hasEditRolesAccess,
        hasEditOrganisationsAccess,
    }

}