import { Button, Col, Input, Row, Spin } from 'antd';
import { debounce, get } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../../common/components/layout';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import UnsubscribeEmailsTable from './Table';

import '../index.scss';
import useUnsubscribeEmailsFilterContext from './useFilterContext';

const { Search } = Input;

const pageSize = 10;

export default () => {

  const navigate = useNavigate();

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useUnsubscribeEmailsFilterContext();

  const {
    pageNo,
    query,
    sortBy,
    sortOrder,
    organisations = [],
  } = searchParams;

  const [searchValue, setSearchValue] = useState(query);

  const { httpPostAsync } = useHttpHelper();

  const getSentEmails = async () => {
    // console.log('call api:page param', pageParam, searchCriteria);

    const response = await httpPostAsync(
      appLinks.unsubscribedEmailList,
      {
        itemPerPage: pageSize,
        query,
        pageNo,
        sortBy,
        sortOrder,
       // filterOrganisationIds: map(organisations, s => s.value),
      }
    );

    const data = response?.data 

    let emailsList = data?.data ?? []
    emailsList = emailsList.map(e => {

      return {
        ...e,
        appZoneCreatedAt: getAppZoneMomentFromUTC(e.createdAt),
      }

    })

    console.log('sent emails', response)

    return {
      total: data?.total ?? 0,
      data: emailsList,
    };
  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.unsubscribedEmailList, pageNo, query, sortBy, sortOrder];

    console.log('filter key', key)

    return key;

  }, [pageNo, query, sortBy, sortOrder]);


  const {
    isLoading,
    //isError,
    //error,
    data = {},
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    queryKey,
    getSentEmails,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })

  let { data: listItems = [], total = 0, } = data ?? {}

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));

    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  const onSearch = async (text) => {
    try {

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }));

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onSearch);
  searchUsersRef.current = onSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const navigateToDetails = (id) => {
    try {

      navigate(`/${appRoutes.sendEmailDetails}/${id}`)

    } catch (error) {
      console.log('error navigating to sent email details', error);
    }
  }

  const navigateWithCopy = (id) => {
    try {

      navigate(`/${appRoutes.sendEmails}`, { state: {
        copyId: id
      } })

    } catch (error) {
      console.log('error navigating to copy email details', error);
    }
  }

  // const onBack = () => {
  //   try {

  //     navigate(`/${appRoutes.emails}`);

  //   } catch (error) {
  //     console.log('error on back click', error);
  //   }
  // }

  let hasDetailAccess = true;

  const navigateToEmails = () => {
    try {

      navigate(`/${appRoutes.emails}`)

    } catch (error) {
      console.log('error navigating to send emails', error);
    }
  }

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);
      //searchFieldRef.current.input.value = '';
      // console.log('search ref', searchFieldRef.current)

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  const [tagsVisible, setTagsVisible] = useState(false);

  const styles = useMemo(() => ({
    itemStyle: {
      paddingRight: 16,
      paddingBottom: 18,
    },
    tagsContainer: {
      paddingTop: tagsVisible ? 16 : 0,
      paddingBottom: tagsVisible ? 8 : 0

    },
    challengeTableContainer: {
      paddingTop: tagsVisible ? 0 : 17
    }
  }), [tagsVisible])

  useEffect(() => {

    const searchTagsLen = organisations.length;

    setTagsVisible(searchTagsLen > 0)

  }, [organisations])

  return (
    <AppLayout activeItem='emails'>
      <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
        <Col>
          <Row>
            <Col style={{ paddingRight: 15 }} >
              <Search
                placeholder="Search"
                value={searchValue}
                onChange={onSearchChange}
                allowClear
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
        <Col >
          <Button
            type="primary"
            block
            onClick={navigateToEmails}
          >
            Send Email List
          </Button>
        </Col>
      </Row>
      <Row style={styles.tagsContainer} justify="space-between">
        {/* <Col>
          <div style={{ marginBottom: 8 }}>
            {map(organisations, org => {

              return (
                <TagChip key={org.key} closable onClose={() => {
                  setSearchParams(p => {

                    //console.log('key, organisations', org.key, typeof org.key, p.organisations)

                    const filtered = filter(p.organisations, o => o.key !== org.key);

                    //console.log('filtered organisations', filtered)

                    return {
                      ...p,
                      organisations: filtered,
                    }

                  })
                }}>
                  {org.label}
                </TagChip>
              )

            })}
          </div>
        </Col> */}
        <Col>
          {
            isSearchActive
              ? (
                <Button type="link" size='small' onClick={onReset}>
                  Reset
                </Button>
              )
              : null
          }
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <UnsubscribeEmailsTable
          key={resetKey}
          listItems={listItems}
          onPagniationChange={onPagniationChange}
          onSortChange={onSortChange}
          navigateToDetails={navigateToDetails}
          pageNo={pageNo}
          total={total}
          defaultPageSize={pageSize}
          hasDetailAccess={hasDetailAccess}
          navigateWithCopy={navigateWithCopy}
        />
      </Spin>
    </AppLayout>
  );

}