import { BackTop, Button, Col, Input, List, Row, Select, Spin } from 'antd';
import { debounce, filter, flatMap, get, join, map } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import AppLayout from '../../../common/components/layout';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys, reportedPostsType } from '../../../config/constants';

import { VerticalAlignTopOutlined } from '@ant-design/icons';
import TagChip from '../../../common/components/tagchip';
import PostCard from './PostCard';
import usePostFilterContext from './usePostFilterContext';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../../common/components/dashboard';

const { Option } = Select;
const { Search } = Input;

const pageSize = 10;

export default function Posts() {

    const { httpPostAsync } = useHttpHelper();

    const navigate = useNavigate();

    const [tagsVisible, setTagsVisible] = useState(false);

    const styles = useMemo(() => ({
        itemStyle: {
            paddingRight: 16,
            paddingBottom: 18,
        },
        tagsContainer: {
            paddingTop: tagsVisible ? 16 : 0,
            paddingBottom: tagsVisible ? 8 : 0

        },
        challengeTableContainer: {
            paddingTop: tagsVisible ? 0 : 17
        }
    }), [tagsVisible])


    const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = usePostFilterContext();

    const {
        //pageNo = 0,  // page no is handled by useInfiniteQuery, rest of the parameter needs to be in query key
        query = '',
        reportedTypes = [],
        organisations = [],
        difficultyLevels = [],
        durations = [],
        types = [],
        statuses = [],
        themes = [],
        skills = [],
        sortBy = null,
        sortOrder = null,
    } = searchParams

    // useEffect(() => {

    //     console.log('reported types', reportedTypes)

    // }, [reportedTypes])

    const fetchPostsAsync = async (fetchParams) => {

        const { pageParam = 0 } = fetchParams || {}

        let searchData = {
            query,
            itemPerPage: pageSize,
            sortBy,
            sortOrder,
            pageNo: pageParam,
            reportedTypes: map(reportedTypes, s => s.value),
        }

        console.log('fetch posts params:', fetchParams, searchData);

        const response = await httpPostAsync(appLinks.challengePosts, searchData);

        console.log('fetch posts', response);

        return response;
    };

    const queryKey = useMemo(() => {

        const key = [queryKeys.challengePosts, query, sortBy, sortOrder]

        let reportedTypesList = '0';
        if (reportedTypes.length) {
            const reportedTypesListValues = map(reportedTypes, s => s.value);
            reportedTypesList = join(reportedTypesListValues, '.');
        }
        key.push(reportedTypesList)


        console.log('filter query key', key)

        return key;

    }, [query, sortBy, sortOrder, reportedTypes])



    const {
        data: postsPages,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching: isLoadingPosts,
        isLoading,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery(queryKey, fetchPostsAsync, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {

            if (lastPage && lastPage.nextPage) {
                console.log('getNextPageParam: has next page', lastPage)
                return lastPage.nextPage;
            }

        },
    })

    const [searchValue, setSearchValue] = useState(query);

    const searchPosts = async (text) => {
        try {

            setSearchParams(p => ({ ...p, query: text }));

        } catch (error) {
            console.log('error searching', error);
        }
    }

    const searchUsersRef = useRef(searchPosts);
    searchUsersRef.current = searchPosts;

    const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

    const onSearchChange = async (e) => {
        setSearchValue(e.target.value)
        debouncedSearch(e.target.value)
    }


    const postList = useMemo(() => {

        const pages = get(postsPages, 'pages');

        const list = flatMap(pages, p => [...(get(p, 'data') || [])]);

        return list;

    }, [postsPages])

    const [resetKey, setResetKey] = useState(0);

    const onReset = () => {
        try {

            resetSearchParams();
            setSearchValue('');
            setResetKey(k => k + 1);

        } catch (error) {
            console.log('error on resetting filters', error);
        }
    }

    const onReportTypeChange = (values, props) => {
        try {

            console.log('report types', values, props)

            const mappedItems = map(values, p => ({ key: p.key, value: p.value, label: p.label }));

            console.log('mapped report types', mappedItems);

            setSearchParams(p => ({ ...p, reportedTypes: [...mappedItems], pageNo: 0 }))

        } catch (error) {
            console.log(`error in ${onReportTypeChange.name}`, error)
        }
    }

    const navigateToUser = (userId) => navigate(`/${appRoutes.dashboard}/${appRoutes.userView}/${userId}`)


    const loadMore =
        (hasNextPage) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button
                    loading={isLoadingPosts}
                    onClick={() => {
                        console.log('on next click', hasNextPage);
                        if (hasNextPage) {
                            //setSearchParams(p => ({ ...p, pageNo: p.pageNo + 1 }));
                            fetchNextPage()
                        }

                    }}>load more</Button>
            </div>
        ) : null;

    return (
        <Dashboard subrouting={false} activeItem='posts'>
            <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
                <Col>
                    <Row>
                        <Col style={styles.itemStyle}>
                            <Search
                                placeholder="Search"
                                value={searchValue}
                                onChange={onSearchChange}
                                allowClear
                                style={{ width: 200 }}
                                key={resetKey}
                            />
                        </Col>
                        <Col style={styles.itemStyle} >
                            <Select
                                mode="multiple"
                                style={{ width: 200 }}
                                placeholder="Reported"
                                onChange={onReportTypeChange}
                                labelInValue
                                value={map(reportedTypes, s => ({ value: s.value, label: s.label }))}
                                tagRender={() => null}
                            >  {
                                    map(reportedPostsType, s => {
                                        return (
                                            <Option key={s.value} value={s.value}>{s.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {/* <Button
                            type="primary"
                            data-testid='create-skill'
                            disabled={!hasSkillsCreateAccess}
                            block
                            onClick={showCreateModal}
                        >
                            New Skill
                        </Button> */}
                </Col>
            </Row>
            <Row style={styles.tagsContainer} justify="space-between">
                <Col>
                    <div style={{ marginBottom: 8 }}>
                        {map(reportedTypes, org => {

                            //  console.log('reportedTypes tag', org)

                            return (
                                <TagChip key={org.key}
                                    closable
                                    //style={styles.searchTags}
                                    onClose={() => {
                                        setSearchParams(p => {

                                            console.log('remove', p, org)

                                            const filtered = filter(p.reportedTypes, o => o.key !== org.key);

                                            console.log('filtered reportTypes', filtered)

                                            return {
                                                ...p,
                                                reportedTypes: filtered,
                                            }

                                        })
                                    }}>
                                    {org.label}
                                </TagChip>
                            )

                        })}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col push={23}>
                    {
                        isSearchActive
                            ? (
                                <Button type="link" size='small' onClick={onReset} style={{ marginBottom: 8 }} >
                                    Reset
                                </Button>
                            )
                            : null
                    }
                </Col>
            </Row>
            <Spin spinning={isLoading || isLoadingPosts}>
                <List
                    key={resetKey}
                    style={{
                        backgroundColor: '#fff',
                        paddingBottom: 16,
                    }}
                    bordered
                    dataSource={postList}
                    itemLayout="vertical"
                    size="large"
                    loading={isLoadingPosts}
                    loadMore={loadMore}
                    //  pagination={{
                    //     current: pageNo + 1,
                    //     defaultPageSize: pageSize,
                    //     //hideOnSinglePage: true,
                    //     total,
                    // }}
                    rowKey={item => item.id}
                    renderItem={item => {
                        return (<PostCard key={item.id} item={item} navigateToUser={navigateToUser} />)
                    }}
                />
                <BackTop>
                    <div >
                        <Button type="primary" shape="circle" icon={<VerticalAlignTopOutlined />} size='large' />
                    </div>
                </BackTop>
            </Spin>
        </Dashboard>
    )


}