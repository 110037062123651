import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const emailListFilterConfig = {
    key: 'emailsList',
    isActiveProp: 'isEmailListSearchActive',
    setAction: 'email-list',
    resetAction: 'rest-email-list',
    setFilterProp: 'setEmailListFilters',
    resetFilterProp: 'resetEmailListFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useEmailsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, emailListFilterConfig)
}

export default useEmailsFilterContext;