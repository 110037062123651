import React, { useCallback } from "react";
import { Form } from "antd";
import { filter } from "lodash";
import TagChip from "../../../../../common/components/tagchip";



export default ({ 
    field, 
    onRemoveCallback, 
    getColor 
}) => {

    const shouldUpdate = useCallback(() =>  (prev, cur) => prev[field] !== cur[field], [field])

    return (
        <Form.Item shouldUpdate={shouldUpdate}>
            {(props) => {

                const items = props.getFieldValue(field);

                const onClose = (itemId) => {

                    const items = props.getFieldValue(field);

                    //console.log('themes', themeId, themesValue);

                    const filtered = filter(items, o => o.value !== itemId);

                    //console.log('filtered themes', filtered)

                    props.setFieldsValue({ [field]: filtered })

                   typeof onRemoveCallback === 'function' && onRemoveCallback(itemId, filtered);

                }

                return (
                    <React.Fragment>
                        {items?.map(item => {

                            //console.log('props', props);
                            const color = typeof getColor === 'function' && getColor(item)

                            return (
                                <TagChip
                                    color={color}
                                    key={item.value}
                                    closable
                                    onClose={() => onClose(item.value)}>
                                    {item.label}
                                </TagChip>
                            )
                        })}
                    </React.Fragment>
                );
            }}
        </Form.Item>)
}