import { Breadcrumb, Button, Row, Spin, message } from 'antd';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import CreateEdit from '../common/createEdit';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Dashboard from '../../../common/components/dashboard';


const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;


export default ({ }) => {

    let { clubId } = useParams();

    const navigate = useNavigate();

    //console.log('params', params);

    const { httpGetAsync } = useHttpHelper();

    const fetchClubAsync = async () => {
        try {

            const creator = await httpGetAsync(appLinks.clubDetails.replace('{clubId}', clubId));

            console.log('club', creator);

            return creator;

        } catch (error) {
            console.log('error fetching creator details', error);
        }
    }

    const {
        data,
        isLoading,
        isSuccess
    } = useQuery(
        [queryKeys.clubDetailsView, clubId],
        fetchClubAsync,
        {
            enabled: clubId > 0,
            refetchOnWindowFocus: false
        }
    );

    const initialValues = useMemo(() => {

        const organisationId = get(data, 'organisationId');
        const name = get(data, 'name');
        const isActive = get(data, 'isActive');

        return {
            organisationId,
            name,
            isActive,
        }

    }, [data]);

    const { httpPutAsync } = useHttpHelper();

    const udpateClubAsync = async (data) => {
        const link = appLinks.updateClub.replace('{clubId}', clubId)
        // console.log('accept challenge link', link);
        console.log('udpate post data', data);
        const createPersonData = { ...data };
        const response = await httpPutAsync(link, createPersonData);
        console.log('update club async', response);

        return response;
    };

    const mutationQueryKey = [queryKeys.updateClub, clubId]

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateClubAsync,
    } = useMutation(
        udpateClubAsync,
        { mutationKey: mutationQueryKey, }
    );

    const onSubmit = async (postData, redirect = false) => {
        try {

            console.log('on submit', postData);

            const response = await onUpdateClubAsync(postData);

            if (response && response.code === 0) {
                message.success('Club was updated successfully');
                redirect && navigate(`/${appRoutes.clubs}`);
            } else if (response && response.code !== 0) {
                message.error('Club could not be updated')
            }

        } catch (error) {
            console.log(' on submit', error);
        }
    }

    const onBack = () => {
        try {

            navigate(`/${appRoutes.clubs}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const name = get(data, 'name');

    return (
        <Dashboard subrouting={false} activeItem='clubs'>
            <Spin spinning={isLoading || isUpdating}>

                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Clubs</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>

                <ContainerDiv className='form-container-one'>
                    <CreateEdit
                        formValues={initialValues}
                        onSubmit={onSubmit}
                        submitTitle='Save' />
                </ContainerDiv>
            </Spin>
        </Dashboard>
    );

}