import { Form } from "antd"
import LookupSelect from "../../../../../../common/components/lookupSelect";
import useOrganisationsLookups from "../../../hooks/useOrganisationsLookups";


function defaultItemPropMapper({ value, label, logoLink, website }) {
    return {
        key: value,
        value,
        link: logoLink,
        website,
        name: label,
        children: label,
    }
}


export default ({ organisationId, form }) => {

    //console.log(' creator organisation id', organisationId);

    const {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    } = useOrganisationsLookups(organisationId, undefined, true);

    const onChange = (e, params) => {
        try {

            console.log('on organisation select', e, params)

            if (params) {

            const creatorOrganisation = {
                key: params.key,
                id: params.value,
                name: params.name,
                logoLink: params.link,
                website: params.website,
            }

            form.setFieldsValue({ creatorOrganisation })

        } else {
            form.setFieldsValue({ creatorOrganisation: undefined })
        }

        } catch (error) {
            console.log('error on creator organistions on change', error)
        }

    }

    return (
        <Form.Item
            name="creatorOrganisationId"
            label="Organisation"
            //rules={[{ required: true, message: 'Please choose an creator organisation.' }]}
            wrapperCol={{ span: 16 }}
        >
            <LookupSelect
                placeholder="Select Organisation"
                queryParams={organisationParams}
                fetchAsync={fetchOrganisationsAsync}
                queryKeyFn={organisationQueryKeyFn}
                lookupId='creator-organisations'
                onChange={onChange}
                itemPropsMapperFn={defaultItemPropMapper}
                allowClear
            />
        </Form.Item>
    )


}