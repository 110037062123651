import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const usersFilterConfig = {
    key: 'users',
    isActiveProp: 'isUsersSearchActive',
    setAction: 'users',
    resetAction: 'reset-users',
    setFilterProp: 'setUsersFilters',
    resetFilterProp: 'resetUsersFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        types: [],
        statuses: [],
        organisations: [],
        sortBy: null,
        sortOrder: null,
    }
}

const useUsersFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, usersFilterConfig)
}

export default useUsersFilterContext;
