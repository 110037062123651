import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const themesFilterConfig = {
    key: 'themes',
    isActiveProp: 'isThemesSearchActive',
    setAction: 'themes',
    resetAction: 'reset-themes',
    setFilterProp: 'setThemesFilters',
    resetFilterProp: 'resetThemesFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useThemesFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, themesFilterConfig)
}

export default useThemesFilterContext;
