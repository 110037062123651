import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from '../../../common/components/layout';
import NotificationDetails from './view';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import { Breadcrumb, Button, Row } from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { filter, get, isEmpty, join, map } from 'lodash';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import appConfig from '../../../config/config';
import Dashboard from '../../../common/components/dashboard';


export default () => {

    let { notificationId } = useParams();

    const navigate = useNavigate();

    const { httpGetAsync } = useHttpHelper();

    const fetchDetailsAsync = async () => {
        try {

            const response = await httpGetAsync(appLinks.sentNotificationDetails.replace('{notificationId}', notificationId));

            console.log('notification details', response);

            let extraInfo = {};

            let createdAt = get(response, 'data.createdAt');
            const mCreatedAt = getAppZoneMomentFromUTC(createdAt);

            if (mCreatedAt && mCreatedAt.isValid()) {
                extraInfo = {
                    createdAt: mCreatedAt.format('Do MMMM, YYYY HH:mm')
                }
            }

            const recipients = get(response, 'data.recipients');
            let emails = map(recipients, r => r.email);
            emails = filter(emails, e => !!e);

            if (!isEmpty(emails)) {
                const delimitedEmails = join(emails, ', ');
                extraInfo = {
                    ...extraInfo,
                    delimitedEmails
                }
            }

            return {
                ...response,
                data: {
                    ...(get(response, 'data') || {}),
                    ...extraInfo
                }
            }

        } catch (error) {
            console.log('error fetching notification details', error);
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.notificationDetails, notificationId],
        fetchDetailsAsync,
        {
            enabled: notificationId > 0,
            refetchOnWindowFocus: false
        }
    );

    const {
        data: details = {}
    } = data || {}

    const {
        createdAt,
        recipientCount,
        template,
        type,
        recipients = [],
        delimitedEmails,
        pushNotificationsSent = false,
    } = details;

    console.log('data', data);


    const onBack = () => {
        try {

            navigate(`/${appRoutes.notifications}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }


    return (
        <Dashboard subrouting={false} activeItem='notifications'>
             <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Notifications</a></Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
            <NotificationDetails 
             createdAt={createdAt}
             recipientCount={recipientCount}
             template={template}
             type={type}
             recipients={recipients}
             delimitedEmails={delimitedEmails}
             pushNotificationsSent={pushNotificationsSent}
            />
        </Dashboard>
       
    )

}