import { BackTop, Button, Col, Form, Image, List, Modal, Row, Spin, message } from 'antd';
import { debounce, flatMap, get } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';

import { useAppContext } from '../../../common/components/appContext';
import Dashboard from '../../../common/components/dashboard';
import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import useThemesPermissionsContext from '../../../common/components/permissionContext/useThemesPermissions';
import Search from '../../../common/components/search';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../../config/constants';
import CreateForm from '../create';
import EditForm from '../edit';
import useThemesFilterContext from './useThemesFilterContext';

import { VerticalAlignTopOutlined } from '@ant-design/icons';

import '../index.scss';
import ItemCard from './ItemCard';
import { DndContext, DragOverlay, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, rectSwappingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { updateThemeListHOF } from '../../../common/hooks/queryHelpers';
import EditDateRange from './EditDateRanges';

const pageSize = 10;

export default ({ }) => {

    const { httpPostAsync, httpPutAsync, httpDeleteAsync } = useHttpHelper();

    const { user } = useAppContext();

    const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useThemesFilterContext();

    const {
        //pageNo = 0,
        query = '',
        organisationId = user.defaultOrganisationId,
        sortBy = 'sortOrder',
        sortOrder = null,
    } = searchParams

    const queryClient = useQueryClient();

    //#region organsation dropdown and default value

    const onOrganisationChange = (e) => {
        try {

            console.log('organisations id', e);
            setSearchParams({ organisationId: e });

        } catch (error) {
            console.log('error on organisation change', error);
        }
    }
    //#endregion


    const fetchThemesAsync = async (fetchParams) => {

        const { pageParam = 0 } = fetchParams ?? {}

        const filterParams = {
            itemPerPage: pageSize,
            organisationId: organisationId,
            query,
            pageNo: pageParam,
            sortBy,
            sortOrder
        }


        const response = await httpPostAsync(appLinks.adminThemes, { ...filterParams });

        return response;
    };

    const queryKey = useMemo(() => {

        const key = [queryKeys.settingsThemesList, organisationId, query, sortBy, sortOrder];

        return key;

    }, [user, organisationId, query, sortBy, sortOrder])

    const isTextSearchActive = typeof query === 'string' && query.trim().length > 0

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching: isLoadingMore,
        isLoading,
        isFetchingNextPage,
        status,
        refetch,
    } = useInfiniteQuery(queryKey, fetchThemesAsync, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {

            // data: []
            // itemPerPage: 10
            // nextPage: 1
            // total: 26
            // totalPages: 2

            //console.log('getNextPageParam', { ...lastPage } , { ...pages });

            if (lastPage && lastPage.nextPage) {
                //console.log('getNextPageParam: has next page', lastPage)
                return lastPage.nextPage;
            }

        },
    })

    const [resetKey, setResetKey] = useState(0);

    const themes = useMemo(() => {

        console.log('map on data change')

        const pages = data?.pages ?? []

        const list = flatMap(pages, p => [...(p?.data ?? [])]);

        return list;

    }, [data, resetKey])

    //#region Edit TODO move to hook
    const editForm = Form.useForm();
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [editTheme, setEditTheme] = useState();

    const showEditModal = (theme) => {
        console.log('show edit modal theme', theme)
        setIsEditVisible(true);
        setEditTheme({ ...theme, organisationId })
    }

    const closeEditModal = () => setIsEditVisible(false);

    const onEditClick = () => {
        try {

            editForm[0].submit();

        } catch (error) {
            console.log('error on edit theme modal', error);
        }
    }

    const udpateThemeAsync = async (data) => {

        console.log('updated data', data);

        const link = appLinks.updateTheme.replace('{themeId}', editTheme.id)
        // console.log('accept challenge link', link);
        console.log('udpate post data', data);
        const themeData = { ...data, organisationId };
        const response = await httpPutAsync(link, themeData);
        console.log('update theme async', response);

        return response;
    };

    const editThemeId = editTheme && editTheme.id;

    const mutationQueryKey = useMemo(() => {
        return [queryKeys.updateTheme, organisationId, editThemeId]
    }, [organisationId, editThemeId])

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateChallengeAsync,
    } = useMutation(
        udpateThemeAsync,
        { mutationKey: mutationQueryKey, }
    );

    const onUpdateAsync = async (values) => {
        try {

            console.log('on update callback', values);

            const response = await onUpdateChallengeAsync(values);

            if (response.code === 0) {
                refetch();
                closeEditModal();
                message.success('Theme was updated successfully');

            } if (response && response.code !== 0) {
                message.error('Theme could not be updated');
                closeEditModal();
            }

            console.log('updated resonse', response);

        } catch (error) {
            console.log('on update async', error);
        }
    }

    //#endregion

    //#region create theme
    const createForm = Form.useForm();
    const [isCreateVisible, setIsCreateVisible] = useState(false);

    const showCreateModal = () => setIsCreateVisible(true)

    const closeCreateModal = () => setIsCreateVisible(false);

    const onCreateClick = () => {
        try {

            createForm[0].submit();

        } catch (error) {
            console.log('error on create theme modal submit', error);
        }
    }

    const createThemeAsync = async (data) => {

        console.log('create data', data);

        const link = appLinks.createTheme
        // console.log('accept challenge link', link);
        console.log('create post data', data);
        const themeData = { ...data, organisationId };
        const response = await httpPostAsync(link, themeData);
        console.log('create theme async', response);

        return response;
    };

    const mutationCreateQueryKey = useMemo(() => {
        return [queryKeys.createTheme, organisationId]
    }, [organisationId])

    const {
        // data: updatePersonResponse,
        isLoading: isCreating,
        mutateAsync: createNewThemeAsync,
    } = useMutation(
        createThemeAsync,
        { mutationKey: mutationCreateQueryKey, }
    );

    const onCreateAsync = async (values) => {
        try {

            console.log('on create callback', values);

            const response = await createNewThemeAsync(values);

            if (response.code === 0) {
                refetch();
                closeCreateModal();
                message.success('Theme was created successfully');

            } if (response && response.code !== 0) {
                message.error('Theme could not be created');
                closeCreateModal();
            }

            console.log('updated resonse', response);

        } catch (error) {
            console.log('on update async', error);
        }
    }

    //#endregion 

    const styles = useMemo(() => ({
        itemStyle: {
            paddingRight: 16,
            paddingBottom: 18,
        },
    }), [])

    const onSearch = (e) => {
        try {
            console.log('on search list', e);
            setSearchParams(p => ({ ...p, query: e }));
        } catch (error) {
            console.log('error on search change', error);
        }
    }

    //#region delete

    const [deleteThemeId, setDeleteThemeId] = useState();

    const deleteMutationQueryKey = [queryKeys.deleteTheme, deleteThemeId]

    const deleteThemeAsync = async (themeId) => {
        try {

            const link = appLinks.deleteTheme.replace('{themeId}', themeId);
            const deleteResponse = await httpDeleteAsync(link);

            console.log('delete response', deleteResponse);

            return deleteResponse;

        } catch (error) {
            console.log('error deleting theme', error);
        }
    }

    const {
        // data: updatePersonResponse,
        isLoading: isDeleting,
        mutateAsync: onDeleteThemeAsync,
    } = useMutation(
        deleteThemeAsync,
        { mutationKey: deleteMutationQueryKey, }
    );

    const onDelete = (themeId, name) => {
        try {

            console.log('delete theme', themeId);
            Promise.resolve(setDeleteThemeId(themeId))

            Modal.confirm({
                title: `Are you sure you want to delete ${name}`,
                okText: 'Delete',
                closable: true,
                okButtonProps: {
                    loading: isDeleting,
                    disabled: isDeleting,
                },
                onOk: async () => {
                    console.log('on delete click');

                    const deleteResponse = await onDeleteThemeAsync(themeId);

                    if (deleteResponse && deleteResponse.code === 0) {
                        message.success('Theme was deleted successfully');
                    } else {
                        message.error('We are facing some issues, Theme could not be deleted');
                    }

                    await refetch();

                }

            });

        } catch (error) {
            console.log('error deleting theme', error);
        }
    }

    //#endregion

    const {
        hasThemesCreateAccess = false,
        hasThemesEditAccess = false,
        hasThemesDeleteAccess = false,
    } = useThemesPermissionsContext();

    //#region udpate sort order api

    const [updateSortKey, setUpdateSortKey] = useState([queryKeys.updateThemeSort])

    const updateSortAsync = async (data) => {
        try {
            // console.log('old index ', fromTheme, 'new index ', toTheme)
            const link = appLinks.updateThemeSort;

            const postData = {
                themes: data
            }

            console.log('postData', postData)
            const deleteResponse = await httpPostAsync(link, postData);

            return deleteResponse;

        } catch (error) {
            console.log('error updating theme order', error);
        }
    }

    const {
        // data: updatePersonResponse,
        isLoading: isUpdatingSortOrder,
        mutateAsync: onUpdateSortOrder,
    } = useMutation(
        updateSortAsync,
        { mutationKey: updateSortKey, }
    );

    const onUpdateSortAsync = async (fromTheme, toTheme) => {
        try {


            console.log('old index ', fromTheme, 'new index ', toTheme)

            const updateResponse = await onUpdateSortOrder([fromTheme, toTheme]);

            console.log('update response', updateResponse)

            return updateResponse


        } catch (error) {
            console.log('error deleting theme', error);
        }
    }

    //#endregion

    //#region Drag n Drop

    const getActiveItem = (id) => {
        const item = themes?.find(e => e.id === id)
        return item
    }

    const [activeId, setActiveId] = useState(null);
    const [activeItem, setActiveItem] = useState({});

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragStart(event) {

        const { active } = event;
        setActiveId(active.id)
        const item = getActiveItem(active.id)
        //console.log('item', item);
        setActiveItem(item);

    }

    async function handleDragEnd(event) {

        const { active, over } = event;
        //console.log('drag end', event);
        // update the item and reload 
        if (active.id !== over.id) {

            // console.log('active item', activeItem)

            setUpdateSortKey([queryKeys.updateThemeSort, active.id, over.id])

            const oldItem = getActiveItem(active.id);
            const oldIndex = oldItem.sortOrder ?? (oldItem.index + 1)

            const newItem = getActiveItem(over.id);
            const newIndex = newItem.sortOrder ?? (oldItem.newItem + 1)

            const fromTheme = { id: oldItem.id, sortOrder: newIndex }
            const toTheme = { id: newItem.id, sortOrder: oldIndex }

            console.log('old index ', fromTheme, 'new index ', toTheme)

            // for quick response in UI before api call
            queryClient.setQueryData(queryKey,
                updateThemeListHOF(
                    { ...oldItem, sortOrder: newIndex },
                    { ...newItem, sortOrder: oldIndex }
                ));

            setResetKey(k => k + 1);
            //queryClient.setQueryData(queryKey, updateThemeListHOF(newItem.id, { sortOrder:  newIndex }));

            const response = await onUpdateSortAsync(fromTheme, toTheme)

            console.log('update theme response', response)

            if (response?.code === '0') {
                message.success('Theme ordered successfully');
            } else {
                message.error('We are facing some issues, Theme sort order could not be changed');
            }

            await refetch()

        }

        setActiveId(null);
        setActiveItem({});
    }

    //#endregion

    const onReset = () => {
        resetSearchParams();
        setResetKey(k => k + 1);
    }

    const [isBusy, setIsBusy] = useState(false);

    //#region toggle visibility

    const toggleVisibilityStatus = async (themeId, checked) => {
        try {

            setIsBusy(true);
            console.log('on toggle visibility', themeId, checked)
            //let payload = { themeId: item.id };

            const response = await httpPostAsync(appLinks.toggleThemeVisibilityStatus.replace('{themeId}', themeId));

            console.log('toggle visibilty response', response)

            const isSuccess = response?.code === '0'

            if (isSuccess) {
                const operation = response?.data?.status === 'hidden' ? 'Hidden' : 'Visible'
                message.success(`Theme visibilty changed ${operation} `);
            } else {
                message.error(`Theme visibilty could not be changed`);
            }

            setIsBusy(false);
            await refetch();

        } catch (error) {
            console.log('change visibility status', error);
        }
    }

    const toggleVisibilityRef = useRef(toggleVisibilityStatus);
    toggleVisibilityRef.current = toggleVisibilityStatus;

    const debouncedVisibilityToggle = useCallback(debounce(toggleVisibilityRef.current, 300), [])

    //#endregion toggle visibility

    //#region toggle Enable date range 

    const toggleEnableDate = async (themeId, checked) => {
        try {

            setIsBusy(true);
            //console.log('on toggle visibility', themeId, checked)
            //let payload = { themeId: item.id };

            const response = await httpPostAsync(appLinks.toggleThemeDateRange.replace('{themeId}', themeId));

            // console.log('toggle visibilty response', response)

            const isSuccess = response?.code === '0'

            if (isSuccess) {
                const operation = response?.data?.hasDateRange ? 'Enable' : 'Disabled'
                message.success(`Date range ${operation}`);
            } else {
                message.error(`Date range could status not be updated`);
            }

            setIsBusy(false);
            await refetch();

        } catch (error) {
            console.log('change visibility status', error);
        }
    }

    const toggleDateRangeRef = useRef(toggleEnableDate);
    toggleDateRangeRef.current = toggleEnableDate;

    const debouncedDateRangeToggle = useCallback(debounce(toggleDateRangeRef.current, 300), [])


    //#endregion toggle Enable date range


    //#region udpate date range

    const dateRangeForm = Form.useForm();

    const [isDateRangeVisible, setIsDateRangeVisible] = useState(false);

    const closeDateRangeModal = () => setIsDateRangeVisible(false);

    const showDateRangeModal = (theme) => {
        //console.log('show date range', theme)
        setIsDateRangeVisible(true);
        setEditTheme({ ...theme, organisationId })
    }

    const onDateRangeOkClick = () => {
        try {
            dateRangeForm[0].submit();
        } catch (error) {
            console.log('error on date range modal submit', error);
        }
    }

    const updateDateRangeAsync = async (data) => {

        console.log('date range data', data);

        const link = appLinks.themeUpdateDateRange.replace('{themeId}', editTheme.id)
        const response = await httpPostAsync(link, data);
        console.log('update theme async', response);

        return response;
    };

    const onUpdateDateRangeAsync = async (data) => {
        try {

            setIsBusy(true)
            const response = await updateDateRangeAsync(data);
            setIsBusy(false)
            if (response.code === '0') {
                refetch();
                closeDateRangeModal();
                message.success('Theme date range updated successfully');

            } else {
                message.error('Theme date range could not be updated');
                closeDateRangeModal();
            }

            console.log('updated resonse', response);

        } catch (error) {
            console.log('on update async', error);
            setIsBusy(false)
        }
    }


    //#endregion

    const showSpinner = isLoading || isLoadingMore || isBusy

    const loadMore =
        (hasNextPage) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button
                    loading={isLoadingMore}
                    onClick={() => {
                        console.log('on next click', hasNextPage);
                        if (hasNextPage) {
                            //setSearchParams(p => ({ ...p, pageNo: p.pageNo + 1 }));
                            fetchNextPage()
                        }

                    }}>load more</Button>
            </div>
        ) : null;

    return (
        <div className='settings-theme'>
            <Dashboard subrouting={false} openKeys={['settings']} activeItem='settings-themes'>
                <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
                    <Col>
                        <Row>
                            <Col style={{ paddingRight: 15 }}>
                                <OrganisationsLookup
                                    mode='single'
                                    onChange={onOrganisationChange}
                                    defaultValue={organisationId}
                                    value={organisationId}
                                    style={{ width: 200 }}
                                    placeholder="Select Organisation"
                                    showSearch
                                    labelInValue={false}
                                />
                            </Col>
                            <Col style={styles.itemStyle}>
                                <Search
                                    key={resetKey}
                                    placeholder="Search"
                                    initialValue={query}
                                    onSearch={onSearch}
                                    allowClear
                                    style={{ width: 200 }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            data-testid='create-theme'
                            block
                            disabled={!hasThemesCreateAccess}
                            onClick={showCreateModal}
                        >
                            New Theme
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col push={23}>
                        {
                            isSearchActive
                                ? (
                                    <Button type="link" size='small' onClick={onReset} style={{ marginBottom: 8 }} >
                                        Reset
                                    </Button>
                                )
                                : null
                        }
                    </Col>
                </Row>
                <Spin spinning={showSpinner}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                        onDragStart={handleDragStart}
                    >
                        <SortableContext
                            items={themes}
                            strategy={rectSwappingStrategy}
                        >
                            <List
                                className='list-component'
                                bordered
                                itemLayout="vertical"
                                loading={isBusy}
                                dataSource={themes}
                                loadMore={loadMore}
                                rowKey={item => item.sortOrder ?? item.index ?? item.id}
                                renderItem={item => {

                                    return (
                                        <ItemCard
                                            item={item}
                                            showEditModal={showEditModal}
                                            hasEditAccess={hasThemesEditAccess}
                                            hasDeleteAccess={hasThemesDeleteAccess}
                                            onDelete={onDelete}
                                            disableSort={isTextSearchActive}
                                            toggleVisibility={debouncedVisibilityToggle}
                                            visible={item.status === 'visible'}
                                            toggleDateRange={debouncedDateRangeToggle}
                                            isDateRangeEnabled={item.hasDateRange}
                                            showDateRangeModal={showDateRangeModal}
                                        />
                                    )
                                }}
                            />
                        </SortableContext>
                        <DragOverlay>
                            {activeId ? (<ItemCard item={activeItem} showBorder viewOnly />) : null}
                        </DragOverlay>
                    </DndContext>
                    <BackTop>
                        <div >
                            <Button type="primary" shape="circle" icon={<VerticalAlignTopOutlined />} size='large' />
                        </div>
                    </BackTop>
                </Spin>
            </Dashboard>
            <Modal
                title="Edit"
                open={isEditVisible}
                onOk={onEditClick}
                onCancel={closeEditModal}
                okText='Save'
                okButtonProps={{
                    loading: isUpdating
                }}
                destroyOnClose
            >
                <EditForm
                    form={editForm[0]}
                    organisationId={organisationId}
                    theme={editTheme}
                    onUpdateAsync={onUpdateAsync}
                />
            </Modal>
            <Modal
                title="New"
                open={isCreateVisible}
                //visible={isCreateVisible}
                onOk={onCreateClick}
                onCancel={closeCreateModal}
                okText='Save'
                okButtonProps={{
                    loading: isCreating
                }}
                destroyOnClose
            >
                <CreateForm
                    form={createForm[0]}
                    organisationId={organisationId}
                    onCreateCallback={onCreateAsync}
                />
            </Modal>
            <Modal
                    title="Edit date ranges"
                    open={isDateRangeVisible}
                    onOk={onDateRangeOkClick}
                    onCancel={closeDateRangeModal}
                    okText='Save'
                    destroyOnClose
                    afterClose={() => {
                        dateRangeForm[0].resetFields()
                        console.log('after close')
                    }}
                    okButtonProps={{
                        loading: isBusy
                    }}
                >
                    <EditDateRange 
                      form={dateRangeForm[0]}
                      theme={editTheme}
                      onUpdateAsync={onUpdateDateRangeAsync}
                    />
                </Modal>
        </div>
    )

}