import { Button, Col, Form, Image, Modal, Row, Typography, Space, Segmented } from "antd";
import { filter, find, findIndex, get, map } from "lodash";
import { getYoutubeVideoId } from "../../../../../helpers/utils";

import { DeleteOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';

import { ReactComponent as ProfileImageDefault } from '../../../../../assets/userProfile.svg';
import React, { useMemo, useState } from "react";

import {
    DndContext,
    closestCenter,
    //  closestCorners,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    //   verticalListSortingStrategy,
    rectSwappingStrategy,
} from '@dnd-kit/sortable';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';


const { Text } = Typography;

const ThumbImageComponent = (props) => {

    const { thumbUrl } = props;

    return (
        thumbUrl ?
            <Image
                height={145}
                width={100}
                src={thumbUrl}
            />
            : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
    )

}

const YoutubeListComponent = (props) => {

    const { videoLink } = props;

    const videoId = getYoutubeVideoId(videoLink)

    console.log('youtube video link', videoLink)

    return (
        videoLink ?
            <Image
                height={145}
                width={100}
                src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
            //fallback={defaultPlaceholderImage}
            />
            : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
    )

}

const listImageComponentMap = {
    'image': ThumbImageComponent,
    'youtubeVideo': YoutubeListComponent,
}

const completionLevelValueToDisplayMap = {
    'activity': 'Activity',
    'organisation': 'Organisation'
}

const completionLevelDisplayToValueMap = {
    'Activity': 'activity',
    'Organisation': 'organisation'
}

const Item = ({
    value,
    label,
    completionLevel,
    listImageType,
    videoLink,
    imageUrl,
    onClose,
    index,
    showBorder = false,
    onCompletionLevelChange,
}) => {

    const ListComponent = useMemo(() => {

        const ViewComponent = get(listImageComponentMap, listImageType) || listImageComponentMap.image;

        return ViewComponent;

    }, [listImageType]);

    const {
        // attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useSortable({
        id: value,
        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });


    const borderStyle = useMemo(() => {

        return showBorder ? {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            borderColor: '#1890ff',
        } : {
            borderColor: 'rgb(243 243 243)',
            borderStyle: 'solid',
            borderRadius: 8,
            borderWidth: 1
        }

    }, [showBorder])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: 12,
        backgroundColor: '#fff',
        ...borderStyle
    };

    const itemContainerStyle = { position: 'relative' }

    return (
        <div >
            {index > 0 ? (<div style={{ padding: 4 }} />) : null}
            <div style={itemContainerStyle} ref={setNodeRef}  >
                {/* <div ref={setNodeRef} style={style} {...attributes} {...listeners} > */}
                <Row>
                    <Col flex={1} >
                        {/* <div  style={style} {...attributes} {...listeners} > */}
                        <div style={style}>
                            <Row justify="start" align="bottom" >
                                <Col >
                                    <ListComponent videoLink={videoLink} thumbUrl={imageUrl} />
                                </Col>
                                <Col style={{ marginLeft: 12 }}>
                                    <div >
                                        <Text>Completion Level</Text>
                                    </div>
                                    <Segmented
                                        options={['Activity', 'Organisation']}
                                        value={completionLevelValueToDisplayMap[completionLevel]}
                                        onChange={(completionLevel) => {
                                            onCompletionLevelChange(value, completionLevel)
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div style={{ marginTop: 8 }}>
                                        <Text type="secondary" >{label}</Text>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <Space direction="vertical" size="middle" style={{
                            padding: 12,
                            height: '100%',
                            justifyContent: 'center'
                        }} >
                            <Button
                                //type='primary'
                                shape='circle'
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {
                                    onClose(value, label)
                                }}
                                danger
                                icon={<DeleteOutlined />}
                            />
                            <Button
                                type="secondary"
                                shape='circle'
                                ref={setActivatorNodeRef}
                                {...listeners}
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {
                                    console.log('draging action')
                                }}
                                //danger
                                icon={<VerticalAlignMiddleOutlined />}
                            />
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

const RequiredList = ({
    items = [],
    onClose,
    onSwap,
    getActiveItem,
    onCompletionLevelChange,
}) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [activeId, setActiveId] = useState(null);

    return (
        <React.Fragment>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <SortableContext
                    items={items}
                    strategy={rectSwappingStrategy}
                >
                    {map(items, (item, index) => (
                        <Item
                            key={item.value}
                            {...item}
                            onClose={onClose}
                            index={index}
                            onCompletionLevelChange={onCompletionLevelChange}
                        />))}
                </SortableContext>
                <DragOverlay>
                    {activeId ? (<Item {...activeId} showBorder />) : null}
                </DragOverlay>
            </DndContext>
            {/* {
                map(items, (item, index) => {
                    console.log('prequiiste item', index, item);
                    return (
                        <Item key={item.value} {...item} onClose={onClose} index={index} />
                    )
                })
            } */}
        </React.Fragment>
    )

    function handleDragStart(event) {

        const { active } = event;

        //setActiveId(active.id);
        const item = getActiveItem(active.id);
        setActiveId(item)

    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            console.log('drag even', active, over)
            onSwap(active.id, over.id)
            //   setItems((items) => {
            //     const oldIndex = items.indexOf(active.id);
            //     const newIndex = items.indexOf(over.id);

            //    
            //   });
        }

        setActiveId(null);
    }

}

export default ({ updatePrerequisites }) => {

    return (
        <div>
            <Form.Item shouldUpdate={(prev, cur) => prev.prerequisites !== cur.prerequisites}>
                {(props) => {

                    const items = props.getFieldValue('prerequisites');

                    const onRemove = (itemId, label) => {

                        Modal.confirm({
                            title: `Are you sure you want to remove ${label}`,
                            okText: 'Remove',
                            closable: true,
                            onOk: () => {
                                console.log('on delete click');

                                const items = props.getFieldValue('prerequisites');

                                //console.log('prerequisites', itemId, items);

                                const filtered = filter(items, o => o.value !== itemId);

                                //console.log('filtered prerequisites', filtered)

                                updatePrerequisites(filtered)

                            }

                        });

                    }

                    const onCompletionLevelChange = (itemId, completionLevel) => {
                        try {

                           // console.log('on completion level change', itemId, completionLevel);

                            const items = props.getFieldValue('prerequisites');

                            const itemIndex = findIndex(items, o => o.value === itemId);

                            console.log('change completion level at index', itemIndex);

                            if (itemIndex >= 0) {
                                items[itemIndex].completionLevel = completionLevelDisplayToValueMap[completionLevel];
                                props.setFieldsValue({ prerequisites: [...items] })
                            }

                        } catch (error) {
                            console.log('error on completion level change', error);
                        }
                    }

                    const getActiveItem = (id) => {

                        const items = props.getFieldValue('prerequisites');

                        const item = find(items, o => o.value === id);

                        return item;
                    }

                    const onSwap = (activeId, overId) => {

                        const items = props.getFieldValue('prerequisites');

                        const oldIndex = findIndex(items, o => o.value === activeId);
                        const newIndex = findIndex(items, o => o.value === overId);

                        let updatedItems = arrayMove(items, oldIndex, newIndex);

                        props.setFieldsValue({ prerequisites: updatedItems })
                    }

                    return (
                    <RequiredList
                        {...props}
                        items={items}
                        onClose={onRemove}
                        onSwap={onSwap}
                        getActiveItem={getActiveItem}
                        onCompletionLevelChange={onCompletionLevelChange}
                    />)
                }}
            </Form.Item>
        </div>
    )

}