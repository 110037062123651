import { Select } from "antd";
import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../../config/constants";
import { useMemo } from "react";

import styles from './RelatedTo.module.scss'
import { useQuery } from "react-query";

const { Option } = Select;

const OrganisationSelect = (props) => {

    const {
        organisationId,
        ...rest
    } = props;

    const { httpPostAsync } = useHttpHelper();

    const fetchOrganisationsAsync = async () => {
        try {

            const items = await httpPostAsync(appLinks.adminOrganisationsV2, { oneOrganisations: false });

            return items;

        } catch (error) {
            console.log('error fetching materials', error);
        }
    }

    const oganisationsQueryKey = [queryKeys.oneOrganisations];

    const {
        data: organisations = [],
        isLoading: isLoadingOrganisations,
    } = useQuery(
        oganisationsQueryKey,
        fetchOrganisationsAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });

    const mappedOrganisations = useMemo(() => {

        const mapped = organisations?.map(item => {

            return {
                ...item,
                disabled: item.value === organisationId
            }
        })

        return mapped;

    }, [organisations, organisationId])



    return (
        <Select
            loading={isLoadingOrganisations}
            labelInValue
            className={styles.organisationSelect}
            optionLabelProp="children"
            {...rest}
        >
            {
                mappedOrganisations?.map((item) => {
                    return (
                        <Option
                            key={item.value}
                            value={item.value}
                            disabled={item.disabled}
                        >
                            {item.label}
                        </Option>
                    )
                })
            }
        </Select>
    )

}

export default OrganisationSelect;