import { Button, Calendar, Form, Modal, Select, Tooltip, message, Spin } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import Header from './Header';
import './index.scss';

import {
    CloseOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusCircleOutlined
} from '@ant-design/icons';
import moment from 'moment-timezone';
import { useQuery } from 'react-query';
import PlaymomentLookup from '../../common/components/lookupSelect/PlaymomentsLookup';
import useHttpHelper from '../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../config/constants';

const { confirm } = Modal;

const colors = ['#35AB71', '#F8BD39', '#D05355', '#D663D7', '#37328e', '#aa1777'];

const DayRenderer = (props) => {

    const {
        value,
        data,
        onEditClick,
        showAddModal,
        onRemove = { onRemove }
    } = props

    const date = value?.format('DD-MM-YYYY')

    const entries = useMemo(() => {

        const items = data?.filter(e => e.scheduledAt === date)
        if (items?.length) {
            console.log('filered items', items)

        }

        return items;

    }, [date, data])

    const itemsCount = entries?.length ?? 0

    // const divRef = useRef()
    // const buttonRef = useRef()

    // const onMouseEnter = e => {
    //     e.stopPropagation()
    //     console.log('on mouse enter', divRef.current)
    //     divRef.current.closest('.ant-picker-cell').classList.add('entered')

    // }

    // const onMouseLeave = e => {
    //     e.stopPropagation()
    //     console.log('on mouse leave')
    //     divRef.current.closest('.ant-picker-cell').classList.remove('entered')
    // }

    return (
        <div
            className='day-cell'
        // onMouseEnter={onMouseEnter} 
        // onMouseLeave={onMouseLeave} 
        >
            <div
                className='add'
            //ref={divRef}
            >
                <Tooltip placement="topLeft" title='Add playmoment'>
                    <Button
                        size='large'
                        type='link'
                        icon={<PlusCircleOutlined />}
                        onClick={e => {
                            e.stopPropagation()
                            showAddModal(value)
                        }}

                    />
                </Tooltip>
            </div>
            <div className='entries-container'>
                {entries?.map((item, index) => {

                    const color = colors?.[index % itemsCount] ?? 'green'

                    let title = item.title

                    if (item.title.length > 10) {
                        title = title.substring(0, 10) + '...'
                    }

                    const onEditIconClick = () => onEditClick(item)

                    const onRemoveClick = (e) => {
                        e.stopPropagation()
                        confirm({
                            maskClosable: true,
                            title: 'Are you sure you want to remove this item?',
                            icon: <ExclamationCircleOutlined />,
                            content: `${item.title} will be removed for age group ${item.fromAge} to ${item.toAge}`,
                            onOk() {
                                onRemove(item)
                            }
                        });
                    }

                    return (
                        <div className='entry-item'>
                            <Tooltip key={item.id} title={`Ages ${item.fromAge} to ${item.toAge} : ${item.title}`}>
                                <div className='entry-tag' style={{ backgroundColor: color }}>
                                    <div className='tag-row' >
                                        <p className='tag-title'>{`${item.fromAge} - ${item.toAge} ${title}`}</p>
                                        <div className='icon-container'>
                                            <a onClick={onEditIconClick}>
                                                <EditOutlined className='edit-icon' />
                                            </a>
                                            <a onClick={onRemoveClick}>
                                                <CloseOutlined className='close-icon' />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Tooltip>
                        </div>
                    )
                })}
            </div>
        </div>
    )

}


const ageGroupOptions = [
    {
        value: 0,
        label: '3 to 4',
    },
    {
        value: 1,
        label: '5 to 6',
    },
    {
        value: 2,
        label: '7 to 8',
    },
]


const ageOptionsMap = [
    {
        key: 0,
        fromAge: 3,
        toAge: 4
    },
    {
        key: 1,
        fromAge: 5,
        toAge: 6
    },
    {
        key: 2,
        fromAge: 7,
        toAge: 8
    },
]

//const organisationId = 16

const todayMDate = moment()

const PlayMomentCalendar = (props) => {

    // const addPlayMoment = (info) => {
    //     console.log('add play moment', info)
    // }

    // const addPlayMomentRef = useRef(addPlayMoment)
    // addPlayMomentRef.current = addPlayMoment

    // const onAddPlayMoment = (info) => {
    //     addPlayMomentRef.current(info)
    // }

    const [selectedDate, setSelecteDate] = useState(todayMDate)

    const [form] = Form.useForm();

    const [addModalVisible, setAddModalVisible] = useState(false)

    const showAddModal = (mDate) => {
        setSelecteDate(mDate)
        setAddModalVisible(true)
    }

    const hideAddModal = () => {
        setAddModalVisible(false)
        form.resetFields()
        setSelectedItem({})
    }

    const { httpPostAsync, httpDeleteAsync } = useHttpHelper()

    const monthYearKey = selectedDate?.format('MM-YYYY')

    const [selectedOrganisation, setSelectedOrganisation] = useState()

    const organisationId = selectedOrganisation?.value

    useEffect(() => {

        console.log('selectedOrganisation?.value', selectedOrganisation?.value)

    }, [selectedOrganisation?.value])

    const fetchEntriesAsync = async () => {
        try {

            console.log('fetch entries api call', selectedOrganisation)

            const date = selectedDate?.format('DD-MM-YYYY')

            const postData = {
                date,
                organisationId: selectedOrganisation?.value,
            }
            const response = await httpPostAsync(appLinks.getPlaymomentScheduleEntries, postData)
            return response
        } catch (error) {
            console.log('error getting scheduled playmoment entries async', error)
        }

    }



    const {
        isLoading,
        data = {},
        isFetching,
        // isPreviousData,
        refetch,
    } = useQuery(
        [queryKeys.playmomentCalendar, monthYearKey, selectedOrganisation?.value],
        fetchEntriesAsync,
        {
            //enabled: selectedOrganisation?.value > 0,
            keepPreviousData: true,
            refetchOnWindowFocus: false
        })

    const playMomentEntries = data?.data ?? []

    const [selectedItem, setSelectedItem] = useState()

    const onEditClick = (item) => {
        console.log('on edit', item)

        const ageItem = ageOptionsMap.find(e => e.fromAge === item.fromAge)

        setSelectedItem(item)

        form.setFieldsValue({
            playmomentId: {
                label: item.title,
                value: item.playmomentId
            },
            ageGroup: ageItem.key
        });

        setAddModalVisible(true)
    }

    const onRemove = async (item) => {
        try {

            console.log('on remove', item)


            const link = appLinks.removePlaymomentEntry.replace('{id}', item.id)

            const response = await httpDeleteAsync(link, data)

            console.log('remove playmoment response', response)

            const code = response?.code

            if (code === '0') {
                message.success('Entry was removed');
            } else {
                message.warn('Entry could not be removed');
            }

            refetch()

        } catch (error) {
            console.log('error on removing a item', error)
            message.error('Entry could not be removed')
        }
    }

    const DayCell = useMemo(() => {

        console.log('day cell memo')

        return (value) => (
            <DayRenderer
                value={value}
                data={playMomentEntries}
                showAddModal={showAddModal}
                onEditClick={onEditClick}
                onRemove={onRemove}
            />)

    }, [playMomentEntries])



    const addPlaymomentAsync = async (data) => {
        try {

            const link = appLinks.addPlaymomentScheduleEntry

            const response = await httpPostAsync(link, data)

            console.log('add playmoment response', response)

            const code = response?.code

            if (code === '0') {
                message.success('Entry was created successfully');
                setAddModalVisible(false)
            } else if (code === '400.12.11') {
                message.warn('Entry for age group already exists');
            }

            return response

        } catch (error) {
            console.log('error adding playmoment', error)
        }
    }

    const editPlaymomentAsync = async (data) => {
        try {

            const link = appLinks.updatePlaymomentScheduleEntry

            const response = await httpPostAsync(link, data)

            console.log('update playmoment response', response)

            const code = response?.code

            if (code === '0') {
                message.success('Entry was updated successfully');
                setAddModalVisible(false)
            } else if (code === '400.12.11') {
                message.warn('Entry for age group already exists');
            }

            refetch()

            return response

        } catch (error) {
            console.log('error updating playmoment', error)
        }
    }

    const [formState, setFormState] = useState()

    const onFinish = async (values) => {
        try {

            const { playmomentId, ageGroup } = values;

            console.log('play moment values', values)

            const ageGroups = ageOptionsMap.find(e => e.key === ageGroup)

            const scheduledAt = selectedDate?.format('DD-MM-YYYY')

            const postData = {
                playmomentId: playmomentId.value,
                scheduledAt,
                ...ageGroups,
                organisationId
            }

            console.log('on add playmoment', postData)

            setFormState('busy')

            if (selectedItem?.id > 0) {
                console.log('edit entry',)
                const postData = {
                    id: selectedItem.id,
                    playmomentId: playmomentId.value,
                    ...ageGroups,
                    organisationId
                }
                await editPlaymomentAsync(postData)
            } else {
                await addPlaymomentAsync(postData)
            }


            setSelectedItem({})
            form.resetFields()
            setFormState('done')
            refetch()


            // show message

        } catch (error) {
            console.log('error on add playmoment', error);
            message.error('Entry could not be created')
            setFormState('done')
            setSelectedItem({})
            form.resetFields()
        }
    }

    const onOkClick = () => {
        form.submit()
    }

    const onSelectOrganisation = (e) => {
        console.log('select Organisation', e)
        setSelectedOrganisation(e)
    }

    const selectOrganisationRef = useRef(onSelectOrganisation)
    selectOrganisationRef.current = onSelectOrganisation

    const HeaderComponent = useMemo(() => {

        const onOrganisationSelect = (e) => {
            typeof selectOrganisationRef.current === 'function' && selectOrganisationRef.current(e)
        }

        return props => <Header {...props} onSelect={onOrganisationSelect} />

    }, [])

    return (
        <div className='playmoment-calendar'>
            <Spin spinning={isLoading || isFetching} >
                <Calendar
                    value={selectedDate}
                    mode='month'
                    dateCellRender={DayCell}
                    // onPanelChange={onPanelChange}
                    headerRender={HeaderComponent}
                    onChange={changedDate => {
                        console.log('calendar date change', changedDate)
                        setSelecteDate(changedDate)
                    }}
                />
            </Spin>
            <div>
                <Modal
                    title={selectedItem?.id > 0 ? 'Update Entry' : 'Add Entry'}
                    open={addModalVisible}
                    onOk={onOkClick}
                    onCancel={hideAddModal}
                    okButtonProps={{
                        loading: formState === 'busy'
                    }}
                >
                    <Form
                        layout='vertical'
                        form={form}
                        scrollToFirstError
                        name='create-challenge-modal'
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="playmomentId"
                            label="Playmoments"
                            rules={[{ required: true, message: 'Please choose an playmoment.' }]}
                            wrapperCol={{ span: 16 }}
                        >
                            <PlaymomentLookup
                                //  style={lookupStyle}
                                placeholder="Select Playmoment"
                                mode='single'
                                //labelInValue={false}
                                organisationId={organisationId}
                            />
                        </Form.Item>
                        <Form.Item
                            name="ageGroup"
                            label="Age groups"
                            rules={[{ required: true, message: 'Please choose age group.' }]}
                            wrapperCol={{ span: 16 }}
                        >
                            <Select
                                options={ageGroupOptions}
                                placeholder="Select age groups"
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    )

}

export default PlayMomentCalendar