import { Select } from "antd";

import { appLinks, queryKeys } from "../../../config/constants";
import EmptyDisplayComponent from "../Empty";
import useLookupsSearch from "./useLookupsSearch";

const { Option } = Select

const ClubsLookup = (props) => {

    const { 
        data = [],
        isLoading,
        onSearch,
        onBlur,
    } = useLookupsSearch(
        appLinks.clubsLookups,
        [queryKeys.clubLookupsFilter],
    )

    return (
        <Select
            mode="multiple"
            labelInValue
            tagRender={EmptyDisplayComponent}
            loading={isLoading}
            // search related
            filterOption={false} // needed to pick items updated after search
            showArrow
            onSearch={onSearch}
            onBlur={onBlur}
            {...props}
        >
            {
                data?.map(item => (<Option key={item.value} value={item.value}>{item.label}</Option>))
            }
        </Select>
    )

}

export default ClubsLookup;