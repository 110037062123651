import React from 'react';
import { useQuery } from 'react-query';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { appLinks, queryKeys } from '../../../config/constants';
import useHttpHelper from '../../../common/hooks/useHttpHelper';


const Label = (props) => {
    // console.log('props', props)
    const { value, name, fill, x, y, textAnchor } = props
    return (
        <text x={x} y={y} fill={fill} textAnchor={textAnchor} dominantBaseline="central">
            {`${name} - ${value}`}
        </text>

    )
}


const EmailOverviewChart = () => {

    const { httpPostAsync } = useHttpHelper();

    const fetchOverViewDetailsAsync = async () => {
        try {

            const link = appLinks.allSentEmailStats

            const response = await httpPostAsync(link)

            console.log('all sent email stats', response?.data)

            return response?.data ?? []

        } catch (error) {
            console.log('error fetching emails overview data')
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.emailsOverview],
        fetchOverViewDetailsAsync,
        {
            refetchOnWindowFocus: false,
        });

    return (
        <div className='chart-container'>
            <div className='title-container'>
                <span className='title'>Sent Emails Overview</span>
            </div>
            <div className='email-pie-chart'>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={300}>
                        <Pie
                            data={data}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={Label}
                        />
                        <Tooltip />
                        <Legend
                            layout='vertical'
                            align='left'
                            verticalAlign="middle"
                            iconType='circle'
                        //height={36}
                        />
                        <Tooltip />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )

}

export default EmailOverviewChart