import { Breadcrumb, Button, Row, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import ChallengeView from './view';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Dashboard from '../../../common/components/dashboard';
import { useChallengesPermissionContext, useMenuPermissionsContext } from '../../../common/components/permissionContext';

import './index.scss'

export default () => {

  let { challengeId } = useParams();

  //console.log('params', params);

  const navigate = useNavigate();

  const { httpGetAsync } = useHttpHelper();

  const fetchChallengeDetailsAsync = async () => {
    try {

      const challenge = await httpGetAsync(appLinks.challengeDetails.replace('{challengeId}', challengeId));

      console.log('challenge details', challenge);

      return challenge;

    } catch (error) {
      console.log('error fetching challenge details', error);
    }
  }

  const {
    data,
    isLoading,
    refetch,
  } = useQuery(
    [queryKeys.challengeDetailsView, challengeId],
    fetchChallengeDetailsAsync,
    {
      enabled: challengeId > 0,
      refetchOnWindowFocus: false
    }
  );

  const {
    id,
    title,
    publishStatus,
    listingStatus,
    organisation,
    difficulty,
    duration,
    xp,
    customIntro,
    introText,
    description,
    isFacilitationNeeded,
    themes = [],
    skills = [],
    sections = [],
    resources = [],
    thumbUrl,
    imageUrl,
    creators = {},
    coverImageType,
    listImageType,
    videoLink,
    clubs = [],
    isClubLevelVisibility = false,
    prerequisites = [],
    ages = [],
    materials = [],
    oneValues,
    areas,
    location,
    primaryRelatedActivity,
    relatedActivities,
    dailyActivity,
    seasons
  } = data ?? {}

  const intro = customIntro ?? introText;

  const onBack = () => {
    try {

      navigate(`/${appRoutes.challenges}`);

    } catch (error) {
      console.log('error on back click', error);
    }
  }

  //   const setDurationLabel = (mDuration) => {
  //     try {

  //         if (!mDuration || !mDuration.isValid()) {
  //             return ''
  //         }

  //         const hour = mDuration.hour();
  //         const min = mDuration.minute();
  //         console.log('moment duration', hour, min);

  //         let text = '';
  //         if (hour > 0) {
  //             text = text + `${hour} hour${hour > 1 ? 's' : ''}`
  //         }

  //         if (hour > 0 && min > 0) {
  //             text = text + ', '
  //         }

  //         if (min > 0) {
  //             text = text + `${min} minute${min > 1 ? 's' : ''}` 
  //         }

  //        return text;

  //     } catch (error) {
  //         console.log('error setting duration text', error);
  //     }
  // }

  //   const durationText = useMemo(() => {

  //     const momentTime = moment().startOf('day').add(durationMins, 'minutes');

  //     return setDurationLabel(momentTime)

  //   }, durationMins)


  const {
    hasAgesPermission = false,
    hasMaterialsPermission = false
  } = useMenuPermissionsContext();

  const { 
    hasCreateEditBannersAccess,
    hasLocationAccess,
 } = useChallengesPermissionContext()

  return (
    <Dashboard subrouting={false} activeItem='challenges' >
      <Spin spinning={isLoading}>
        <Row align="middle" style={{ paddingBottom: 8 }}>
          <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
          <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
            <Breadcrumb.Item><a onClick={onBack}>Challenges</a></Breadcrumb.Item>
            <Breadcrumb.Item>{title}</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <ChallengeView
          challengeId={challengeId}
          publishStatus={publishStatus}
          listingStatus={listingStatus}
          title={title}
          organisation={organisation}
          difficulty={difficulty}
          duration={duration}
          xp={xp}
          intro={intro}
          description={description}
          isFacilitationNeeded={isFacilitationNeeded}
          themes={themes}
          skills={skills}
          sections={sections}
          resources={resources}
          thumbUrl={thumbUrl}
          imageUrl={imageUrl}
          creators={creators}
          reloadDetails={refetch}
          coverImageType={coverImageType}
          listImageType={listImageType}
          videoLink={videoLink}
          clubs={clubs}
          isClubLevelVisibility={isClubLevelVisibility}
          prerequisites={prerequisites}
          ages={ages}
          materials={materials}
          showAges={hasAgesPermission}
          showMaterials={hasMaterialsPermission}
          oneValues={oneValues}
          areas={areas}
          showBannersTab={hasCreateEditBannersAccess}
          hasLocationAccess={hasLocationAccess}
          location={location}
          showRelatedToTab
          primaryRelatedActivity={primaryRelatedActivity}
          relatedActivities={relatedActivities}
          dailyActivity={dailyActivity}
          seasons={seasons}
        />
      </Spin>
    </Dashboard>
  );

}