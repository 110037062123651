import { Row, Col, Image, Card, Typography, Badge, Tabs, Switch, message } from 'antd';
import styled from 'styled-components';
import React, { useState } from 'react';

import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import { ReactComponent as LogoImageDefault } from '../../../assets/logoPlaceholder.svg';
import Pinned from './Pinned';

const { Text } = Typography;
const { TabPane } = Tabs;

const TitleComponent = styled.span`
font-size: 16px;
font-weight: bold;
`

const TitleDiv = styled.div`
padding-top: 8px
`;

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    }
}


const ItemTitle = ({ title, value }) => {

    return (
        <TitleDiv>
            <div>
                <Text type="secondary">{title}</Text>
            </div>
            <Text>{value}</Text>
        </TitleDiv>
    )
}

export default (props) => {

    const {
        name,
        parentOrganisation,
        status,
        showInListing,
        supportedLanguages,
        type,
        email,
        website,
        logoLink,
        imageUrl,
        pinned = [],
        showPinned,
        onShowPinnedToggle,
        showPopular,
        onShowPopularToggle
    } = props;

    const pinnedCount = pinned && pinned.length || 0;

    const isActive = status === 'active';

    return (
        <div>
            <Card>
                <Row>
                    <Col md={24} sm={24} xs={24} lg={18} xl={12} xxl={12} style={{ paddingRight: 24 }}>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: '12px' }}>
                                    <Col>
                                        <TitleComponent>{name}</TitleComponent>
                                    </Col>
                                    <Col>
                                        <Badge style={{ paddingLeft: 32 }}
                                            status={isActive ? "success" : 'default'}
                                            text={isActive ? 'Active' : 'Inactive'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div style={styles.statsContainer}>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col span={12}>
                                    <ItemTitle title="Parent organisation" value={parentOrganisation} />
                                </Col>
                                <Col span={12}>
                                    <ItemTitle title="Type" value={type} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <ItemTitle title="Show in App" value={showInListing ? 'Yes' : 'No'} />
                                </Col>
                                <Col span={12}>
                                    <ItemTitle title="Languages" value={supportedLanguages} />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <ItemTitle title="Email" value={email} />
                                </Col>
                                <Col span={12}>
                                    <ItemTitle title="Website" value={website} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 16 }}>
                                <Col span={12}>
                                    <div>
                                        <Text type="secondary">Show pinned list</Text>
                                    </div>
                                    <Switch
                                        onClick={onShowPinnedToggle}
                                        checked={showPinned}
                                    />
                                </Col>
                                <Col span={12}>
                                    <div>
                                        <Text type="secondary">Show popular list</Text>
                                    </div>
                                    <Switch
                                        onClick={onShowPopularToggle}
                                        checked={showPopular}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col md={24} sm={24} xs={24} lg={12} xl={12} xxl={12}>
                        <Row style={{ paddingBottom: '12px' }} >
                            <TitleComponent>Cover Image</TitleComponent>
                        </Row>
                        <Row>
                            {
                                imageUrl ?
                                    <Image
                                        height={300}
                                        width={480}
                                        src={imageUrl}
                                    //fallback={defaultPlaceholderImage}
                                    />
                                    : <CoverImageDefault height={300} width={480} style={{ borderRadius: 12 }} />
                            }
                        </Row>
                        <Row style={{ paddingBottom: '12px', paddingTop: 20 }} >
                            <TitleComponent>Logo</TitleComponent>
                        </Row>
                        <Row>
                            {
                                logoLink ?
                                    <Image
                                        height={160}
                                        width={'auto'}
                                        src={logoLink}
                                    //fallback={defaultPlaceholderImage}
                                    />
                                    : <LogoImageDefault height={160} width={160} style={{ borderRadius: 8 }} />
                            }
                        </Row>

                    </Col>
                </Row>
                {
                    (pinned && pinned.length > 0)
                        ? (
                            <Row justify='start'>
                                <Tabs defaultActiveKey={1} style={{ minHeight: 300 }}>
                                    <TabPane tab={`Pinned activities ${pinnedCount > 0 ? ` (${pinnedCount})` : ''}`} key={1}>
                                        <Pinned pinned={pinned} />
                                    </TabPane>
                                </Tabs>
                            </Row>
                        )
                        : null
                }
            </Card>
        </div>
    )
}