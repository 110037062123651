import { get } from 'lodash';
import React, { useEffect } from 'react';
import CreateEdit from '../common/createEdit';


export default ({ theme = {}, organisationId, form, onUpdateAsync }) => {

    useEffect(() => {

        let imagefile = {}

        let imageId = get(theme, 'imageId')
        let imageUrl = get(theme, 'imageUrl')

        if (imageId && imageUrl) {
            imagefile = {
                imageFile: [
                    {
                        uid: imageId,
                        name: 'iconimage',
                        status: 'done',
                        url: imageUrl,
                    }
                ]
            }

        }

        let thumbImage = {}

        let thumbId = get(theme, 'thumbImageId')
        let thumbImageUrl = get(theme, 'thumbImageUrl')

        if (thumbId && thumbImageUrl) {
            thumbImage = {
                thumbImage: [
                    {
                        uid: thumbId,
                        name: 'thumbimage',
                        status: 'done',
                        url: thumbImageUrl,
                    }
                ]
            }

        }

        const value = {
            title: get(theme, 'title'),
            ...imagefile,
            ...thumbImage,
        }

        form.setFieldsValue(value);

    }, [theme]);


    return (
        <CreateEdit
            form={form}
            themeImageId={theme.imageId}
            themeImageUrl={theme.imageUrl}
            thumbImageId={theme.thumbImageId}
            thumbImageUrl={theme.thumbImageUrl}
            onSubmitCallback={onUpdateAsync}
            organisationId={organisationId}
        />
    )

}