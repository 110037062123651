import { get } from 'lodash';
import React, { useEffect } from 'react';
import CreateEdit from '../common/createEdit';


export default ({ skill = {}, organisationId, form, onUpdateAsync }) => {

    useEffect(() => {

        const color = get(skill, 'color');

        let value = {
            title: get(skill, 'title'),
        }

        if (color) {
            value = {
                ...value,
                hexColor: color,
                color: color,
            }
        }

        form.setFieldsValue(value);

    }, [skill]);

    return (
        <CreateEdit
            form={form}
            onSubmitCallback={onUpdateAsync}
            organisationId={organisationId}
        />
    )

}