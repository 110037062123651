import { Breadcrumb, Button, Row, Spin } from 'antd';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import View from './view';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { get, map } from 'lodash';
import { useMemo } from 'react';


export default () => {

    let { userId } = useParams();

    //console.log('params', params);

    const navigate = useNavigate();

    const { httpGetAsync } = useHttpHelper();

    const fetchUserDetailsAsync = async () => {
        try {

            const response = await httpGetAsync(appLinks.usersDetails.replace('{userId}', userId));

            console.log('user', response);

            return response;

        } catch (error) {
            console.log('error fetching user details', error);
        }
    }

    const {
        data,
        isLoading,
        isSuccess
    } = useQuery(
        [queryKeys.userDetails, userId],
        fetchUserDetailsAsync,
        {
            enabled: userId > 0,
            refetchOnWindowFocus: false
        }
    );

    const {
        name,
        email,
        status,
        roles,
        organisations,
        //creator info
        creatorName,
        creatorShortBio,
        creatorTitle,
        creatorWebsite,
        creatorProfileImageUrl,
        creatorCoverImageUrl,
        accountType,
        clubs,
    } = useMemo(() => {

            const name = get(data, 'data.name') || '';
            const email = get(data, 'data.email');
            const status = get(data, 'data.status');
            const roles = get(data, 'data.roles') || [];
            const clubs = get(data, 'data.clubs') || [];
            let organisations = get(data, 'data.organisations') || [];
            organisations = map(organisations, o => ({ value: o.id, label: o.name }))

            console.log('organisations', organisations)

            // creator info
            const creatorName = get(data, 'data.creatorName');
            const creatorShortBio = get(data, 'data.creatorShortBio');
            const creatorTitle = get(data, 'data.creatorTitle');
            const creatorWebsite = get(data, 'data.creatorWebsite');
            const creatorProfileImageUrl = get(data, 'data.creatorProfileImageUrl');
            const creatorCoverImageUrl = get(data, 'data.creatorCoverImageUrl');

            return {
                name,
                email,
                status,
                roles,
                clubs,
                organisations,
                accountType: data?.data?.accountType,
                //creator info
                creatorName,
                creatorShortBio,
                creatorTitle,
                creatorWebsite,
                creatorProfileImageUrl,
                creatorCoverImageUrl,
            }

        }, [data])


   // const intro = customIntro || introText;

    const onBack = () => {
        try {

            //navigate(`/${appRoutes.dashboard}/${appRoutes.users}`);
            navigate(-1);

        } catch (error) {
            console.log('error on back click', error);
        }
    }


    return (
            <Spin spinning={isLoading}>
                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Users</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{name || ''}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <View
                    name={name}
                    status={status}
                    email={email}
                    roles={roles}
                    clubs={clubs}
                    organisations={organisations}
                    accountType={accountType}
                    //creator info
                    creatorName={creatorName}
                    creatorShortBio={creatorShortBio}
                    creatorTitle={creatorTitle}
                    creatorWebsite={creatorWebsite}
                    creatorProfileImageUrl={creatorProfileImageUrl}
                    creatorCoverImageUrl={creatorCoverImageUrl}
                />
            </Spin>
    );

}