import { Button, Col, Input, Row, Spin } from 'antd';
import { debounce, filter, get, join, map } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import AppLayout from '../../../common/components/layout';
import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import TagChip from '../../../common/components/tagchip';
import Unauthorized from '../../../common/components/unauthorized';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import appConfig from '../../../config/config';
import { appLinks, queryKeys } from '../../../config/constants';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import ListTable from './ListTable';
import useUserMessagesFilterContext from './useUserMessagesFilterContext';
import Dashboard from '../../../common/components/dashboard';


const { Search } = Input;

const pageSize = 10;


export default function UserMessages() {

  const [tagsVisible, setTagsVisible] = useState(false);

  const styles = useMemo(() => ({
    itemStyle: {
      paddingRight: 16,
      paddingBottom: 18,
    },
    tagsContainer: {
      paddingTop: tagsVisible ? 16 : 0,
      paddingBottom: tagsVisible ? 8 : 0

    },
    challengeTableContainer: {
      paddingTop: tagsVisible ? 0 : 17
    }
  }), [tagsVisible])

  // let navigate = useNavigate();

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useUserMessagesFilterContext();

  const {
    pageNo = 0,
    query = '',
    organisations = [],
    sortBy = null,
    sortOrder = null,
  } = searchParams

  useEffect(() => {

    const searchTagsLen = searchParams?.organisations?.length ?? 0

    setTagsVisible(searchTagsLen > 0)

  }, [searchParams])

  const { httpPostAsync } = useHttpHelper();


  const fetchMessagesAsync = async () => {

    const filterParams = {
      itemPerPage: pageSize,
      query,
      pageNo,
      organisations: map(organisations, s => s.value),
      sortBy,
      sortOrder
    }

    console.log('call api filter params:', filterParams);

    const response = await httpPostAsync(
      appLinks.userMessages,
      filterParams
    );

    console.log('fetch messages', response);

    return response;
  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.userMessages, pageNo, query, sortBy, sortOrder]

    let organisationsList = '0';
    if (organisations.length) {
      const organisationsValues = map(organisations, s => s.value);
      organisationsList = join(organisationsValues, '.');
    }
    key.push(organisationsList)

    console.log('filter key', key)

    return key;

  }, [pageNo, query, organisations, sortBy, sortOrder])

  const {
    isLoading,
    //isError,
    //error,
    data = {},
    isFetching,
    // isPreviousData,
    // refetch,
  } = useQuery(
    queryKey,
    fetchMessagesAsync,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })

  let { data: messages = [], total = 0 } = data || {}

  const mappedMessages = useMemo(() => {
    try {

      const mappedItems = map(messages, m => {

        let createdAtText = m.createdAt || ''

        const createdMomentDate = getAppZoneMomentFromUTC(m.createdAt);

        if (createdMomentDate && createdMomentDate.isValid()) {
          createdAtText = createdMomentDate.format(appConfig.displayFormat)
        }

        return {
          ...m,
          createdAt: createdAtText,
        }

      })

      return mappedItems;

    } catch (error) {
      console.error('error mapping messages', error);
      return [];
    }
  }, [messages])

  const [searchValue, setSearchValue] = useState(query);

  const onChallengeSearch = async (text) => {
    try {

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }));

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onChallengeSearch);
  searchUsersRef.current = onChallengeSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));

    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);
      //searchFieldRef.current.input.value = '';
      // console.log('search ref', searchFieldRef.current)

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  //#region filters dropdowns
  // const fetchOrganisationsAsync = async () => {
  //   try {

  //     const items = await httpPostAsync(appLinks.adminOrganisationsV2, { type: organisationTypes.foundation });

  //     console.log('organisations', items);

  //     return items;

  //   } catch (error) {
  //     console.log('error fetching organisations', error);
  //   }
  // }

  // const organisationQueryKey = [queryKeys.foundationOrganisations, organisationTypes.foundation]

  // const {
  //   data: organisationsList = [],
  //  // isLoading: isLoadingOrganisations,
  // } = useQuery(organisationQueryKey, fetchOrganisationsAsync, { refetchOnWindowFocus: false });

  const onOrganisationChange = (values) => {
    try {

      console.log('organisation values', values)

      setSearchParams(p => ({ ...p, organisations: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on organisation change', error)
    }
  }

  //#endregion

  //#region Access/Permission

  const hasListAccess = true

  //#endregion

  return (
    <Dashboard subrouting={false} activeItem='messages'>
      {
        !hasListAccess
          ?
          (<Unauthorized />)
          : (
            <React.Fragment>
              <Row justify='space-between'>
                <Col span={20}>
                  <Row justify='start' >
                    <Col>
                      <Row>
                        <Col style={styles.itemStyle} >
                          <OrganisationsLookup
                            onChange={onOrganisationChange}
                            value={mapKeyValues(organisations)}
                            style={{ width: 200 }}
                            placeholder="Organisation filter"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col style={styles.itemStyle}>
                      <Search
                        placeholder="Search"
                        value={searchValue}
                        onChange={onSearchChange}
                        allowClear
                        style={{ width: 200 }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                </Col>
              </Row>
              <Row style={styles.tagsContainer} justify="space-between">
                <Col>
                  <div style={{ marginBottom: 8 }}>
                    {map(organisations, org => {

                      console.log('organisation tag', org)

                      return (
                        <TagChip key={org.key}
                          closable
                          //style={styles.searchTags}
                          onClose={() => {
                            setSearchParams(p => {

                              const filtered = filter(p.organisations, o => o.key !== org.key);

                              // console.log('filtered organisations', filtered)

                              return {
                                ...p,
                                organisations: filtered,
                              }

                            })
                          }}>
                          {org.label}
                        </TagChip>
                      )

                    })}
                  </div>
                </Col>
                <Col>
                  {
                    isSearchActive
                      ? (
                        <Button type="link" size='small' onClick={onReset}>
                          Reset
                        </Button>
                      )
                      : null
                  }
                </Col>
              </Row>
              <Spin spinning={isLoading || isFetching}>
                <div style={styles.challengeTableContainer}>
                  <ListTable
                    key={resetKey}
                    items={mappedMessages}
                    onPagniationChange={onPagniationChange}
                    onSortChange={onSortChange}
                    pageNo={pageNo}
                    total={total}
                    defaultPageSize={pageSize}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                  />
                </div>
              </Spin>
            </React.Fragment>
          )
      }
    </Dashboard>
  );

}