import { Tag } from 'antd';

const styles = {
    tag: {
        marginBottom: 8,
        borderRadius: 12,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2
    }
}

const TagChip = (props) =>  (<Tag style={styles.tag} { ...props } />)

export default TagChip;