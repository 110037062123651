import { isEmpty, isEqual } from "lodash";
import { createContext, useContext, useMemo, useReducer } from "react";
import { unsubscribeFilterConfig } from "../../../screens/emails/unsubscribed/useFilterContext";
import { emailListFilterConfig } from "../../../screens/emails/list/useFilterContext";
import { oneKidsFilterConfig } from "../../../screens/OneKidsPosts/list/useFilterContext";
import { clubsFilterConfig } from "../../../screens/club/list/useFilterContext";
import { ageFilterConfig } from "../../../screens/ages/list/useFilterContext";
import { materialsFilterConfig } from "../../../screens/materials/list/useMaterialsFilterContext";
import { reportedItemsFilterConfig } from "../../../screens/reportedItems/List/useReportedItemsFilterContext";
import { userMessagesFilterConfig } from "../../../screens/userMessages/List/useUserMessagesFilterContext";
import { orgisationsFilterConfig } from "../../../screens/organistion/list/useOrganisationsFilterContext";
import { notificationsFilterConfig } from "../../../screens/notifications/list/useNotificationsFilterContext";
import { postsFilterConfig } from "../../../screens/posts/list/usePostFilterContext";
import { usersFilterConfig } from "../../../screens/users/list/useUsersFilterContext";
import { usersRolesFilterConfig } from "../../../screens/roles/list/useUserRolesFilterContext";
import { durationsFilterConfig } from "../../../screens/duration/list/useDurationFilterContext";
import { difficultyFilterConfig } from "../../../screens/difficulty/list/useDifficultyFilterContext";
import { skillsFilterConfig } from "../../../screens/skills/list/useSkillFilterContext";
import { themesFilterConfig } from "../../../screens/themes/list/useThemesFilterContext";
import { challengesFilterConfig } from "../../../screens/challenge/list/useChallengesFilterContext";
import { usersLastActivityFilterConfig } from "../../../screens/users/userActivites/useUsersFilterContext";
import { npsListFilterConfig } from "../../../screens/NpsResponses/list/useFilterContext";


export const initialState = {}

const configList = [
    challengesFilterConfig,
    themesFilterConfig,
    clubsFilterConfig,
    skillsFilterConfig,
    difficultyFilterConfig,
    durationsFilterConfig,
    usersRolesFilterConfig,
    usersFilterConfig,
    postsFilterConfig,
    notificationsFilterConfig,
    orgisationsFilterConfig,
    userMessagesFilterConfig,
    reportedItemsFilterConfig,
    materialsFilterConfig,
    ageFilterConfig,
    unsubscribeFilterConfig,
    emailListFilterConfig,
    oneKidsFilterConfig,
    usersLastActivityFilterConfig,
    npsListFilterConfig
]


// for reducer actions
const actionConfigMap = new Map()

// for context setter functions
const filterConfigMap = new Map()

for (const config of configList) {
    // for reducre actions
    actionConfigMap.set(config.resetAction, config)
    actionConfigMap.set(config.setAction, config)

    // for setter
    filterConfigMap.set(config.key, config)

    // set initial values
    initialState[config.key] = config.initialValue
    initialState[config.isActiveProp] =  false

}

function isFilterEmptyHOF(filterType) {
    return function (filter) {
        try {
            return !filter || isEmpty(filter) || !isEqual(initialState[filterType], filter);
        } catch (error) {
            console.log('error checking for empty filter', error);
            return false;
        }
    }
}

export const FilterStoreContext = createContext({ ...initialState });


export const useFilterContext = () => {

    const {
        resetFilterState,
    } = useContext(FilterStoreContext);

    return {
        resetFilterState
    }

}

function getFilterFromStateHOF(stateProps, searchActiveProp) {

    return function (state, action) {
        try {

            console.log('state pros', stateProps, searchActiveProp);

            const isFilterStateEmpty = isFilterEmptyHOF(stateProps);


            if (typeof action.filters === 'function') {

                const filters = action.filters(state[stateProps] ?? {}) ?? {};

                return { ...state, [stateProps]: { ...filters }, [searchActiveProp]: isFilterStateEmpty(filters) };

            } else if (action.filters && typeof action.filters === 'object') {

                return {
                    ...state,
                    [searchActiveProp]: isFilterStateEmpty(action.filters),
                    [stateProps]: {
                        ...action.filters
                    }
                };

            }

            return state;

        } catch (error) {
            console.log(`error getting filter from state and action for ${stateProps} ${searchActiveProp}`, error)
        }

    }

}

export const getFilterContextValue = (contextValue, filterConfig) => ({
    searchParams: contextValue[filterConfig.key] ?? {},
    isSearchActive: contextValue[filterConfig.isActiveProp] ?? false,
    setSearchParams: contextValue[filterConfig.setFilterProp],
    resetSearchParams: contextValue[filterConfig.resetFilterProp],
})

function reducer(state, action) {

    //console.log('filter state action', action);

    const actionType = action.type

    const filterConfig = actionConfigMap.get(actionType)
    if (filterConfig !== null && typeof filterConfig === 'object') {

        console.log('action', actionType)
        if (actionType === filterConfig.setAction) {
            return getFilterFromStateHOF(filterConfig.key, filterConfig.isActiveProp)(state, action);
        } else if (actionType === filterConfig.resetAction) {
            return {
                ...state,
                [filterConfig.isActiveProp]: false,
                [filterConfig.key]: {
                    ...(initialState[filterConfig.key] ?? {})
                }
            };
        }
    }


    switch (action.type) {
        case 'reset':

            return {
                ...initialState
            };

        default:
            throw new Error();
    }
}



export default (props) => {

    const [state, dispatch] = useReducer(reducer, { ...initialState });

    const setters = useMemo(() => {

        const getSetFilterFunction = (type) => (filters) => dispatch({ type, filters })

        const getResetFilterFunction = (type) => (filters) => dispatch({ type, filters })

        let initalValue = {}

        // console.log('iterator', filterConfigMap.entries())

        for (const [key, value] of filterConfigMap.entries()) {

            // console.log('keyval', key)
            initalValue = {
                ...initalValue,
                [value.setFilterProp]: getSetFilterFunction(value.setAction),
                [value.resetFilterProp]: getResetFilterFunction(value.resetAction),
            }

        }

        console.log('initalValue', initalValue)

        return initalValue

    }, [dispatch])

    const resetFilterState = () => dispatch({ type: 'reset' });

    const appContextValue = useMemo(() => {

        return {
            ...state,
            resetFilterState,
            // auto generated
            ...setters,
        }

    }, [state, dispatch]);
    // function depend on dispatch, which is not supposed to change

    return (<FilterStoreContext.Provider {...props} value={appContextValue} />)

}


