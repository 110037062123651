import { Breadcrumb, Button, Row, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import AppLayout from '../../../common/components/layout';
import { appLinks, appRoutes } from '../../../config/constants';
import { ArrowLeftOutlined } from '@ant-design/icons';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import View from './view';
import { useMemo, useState } from 'react';
import { useAppContext } from '../../../common/components/appContext';


export default () => {

    const navigate = useNavigate();

    const onBack = () => {
        try {

            navigate(`/${appRoutes.notifications}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    //const isLoading = false;

    const { user } = useAppContext();

    const { httpPostAsync } = useHttpHelper();

    const [isSending, setIsSending] = useState(false);

    const sentNotificationAsync = async (postData) => {
        try {
            // const payload = {
            //     recipentInfo,
            //     messageTemplate,
            // }

            setIsSending(true);

            const sentResponse = await httpPostAsync(appLinks.sentAppNotifications, postData);

            setIsSending(false);

            console.log('send notification response', sentResponse)

            if (sentResponse && sentResponse.code === 0) {

                message.success('App notifications sent successfully');

                const notificationSentId = get(sentResponse, 'data.notificationSentId');
                if (notificationSentId > 0) {
                    navigate(`/${appRoutes.notificationDetails}/${notificationSentId}`);
                }

            } else {
                message.error('App notifications could not be sent');
            }

        } catch (error) {
            console.log('error sending notification', error);
            setIsSending(false);
        }
    }

    const formValues = useMemo(() => {

        let extraInfo = {};

        const organisation = get(user, 'organisation');

        console.log('logged in user', user);

        if (organisation) {
            extraInfo = {
                organisation: {
                    label: organisation.label,
                    value: organisation.value,
                    key: `${organisation.value}`
                },
                organisationItems: [{
                    label: organisation.label,
                    value: organisation.value,
                    key: `${organisation.value}`,
                    receipients: []
                }]
            }
        }

        //console.log('organisation', organisation);

        return {
            sendAll: false,
            sendPushNotifications: false,
            ...extraInfo,
        }

    }, [user])

    return (
        <AppLayout activeItem='notifications'>
            <Row align="middle" style={{ paddingBottom: 8 }}>
                <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                    <Breadcrumb.Item><a onClick={onBack}>Notifications</a></Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <View
                sentNotification={sentNotificationAsync}
                formValues={formValues}
                isSending={isSending}
            />
        </AppLayout>
    );

}