
import React from 'react';
import { Col, Row, Space, Avatar, List, Tooltip, Typography } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import ItemTitle from '../../../../common/components/itemTitle';
import { getAppZoneMomentFromUTC } from '../../../../helpers/dateTimeHelpers';
import config from '../../../../config/config';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../config/constants';

const { Text } = Typography;

const IconText = ({ icon, text }) => (
    <Space>
        {icon && React.createElement(icon)}
        {text}
    </Space>
);

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}


export default ({ item = {} }) => {

    const {
        user = {},
        organisation,
    } = item;

    const {
        flagged = 0,
        id: userId = 0,
        lastFlagged,
        userName,
        imageUrl,
        status,
        email,
    } = user;

    const lastReportedMomentDate = getAppZoneMomentFromUTC(lastFlagged);

    let navigate = useNavigate();

    const navigateToUserDetails = () => {
        try {

            navigate(`/${appRoutes.dashboard}/${appRoutes.userView}/${userId}`)

        } catch (error) {
            console.log('error navigating to user', error);
        }
    }

    return (
        <List.Item>
            <List.Item.Meta
                avatar={!!imageUrl ?
                    (
                        <Avatar src={imageUrl} />
                    ) : (
                        <Avatar icon={<UserOutlined />} />
                    )}
                title={
                    <div>
                        <a onClick={navigateToUserDetails}>{userName}</a>
                        <div>
                            <Text type="secondary">{email}</Text>
                        </div>
                    </div>
                }
            />
            <Row style={{ paddingBottom: 12 }} justify="start">
                <Col style={styles.statItem}>
                    <ItemTitle
                        title="User last reported on"
                        value={lastReportedMomentDate && lastReportedMomentDate.isValid() && lastReportedMomentDate.format(config.displayFormat)}
                    />
                </Col>
            </Row>
            <Row style={{ paddingBottom: 12 }} justify="start">
                <Col style={styles.statItem}>
                    <ItemTitle title="Organisation" value={organisation} />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle title="User Status" value={status} />
                </Col>
            </Row>
            <Row>
                <Col style={styles.statItem}>
                    <Tooltip title="Report count" placement="topLeft">
                        <IconText icon={InfoCircleOutlined} text={flagged} key="list-vertical-like-o" />
                    </Tooltip>
                </Col>
            </Row>
        </List.Item>
    )

}