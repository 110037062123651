import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const reportedItemsFilterConfig = {
    key: 'reportedItems',
    isActiveProp: 'isReportedItemsSearchActive',
    setAction: 'reported-items',
    resetAction: 'reset-reported-items',
    setFilterProp: 'setReportedItemsFilters',
    resetFilterProp: 'resetReportedItemsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useReportedItemsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, reportedItemsFilterConfig)
}

export default useReportedItemsFilterContext;
