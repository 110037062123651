
import React from 'react';
import { Col, Row, Avatar, List, Typography, Collapse, Carousel, Image } from 'antd';
import { map } from 'lodash';
import ItemTitle from '../../../common/components/itemTitle';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import config from '../../../config/config';
import UploadItem from '../../reportedItems/List/cards/PostUploads/factory';

const { Text } = Typography;
const { Panel } = Collapse;


const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}


export default (props) => {

    const { item = {}, navigateToUser } = props;

    const {
        //id,
        //title,
        description,
        createdAt,
        organisation,
        totalUploads = 0,
        images = [],
        uploads = [],
        userDetails = {},
        activityDetails = {},
    } = item;

    const createdMomentDate = getAppZoneMomentFromUTC(createdAt);

    //console.log('user detials', userDetails)

    return (
        <List.Item>
            <List.Item.Meta
                avatar={<Avatar src={userDetails.profileImageUrl} />}
                title={<a onClick={(e) => {
                    e.preventDefault()
                    console.log('test', userDetails)
                    typeof navigateToUser === 'function' && navigateToUser(userDetails.id)
                }}>{userDetails.name}</a>}
            />
            <List.Item.Meta
                //title={title}
                description={<Text type="secondary" >{description}</Text>}
            />
            <Row style={{ paddingBottom: 12 }} justify="start">
                <Col style={styles.statItem}>
                    <ItemTitle title="Created" value={createdMomentDate && createdMomentDate.isValid() && createdMomentDate.format(config.displayFormat)} />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle title="Organisation" value={organisation} />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle title="Activity" value={activityDetails.title} />
                </Col>
            </Row>
            <Row>
            </Row>
            {
                totalUploads > 0
                    ?
                    <Collapse ghost class="custom-collapse" >
                        <Panel header={`Uploads (${totalUploads})`} key="1">
                            <Carousel>
                                {
                                    map(images, image => {

                                        return (
                                            <Image alt={image.name} src={image.imageUrl} height={240} />
                                        )

                                    })
                                }
                            </Carousel>
                            <Row style={{ paddingTop: 16 }}>
                                <Col>
                                    {
                                        map(uploads, upload => (<UploadItem {...upload} />))
                                    }
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    : null
            }
        </List.Item>
    )

}