import { Avatar, Form, List, Row, Button, Col, Space, Modal, Slider } from "antd";
import { useMemo, useState } from "react";
import { DeleteOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';


import {
    DndContext,
    closestCenter,
    //  closestCorners,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    //   verticalListSortingStrategy,
    rectSwappingStrategy,
} from '@dnd-kit/sortable';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { findIndex } from "lodash";

const onOneValuesChange = (prev, cur) => prev.oneValues !== cur.oneValues;

const OneValueItems = () => {
    return (
        <div>
            <Form.Item shouldUpdate={onOneValuesChange}>
                {(props) => {

                    const items = props.getFieldValue('oneValues');

                    console.log('one value items', items)

                    const onRemove = (itemId, label) => {

                        Modal.confirm({
                            title: `Are you sure you want to remove ${label}`,
                            okText: 'Remove',
                            closable: true,
                            onOk: () => {
                                console.log('on delete click');

                                const items = props.getFieldValue('oneValues');

                                console.log('oneValues', itemId, items);

                                const filtered = items?.filter(o => o.value !== itemId);

                                //console.log('filtered prerequisites', filtered)
                                props.setFieldsValue({ oneValues: filtered })

                            }

                        });

                    }

                    const getActiveItem = (id) => {

                        const items = props.getFieldValue('oneValues');

                        const item = items?.find(o => o.value === id);

                        return item;
                    }

                    const onSwap = (activeId, overId) => {

                        const items = props.getFieldValue('oneValues');

                        const oldIndex = findIndex(items, o => o.value === activeId);
                        const newIndex = findIndex(items, o => o.value === overId);

                        let updatedItems = arrayMove(items, oldIndex, newIndex);

                        props.setFieldsValue({ oneValues: updatedItems })
                    }

                    const onScoreChange = (itemId, score) => {
                        try {

                            const items = props.getFieldValue('oneValues');

                            const updatesValues = items?.map(o => {
                                if(o.value === itemId) {
                                  return {
                                    ...o,
                                    score
                                  }
                                } 
                                return o;
                            });

                            //console.log('filtered prerequisites', filtered)
                            props.setFieldsValue({ oneValues: updatesValues })

                            
                        } catch (error) {
                            console.log('error updating value score', error)
                        }
                    }

                    return (
                        <div>
                            <DraggableList
                                {...props}
                                oneValues={items}
                                onClose={onRemove}
                                onSwap={onSwap}
                                getActiveItem={getActiveItem}
                                onScoreChange={onScoreChange}
                            />
                        </div>
                    )

                }}
            </Form.Item>
        </div>
    )
}

const OneValueItem = ({ 
    value, 
    index, 
    title, 
    character, 
    score,
    showBorder, 
    onClose,
    onScoreChange,
}) => {

    const {
        // attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useSortable({
        id: value,
        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });


    const borderStyle = useMemo(() => {

        return showBorder ? {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            borderColor: '#1890ff',
        } : {
            borderColor: 'rgb(243 243 243)',
            borderStyle: 'solid',
            borderRadius: 8,
            borderWidth: 1
        }

    }, [showBorder])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: 12,
        backgroundColor: '#fff',
        ...borderStyle
    };

    const itemContainerStyle = { position: 'relative' }

    return (
        <div>
            {index > 0 ? (<div style={{ padding: 4 }} />) : null}
            <div
                style={itemContainerStyle}
                ref={setNodeRef} 
            >
                <Row>
                    <Col flex={1}>
                        <div style={style}>
                            <List.Item>
                                <List.Item.Meta
                                    // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                    title={<span>{title} {index === 0 ? '(Primary)' : ''}</span>}
                                    description={character}
                                />
                               
                            </List.Item>
                            <span>Score: {score ?? ''}</span>
                            <Slider
                                    min={0}
                                    max={10}
                                    onChange={onScoreChange}
                                    value={typeof score === 'number' ? score : 0}
                                />
                        </div>
                    </Col>
                    <Col>
                        <Space direction="vertical" size="middle" style={{
                            padding: 12,
                            height: '100%',
                            justifyContent: 'center'
                        }} >
                            <Button
                                //type='primary'
                                shape='circle'
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {
                                    onClose(value, title)
                                }}
                                danger
                                icon={<DeleteOutlined />}
                            />
                            <Button
                                type="secondary"
                                shape='circle'
                                ref={setActivatorNodeRef}
                                {...listeners}
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                // onClick={() => {
                                //     console.log('draging action')
                                // }}
                                //danger
                                icon={<VerticalAlignMiddleOutlined />}
                            />
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

const DraggableList = ({
    oneValues = [],
    onClose,
    onSwap,
    getActiveItem,
    onScoreChange,
}) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [activeItem, setActiveItem] = useState(null);

    function handleDragStart(event) {

        const { active } = event;
        console.log('active item', active)
        //setActiveId(active.id);
        const item = getActiveItem(active.id);
        setActiveItem(item)

    }

    function handleDragEnd(event) {
        const { active, over } = event;

        if (active.id !== over.id) {
            console.log('drag even', active, over)
            onSwap(active.id, over.id)
            //   setItems((items) => {
            //     const oldIndex = items.indexOf(active.id);
            //     const newIndex = items.indexOf(over.id);

            //    
            //   });
        }

        setActiveItem(null);
    }

    return (
        <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
    >
        <div>
        <SortableContext
                    items={oneValues}
                    strategy={rectSwappingStrategy}
                >
            {
                oneValues?.map((item, index) => {

                    const onChange = (updatedScore) => onScoreChange(item.value, updatedScore)

                    return (
                        <OneValueItem
                            key={item.value}
                            value={item.value}
                            index={index}
                            title={item.label}
                            character={item.character}
                            onClose={onClose}
                            score={item.score}
                            onScoreChange={onChange}
                        />
                    )

                })
            }
               </SortableContext>
               <DragOverlay>
                    {activeItem ? (<OneValueItem {...activeItem }  title={activeItem.label} showBorder />) : null}
                </DragOverlay>
        </div>
        </DndContext>
    )

}

export default OneValueItems;