import { Button, Col, Input, Row, Select } from 'antd';
import React, { useCallback } from 'react';

import EmptyDisplayComponent from '../../../common/components/Empty';
import ClubsLookup from '../../../common/components/lookupSelect/ClubsLookup';
import DifficultyLookup from '../../../common/components/lookupSelect/DifficultyLookup';
import OneOrganisationsLookup from '../../../common/components/lookupSelect/OneOrganisationsLookup';
import SkillsLookup from '../../../common/components/lookupSelect/SkillsLookups';
import ThemesLookup from '../../../common/components/lookupSelect/ThemesLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import FilterTags from './FilterTags';

const { Option } = Select;
const { Search } = Input;

const lookupStyle = { width: 200 }

const createdTypes = [
  { value: 'default', label: 'Created by user' },
  { value: 'chatGPT', label: 'Created using ChatGPT' },
]

const Header = (props) => {

    const {
        styles,
        onFilterParamChangeHOF,
        onDurationChange,
        organisations,
        difficultyLevels,
        durationLevels,
        durations,
        themes,
        skills,
        clubs,
        created,
        searchValue,
        onSearchChange,
        disableCreateChallenge,
        onCreateChallenge,
        onRemoveHOF,
        filterTagItems,
        isSearchActive,
        onReset
    } = props

    const onOrganisationChange = useCallback(onFilterParamChangeHOF('organisations'), [])

    const onDifficultyLevelChange = useCallback(onFilterParamChangeHOF('difficultyLevels'), [])

    const onThemesChange = useCallback(onFilterParamChangeHOF('themes'), [])

    const onSkillsChange = useCallback(onFilterParamChangeHOF('skills'), [])

    const onclubsChange = useCallback(onFilterParamChangeHOF('clubs'), [])

    const onCreatedTypeChange = useCallback(onFilterParamChangeHOF('created'), [])

    return (
        <React.Fragment>
            <Row justify='space-between'>
                <Col span={20}>
                    <Row justify='start' >
                        <Col>
                            <Row>
                                <Col style={styles.itemStyle} >
                                    <OneOrganisationsLookup
                                        onChange={onOrganisationChange}
                                        value={mapKeyValues(organisations)}
                                        style={lookupStyle}
                                        placeholder="Organisation filter"
                                    />
                                </Col>
                                <Col style={styles.itemStyle} >
                                    <DifficultyLookup
                                        onChange={onDifficultyLevelChange}
                                        value={mapKeyValues(difficultyLevels)}
                                        style={lookupStyle}
                                        placeholder="Difficulty filter"
                                    />
                                </Col>
                                <Col style={styles.itemStyle} >
                                    <Select
                                        mode="multiple"
                                        style={lookupStyle}
                                        placeholder="Duration filter"
                                        onChange={onDurationChange}
                                        labelInValue
                                        value={durations}
                                        tagRender={EmptyDisplayComponent}
                                    >
                                        {
                                            durationLevels?.map(s => {
                                                return (
                                                    <Option
                                                        key={s.value}
                                                        start={s.start}
                                                        end={s.end}
                                                        value={s.value}
                                                        text={s.label}
                                                    >{s.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col style={styles.itemStyle}>
                            <ThemesLookup
                                onChange={onThemesChange}
                                value={mapKeyValues(themes)}
                                style={lookupStyle}
                                placeholder="Themes filter"
                            />
                        </Col>
                        <Col style={styles.itemStyle}>
                            <SkillsLookup
                                onChange={onSkillsChange}
                                value={mapKeyValues(skills)}
                                style={lookupStyle}
                                placeholder="Skills filter"
                            />
                        </Col>
                        <Col style={styles.itemStyle}>
                            <ClubsLookup
                                onChange={onclubsChange}
                                value={mapKeyValues(clubs)}
                                style={lookupStyle}
                                placeholder="Clubs filter"
                            />
                        </Col>
                        <Col style={styles.itemStyle}>
                            <Select
                                mode="multiple"
                                style={lookupStyle}
                                placeholder="Created by filter"
                                onChange={onCreatedTypeChange}
                                labelInValue
                                value={mapKeyValues(created)}
                                tagRender={EmptyDisplayComponent}
                            >
                                {
                                    createdTypes?.map(s => (
                                        <Option key={s.value} value={s.value}>{s.label}</Option>
                                    ))
                                }
                            </Select>
                        </Col>
                        <Col style={styles.itemStyle}>
                            <Search
                                placeholder="Search"
                                value={searchValue}
                                onChange={onSearchChange}
                                allowClear
                                style={{ width: 200 }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        block
                        data-testid='create-challenge'
                        disabled={disableCreateChallenge}
                        onClick={onCreateChallenge}
                    >
                        New Challenge
                    </Button>
                </Col>
            </Row>
            <Row style={styles.tagsContainer} justify="space-between">
                <Col>
                    <div style={{ marginBottom: 8 }}>
                        <FilterTags
                            onRemoveHOF={onRemoveHOF}
                            items={filterTagItems}
                        />
                    </div>
                </Col>
                <Col>
                    {
                        isSearchActive
                            ? (
                                <Button type="link" size='small' onClick={onReset}>
                                    Reset
                                </Button>
                            )
                            : null
                    }
                </Col>
            </Row>
        </React.Fragment>
    )

}

export default Header