import { useMemo } from "react";
import { Typography, Image, Divider } from 'antd';
// import styled from 'styled-components';
import { get, map } from "lodash";
import EmptyComponent from '../../../../common/components/Empty';
import { resourceType } from "../../../../config/constants";
import EmptyResult from "../../../../common/components/EmptyResult";

const { Text, Link } = Typography;

// const TitleComponent = styled.span`
// font-size: 16px;
// font-weight: bold;
// `


const LinkResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)

    return (
        <div>
            <Link copyable={{ tooltips: 'Copy link' }}
                href={link} target="_blank">
                {name}
            </Link>
        </div>
    )

}

const YoutubeResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)

    return (
        <div>
            <Text>{name}</Text>
            {link ? <a href={link} target="_blank" style={{ marginLeft: 8 }} >View</a> : null} 
            <span style={{ padding: 4, }}>|</span>
            <Link copyable={{ tooltips: 'Copy link' }}
                href={link} target="_blank">
            </Link>
        </div>
    )

}

const PDFResource = (props) => {

    const { downloadLink, name } = props;

    //console.log('sectoin step', props)

    return (
        <div>
            {
                name
                    ? <Text style={{ paddingRight: 8 }}>{name}</Text> : null}
            {downloadLink ? <a href={downloadLink} target="_blank" >Download</a>
                : null
            } 
             {/* <span style={{ padding: 4, }}> |</span> */}
            <Link 
                //copyable={{ tooltips: 'Copy link', text: downloadLink }}
                href={downloadLink} target="_blank">
            </Link>
           
        </div>
    )

}

const ImageResource = (props) => {

    const { downloadLink, name } = props;

    //console.log('sectoin step', props)

    return (
        <div>
            <Image src={downloadLink} alt={name} />
            <Text>{name}</Text>
        </div>
    )

}

const ViewDownloadResource = (props) => {

    const { link, name } = props;

    //console.log('sectoin step', props)

    return (
        <div>
            <iframe style={{ width: '70vw', height: 300 }}  title={name} src={link} ></iframe>
            <div>
                <Link copyable={{ tooltips: 'Copy link' }}
                    href={link} target="_blank">
                    {name}
                </Link>
            </div>
        </div>
    )

}

const sectionFactory = {
    [resourceType.link]: LinkResource,
    [resourceType.youtubeVideo]: YoutubeResource,
    [resourceType.pdf]: PDFResource,
    [resourceType.image]: ImageResource,
    [resourceType.viewDownload]: ViewDownloadResource,
}


const ResourceElement = (props) => {

    const { type } = props;

    //console.log('resource elment', type)

    const ResourceComponent = useMemo(() => {
        return get(sectionFactory, type, EmptyComponent)
    }, [type]);

    return (<ResourceComponent {...props} />)
}


export default (props) => {

    const { resources = [] } = props;

    console.log('resources', resources);

    return (
        <div>
            {
                !resources || !resources.length
                    ? (<EmptyResult description='No resources' />)
                    : null
            }
            {
                map(resources, (resource, index) => {
                    return (
                    <div>
                        {index > 0 ? (<Divider />) : null}
                        <ResourceElement key={resource.id} {...resource} />
                    </div>)

                })
            }
        </div>
    )
}
