import { Col, Divider, Form, Row, Spin } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import CharacterComponentMap from "../../../../../common/components/characters";
import empty from "../../../../../common/components/Empty";
import Lookup from "../../../../../common/components/lookupSelect/index";
import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../../config/constants";
import AreasItems from "./AreasItems";
import OneValueItems from "./OneValueItems";
import './style.css';

export const areasValueName = 'oneAreas';

export const onValuesOrAreasChange = (prev, cur) => prev.onevalues !== cur.onevalues || prev.oneAreas !== cur.oneAreas;

function oneValueItemMapper(item) {
    const { value, label, character } = item;    
   // console.log('oneValueItemMapper', item)
    return {
        key: value,
        value,
        label: label,
        children: label,
        character,
    }
}

function areasItemMapper({ value, label, imageUrl, listImageUrl }) {
    return {
        key: value,
        value,
        label: label,
        children: label,
        imageUrl,
        listImageUrl,
    }
}

export const BannerBackgroundImage = (props) => {

    const { imageUrl, ...rest } = props;

    const [loadingState, setLoadingState] = useState()

    const onLoad = () => {
        console.log('on image load')
        setLoadingState('loaded')
    }

    useEffect(() => {

        console.log('start loading', typeof imageUrl === 'string')

        if (typeof imageUrl === 'string' && imageUrl.length > 0) {
            console.log('start loading: state')
            setLoadingState('loading')
        }

    }, [imageUrl])

    const imageElements = imageUrl
        ? (
            <img
                src={imageUrl}
                alt="background"
                className="banner-image"
                onLoad={onLoad}
                onError={() => setLoadingState('error')}
                {...rest}
            />
        ) : (
            <div {...rest} className="default-banner-image" />
        )

    return (
        (loadingState === 'loading')
            ? (
                <Spin spinning={loadingState === 'loading'} className="background-spinner">{imageElements}</Spin>
            ) : (imageElements)
    )
}

export const MainBanner = ({ character, title, imageUrl }) => {

    const CharacterComponent = useMemo(() => CharacterComponentMap?.[character] ?? CharacterComponentMap.default, [character])

    return (
        <div className="banner-container">
            <div className={character ? "banner" : "banner-center"}>
                {/* <span className="title">{title}</span> */}
                <div className="character-container">
                    <CharacterComponent className="character" />
                </div>
            </div>
            <BannerBackgroundImage imageUrl={imageUrl} />
        </div>
    )

}

export const ListBackgroundImage = (props) => {

    const { imageUrl, ...rest } = props;

    const [loadingState, setLoadingState] = useState()

    const onLoad = () => {
        console.log('on image load')
        setLoadingState('loaded')
    }

    useEffect(() => {

        console.log('start loading', typeof imageUrl === 'string')

        if (typeof imageUrl === 'string' && imageUrl.length > 0) {
            console.log('start loading: state')
            setLoadingState('loading')
        }

    }, [imageUrl])

    const imageElements = imageUrl
        ? (
            <img
                src={imageUrl}
                alt="background"
                className="list-banner-image"
                onLoad={onLoad}
                onError={() => setLoadingState('error')}
                {...rest}
            />
        ) : (
            <div {...rest} className="default-list-banner-image" />
        )

    return (
        (loadingState === 'loading')
            ? (
                <Spin spinning={loadingState === 'loading'} className="background-spinner">{imageElements}</Spin>
            ) : (imageElements)
    )
}

export const ListBanner = ({ character, title, imageUrl }) => {

    const CharacterComponent = useMemo(() => CharacterComponentMap?.[character] ?? CharacterComponentMap.default, [character])

    return (
        <div className="list-banner-container">
            <div className={character ? "banner" : "banner-center"}>
                <span className="title">{title}</span>
                <div className="character-container">
                    <CharacterComponent className="character" />
                </div>
            </div>
            <ListBackgroundImage imageUrl={imageUrl} />
            <div className="transparent" >
                <span />
            </div>
        </div>
    )

}


const Banner = (props) => {

    const { title, organisationId, form } = props;

    const { httpPostAsync } = useHttpHelper();

    //#region OneValues lookups

    const [searchParams, setSearchParams] = useState({ query: '' });

    const { query = '' } = searchParams;

    const queryParams = useMemo(() => ({ organisationId, query }), [organisationId, query]);

    const fetchDataAsync =
        useCallback(async (params = {}) => {

            const link = appLinks.oneValuesLookups.replace('{organisationId}', organisationId);
            const response = await httpPostAsync(link, { ...params });

            console.log('one values resopnse', response)

            return response?.data ?? [];

        }, [httpPostAsync, organisationId]);

    const enableFetchFn = useCallback(({ organisationId = 0 }) => organisationId > 0, []);

    const queryKeyFn =
        useCallback(({ organisationId, query }) => [queryKeys.oneValuesLookups, organisationId, query], []);

    const deboundedSearch = useCallback(debounce(q => setSearchParams(p => ({ ...p, query: q })), 300), [])

    const onSearch = (e) => {
        // console.log('on search', e);
        deboundedSearch(e);
    }

    const onBlur = (e) => {
        //console.log('on select', e)
        deboundedSearch();
    }
    //#endregion One Values

    //#region areas of development lookups
    const [areasSearchParams, setAreasSearchParams] = useState({ areasQuery: '' });

    const { areasQuery = '' } = areasSearchParams;

    const areasQueryParams = useMemo(() => ({ organisationId, areasQuery }), [organisationId, areasQuery]);

    const fetchAreasDataAsync =
        useCallback(async (params = {}) => {

            const link = appLinks.areasLookups.replace('{organisationId}', organisationId);
            const response = await httpPostAsync(link, { ...params });

            console.log('areas response', response)

            return response?.data ?? [];

        }, [httpPostAsync, organisationId]);

    const enableAreasFetchFn = useCallback(({ organisationId = 0 }) => organisationId > 0, []);

    const areasQueryKeyFn =
        useCallback(({ organisationId, areasQuery }) => [queryKeys.areasLookups, organisationId, areasQuery], []);

    const areasDeboundedSearch = useCallback(debounce(q => setAreasSearchParams(p => ({ ...p, areasQuery: q })), 300), [])

    const onAreasSearch = (e) => {
        // console.log('on search', e);
        areasDeboundedSearch(e);
    }

    const onAreasBlur = (e) => {
        //console.log('on select', e)
        areasDeboundedSearch();
    }
    //#endregion
    // const updatePrerequisites = (newItems = []) => {
    //     try {

    //         form.setFieldsValue({ prerequisites: [...newItems] })
    //         setPrequisiteCount(newItems.length)
    //         // form.setFieldValue('prerequisites', [ ...newItems ])

    //     } catch (error) {
    //         console.log('error updating challenge prequisites', error);
    //     }
    // }

    return (
        <div className="challenge-banner">
            <div className="values-col">
                <Form.Item shouldUpdate={onValuesOrAreasChange}>
                    {(itemProps) => {

                        const items = itemProps.getFieldValue('oneValues');
                        const character = items?.[0]?.character;

                        console.log('one values', items);

                        const areas = itemProps.getFieldValue(areasValueName)
                        const imageUrl = areas?.[0]?.imageUrl;

                        //console.log('backgroundUrl', backgroundUrl)

                        return (
                            <MainBanner character={character} title={title} imageUrl={imageUrl} />
                        )

                    }}
                </Form.Item>
                <div className="values-entries">
                    <Form.Item
                        name="oneValues"
                        label="Values"
                        getValueFromEvent={(args, params) => {

                            //console.log('get value from event', args, params)

                            const oneValues = form.getFieldValue('oneValues');

                            const items = params?.map(e => {

                                const existingValueItem = oneValues?.find(el => el.value === e.value);

                                //console.log('existingValueItem', existingValueItem)

                                return ({
                                disabled: undefined,
                                //key: e.key,
                                value: e.value,
                                label: e.label,
                                score: existingValueItem?.score ?? 0,
                                character: e.character,
                            })

                        })

                            return items;
                        }}
                    >
                        <Lookup
                            placeholder="Select values"
                            queryParams={queryParams}
                            fetchAsync={fetchDataAsync}
                            queryKeyFn={queryKeyFn}
                            enabledFn={enableFetchFn}
                            lookupId='one-values'
                            //allowClear
                            showSearch
                            onSearch={onSearch}
                            filterOption={false}
                            mode='multiple'
                            labelInValue
                            tagRender={empty}
                            onBlur={onBlur}
                            style={{ width: 240 }}
                            itemPropsMapperFn={oneValueItemMapper}
                        />
                    </Form.Item>
                    <OneValueItems />
                </div>
            </div>
            <div className="areas-col">
                <Form.Item shouldUpdate={onValuesOrAreasChange}>
                    {(itemProps) => {

                        const items = itemProps.getFieldValue('oneValues');
                        const character = items?.[0]?.character;

                        const areas = itemProps.getFieldValue(areasValueName)
                        const imageUrl = areas?.[0]?.listImageUrl;

                        return (
                            <ListBanner character={character} title={title} imageUrl={imageUrl} />
                        )

                    }}
                </Form.Item>
                <div className="areas-entries">
                    <Form.Item
                        name={areasValueName}
                        label="Areas of development"
                        getValueFromEvent={(args, params) => {
                            console.log('get value from event', args, params)

                            const oneAreas = form.getFieldValue(areasValueName);

                            const items = params?.map(e => {
                               
                                const areaItem = oneAreas?.find(el => el.value === e.value);
                                
                                return ({
                                disabled: undefined,
                                value: e.value,
                                label: e.label,
                                imageUrl: e.imageUrl,
                                score: areaItem?.score ?? 0,
                                listImageUrl: e.listImageUrl,
                            })
                        })

                            return items;

                        }}
                    >
                        <Lookup
                            placeholder="Select area of development"
                            queryParams={areasQueryParams}
                            fetchAsync={fetchAreasDataAsync}
                            queryKeyFn={areasQueryKeyFn}
                            enabledFn={enableAreasFetchFn}
                            lookupId='areas-of-development'
                            //allowClear
                            showSearch
                            onSearch={onAreasSearch}
                            filterOption={false}
                            mode='multiple'
                            labelInValue
                            tagRender={empty}
                            onBlur={onAreasBlur}
                            style={{ width: 240 }}
                            itemPropsMapperFn={areasItemMapper}
                        />
                    </Form.Item>
                    <AreasItems />
                </div>
            </div>
        </div>
    )

}

export default Banner;