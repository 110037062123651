import React from 'react';
import { Form, Divider, Select } from 'antd';
import { map, get, filter } from 'lodash';

import TagChip from '../../../../common/components/tagchip';
import useHttpHelper from '../../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../../../config/constants';
import { useQuery } from 'react-query';
import Empty from '../../../../common/components/Empty';

const { Option } = Select;


const shouldUpdateCubs = () => (prev, cur) => prev.clubs !== cur.clubs

const OrganisationClubSelect = (props) => {

    const { index, form } = props;

    const items = form.getFieldValue('clubs');

    const organisationId = get(items, `[${index}].organisationId`)

    const { httpPostAsync } = useHttpHelper();

    const fetchRolesAsync = async () => {
        try {

            return await httpPostAsync(appLinks.clubsLookups, { organisationId })
        } catch (error) {
            console.log('error getting user clubs for organisations', error);
        }
    };

    const queryKey = [queryKeys.organisationClubs, organisationId];

    const {
        data: clubs = [],
        isLoading,
    } = useQuery(
        queryKey,
        fetchRolesAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });

    //console.log('roles', roles);

    return (
        <Form.Item
            //label="Roles"
            name={[index, "clubs"]}
            //rules={[{ required: true, message: 'Please choose an Type.' }]}
            wrapperCol={{ span: 16 }}
        >
            <Select
                placeholder="Select clubs"
                mode="multiple"
                labelInValue
                tagRender={Empty}
                //notFoundContent={e => Empty}
                loading={isLoading}
            // disabled={disableSkills}
            //allowClear
            >
                {
                    map(clubs, item => {
                        return (
                            <Option key={item.value} value={item.value}>{item.label}</Option>
                        )
                    })
                }
            </Select>
        </Form.Item>
    )
}

export default ({ form }) => {

    return (
        <Form.List name='clubs'>
            {(fields, { add, remove }) => {
                return (
                    <div>
                        <div style={{ minHeight: 300 }}>
                            {map(fields, (field, index) => (
                                <div key={field.key}>
                                    {index > 0 ? <div><Divider /></div> : null}
                                    <Form.Item shouldUpdate={shouldUpdateCubs}>
                                        {(props) => {

                                            const items = props.getFieldValue('clubs');

                                            const organisation = get(items, `[${index}].organisation`)

                                            //console.log('items', items);

                                            return (<div>Clubs for: {organisation}</div>);

                                        }}
                                    </Form.Item>
                                    <OrganisationClubSelect index={index} form={form} />
                                    <Form.Item shouldUpdate={shouldUpdateCubs}>
                                        {(props) => {

                                            // roles grouped by organisation
                                            const items = props.getFieldValue('clubs');

                                            const organisationRoles = get(items, `[${index}].clubs`);

                                            //console.log('items', items);

                                            return (
                                                <React.Fragment>
                                                    {map(organisationRoles, role => {

                                                        return (
                                                            <TagChip
                                                                key={role.value}
                                                                closable
                                                                onClose={() => {

                                                                    const filtered = filter(organisationRoles, o => o.value !== role.value);

                                                                    console.log('filtered clubs', filtered)

                                                                    items[index].clubs = [...filtered ]
                            
                                                                    props.setFieldsValue({ clubs: items })

                                                                }}
                                                            >
                                                                {role.label}
                                                            </TagChip>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        }}
                                    </Form.Item>
                                </div>))}
                        </div>
                    </div>
                )
            }}
        </Form.List>)
}