import { Button, Form, Input, Modal } from "antd"
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export const paraFieldName = 'sectionParas'

const ParagraphItems = (props) => {

    const {
        getParagraphs,
        field,
        form,
    } = props

    const getUniqueId = () => {
        try {

            const items = form.getFieldValue('emailSections')

            console.log('sectins', items)

            const paras = getParagraphs()

            let timeStamp = new Date().getTime();
            let index = 0
            for (; index < 100; index++) {
                const elem = paras?.find(e => e.uid === timeStamp)
                if (!elem) {
                    break;
                }
                timeStamp = new Date().getTime();
            }

            console.log('paras', paras)

            return timeStamp;

        } catch (error) {
            console.log('error getting unique id', error)
        }
    }

    return (
        <div style={{
            marginBottom: 16
        }}>
            <Form.List name={[field.name, paraFieldName]}>
                {(paraFields, { add, remove, move }) => {


                    // console.log('para items', fields?.length)

                    return (
                        <div>
                            {
                                paraFields?.map((paraField, index) => {

                                    //console.log('para', paraField.name, index)

                                    const onRemove = () => {
                                        try {

                                            confirm({
                                                title: `Are you sure remove this paragraph ${index + 1}?`,
                                                icon: <ExclamationCircleOutlined />,
                                                content: 'This paragraph will be removed permently after save.',
                                                okText: 'Remove',
                                                okType: 'danger',
                                                cancelText: 'No',
                                                onOk() {

                                                    console.log('Remove', paraField.name,);
                                                    remove(paraField.name);
                                                },
                                                onCancel() {
                                                    console.log('Cancel');
                                                },
                                            });


                                        } catch (error) {
                                            console.log('error removing step', error);
                                        }
                                    }

                                    return (
                                        <div key={paraField.key}
                                            className="para-item"
                                        >
                                            <Button
                                                className="delete-btn"
                                                //type='primary'
                                                shape='circle'
                                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                                onClick={() => {
                                                    onRemove && onRemove();
                                                    // onClose(value, label)
                                                }}
                                                danger
                                                icon={<DeleteOutlined />}
                                            />

                                            <Form.Item
                                                className="para-text-input"
                                                label={`Paragraph ${index + 1}`}
                                                {...paraField}
                                                name={[paraField.name, "para"]}

                                            >
                                                <Input.TextArea
                                                    placeholder="Header text"
                                                    rows={3}
                                                />
                                            </Form.Item>
                                        </div>
                                    )

                                })
                            }
                            <Button
                                type="primary"
                                onClick={() => {

                                    const uid = getUniqueId()

                                    add({
                                        uid,
                                        para: ''
                                    })

                                }}
                                icon={<PlusOutlined />}
                            >
                                Add Paragraph
                            </Button>
                        </div>
                    )

                }}
            </Form.List>
        </div>
    )

}


export default ParagraphItems