import React, { useMemo, useRef, useState } from 'react';
import { Form, Typography, message, Upload, Result } from 'antd';
import { get, includes } from 'lodash';
import FormUploader from '../../../formUploader';

import { ReactComponent as UserProfileDefault } from '../../../../../../assets/userProfile.svg';
import { appLinks } from '../../../../../../config/constants';
import useHttpHelper from '../../../../../../common/hooks/useHttpHelper';

import { CheckCircleTwoTone } from '@ant-design/icons';



const { Text } = Typography;


const normFile = (e) => {

    console.log('Upload event:', e);

    if (Array.isArray(e)) {
        return e;
    }

    return e && e.fileList;
};

const DocumentPreview = (props) => {

    const { resources = [], index, isUploaded = false } = props;

    const { fileName, name, downloadLink } = useMemo(() => {
        try {

            const resource = resources[index];
            return resource || {};

        } catch (error) {
            console.log('error getting image resources url', error);
            return {};
        }

    }, [resources, index]);

    console.log('resource info', resources[index])

    return (
        <div>
            {
                fileName && isUploaded ?
                    (
                    <span style={{ paddingRight: 8  }}>
                    <Text style={{ paddingRight: 8 }}>{fileName}</Text> 
                    <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 16 }}/>
                    </span>
                    )
                    : null
            }
            {
                fileName && !isUploaded
                    ? <Text style={{ paddingRight: 8 }}>{fileName}</Text> : null
            }
            {downloadLink && !isUploaded ? <a href={downloadLink} target="_blank" >Download</a> : null}
        </div>
    ) 

}

export default (props) => {

    const { index, form } = props;

    const { httpPostAsync } = useHttpHelper();
    const documentIdRef = useRef();
    //const fileInfoRef = useRef({});

    const [isUploaded, setIsUploaded] = useState(false);

    const getUploadUrlAsync = async (UploadFile = {}) => {
        try {

            setIsUploaded(false);

            const uploadLinkResponse = await httpPostAsync(appLinks.uploadResourceUrl, UploadFile)

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload link response', uploadLinkResponse)

            documentIdRef.current = documentId;

            return uploadUrl;

        } catch (error) {
            console.log('error getting upload url', error)
        }
    }

    const onUploadSuccess = (fileInfo) => {
        try {

            const resources = form.getFieldValue('resources');
            console.log('resources', resources);
            const resource = get(resources, `[${index}]`);
            console.log('resource', fileInfo, resource); 

            //fileInfoRef.current = fileInfo;

            if (resource && resource.type === 'pdf' && documentIdRef.current > 0) {

                //resource.documentId = documentIdRef.current

                resources[index] = {
                    ...resource,
                    documentId: documentIdRef.current,
                    fileName: fileInfo.fileName,
                }

                form.setFieldsValue({ resources: [...resources] })
            }

            setIsUploaded(true);

        } catch (error) {
            console.log('error in image resource upload success', error);
        }
    }


    const beforeUpload = (file) => {

        const extenstion = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

        console.log('extension ', extenstion);

        const isImage = includes(['.pdf', '.PDF'], extenstion);
        if (!isImage) {
            message.error(`Only pdf files can be uploaded`);
        }

        if (file.size > 100 * 1024 * 1024) {
            message.error(`File size needs to be less then 100MB`);
        }

        return isImage || Upload.LIST_IGNORE;
    }


    return (
        <Form.Item
            label="Pdf Document (max upload size 100MB)"
            name={[index, "pdfFile"]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            wrapperCol={{ span: 22 }}
            // restrict file types to image
            rules={[{ required: true, message: 'File upload is required' }]}
        >
            <FormUploader
                getUploadUrlAsync={getUploadUrlAsync}
                onUploadSuccess={onUploadSuccess}
                beforeUpload={beforeUpload}
                previewComponent={
                    <Form.Item shouldUpdate={(prev, cur) => prev.resouces !== cur.resources}>
                        {(props) => {

                            const resources = props.getFieldValue('resources');

                            return (<DocumentPreview resources={resources} index={index} isUploaded={isUploaded} />)
                        }}
                    </Form.Item>
                }
            />
        </Form.Item>
    )
}


