import { Navigate, useLocation } from 'react-router-dom';
import { useAppContext } from '../../common/components/appContext';
import AppLayout from '../../common/components/layout';
import Users from './user';


export default ({ }) => {

  const { isAuthenticated } = useAppContext();

  let location = useLocation();

  if (!isAuthenticated) {
    // TODO give this responsibility to other component
    console.log('unauthenticated redirect back to login')
    return (<Navigate to="/login" state={{ from: location }} replace />);
  }

  return (
    <AppLayout activeItem='users'>
      <Users />
    </AppLayout>
  );

}