import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const unsubscribeFilterConfig = {
    key: 'unsubscribeEmails',
    isActiveProp: 'isUnsubscribeEmailsSearchActive',
    setAction: 'unsubscribe-emails',
    resetAction: 'rest-unsubscribe-emails',
    setFilterProp: 'setUnsubscribeEmailsFilters',
    resetFilterProp: 'resetUnsubscribeEmailsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}


const useUnsubscribeEmailsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, unsubscribeFilterConfig)
}

export default useUnsubscribeEmailsFilterContext;