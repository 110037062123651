import { get } from 'lodash';
import { useCallback } from 'react';
import { useAppContext } from '../components/appContext';


export default function useHttpHelper() {

    const { axiosInstance, token } = useAppContext();

    const httpPostAsync = useCallback(async (
        link, 
        payload = {}, 
        config = {},
        defaultResponse = {}) => {
        try {

         // console.log('http post token', !!token);
           
        const axios = axiosInstance;

            const requestConfig = {
              ...config,
                headers: {
                    ...get(config, 'headers', {}),
                    Authorization: `Bearer ${token}`,
                },
            }
            
          const response = await axios.post(link, payload, requestConfig);
      
          const { data = defaultResponse } = response || {};
      
          return data;

        } catch (error) {
          console.log('error in http helper, when making post call', error);
          return defaultResponse;
        }
      }, [axiosInstance, token])


      const httpGetAsync = useCallback(async (
        link, 
        payload = {}, 
        config = {},
        defaultResponse = {}) => {
        try {
           
        const axios = axiosInstance;

            const requestConfig = {
              ...config,
                headers: {
                    ...(config?.['headers'] ?? {}),
                    Authorization: `Bearer ${token}`,
                },
            }
            
          const response = await axios.get(link, { data: payload, ...requestConfig });
      
          const  data = response?.data ?? defaultResponse ?? {};
      
          return data;
          
        } catch (error) {
          console.log('error in http helper, when making get call', error);
          return defaultResponse;
        }
      }, [axiosInstance, token])


      const httpPutAsync = useCallback(async (
        link, 
        payload = {}, 
        config = {},
        defaultResponse = {}) => {
        try {
           
        const axios = axiosInstance;

            const requestConfig = {
              ...config,
              headers: {
                  ...get(config, 'headers', {}),
                  Authorization: `Bearer ${token}`,
              },
            }
            
          const response = await axios.put(link, payload, requestConfig);
      
          const { data = defaultResponse } = response || {};
      
          return data;

        } catch (error) {
          console.log('error in http helper, when making put call', error);
          return defaultResponse;
        }
      }, [axiosInstance, token])

      const httpDeleteAsync = useCallback(async (
        link, 
        config = {},
        defaultResponse = {}) => {
        try {
           
        const axios = axiosInstance;

            const requestConfig = {
              ...config,
              headers: {
                  ...get(config, 'headers', {}),
                  Authorization: `Bearer ${token}`,
              },
            }
            
          const response = await axios.delete(link, requestConfig);
      
          const { data = defaultResponse } = response || {};
      
          return data;

        } catch (error) {
          console.log('error in http helper, when making delete call', error);
          return defaultResponse;
        }
      }, [axiosInstance, token])

      return {
        httpPostAsync,
        httpGetAsync,
        httpPutAsync,
        httpDeleteAsync,
    }

}

