import { useState } from "react"
import useHttpHelper from "../../../../common/hooks/useHttpHelper"
import { appLinks } from "../../../../config/constants"
import { useAppContext } from "../../../../common/components/appContext"
import axios from "axios"
import appConfig from "../../../../config/config"


export default function useGenerateChallenge() {

    const [promptResponse, setProptResponse] = useState('')
    const [promptState, setPromptState] = useState('new')
    // const [promp, setProp] = useState()

    const { axiosInstance, token } = useAppContext();

    const { httpPostAsync } = useHttpHelper()

    const fetchGptResponseAsync = async (postData) => {
        try {

            const link = appConfig.chapgptUrl ?? appLinks.generateChallenge

            setPromptState('loading')

            const response = await httpPostAsync(link, postData)
            setPromptState('loaded')
            console.log('response', response)

            const data = response?.data ?? {}

            setProptResponse(data)

            return data

        } catch (error) {
            console.log('error fetching prompt response', error)
            setPromptState('error')
            setProptResponse('')
        }
    }


    const isGptResponseFetched = promptState === 'loaded'

    const hasNewMaterials = !!promptResponse?.materials?.find(e => e.isNew)
    const hasNewValues = !!promptResponse?.oneValues?.find(e => e.isNew)
    const hasNewAreas = !!promptResponse?.oneAreas?.find(e => e.isNew)

    // const updateMaterialText = (value, text) => {
    //     setProptResponse(p => {

    //         const materials = p?.materials?.map(e => {

    //             return (e.value === value && e.isNew === true) ?
    //                 ({
    //                     ...e,
    //                     label: text
    //                 })
    //                 : e

    //         }) ?? []

    //         return {
    //             ...p,
    //             materials,
    //         }

    //     })
    // }

    // const onRemoveMaterial = (value) => {
    //     setProptResponse(p => {

    //         let materials = p?.materials?.filter(e => e.value !== value)

    //         return {
    //             ...p,
    //             materials,
    //         }

    //     })
    // }

    // const replaceMaterial = (value, newItem) => {

    //     const isAlreadyExisting = promptResponse?.materials?.find(e => e.value === newItem.value && !e.isNew)

    //     if (isAlreadyExisting) {
    //         return '-1'
    //     }

    //       setProptResponse(p => {

    //           const materials = p?.materials?.map(e => {

    //               return (e.value === value && e.isNew === true) ?
    //                   ({
    //                       ...e,
    //                       ...newItem,
    //                       isNew: false,
    //                   }) : e

    //           }) ?? []

    //         return {
    //             ...p,
    //             materials,
    //         }

    //     })

    //   return '0'

    // }


    const onStepTextUpdate = (uniqueId, text) => {

        const steps = promptResponse?.steps?.map(e => {

            return (e.uniqueId === uniqueId) ?
                ({
                    ...e,
                    description: text
                })
                : e

        })

        setProptResponse(p => ({
            ...p,
            steps,
        }))

        return steps;

    }

    return {
        isGptResponseFetched,
        fetchGptResponseAsync,
        promptState,
        promptResponse,
        intro: promptResponse?.intro,
        description: promptResponse?.description,
        hasNewMaterials,
        hasNewValues,
        hasNewAreas,
        // updateMaterialText,
        // onRemoveMaterial,
        // replaceMaterial,
        onStepTextUpdate
    }

}