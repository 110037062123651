import { Form, message, Row, Upload, Input } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { appLinks, imageFilesExenstions } from '../../../config/constants';
import { normFile } from '../../../helpers/utils';

import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { includes } from 'lodash';
import FormUploader from '../../challenge/common/formUploader';
import { HexColorPicker } from 'react-colorful';


const CoverImagePreview = (props) => {

    const { previewUrl } = props;

    return (
        previewUrl
            ? (
                <Row style={{ paddingBottom: '15px' }} >
                    <img src={previewUrl} alt="avatar" style={{ width: 280, height: 180, borderRadius: '8px' }} />
                </Row>
            )
            : (
                <Row style={{ paddingBottom: '15px' }}>
                    <CoverImageDefault height={180} width={280} style={{ borderRadius: 8 }} />
                </Row>
            )
    )

}


export default ({
    form,
    themeImageId,
    themeImageUrl,
    thumbImageId,
    thumbImageUrl,
    onSubmitCallback,
    organisationId
}) => {

    const { httpPostAsync } = useHttpHelper();

    //#region cover image 
    const [imageId, setImageId] = useState();
    const [imageUrl, setImageUrl] = useState();


    useEffect(() => {

        setImageId(themeImageId);
        setImageUrl(themeImageUrl);

    }, [themeImageId, themeImageUrl])

    const getImageUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.themeCoverImageUploadUrl, { organisationId, ...UploadFile })

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload theme cover image response', uploadLinkResponse)

            setImageId(documentId)

            return uploadUrl;

        } catch (error) {
            console.log('error getting theme cober upload url', error)
        }
    }

    const onImageUploadSuccess = ({ url }) => {
        try {

            console.log('on file upload success', imageId)

            //setThumbId(challengeThumbDocumentIdRef.current);
            setImageUrl(url);
            //form.setFieldsValue({ thumbId: thumbId })



        } catch (error) {
            console.log('error in image resource upload success', error);
        }
    }

    //#endregion

    //#region thumb image

    // thumb image url
    const [listThumbImageId, setListThumbImageId] = useState();
    const [listThumbImageUrl, setListThumbImageUrl] = useState();

    useEffect(() => {

        setListThumbImageId(thumbImageId);
        setListThumbImageUrl(thumbImageUrl);

    }, [thumbImageId, thumbImageUrl])


    const getThumbImageUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.themeCoverImageUploadUrl, { organisationId, ...UploadFile })

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload theme thumb image response', uploadLinkResponse)

            setListThumbImageId(documentId)

            return uploadUrl;

        } catch (error) {
            console.log('error getting thumb upload url', error)
        }
    }

    const onThumbImageUploadSuccess = ({ url }) => {
        try {

            console.log('on thumb image upload success', imageId)

            //setThumbId(challengeThumbDocumentIdRef.current);
            setListThumbImageUrl(url);
            //form.setFieldsValue({ thumbId: thumbId })



        } catch (error) {
            console.log('error in theme thume image upload success', error);
        }
    }

    //#endregion

    const beforeImageUpload = (file) => {

        const extenstion = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

        console.log('extension ', extenstion);

        const isImage = includes(imageFilesExenstions, extenstion);
        if (!isImage) {
            message.error(`Only image files can be uploaded`);
        }

        if (file.size > 3 * 1024 * 1024) {
            message.error(`File size needs to be less then 3MB`);
        }

        return isImage || Upload.LIST_IGNORE;
    }

    //#region color pick

    const shouldUpdateColorInput = useCallback(() => (prev, cur) => prev.color !== cur.color, []);

    const onColorPickerChange = (e) => {
        try {

            //console.log('color picker', e);

            form.setFieldsValue({ hexColor: e });

        } catch (error) {
            console.log('error on color picker change', error);
        }
    }

    const onColorInputChange = (e) => {
        try {

            //console.log('on color input change', e.target.value);
            form.setFieldsValue({ color: e.target.value });

        } catch (error) {
            console.log('on color input change', error);
        }
    }

    //#endregion

    const onSubmit = (values) => {
        try {

            let postData = {
                title: values.title,
                imageId,
                thumbId: listThumbImageId,
                color: values.color,
            }

            onSubmitCallback && onSubmitCallback(postData);

        } catch (error) {
            console.log('error in edit theme submit form', error);
        }
    }

    return (
        <Form
            layout='vertical'
            form={form}
            scrollToFirstError
            name='create-edit-theme'
            //initialValues={initialValues}
            onFinish={onSubmit}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Title is required.' }]}
                wrapperCol={{ span: 16 }}
            >
                <Input placeholder="title" showCount maxLength={50} />
            </Form.Item>
            <Form.Item
                label="Color"
                name="color"
                //wrapperCol={{ span: 22 }}
                valuePropName="color"
                //getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please choose an color' }]}
            >
                <HexColorPicker onChange={onColorPickerChange} />
            </Form.Item>
            <Form.Item shouldUpdate={shouldUpdateColorInput}>
                {(props) => {

                    return (
                        <Form.Item
                            label="Color"
                            name="hexColor"
                            rules={[
                                { required: true, message: 'Please enter an color' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        var reg = /^#([0-9a-f]{3}){1,2}$/i;
                                        if (reg.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Invalid hex color code'));
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="Color" onChange={onColorInputChange} />
                        </Form.Item>
                    )

                }}
            </Form.Item>
            <Form.Item
                label="Cover Image (1920 X 1080)"
                name="imageFile"
                //wrapperCol={{ span: 22 }}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please choose a cover image' }]}
            >
                <FormUploader
                    getUploadUrlAsync={getImageUploadUrlAsync}
                    onUploadSuccess={onImageUploadSuccess}
                    beforeUpload={beforeImageUpload}
                    previewComponent={
                        <Form.Item shouldUpdate={(prev, cur) => prev.listingImage !== cur.listingImage}>
                            {() => {
                                return (<CoverImagePreview previewUrl={imageUrl} />)
                            }}
                        </Form.Item>
                    }
                />
            </Form.Item>
            <Form.Item
                label="List Image (1920 X 1080)"
                name="thumbImage"
                //wrapperCol={{ span: 22 }}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: true, message: 'Please choose a list image' }]}
            >
                <FormUploader
                    getUploadUrlAsync={getThumbImageUploadUrlAsync}
                    onUploadSuccess={onThumbImageUploadSuccess}
                    beforeUpload={beforeImageUpload}
                    previewComponent={
                        <Form.Item shouldUpdate={(prev, cur) => prev.thumbImage !== cur.thumbImage}>
                            {() => {
                                return (<CoverImagePreview previewUrl={listThumbImageUrl} />)
                            }}
                        </Form.Item>
                    }
                />
            </Form.Item>
        </Form>
    )

}