import { Spin } from 'antd';
import { useQuery } from 'react-query';

import { useMemo, useState } from 'react';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import appConfig from '../../../config/config';
import { appLinks, queryKeys } from '../../../config/constants';
import NpsResponseTable from './table';

import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import './index.scss';
import useNpsFilterContext from './useFilterContext';


const pageSize = 10;

const NpsResponses = (props) => {

    const [resetKey, setResetKey] = useState(0);

    const { searchParams, setSearchParams } = useNpsFilterContext();

    const {
      pageNo,
      query,
      sortBy,
      sortOrder,
      organisations = [],
    } = searchParams;

    const {
        httpPostAsync,
    } = useHttpHelper();

    const fetchSessionLogsAsync = async (params) => {
        // console.log('call api:page param', pageParam, searchCriteria);

        let postData = {
            timezone: appConfig.appTimeZone,
            itemPerPage: pageSize,
            pageNo,
            sortBy,
            sortOrder,
        }

        console.log('nps list post data', postData)

        const response = await httpPostAsync(
            appLinks.npsReponses,
            postData
            //   {
            //     query,
            //     sortBy,
            //     sortOrder
            //   }
        );

        const data = response?.data 

        console.log('nps list response', data)

        return data

    }


    const queryKey = useMemo(() => {

        const key = [queryKeys.npsResponses, pageNo, query, sortBy, sortOrder];
    
        console.log('filter key', key)
    
        return key;
    
      }, [pageNo, query, sortBy, sortOrder]);

    const {
        isLoading,
        //isError,
        //error,
        data = {},
        isFetching,
        isFetchingNextPage,
    } = useQuery(
        queryKey,
        fetchSessionLogsAsync,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            getNextPageParam: (currentResponse) => {
                const { LastEvaluatedKey } = currentResponse ?? {};
                //console.log('getNextPageParam', nextPage);
                return LastEvaluatedKey;
            },
        })

        const onPagniationChange = (pagination) => {
            try {
        
              console.log('on change', pagination,);
              let currentPage = pagination?.current
              currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
              setSearchParams(p => ({ ...p, pageNo: currentPage }));
        
            } catch (error) {
              console.log('error on table change', error);
            }
          }
        
          const onSortChange = (sort) => {
            try {
        
        
              console.log('sort', sort)
        
              const { field, order, column } = sort || {};
        
              if (column) {
                setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
              } else {
                setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
              }
        
            } catch (error) {
              console.log('error sorting', error);
            }
          }
        


    const items = useMemo(() => {

        const list = Array.isArray(data?.data) ? data?.data : []
        
        return list.map(e => {

            let createdAt = e.createdAt

            const appZoneCreatedAt = getAppZoneMomentFromUTC(e.createdAt)

            if (appZoneCreatedAt?.isValid()) {
                createdAt = appZoneCreatedAt.format('Do MMMM, YYYY HH:mm')
            }

            {appZoneCreatedAt && appZoneCreatedAt.isValid() && appZoneCreatedAt.format('Do MMMM, YYYY HH:mm')}

            return {
                ...e,
                createdAt
            }

        })
        // 

    }, [data?.data])

    const total = data?.total ?? 0

    //console.log('data', items)

    return (
        <div className='nps-responses-list'>
            <Spin spinning={isLoading || isFetching}>
                <NpsResponseTable
                    key={resetKey}
                    logs={items}
                    defaultPageSize={pageSize}
                    pageNo={pageNo}
                    total={total}
                    isFetchingNextPage={isFetchingNextPage}
                    onPagniationChange={onPagniationChange}
                    onSortChange={onSortChange}
                />
            </Spin>
        </div>
    )
}

export default NpsResponses;