import React, { useCallback, useMemo, useState } from 'react';
import {
    Button, Col, Form, Input, Row, Typography, Modal, Space, Divider
} from 'antd';
import { find, get, map, random, uniqueId } from 'lodash';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    rectSwappingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const { Text } = Typography;

const styles = {
    stepRow: { paddingBottom: 12, }
}

const StepItem = ({ index, uniqueId, showBorder = false, onRemove, viewOnly = false, description }) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useSortable({
        id: uniqueId,

        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });

    const borderStyle = useMemo(() => {

        return showBorder ? {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            borderColor: '#1890ff',
            // width: 500,
        } : {

            borderColor: 'rgb(243 243 243)',
            borderStyle: 'solid',
            borderRadius: 8,
            borderWidth: 1
        }

    }, [showBorder])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: 12,
        backgroundColor: '#fff',

        ...borderStyle
    };

    const itemContainerStyle = {
        position: 'relative',
        //opacity:`${showBorder ? '0.8' : '1'}`

    }

    return (
        <div>
            {index > 0 ? (<div style={{ marginTop: 8 }} />) : null}
            <div style={itemContainerStyle} ref={setNodeRef}  >
                <Row>
                    <Col flex={1}>
                        <div style={style} {...attributes} >
                            <Row>
                                <Text style={{ paddingBottom: 12 }}>{`Step ${index + 1}`}</Text>
                            </Row>
                            <Row justify="space-between" >
                                <Col flex={1}>
                                    {
                                        !viewOnly
                                        ? (
                                            <Form.Item
                                        name={[index, "description"]}
                                        rules={[{ required: true, message: 'Step description is required.' }]}
                                        
                                    >
                                        <Input.TextArea
                                            showCount
                                            maxLength={800}
                                            placeholder="Description"
                                            rows={6}
                                            className='steps-input'
                                            data-testid={`step-description-${index}`}
                                        />
                                    </Form.Item>
                                        )
                                        : 
                                        (
                                            <Input.TextArea
                                            showCount
                                            maxLength={800}
                                            placeholder="Description"
                                            rows={6}
                                            className='steps-input'
                                            data-testid={`step-description-${index}`}
                                            value={description}
                                            contentEditable={!viewOnly}
                                        />
                                        )
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <Space direction="vertical" size="middle" style={{
                            padding: 12,
                            height: '100%',
                            justifyContent: 'center'
                        }} >
                            <Button
                                //type='primary'
                                shape='circle'
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {
                                    !viewOnly && onRemove && onRemove();
                                    // onClose(value, label)
                                }}
                                danger
                                icon={<DeleteOutlined />}
                            />

                            <Button
                                type="secondary"
                                shape='circle'
                                ref={setActivatorNodeRef}
                                {...listeners}
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {
                                 
                                    console.log('draging action')
                                }}
                                //danger
                                icon={<VerticalAlignMiddleOutlined />}
                            />
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

const SortableSteps = ({ fields, form, move, remove }) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [activeId, setActiveId] = useState(null);
    const [activeItem, setActiveItem] = useState({});

    const steps = form.getFieldValue('steps') || [];

    const getItemByIndex = useCallback((index) => {

        //console.log('get unique id callback', get(steps, `[${index}]`))

        return get(steps, `[${index}]`) || {}

    }, [steps])

    const getItemByStepId = useCallback((stepId) => {

        //console.log('get unique id callback', get(steps, `[${index}]`))

        return find(steps, s => s.uniqueId === stepId)

    }, [steps])

    return (
        <div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <SortableContext
                    items={fields}
                    strategy={rectSwappingStrategy}
                >
                    {
                        map(fields, (field, index) => {

                            const onRemove = () => {
                                try {
                        
                                    confirm({
                                        title: `Are you sure remove this step ${index + 1}?`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'This step will be removed permently after save.',
                                        okText: 'Remove',
                                        okType: 'danger',
                                        cancelText: 'No',
                                        onOk() {
                        
                                            console.log('Remove', index,);
                                            remove(index);
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });
                        
                                    
                                } catch (error) {
                                    console.log('error removing step', error);
                                }
                            }

                            const item = getItemByIndex(index);

                            return (
                                <StepItem 
                                key={item.uniqueId} 
                                field={field} 
                                {...item} 
                                index={index}
                                onRemove={onRemove}
                                 />
                            )
                        })
                    }
                </SortableContext>
                <DragOverlay>
                    {activeId ? (<StepItem id={activeId} {...activeItem} showBorder viewOnly/>) : null}
                </DragOverlay>
            </DndContext>
        </div>
    )

    function handleDragStart(event) {
        const { active } = event;

        //console.log('drag start', active);

        setActiveId(active.id)

        //setActiveId(active.id);
        const item = getItemByStepId(active.id) || {};
        const stepIndex = findIndexOf(steps, active.id);
        setActiveItem({ ...item, index: stepIndex });

    }

    function findIndexOf(items = 0, stepId) {
        try {

            let indexOfItem = -1;

            for (let i = 0; i < items.length; i++) {

                if (items[i].uniqueId === stepId) {
                    indexOfItem = i;
                    break;
                }

            }

            return indexOfItem;

        } catch (error) {
            console.log('error finding index of item', error);
            return -1
        }

    }

    function handleDragEnd(event) {

        const { active, over } = event;

        //console.log('drag end', event);

        if (active.id !== over.id) {
            // console.log('active item', activeItem)

            const oldIndex = findIndexOf(steps, active.id);
            const newIndex = findIndexOf(steps, over.id);
            console.log('old index ', oldIndex, 'new index ', newIndex)
            move(oldIndex, newIndex);
        }

        setActiveId(null);
        setActiveItem({});
    }

}


export default ({ form }) => {

    const getUniqueId = (steps) => {

        try {

            let stepUniqueId = uniqueId();

            let exists = find(steps, s => s.uniqueId === stepUniqueId);
            console.log('isUnique ', exists)

            let maxCount = steps.length;

            while (!!exists && maxCount < 1000) {
                exists = find(steps, s => s.uniqueId === stepUniqueId);
                let newId = uniqueId();
                stepUniqueId = `${stepUniqueId}.${newId}.${maxCount}`;
                maxCount++;
            }

            return stepUniqueId;

        } catch (error) {
            console.log('error getting unique if for setps', error);
            let stepUniqueId = uniqueId();
            return `${stepUniqueId}.${random(0, 999999)}`
        }
    }

    const getMaxSortOrder = (steps) => {
        try {

            let max = 0;

            if (!steps.length) {
                // of no steps return 0
                return max
            }

            for (let i = 0; i < steps.length; i++) {
                const sortOrder = get(steps[i], 'sortOrder');
                if (sortOrder >= max) {
                    max = sortOrder;
                }
            }

            return max + 1;

        } catch (error) {
            console.log('error gettin max sort order', error);
            return steps.length;
        }
    }

    return (
        <div>
            <Form.List name='steps'>
                {(fields, { add, remove, move }) => {
                    return (
                        <div >
                            <div style={{ minHeight: 300 }}>
                                <SortableSteps fields={fields} form={form} move={move} remove={remove} />
                            </div>
                            <Row justify='center' align='bottom'>
                                <Button
                                    type="primary"
                                    data-testid="add-steps"
                                    onClick={() => {
                                        const steps = form.getFieldValue('steps') || [];
                                        const stepUniqueId = getUniqueId(steps);
                                        let sortOrder = getMaxSortOrder(steps);
                                        add({ sortOrder, uniqueId: stepUniqueId })
                                    }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Step
                                </Button>
                            </Row>
                        </div>
                    )
                }}
            </Form.List>
        </div>
    )
}