import { Col, Form, Row, Switch } from "antd";
import { debounce, map } from "lodash";
import { useCallback, useMemo, useState } from "react";
import Items from './items';
import useHttpHelper from "../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../config/constants";
import empty from "../../../../common/components/Empty";
import LookupSelect from "../../../../common/components/lookupSelect";


function defaultItemPropMapper({ id, value, label, listImageType, videoLink, imageUrl }) {
    return {
        id,
        key: value,
        value,
        videoLink,
        listImageType,
        imageUrl,
        name: label,
        children: label,
    }
}

export default function Pinnned({ form, setPrequisiteCount, organisationId }) {

    // fetch challenges list
    const { httpPostAsync } = useHttpHelper();

    const [searchParams, setSearchParams] = useState({ query: '' });

    const { query = '' } = searchParams;

    const challengesQueryParams = useMemo(() => ({ organisationId, query }), [organisationId, query]);

    const fetchChallengesAsync =
        useCallback(async (params = {}) => {

            const link = appLinks.challengesLookup.replace('{organisationId}', organisationId);
            const response = await httpPostAsync(link, { ...params });

            console.log('challenges resopnse', response)

            return response;

        }, [httpPostAsync, organisationId]);

    const enablechallengesFetchFn = useCallback(({ organisationId = 0 }) => organisationId > 0, []);

    const challengesQueryKeyFn =
        useCallback(({ organisationId, query }) => [queryKeys.challengesLookup, organisationId, query], []);

    const deboundedSearch = useCallback(debounce(q => setSearchParams(p => ({ ...p, query: q })), 300), [])

    const onSearch = (e) => {
        // console.log('on search', e);
        deboundedSearch(e);
    }

    const onSelect = (e) => {
        //console.log('on select', e)
        deboundedSearch();
    }

    const updatePrerequisites = (newItems = []) => {
        try {

            form.setFieldsValue({ pinned: [...newItems] })
            setPrequisiteCount(newItems.length)
            // form.setFieldValue('prerequisites', [ ...newItems ])

        } catch (error) {
            console.log('error updating pinned challenge', error);
        }
    }

    // show prequisite challenges
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Form.Item
                        label="Show pinned list"
                        name="showPinned"
                        valuePropName="checked"
                        wrapperCol={{ span: 16 }}
                    >
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>
            <Row>

                <Col span={12}>
                    <Form.Item
                         name="pinned"
                         label="Pinned"
                        getValueFromEvent={(args, params) => {
                            //console.log('get value from event', args, params)

                            const items = map(params, param => {

                                return {
                                    id: param.id,
                                    disabled: undefined,
                                    key: param.key,
                                    label: param.name,
                                    value: param.value,
                                    imageUrl: param.imageUrl,
                                    listImageType: param.listImageType,
                                    videoLink: param.videoLink,
                                }
                            })

                            return items;
                        }}
                    >
                        <LookupSelect
                            placeholder="Select challenge"
                            queryParams={challengesQueryParams}
                            fetchAsync={fetchChallengesAsync}
                            queryKeyFn={challengesQueryKeyFn}
                            enabledFn={enablechallengesFetchFn}
                            lookupId='challenges'
                            //allowClear
                            showSearch
                            onSearch={onSearch}
                            filterOption={false}
                            mode='multiple'
                            labelInValue
                            tagRender={empty}
                            onBlur={onSelect}
                            style={{ width: 240 }}
                            itemPropsMapperFn={defaultItemPropMapper}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Items updatePrerequisites={updatePrerequisites} />
                </Col>
            </Row>
        </div>
    )

}