import { BackTop, Button, Col, Input, List, Row, Spin } from 'antd';
import { debounce, flatMap, get, map } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';

import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../../common/components/dashboard';
import PostCard from './PostCard';
import usePostFilterContext from './useFilterContext';

const { Search } = Input;

const pageSize = 10;

export default function Posts() {

    const { httpPostAsync } = useHttpHelper();

    const navigate = useNavigate();

    const styles = useMemo(() => {

        const tagsVisible = false

        return ({
            itemStyle: {
                paddingRight: 16,
                paddingBottom: 18,
            },
            tagsContainer: {
                paddingTop: tagsVisible ? 16 : 0,
                paddingBottom: tagsVisible ? 8 : 0

            },
            challengeTableContainer: {
                paddingTop: tagsVisible ? 0 : 17
            }
        })
    }, [])


    const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = usePostFilterContext();

    const {
        //pageNo = 0,  // page no is handled by useInfiniteQuery, rest of the parameter needs to be in query key
        query = '',
        reportedTypes = [],
        sortBy = null,
        sortOrder = null,
    } = searchParams


    const fetchPostsAsync = async (fetchParams) => {

        const { pageParam = 0 } = fetchParams || {}

        let searchData = {
            query,
            itemPerPage: pageSize,
            sortBy,
            sortOrder,
            pageNo: pageParam,
            reportedTypes: map(reportedTypes, s => s.value),
        }

        console.log('fetch posts params:', fetchParams, searchData);

        const response = await httpPostAsync(appLinks.oneKidsPosts, searchData);

        console.log('fetch posts', response);

        return response;
    };

    const queryKey = useMemo(() => {

        const key = [queryKeys.oneKidsPosts, query, sortBy, sortOrder]

        console.log('filter query key', key)

        return key;

    }, [query, sortBy, sortOrder, reportedTypes])



    const {
        data: postsPages,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching: isLoadingPosts,
        isLoading,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery(queryKey, fetchPostsAsync, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {

            // data: []
            // itemPerPage: 10
            // nextPage: 1
            // total: 26
            // totalPages: 2

            //console.log('getNextPageParam', { ...lastPage } , { ...pages });

            if (lastPage && lastPage.nextPage) {
                console.log('getNextPageParam: has next page', lastPage)
                return lastPage.nextPage;
            }

        },
    })

    const [searchValue, setSearchValue] = useState(query);

    const searchPosts = async (text) => {
        try {

            setSearchParams(p => ({ ...p, query: text }));

        } catch (error) {
            console.log('error searching', error);
        }
    }

    const searchUsersRef = useRef(searchPosts);
    searchUsersRef.current = searchPosts;

    const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

    const onSearchChange = async (e) => {
        setSearchValue(e.target.value)
        debouncedSearch(e.target.value)
    }

    const postList = useMemo(() => {

        const pages = get(postsPages, 'pages');

        const list = flatMap(pages, p => [...(get(p, 'data') || [])]);

        return list;

    }, [postsPages])


    const [resetKey, setResetKey] = useState(0);

    const onReset = () => {
        try {

            resetSearchParams();
            setSearchValue('');
            setResetKey(k => k + 1);
            //searchFieldRef.current.input.value = '';
            // console.log('search ref', searchFieldRef.current)

        } catch (error) {
            console.log('error on resetting filters', error);
        }
    }

    const navigateToUser = (userId) =>  navigate(`/${appRoutes.dashboard}/${appRoutes.userView}/${userId}`)


    const loadMore =
        (hasNextPage) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button
                    loading={isLoadingPosts}
                    onClick={() => {
                        console.log('on next click', hasNextPage);
                        if (hasNextPage) {
                            //setSearchParams(p => ({ ...p, pageNo: p.pageNo + 1 }));
                            fetchNextPage()
                        }

                    }}>load more</Button>
            </div>
        ) : null;

    return (
        <Dashboard subrouting={false} activeItem='onekids-posts'>
            <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
                <Col>
                    <Row>
                        <Col style={styles.itemStyle}>
                            <Search
                                placeholder="Search"
                                value={searchValue}
                                onChange={onSearchChange}
                                allowClear
                                style={{ width: 200 }}
                                key={resetKey}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* <Row style={styles.tagsContainer} justify="space-between">
                <Col>
        
                </Col>
                </Row> */}
            <Row>
                <Col push={23}>
                    {
                        isSearchActive
                            ? (
                                <Button type="link" size='small' onClick={onReset} style={{ marginBottom: 8 }} >
                                    Reset
                                </Button>
                            )
                            : null
                    }
                </Col>
            </Row>
            <Spin spinning={isLoading || isLoadingPosts}>
                <List
                    key={resetKey}
                    style={{
                        backgroundColor: '#fff',
                        paddingBottom: 16,
                    }}
                    bordered
                    dataSource={postList}
                    itemLayout="vertical"
                    size="large"
                    loading={isLoadingPosts}
                    loadMore={loadMore}
                    //  pagination={{
                    //     current: pageNo + 1,
                    //     defaultPageSize: pageSize,
                    //     //hideOnSinglePage: true,
                    //     total,
                    // }}
                    rowKey={item => item.id}
                    renderItem={item => {
                        return (<PostCard key={item.id} item={item} navigateToUser={navigateToUser} />)
                    }}
                />
                <BackTop>
                    <div >
                        <Button type="primary" shape="circle" icon={<VerticalAlignTopOutlined />} size='large' />
                    </div>
                </BackTop>
            </Spin>
        </Dashboard>
    )


}