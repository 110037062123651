
import React from 'react';
import { Col, Row, Space, Avatar, List, Typography, Collapse, Carousel, Image, Tooltip } from 'antd';
import { get, map } from 'lodash';
import { HeartOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ItemTitle from '../../../../common/components/itemTitle';
import { getAppZoneMomentFromUTC } from '../../../../helpers/dateTimeHelpers';
import config from '../../../../config/config';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../../config/constants';
import UploadItem from './PostUploads/factory';

const { Text } = Typography;
const { Panel } = Collapse;

const IconText = ({ icon, text }) => (
    <Space>
        {icon && React.createElement(icon)}
        {text}
    </Space>
);

const sharedStatusMap = {
    'shared': 'Shared'
}

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}


export default ({ item = {} }) => {

    const {
        post = {},
        createdAt,
        organisation,
        userDetails = {},
        activityDetails = {}
    } = item;

    const {
        id: postId,
        title,
        description,
        lastFlagged,
        flagged = 0,
        likes,
        status,
        postAuthor = {},
        activity = {},
        images = [],
        totalUploads = 0,
        uploads = [],
        reportedBy = [],
    } = post;

    const {
        name: userName,
        id: userId,
        imageUrl: profileImageUrl,
        email,
    } = postAuthor;

    const { title: activityTitle } = activity;

    const lastReportedMomentDate = getAppZoneMomentFromUTC(lastFlagged);

    let navigate = useNavigate();

    const navigateToUserDetails = () => {
        try {

            navigate(`/${appRoutes.userView}/${userId}`)

        } catch (error) {
            console.log('error navigating to user', error);
        }
    }

    return (
        <List.Item
        // extra={
        //     <React.Fragment>
        //         <Image
        //             style={{
        //                 borderRadius: 4,
        //                 marginBottom: 12
        //             }}
        //             width={272}
        //             alt={activityDetails.title}
        //             src={activityDetails.imageUrl}
        //         />
        //         <div>
        //             <Text>{activityDetails.title}</Text>
        //         </div>

        //     </React.Fragment>
        // }
        >
            <List.Item.Meta
                avatar={<Avatar src={profileImageUrl} />}
                title={
                    <div>
                        <a onClick={navigateToUserDetails}>{userName}</a>
                        <div>
                            <Text type="secondary">{email}</Text>
                        </div>
                    </div>
                }
            />
            <Row style={{ paddingBottom: 12 }} justify="start">
                <Col style={styles.statItem}>
                    <ItemTitle
                        title="Post last reported on"
                        value={lastReportedMomentDate && lastReportedMomentDate.isValid() && lastReportedMomentDate.format(config.displayFormat)}
                    />
                </Col>
            </Row>
            <List.Item.Meta
                title={title}
                description={description}
            />
            <Row style={{ paddingBottom: 12 }} justify="start">
                <Col style={styles.statItem}>
                    <ItemTitle
                        title="Last reported"
                        value={lastReportedMomentDate && lastReportedMomentDate.isValid() && lastReportedMomentDate.format(config.displayFormat)}
                    />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle
                        title="Organisation"
                        value={organisation}
                    />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle
                        title="Status"
                        value={status}
                    />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle
                        title="Activity"
                        value={activityTitle}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={styles.statItem}>
                    <IconText icon={HeartOutlined} text={likes} key="list-vertical-like-o" />
                </Col>
                <Col style={styles.statItem}>
                    <Tooltip title="Report count" placement="topLeft">
                        <IconText icon={InfoCircleOutlined} text={flagged} key="list-vertical-like-o" />
                    </Tooltip>
                </Col>
            </Row>
            {
                reportedBy?.length > 0
                    ?
                    <Collapse ghost class="custom-collapse" >
                        <Panel header={`Reported by (${reportedBy.length})`} key="1">
                            <List
                                // itemLayout="horizontal"
                                dataSource={reportedBy}
                                rowKey={item => `${item.id}`}
                                renderItem={item => {

                                    const reportedAtMomentDate = getAppZoneMomentFromUTC(item.reportedAt);

                                    return (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.imageUrl} />}
                                                title={<a>{item.name}</a>}
                                                description={
                                                    <Row>
                                                        <Col>
                                                            <div>
                                                                <div>
                                                                    <Text type="secondary">Reported at</Text>
                                                                </div>
                                                                <Text>{reportedAtMomentDate && reportedAtMomentDate.isValid() && reportedAtMomentDate.format(config.displayFormat)}</Text>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <div style={{ marginLeft: 16 }}>
                                                                <div>
                                                                    <Text type="secondary">Type</Text>
                                                                </div>
                                                                <Text>{item.type}</Text>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            />


                                        </List.Item>
                                    )
                                }}
                            />
                        </Panel>
                    </Collapse>
                    : null
            }
            {
                totalUploads > 0
                    ?
                    <Collapse ghost class="custom-collapse" >
                        <Panel header={`Uploads (${totalUploads})`} key="2">
                            <Carousel>
                                {
                                    map(images, image => {

                                        return (
                                            <Image alt={image.name} src={image.imageUrl} height={240} />
                                        )

                                    })
                                }
                            </Carousel>
                            <Row style={{ paddingTop: 16 }}>
                                <Col>
                                    {
                                        map(uploads, upload => (<UploadItem {...upload} />))
                                    }
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    : null
            }
        </List.Item>
    )


}