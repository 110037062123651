import React, { useCallback } from 'react';
import { Form } from 'antd';
import { map, filter, debounce } from 'lodash';
import TagChip from '../../../../common/components/tagchip';
import { organisationTypes } from '../../../../config/constants';
import empty from '../../../../common/components/Empty';
import LookupSelect from '../../../../common/components/lookupSelect';
import { useOrganisationsLookupsV2 } from '../../../challenge/common/hooks/useOrganisationsLookups';


function optionsMapper({ value, label }) {
    return {
        key: value,
        value,
        label: label,
        children: label,
    }
}


const shouldUpdate = () => (prev, cur) => prev.organisations !== cur.organisations

const OrganisationSelect = () => {

    const [query, setQuery] = React.useState('')

    const deboundedSearch = useCallback(debounce((q) => {
            setQuery(q)
            console.log('debounded search call', q)
        }, 300), [])

    const onSearch = (q) => deboundedSearch(q)

    const onBlur = () => {
        if (typeof query === 'string' && query.length > 0) {
            console.log('query string length', query.length)
            setQuery('')
        }
    }

    const {
        organisationParams,
        fetchOrganisationsAsync: fetchFoundationOrganisationsAsync,
        organisationQueryKeyFn,
      } = useOrganisationsLookupsV2(organisationTypes.foundation, query);

    return (
        <Form.Item
            name={"organisations"}
            wrapperCol={{ span: 16 }}
        >
            <LookupSelect
                mode="multiple"
                labelInValue
                tagRender={empty}
                placeholder="Assign Organisation"
                queryParams={organisationParams}
                fetchAsync={fetchFoundationOrganisationsAsync}
                queryKeyFn={organisationQueryKeyFn}
                lookupId='assign-organisations'
                itemPropsMapperFn={optionsMapper}
                filterOption={false}
                onSearch={onSearch}
                onBlur={onBlur}
            />
        </Form.Item>
    )
}

export default ({ form }) => {

                return (
                    <div>
                        <div style={{ minHeight: 300 }}>
                            <div>Associated with</div>
                                <div>
                                   <OrganisationSelect form={form} />
                                    <Form.Item shouldUpdate={shouldUpdate}>
                                        {(props) => {

                                            const items = props.getFieldValue('organisations');

                                            return (
                                                <React.Fragment>
                                                    {map(items, org => {

                                                        return (
                                                            <TagChip
                                                                key={org.value}
                                                                closable
                                                                onClose={() => {

                                                                    const filtered = filter(items, o => o.value !== org.value);

                                                                    console.log('filtered organisations', filtered)
                                                                   
                                                                    props.setFieldsValue({ organisations: filtered })

                                                                }}
                                                            >
                                                                {org.label}
                                                            </TagChip>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        }}
                                    </Form.Item>
                                </div>
                        </div>
                    </div>
                )
}