import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";

export const orgisationsFilterConfig = {
    key: 'organisations',
    isActiveProp: 'isOrganisationsSearchActive',
    setAction: 'organisations',
    resetAction: 'reset-organisations',
    setFilterProp: 'setOrganisationsFilters',
    resetFilterProp: 'resetOrganisationsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        types: [],
        statuses: [],
        sortBy: null,
        sortOrder: null,
    }
}

const useOrganisationsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, orgisationsFilterConfig)
}

export default useOrganisationsFilterContext;
