import React, { useCallback, useMemo } from "react";
import { Form } from "antd"
import LookupSelect from "../../../../../../common/components/lookupSelect";
import { appLinks, queryKeys } from "../../../../../../config/constants";
import useHttpHelper from "../../../../../../common/hooks/useHttpHelper";


export default ({ organisationId }) => {

    console.log(' club organisation id', organisationId);

    const { httpPostAsync } = useHttpHelper();

    const clubQueryParams = useMemo(() => ({ organisationId }), [organisationId]);

    const fetchClubsAsync =
        useCallback(async (params = {}) => await httpPostAsync(appLinks.clubsLookups, { ...params }), [httpPostAsync]);

    const enableClubFetchFn = useCallback(({ organisationId = 0 }) => organisationId > 0, []);

    const clubQueryKeyFn =
        useCallback(({ organisationId }) => [queryKeys.clubLookups, organisationId], []);

    return (
        <Form.Item
            name="creatorClubId"
            label="Club"
            //rules={[{ required: true, message: 'Please choose an creator club.' }]}
            wrapperCol={{ span: 16 }}
        >
            <LookupSelect
                placeholder="Select Club"
                queryParams={clubQueryParams}
                fetchAsync={fetchClubsAsync}
                queryKeyFn={clubQueryKeyFn}
                enabledFn={enableClubFetchFn}
                lookupId='creators-club'
                allowClear
            />
        </Form.Item>
    )


}