import React, { useMemo, useRef } from 'react';
import { Form, Row, message, Upload } from 'antd';
import { get, includes } from 'lodash';
import useHttpHelper from '../../../../../../common/hooks/useHttpHelper';
import FormUploader from '../../../formUploader';
import { normFile } from '../../../../../../helpers/utils';
import { appLinks, imageFilesExenstions } from '../../../../../../config/constants';

import { ReactComponent as UserProfileDefault } from '../../../../../../assets/userProfile.svg';


const ImagePreview = (props) => {

    const { resources = [], index } = props;

    const previewUrl = useMemo(() => {
        try {

            const resource = resources[index];
            return resource.downloadLink;

        } catch (error) {
            console.log('error getting image resources url', error);
            return null;
        }

    }, [resources, index]);

    return (
        previewUrl
            ? (
                <Row style={{ paddingBottom: '15px' }} >
                    <img src={previewUrl} alt="Uploaded image" style={{ width: 120, height: 130, borderRadius: '8px' }} />
                </Row>)
            : (
                <Row justify="start" style={{ paddingBottom: 12 }}>
                    <UserProfileDefault height={130} width={120} style={{ borderRadius: 8 }} />
                </Row>)
    )

}

export default (props) => {

    const { index, form } = props;

    const { httpPostAsync } = useHttpHelper();
    const documentIdRef = useRef();

    const getUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.uploadResourceUrl, UploadFile)

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload link response', uploadLinkResponse)

            documentIdRef.current = documentId;

            return uploadUrl;

        } catch (error) {
            console.log('error getting upload url', error)
        }
    }

    //const onGetPreviewUrl = (previewUrl) => setPreviewUrl(previewUrl);

    const onUploadSuccess = ({ url, fileName }) => {
        try {

            const resources = form.getFieldValue('resources');
            console.log('resources', resources);
            const resource = get(resources, `[${index}]`);
            console.log('resource', documentIdRef.current, resource);

            if (resource && resource.type === 'image' && documentIdRef.current > 0) {

                //resource.documentId = documentIdRef.current

                resources[index] = {
                    ...resource,
                    documentId: documentIdRef.current,
                    downloadLink: url,
                    fileName: fileName,
                }

                form.setFieldsValue({ resources: [...resources] })
            }

        } catch (error) {
            console.log('error in image resource upload success', error);
        }
    }

    const beforeUpload = (file) => {

        const extenstion = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

        console.log('extension ', extenstion);
        
        const isImage = includes(imageFilesExenstions, extenstion);
        if (!isImage) {
          message.error(`Only image files can be uploaded`);
        }

        if (file.size > 3 * 1024 * 1024) {
            message.error(`File size needs to be less then 3MB`);
        }

        return isImage || Upload.LIST_IGNORE;
    }

    return (
        <Form.Item
            label="Image (max upload size 3MB)"
            name={[index, "imageFile"]}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            wrapperCol={{ span: 22 }}
            // restrict file types to image
            rules={[{ required: true, message: 'Image upload is required' }]}
        >
            <FormUploader
                getUploadUrlAsync={getUploadUrlAsync}
                onUploadSuccess={onUploadSuccess}
                beforeUpload={beforeUpload}
                previewComponent={
                    <Form.Item shouldUpdate={(prev, cur) => prev.resouces !== cur.resources}>
                        {(props) => {

                            const resources = props.getFieldValue('resources');

                            return (<ImagePreview resources={resources} index={index} />)
                        }}
                    </Form.Item>
                }
            />
        </Form.Item>
    )
}
