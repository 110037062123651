import { Button, Select } from 'antd';
import moment from 'moment-timezone';
import OrganisationsLookup from './OrganisationLookup';

const monthOptions = [
    {
        value: 0,
        label: 'January',
    },
    {
        value: 1,
        label: 'February',
    },
    {
        value: 2,
        label: 'March',
    },
    {
        value: 3,
        label: 'April',
    },
    {
        value: 4,
        label: 'May',
    },
    {
        value: 5,
        label: 'June',
    },
    {
        value: 6,
        label: 'July',
    },
    {
        value: 7,
        label: 'August',
    },
    {
        value: 8,
        label: 'September',
    },
    {
        value: 9,
        label: 'October',
    },
    {
        value: 10,
        label: 'November',
    },
    {
        value: 11,
        label: 'December',
    },
]

const Header = (props) => {

    const {
        value,
        //type,
        onChange,
        onSelect,
        // onTypeChange,
        
    } = props

    //console.log('value', value.month())


    //const monthCounterRef = useRef(0)

    const formattedDate = value.format('MMMM, YYYY')

    const month = value.month()

    return (
        <div>
            <div className='organisation-lookup'>
                <span className='title'>Organisation</span>
                <OrganisationsLookup 
                 style={{ minWidth: 200 }}
                 onSelect={onSelect}
                 />
            </div>
        <div className='calender-header'>
            <span className='date-title'>{formattedDate}</span>
            <div>
                <Button
                    title='Next Month'
                    className='previous-btn'
                    onClick={() => {

                        const nextMonth = moment(value).add(-1, 'month')

                        console.log('on daate change', props)

                        onChange(nextMonth)
                    }}
                >
                    Previous
                </Button>
                <Button
                    title='Next Month'
                    className='next-btn'
                    onClick={() => {

                        const nextMonth = moment(value).add(1, 'month')

                        console.log('on daate change', props)

                        onChange(nextMonth)
                    }}
                >
                    Next
                </Button>
                <Select
                    style={{ minWidth: 200 }}
                    options={monthOptions}
                    value={month}
                    onSelect={(e) => {
                        console.log('on select', e)
                        const nextMonth = moment(value).month(e)
                        onChange(nextMonth)
                    }}
                />
            </div>
        </div>
        </div>
    )
}

export default Header