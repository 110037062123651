import { Divider } from "antd"
import EmailOverviewChart from "./EmailsOverviewChart"
import SentEmailBarChart from "./SentEmailBarChart"
import './style.scss'
import UsersChart from "./UsersChart"
import UserStatus from "./UserStatus"
//import ChallengesCharts from "./Challenges"


const DashboardHome = () => {

    return (
        <div className="dash-home">
            <div className="user-charts-container">
                <UserStatus />
            </div>
            <Divider />
            <div className="one-users">
                <UsersChart />
            </div>
            <Divider />
            <div className="email-overview">
                <EmailOverviewChart />
            </div>
            <Divider />
            <div className="sent-email">
                <SentEmailBarChart />
            </div>
        </div>
    )

}

export default DashboardHome