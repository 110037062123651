import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const materialsFilterConfig = {
    key: 'materials',
    isActiveProp: 'isMaterialsSearchActive',
    setAction: 'materials',
    resetAction: 'reset-materials',
    setFilterProp: 'setMaterialsFilters',
    resetFilterProp: 'resetMaterialsFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useMaterialsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, materialsFilterConfig)
}

export default useMaterialsFilterContext;
