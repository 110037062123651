import React, { useMemo } from "react";
import { filter, find, findIndex, get, join, map } from "lodash";
import OrganisationView from "./OrganisationView";


// const borderStyle = {
//     borderWidth: 1,
//     borderStyle: 'solid',
//     borderColor: 'black'
// }

export default (props) => {

    const selectedOrganisation = props.getFieldValue('organisation');
    const organisationItems = props.getFieldValue('organisationItems');

    const visibleItem = useMemo(() => {
        try {

            //let list = [];
            let organisationRecipientInfo;
            if (selectedOrganisation && !Array.isArray(selectedOrganisation)) {
                //iterateItems = [selectedOrganisation]
                const item = find(organisationItems, o => o.value === selectedOrganisation.value);
                if (item) {
                    //list.push(item);
                    organisationRecipientInfo = item;
                }
            }

            return organisationRecipientInfo;

        } catch (error) {
            console.log('error generating organisations list', error);
            return []
        }

    }, [selectedOrganisation, organisationItems])

    const removeOrganisation = (itemId) => {

        const items = props.getFieldValue('organisations');

        let iterateItems = items;
        if (items && !Array.isArray(items)) {
            iterateItems = [items]
        }

        //console.log('themes', themeId, themesValue);

        const filtered = filter(iterateItems, o => o.value !== itemId);

        //console.log('filtered themes', filtered)

        props.setFieldsValue({ organisations: filtered })

    }

    // const onCheckSendAll = (itemId, check) => {
    //     try {

    //         //console.log('check send all', itemId, check);

    //         let items = props.getFieldValue('organisationItems') || [];

    //         const itemIndex = findIndex(items, o => o.value === itemId);
    //         if (itemIndex >= 0) {
    //             // console.log('item index', itemIndex)
    //             items[itemIndex] = {
    //                 ...items[itemIndex],
    //                 sendAll: !!check,
    //             }
    //         }

    //         //console.log('modified items', items)

    //         props.setFieldsValue({ organisationItems: [...items] })

    //     } catch (error) {
    //         console.log('error check send all', error)
    //     }
    // }

    const onAddRecipients = (itemId, receipients = []) => {
        try {

            //console.log('add receipients', itemId, receipients)

            let items = props.getFieldValue('organisationItems') || [];

            const itemIndex = findIndex(items, o => o.value === itemId);
            if (itemIndex >= 0) {
                // console.log('item index', itemIndex)
                items[itemIndex] = {
                    ...items[itemIndex],
                    receipients: receipients,
                }
            }

            //console.log('modified items', items)

            props.setFieldsValue({ organisationItems: [...items] })

            if (receipients && receipients.length) {
                //const recipients = get(item, 'receipients') ;
                let emails = filter(map(receipients, r => r.email), e => !!e);
                emails = join(emails, ', ') || ''
                //console.log('emails', emails)
                emails && props.setFieldsValue({ emails });
            } else {
                props.setFieldsValue({ emails: '' });
            }

        } catch (error) {
            console.log('error adding receipients', error);
        }
    }

    const removeRecipient = (recipientId, orgId) => {
        try {

            console.log('remove recipient', recipientId, orgId)

            let items = props.getFieldValue('organisationItems') || [];

            const itemIndex = findIndex(items, o => o.value === orgId);
            if (itemIndex >= 0) {
                // console.log('item index', itemIndex)
                const receipients = get(items[itemIndex], 'receipients') || [];
                console.log('receipients', receipients);
                const filteredRecipients = filter(receipients, r => r.value !== recipientId);
                console.log('receipients', receipients);
                items[itemIndex] = {
                    ...items[itemIndex],
                    receipients: filteredRecipients,
                }

                props.setFieldsValue({ organisationItems: [...items] });

                if (filteredRecipients && filteredRecipients.length) {
                    //const recipients = get(item, 'receipients') ;
                    let emails = filter(map(filteredRecipients, r => r.email), e => !!e);
                    emails = join(emails, ', ') || ''
                    //console.log('emails', emails)
                    emails && props.setFieldsValue({ emails });
                } else {
                    props.setFieldsValue({ emails: '' });
                }

            }



        } catch (error) {
            console.log('error removing recipient', error)
        }
    }

    const onRemove = () => removeOrganisation(visibleItem.value);

    //const onSendAllCheck = (check) => onCheckSendAll(visibleItem.value, check)

    const addRecipients = (receipients) => onAddRecipients(visibleItem.value, receipients);

    const onRemoveRecipient = (id) => removeRecipient(id, visibleItem.value)

    return (
        <div>
            {
                visibleItem
                    ? (
                        <OrganisationView
                            onRemove={onRemove}
                           // onSendAllCheck={onSendAllCheck}
                            addRecipients={addRecipients}
                            //checked={visibleItem.sendAll}
                            organisationId={visibleItem.value}
                            recipients={visibleItem.receipients}
                            onRemoveRecipient={onRemoveRecipient}
                        />
                    )
                    : null
            }
        </div>
    )
}