import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const difficultyFilterConfig = {
    key: 'difficulty',
    isActiveProp: 'isDifficultySearchActive',
    setAction: 'difficulty',
    resetAction: 'reset-difficulty',
    setFilterProp: 'setDifficultyFilters',
    resetFilterProp: 'resetDifficultyFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useDifficultyFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, difficultyFilterConfig)
}

export default useDifficultyFilterContext;
