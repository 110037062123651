import { Button, Col, Input, Modal, Row, Select, Spin, message } from 'antd';
import { debounce, filter, get, join, map } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../../common/components/layout';
import { useOrganisationPermissionContext } from '../../../common/components/permissionContext';
import TagChip from '../../../common/components/tagchip';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import OrganisationsTable from './organisations';
import useOrganisationsFilterContext from './useOrganisationsFilterContext';
import Dashboard from '../../../common/components/dashboard';


const { Option } = Select;
const { Search } = Input;

const pageSize = 10;

// const styles = {
//   tags:{}
// }

const organisationStatuses = [
  { key: 'inactive', label: 'Inactive' },
  { key: 'active', label: 'Active' },
]

export const organisationTypesList = [
  { key: 'foundation', label: 'Organisation' },
  { key: 'organisation', label: 'Sponsor' },
]

export default () => {

  let navigate = useNavigate();

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useOrganisationsFilterContext();

  const {
    pageNo,
    query,
    types = [],
    statuses = [],
    sortBy,
    sortOrder,
  } = searchParams;

  const [searchValue, setSearchValue] = useState(query);

  const { httpPostAsync, httpDeleteAsync } = useHttpHelper();

  const {
    hasListAccess,
    hasDetailAccess = false,
    hasCreateAccess = false,
    hasEditAccess = false,
    hasDeleteAccess = false,
  } = useOrganisationPermissionContext();

  const fetchOrganisationsAsync = async () => {
    // console.log('call api:page param', pageParam, searchCriteria);

    const response = await httpPostAsync(
      appLinks.organistions,
      {
        itemPerPage: pageSize,
        query,
        pageNo,
        types: map(types, s => s.value),
        //organisations: map(organisations, org => org.value),
        statuses: map(statuses, s => s.value),
        sortBy,
        sortOrder
      }
    );

    console.log('oranisations', response)

    return response;
  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.creatorsList, pageNo, query, sortBy, sortOrder]

    if (types.length) {
      const typesValues = map(types, s => s.value);
      const typesList = join(typesValues, '.');
      key.push(typesList)
    }


    if (statuses.length) {

      const statusValues = map(statuses, s => s.value);
      const statusList = join(statusValues, '.');
      key.push(statusList)

    }

    console.log('filter key', key)

    return key;

  }, [pageNo, query, types, statuses, sortBy, sortOrder])

  const {
    isLoading,
    //isError,
    //error,
    data = {},
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    queryKey,
    fetchOrganisationsAsync,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })



  let { data: organisations = [], total = 0, } = data || {}
  const navigateToCreateNew = () => {
    try {

      navigate(`/${appRoutes.newOrganisation}`)

    } catch (error) {
      console.log('error navigating to create new organisation', error);
    }
  }

  const onSearch = async (text) => {
    try {

      //console.log('search users', text);

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }))

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onSearch);
  searchUsersRef.current = onSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));


    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  //#region filters
  const onStatusChange = (values) => {
    try {

      console.log('statuses', values)

      setSearchParams(p => ({ ...p, statuses: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on status change', error);
    }
  }

  const onTypeChange = (values) => {
    try {

      console.log('types', values);

      setSearchParams(p => ({ ...p, types: [...values], pageNo: 0 }))


    } catch (error) {
      console.log('error on organisation error type change', error);
    }
  }

  //#endregion

  //#region delete

  const [deleteOrganisationId, setDeleteOrganisationId] = useState();

  const mutationQueryKey = [queryKeys.deleteOrganisation, deleteOrganisationId]

  const deleteOrganisationAsync = async (organisationId) => {
    try {

      const link = appLinks.deleteOrganisation.replace('{organisationId}', organisationId);
      const deleteResponse = await httpDeleteAsync(link);

      console.log('delete response', deleteResponse);

      return deleteResponse;

    } catch (error) {
      console.log('error deleting organisation', error);
    }
  }

  const {
    // data: updatePersonResponse,
    isLoading: isDeleting,
    mutateAsync: onDeleteOrganisationAsync,
  } = useMutation(
    deleteOrganisationAsync,
    { mutationKey: mutationQueryKey, }
  );

  const onDelete = (organisatino, name) => {
    try {

      console.log('delete organisation', organisatino);
      Promise.resolve(setDeleteOrganisationId(organisatino))

      Modal.confirm({
        title: `Are you sure you want to delete ${name}`,
        okText: 'Delete',
        closable: true,
        okButtonProps: {
          loading: isDeleting,
          disabled: isDeleting,
        },
        onOk: async () => {
          console.log('on delete click');

          const deleteResponse = await onDeleteOrganisationAsync(organisatino);

          if (deleteResponse && deleteResponse.code === 0) {
            message.success('Organisation was deleted successfully');
          } else {
            message.error('We are facing some issues, organisation could not be deleted');
          }

          await refetch();

        }

      });

    } catch (error) {
      console.log('error deleting organisation', error);
    }
  }

  //#endregion

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);
      //searchFieldRef.current.input.value = '';
      // console.log('search ref', searchFieldRef.current)

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  const [tagsVisible, setTagsVisible] = useState(false);

  useEffect(() => {

    const searchTagsLen = types.length + statuses.length;

    setTagsVisible(searchTagsLen > 0)

  }, [types, statuses])

  const styles = useMemo(() => ({
    itemStyle: {
      paddingRight: 16,
      paddingBottom: 18,
    },
    tagsContainer: {
      paddingTop: tagsVisible ? 16 : 0,
      paddingBottom: tagsVisible ? 8 : 0

    },
    challengeTableContainer: {
      paddingTop: tagsVisible ? 0 : 17
    }
  }), [tagsVisible])

  return (
    <Dashboard subrouting={false} activeItem='org'>
      <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
        <Col>
          <Row>
            <Col style={{ paddingRight: 15 }} >
              <Select
                mode="multiple"
                //allowClear
                style={{ width: 200 }}
                placeholder="Type filter"
                onChange={onTypeChange}
                labelInValue
                value={map(types, s => ({ value: s.value, label: s.label }))}
                tagRender={() => null}
                optionFilterProp='label'
              >
                {
                  map(organisationTypesList, s => {
                    return (
                      <Option key={s.key} value={s.value} label={s.label}>{s.label}</Option>
                    )
                  })
                }
              </Select>
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Select
                mode="multiple"
                //allowClear
                style={{ width: 200 }}
                placeholder="Status filter"
                onChange={onStatusChange}
                labelInValue
                value={map(statuses, s => ({ value: s.value, label: s.label }))}
                tagRender={() => null}
                optionFilterProp='label'
              >
                {
                  map(organisationStatuses, s => {
                    return (
                      <Option key={s.key} value={s.key} label={s.label}>{s.label}</Option>
                    )
                  })
                }
              </Select>
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Search
                placeholder="Search organisations"
                onChange={onSearchChange}
                value={searchValue}
                allowClear
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
        <Col >
          <Button
            type="primary"
            block
            disabled={!hasCreateAccess}
            data-testid="create-organisation"
            onClick={navigateToCreateNew}
          >
            New Organisation
          </Button>
        </Col>
      </Row>
      <Row style={styles.tagsContainer} justify="space-between">
        <Col>
          <div style={{ marginBottom: 8 }}>
            {
              map(types, type => {

                console.log('tag type', type);

                return (
                  <TagChip
                    key={type.key}

                    closable onClose={() => {
                      setSearchParams(p => {

                        const filtered = filter(p.types, s => s.key !== type.key);

                        console.log('filtered type', filtered)

                        return {
                          ...p,
                          types: filtered,
                        }

                      })
                    }}>
                    {type.label}
                  </TagChip>
                )

              })
            }
            {
              map(statuses, status => {

                return (
                  <TagChip key={status.key} closable onClose={() => {
                    setSearchParams(p => {

                      const filtered = filter(p.statuses, s => s.key !== status.key);

                      console.log('filtered statuses', filtered)

                      return {
                        ...p,
                        statuses: filtered,
                      }

                    })
                  }}>
                    {status.label}
                  </TagChip>
                )

              })
            }
          </div>
        </Col>
        <Col>
          {
            isSearchActive
              ? (
                <Button type="link" size='small' onClick={onReset}>
                  Reset
                </Button>
              )
              : null
          }
        </Col>
      </Row>
      <Spin spinning={isLoading || isFetching || isDeleting}>
        <OrganisationsTable
          key={resetKey}
          organisations={organisations}
          onSearch={debouncedSearch}
          onPagniationChange={onPagniationChange}
          onSortChange={onSortChange}
          onCreateNew={navigateToCreateNew}
          onDelete={onDelete}
          pageNo={pageNo}
          total={total}
          defaultPageSize={pageSize}
          hasDetailAccess={hasDetailAccess}
        // hasEditAccess={hasEditAccess}
        // hasDeleteAccess={hasDeleteAccess}
        />
      </Spin>
    </Dashboard>
  );

}