import { useMemo } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../config/constants';

const { Text } = Typography;


const OrganisationsTable = props => {

    const {
        organisations,
        onSearch,
        onPagniationChange,
        onSortChange,
        onCreateNew,
        pageNo,
        total,
        defaultPageSize,
        onDelete,
        // hasDetailAccess = false,
        // hasEditAccess = false,
        // hasDeleteAccess = false,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return !record.canView
                ? (<Text>{record.name}</Text>)
                : (<a
                    onClick={() => {
                        //console.log('on click', record)
                        navigate(`/${appRoutes.organisations}/${record.id}`)
                    }}>
                        {record.name}
                    </a>)

                // return (
                //     <a
                //     onClick={() => {
                //         //console.log('on click', record)
                //         navigate(`/${appRoutes.organisations}/${record.id}`)
                //     }}>
                //         {record.name}
                //     </a>
                // )
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: true,
        },
        {
            title: 'Email address',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: 'Parent Organisation',
            dataIndex: 'parentOrganisation',
            key: 'parentOrganisation',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: true,
            render: (text, record) => {

                return (
                    <Text>
                        {record.status === 'active' ? 'Active' : 'Inactive'}
                    </Text>
                )
            },
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return (
                    <a 
                    data-testid='edit-organisation'
                    style={record.canEdit  ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                    data-can-edit={record.canEdit ? 'yes' : ''}
                    onClick={() => {
                        console.log('on click', record)
                        record.canEdit && navigate(`/${appRoutes.editOrganisation}/${record.id}`)
                    }}>
                        Edit
                    </a>
                )
            },
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return (
                    <a 
                    data-testid='delete-organisation'
                    style={record.canDelete ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                    data-can-delete={record.canDelete ? 'yes' : ''}
                    onClick={() => {
                        //console.log('on click', record)
                         record.canDelete  && onDelete && onDelete(record.id, record.name)
                    }}>
                        Delete
                    </a>
                )
            },
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

          // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{
                    //padding: '12px',
                    paddingTop: '0px'
                }}>
                    <Table
                        columns={columns}
                        dataSource={organisations}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                            pageSizeOptions: []
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default OrganisationsTable;