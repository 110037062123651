import { Form } from "antd";
import { map } from "lodash";
import empty from "../../../../../../common/components/Empty";
import LookupSelect from "../../../../../../common/components/lookupSelect";
import useOrganisationsLookups from "../../../hooks/useOrganisationsLookups";


function defaultItemPropMapper({ value, label, logoLink, website }) {
    return {
        key: value,
        value,
        link: logoLink,
        website,
        name: label,
        children: label,
    }
}

export default ({ organisationId, valueProp, form }) => {

    const {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    } = useOrganisationsLookups(organisationId, undefined, true);


    // const onChange = (selected, params) => {
    //     try {

    //        // const items = form.getFieldValue('poweredByOrganisations');

    //         //const filteredItem = 

    //         console.log('selected item', selected, params);

    //         // children: "Centrul Schubz"
    //         // key: "5"
    //         // link: "https://32a20lrqu7.execute-api.eu-central-1.amazonaws.com/default/post/image?size=standard&path=eyJidWNrZXQiOiJvbmVhcHAtc3RvcmFnZTEiLCJrZXkiOiJsb2dvcy81In0="
    //         // name: "Centrul Schubz"
    //         // value: 5
    //         // website: "https://schubz.ro/"


    //         const items = map(params, param => {

    //             return {
    //                 disabled: undefined,
    //                 key: param.key,
    //                 label: param.name,
    //                 value: param.value,
    //                 link: param.link,
    //                 website: param.website,
    //             }


    //         })
            
    //     } catch (error) {
    //         console.log('error handling onChange of powered by organsiations', error);
    //     }
    // }

    return (
        <div>
            <Form.Item
                name="poweredByOrganisations"
                label="Powered by"
                //rules={[{ required: true, message: 'Please choose organisations.' }]}
                wrapperCol={{ span: 16 }}
                getValueFromEvent={(args, params) => {
                    console.log('get value from event', args, params)

                    const items = map(params, param => {

                        return {
                            disabled: undefined,
                            key: param.key,
                            label: param.name,
                            value: param.value,
                            name: param.name,
                            logoLink: param.link,
                            website: param.website,
                        }
        
        
                    })

                    return items;
                }}
            >
                <LookupSelect
                    placeholder="Select Organisations"
                    queryParams={organisationParams}
                    fetchAsync={fetchOrganisationsAsync}
                    queryKeyFn={organisationQueryKeyFn}
                    lookupId='creator-organisations'
                    mode="multiple"
                    labelInValue
                    tagRender={empty}
                    itemPropsMapperFn={defaultItemPropMapper}
                  //  onChange={onChange}
                />
            </Form.Item>
        </div>
    )
}