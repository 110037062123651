import React from 'react';
import { Form, Input, Typography } from 'antd';

const { Text } = Typography;


export default (props) => {

    const { index } = props;

    return (
        <div>
            <Text>Image</Text>
            <Form.Item
                name={[index, "viewDownload"]}
                label='Resource'
            >
                <Input placeholder='Name' />
            </Form.Item>
        </div>
    )
}