import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
//import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

//React 18 changes
// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// //import './index.css';
// import App from './App';
// //import reportWebVitals from './reportWebVitals';

// const container = document.getElementById('root');
// const root = createRoot(container); 

// root.render(
//   <React.StrictMode>
//       <App />
//   </React.StrictMode>);