import passwordValidator from 'password-validator';

export const passwordSchema = new passwordValidator();

passwordSchema
.is().min(8)                                    // Minimum length 8
.is().max(14)                                  // Maximum length 100
// .has().uppercase()                              // Must have uppercase letters
// .has().lowercase()                              // Must have lowercase letters
// .has().digits(2)                                // Must have at least 2 digits
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['password', 'password123', 'password@123']); // Blacklist these values


export const passwordErrorMessageMap = {
    min: 'Minimum of 8 characters required',
    max: 'Minimum of 14 characters allowed',
    spaces: 'Spaces not allowed in password',
    oneOf: 'Basic passwords like password, password123 not allowed'
}

