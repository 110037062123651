import { Badge, Card, Col, Row, Typography } from "antd"
import { map } from "lodash";
import styled from 'styled-components';
import TagChip from "../../../common/components/tagchip";
import appConfig from "../../../config/config";

const { Text, Title } = Typography;

const TitleComponent = styled.span`
font-size: 16px;
font-weight: bold;
`

const TitleDiv = styled.div`
padding-top: 8px
`;

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    }
}

const ItemTitle = ({ title, value }) => {

    return (
        <TitleDiv>
            <div>
                <Text type="secondary">{title}</Text>
            </div>
            <Text>{value}</Text>
        </TitleDiv>
    )
}

export default (props) => {

    const {
        createdAt,
        recipientCount,
        template,
        type,
        recipients = [],
        delimitedEmails = '',
        pushNotificationsSent = false,
    } = props

    return (
        <div>
            <Card>
                <Row>
                    <Col md={24} sm={24} xs={24} lg={18} xl={12} xxl={12} style={{ paddingRight: 24 }}>
                        <Row>
                            <Col>
                                <Row style={{ paddingBottom: '12px' }}>
                                    <Col>
                                        <TitleComponent>Sent on</TitleComponent>
                                    </Col>
                                    <Col>
                                        <Text style={{ paddingLeft: 32 }}>
                                            {createdAt}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div style={styles.statsContainer}>
                            <Row style={{ paddingTop: '15px' }}>
                                <Col span={8}>
                                    <ItemTitle title="Organisation" value={createdAt} />
                                </Col>
                                <Col span={8}>
                                    <ItemTitle title="Recipient Count" value={recipientCount ? recipientCount : '-'} />
                                </Col>
                                <Col span={8}>
                                    <ItemTitle title="Push notifications sent" value={pushNotificationsSent ? 'Yes' : 'No'} />
                                </Col>
                                <Col span={8}>
                                    <ItemTitle title="Type" value={type} />
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 24 }}>
                                <Col span={24}>
                                    <TitleDiv>
                                        <div>
                                            <Title copyable={{
                                                text: template,
                                                tooltips: 'Copy message template'
                                            }} level={5} >Template</Title>
                                        </div>
                                        <Text>{template}</Text>
                                    </TitleDiv>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 24 }}>
                                <Col span={24}>
                                    <TitleDiv>
                                        <div>
                                            <Title level={5} copyable={{
                                                text: delimitedEmails,
                                                tooltips: 'Copy recipients emails'
                                            }} >Recipients</Title>
                                        </div>
                                    </TitleDiv>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: 12 }}>
                                <Col span={24}>
                                    <div>
                                        {
                                            map(recipients, item => {
                                                return (
                                                    <TagChip
                                                        key={item.id}>
                                                        {`${item.name} (${item.email})`}
                                                    </TagChip>
                                                )
                                            })
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )

}