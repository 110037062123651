import { Button, Col, Form, Modal, Row, Spin, message } from 'antd';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useAppContext } from '../../../common/components/appContext';
import AppLayout from '../../../common/components/layout';
import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import useDifficultyPermissionsContext from '../../../common/components/permissionContext/useDifficultyPermissions';
import Search from '../../../common/components/search';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../../config/constants';
import CreateForm from '../create';
import EditForm from '../edit';
import Table from './table';
import useDifficultyFilterContext from './useDifficultyFilterContext';
import Dashboard from '../../../common/components/dashboard';


const pageSize = 10;

export default () => {

    const { httpPostAsync, httpPutAsync, httpDeleteAsync } = useHttpHelper();

    const { user } = useAppContext();

    const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useDifficultyFilterContext();

    const {
        pageNo = 0,
        query = '',
        organisationId = user.defaultOrganisationId,
        sortBy = null,
        sortOrder = null,
    } = searchParams

    //#region organsation dropdown and default value

    const onOrganisationChange = (e) => {
        try {

            console.log('organisations id', e);
            setSearchParams({ organisationId: e });

        } catch (error) {
            console.log('error on organisation change', error);
        }
    }
    //#endregion


    const fetchDifficultyAsync = async () => {

        const filterParams = {
            itemPerPage: pageSize,
            organisationId: organisationId,
            query,
            pageNo,
            sortBy,
            sortOrder
        }

        console.log('fetch difficulty filter', filterParams)

        const response = await httpPostAsync(appLinks.adminDifficulty, { ...filterParams });

        console.log('fetch difficulty for admin', response);

        return response;
    };

    const queryKey = useMemo(() => {

        const key = [queryKeys.settingsDifficultyList, organisationId, query, sortBy, sortOrder, pageNo];

        return key;

    }, [user, organisationId, query, sortBy, sortOrder, pageNo])

    const {
        isLoading,
        //isError,
        //error,
        data = [],
        isFetching,
        // isPreviousData,
        refetch,
    } = useQuery(
        queryKey,
        fetchDifficultyAsync,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });

    let { data: difficulty = [], total = 0, } = data || {}

    //#region Edit TODO move to hook
    const editForm = Form.useForm();
    const [isEditVisible, setIsEditVisible] = useState(false);
    const [editDifficultyLevel, setEditDifficultyLevel] = useState({});

    const showEditModal = (theme) => {
        console.log('show edit modal difficult ', theme)
        setIsEditVisible(true);
        setEditDifficultyLevel(theme)
    }

    const closeEditModal = () => setIsEditVisible(false);

    const onEditClick = () => {
        try {

            editForm[0].submit();

        } catch (error) {
            console.log('error on edit difficulty modal', error);
        }
    }

    const udpateDifficultyAsync = async (data) => {

        console.log('updated data', data);

        const link = appLinks.updateDifficulty.replace('{difficultyId}', editDifficultyLevel.id);
        // console.log('accept challenge link', link);
        console.log('udpate post data', data);
        const difficultyData = { ...data, organisationId };
        const response = await httpPutAsync(link, difficultyData);
        console.log('update difficulty async', response);

        return response;
    };

    const editDifficultyId = editDifficultyLevel && editDifficultyLevel.id;

    const mutationQueryKey = useMemo(() => {
        return [queryKeys.updateDifficulty, organisationId, editDifficultyId]
    }, [organisationId, editDifficultyId])

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateDifficultyAsync,
    } = useMutation(
        udpateDifficultyAsync,
        { mutationKey: mutationQueryKey, }
    );

    const onUpdateAsync = async (values) => {
        try {

            console.log('on update callback', values);

            const response = await onUpdateDifficultyAsync(values);

            if (response.code === 0) {
                refetch();
                closeEditModal();
                message.success('Difficulty level was updated successfully');

            } if (response && response.code !== 0) {
                message.error('Difficulty level could not be updated');
                closeEditModal();
            }

            // console.log('updated resonse', response);

        } catch (error) {
            console.log('error on update async', error);
        }
    }

    //#endregion

    //#region create theme
    const createForm = Form.useForm();
    const [isCreateVisible, setIsCreateVisible] = useState(false);

    const showCreateModal = () => {
        setIsCreateVisible(true);
    }

    const closeCreateModal = () => setIsCreateVisible(false);

    const onCreateClick = () => {
        try {

            createForm[0].submit();

        } catch (error) {
            console.log('error on create theme modal submit', error);
        }
    }

    const createDifficultyAsync = async (data) => {

        console.log('create data', data);

        const link = appLinks.createDifficulty
        // console.log('accept challenge link', link);
        console.log('create post data', data);
        const themeData = { ...data, organisationId };
        const response = await httpPostAsync(link, themeData);
        console.log('create difficulty async', response);

        return response;
    };

    const mutationCreateQueryKey = useMemo(() => {
        return [queryKeys.createDifficulty, organisationId]
    }, [organisationId])

    const {
        // data: updatePersonResponse,
        isLoading: isCreating,
        mutateAsync: createNewDifficultyAsync,
    } = useMutation(
        createDifficultyAsync,
        { mutationKey: mutationCreateQueryKey, }
    );

    const onCreateAsync = async (values) => {
        try {

            console.log('on create callback', values);

            const response = await createNewDifficultyAsync(values);

            if (response.code === 0) {
                refetch();
                closeCreateModal();
                message.success('Difficulty level was created successfully');

            } if (response && response.code !== 0) {
                message.error('Difficulty level could not be created');
                closeCreateModal();
            }

            console.log('updated resonse', response);

        } catch (error) {
            console.log('on update async', error);
        }
    }

    //#endregion 

    const onSortChange = (sort) => {
        try {

            console.log('sort', sort)

            const { field, order, column } = sort || {};

            if (column) {
                setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
            } else {
                setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
            }

        } catch (error) {
            console.log('error sorting', error);
        }
    }

    const onPagniationChange = (pagination) => {
        try {

            console.log('on change', pagination,);
            let currentPage = get(pagination, 'current');
            currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
            setSearchParams(p => ({ ...p, pageNo: currentPage }));


        } catch (error) {
            console.log('error on table change', error);
        }
    }

    const styles = useMemo(() => ({
        itemStyle: {
            paddingRight: 16,
            paddingBottom: 18,
        },
        // tagsContainer: {
        //   paddingTop: tagsVisible ? 16 : 0,
        //   paddingBottom: tagsVisible ? 8 : 0

        // },
        // challengeTableContainer: {
        //   paddingTop: tagsVisible ? 0 : 17
        // }
    }), [])

    const onSearch = (e) => {
        try {
            console.log('on search list', e);
            setSearchParams(p => ({ ...p, query: e }));
        } catch (error) {
            console.log('error on search change', error);
        }
    }

    //#region delete

    const [deleteDifficultyId, setDeleteDifficultyId] = useState();

    const deleteMutationQueryKey = [queryKeys.deleteDifficulty, deleteDifficultyId]

    const deleteDifficultyAsync = async (difficultyId) => {
        try {

            const link = appLinks.deleteDifficulty.replace('{difficultyId}', difficultyId);
            const deleteResponse = await httpDeleteAsync(link);

            console.log('delete response', deleteResponse);

            return deleteResponse;

        } catch (error) {
            console.log('error deleting difficulty level', error);
        }
    }

    const {
        // data: updatePersonResponse,
        isLoading: isDeleting,
        mutateAsync: onDeleteDifficultyAsync,
    } = useMutation(
        deleteDifficultyAsync,
        { mutationKey: deleteMutationQueryKey, }
    );

    const onDelete = (difficultyId, name) => {
        try {

            console.log('delete difficulty level', difficultyId);
            Promise.resolve(setDeleteDifficultyId(difficultyId))

            Modal.confirm({
                title: `Are you sure you want to delete ${name}`,
                okText: 'Delete',
                closable: true,
                okButtonProps: {
                    loading: isDeleting,
                    disabled: isDeleting,
                },
                onOk: async () => {
                    console.log('on delete click');

                    const deleteResponse = await onDeleteDifficultyAsync(difficultyId);

                    if (deleteResponse && deleteResponse.code === 0) {
                        message.success('Difficulty level was deleted successfully');
                    } else {
                        message.error('We are facing some issues, Difficulty level could not be deleted');
                    }

                    await refetch();

                }

            });

        } catch (error) {
            console.log('error deleting theme', error);
        }
    }

    //#endregion

    const { 
        hasCreateAccess = false,
        hasEditAccess = false,
        hasDeleteAccess = false,
    } = useDifficultyPermissionsContext();

    const [resetKey, setResetKey] = useState(0);

    const onReset = () => {
        resetSearchParams();
        setResetKey(k => k + 1);
    }

    return (
        <React.Fragment>
            <Dashboard subrouting={false}  openKeys={['settings']} activeItem='settings-difficulty'>
                <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
                    <Col>
                        <Row>
                            <Col style={{ paddingRight: 15 }}>
                            <OrganisationsLookup
                                    mode='single'
                                    onChange={onOrganisationChange}
                                    defaultValue={organisationId}
                                    value={organisationId}
                                    style={{ width: 200 }}
                                    placeholder="Select Organisation"
                                    showSearch
                                    labelInValue={false}
                                />
                            </Col>
                            <Col style={styles.itemStyle}>
                                <Search
                                    key={resetKey}
                                    placeholder="Search"
                                    initialValue={query}
                                    onSearch={onSearch}
                                    allowClear
                                    style={{ width: 200 }}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Button
                            type="primary"
                            data-testid='create-difficulty'
                            disabled={!hasCreateAccess}
                            block
                            onClick={showCreateModal}
                        >
                            New Difficulty
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col push={23}>
                        {
                            isSearchActive
                                ? (
                                    <Button type="link" size='small' onClick={onReset} style={{ marginBottom: 8 }} >
                                        Reset
                                    </Button>
                                )
                                : null
                        }
                    </Col>
                </Row>
                <Spin spinning={isLoading || isFetching}>
                    <Table
                        items={difficulty}
                        showEditModal={showEditModal}
                        onPagniationChange={onPagniationChange}
                        onSortChange={onSortChange}
                        onDelete={onDelete}
                        pageNo={pageNo}
                        total={total}
                        defaultPageSize={pageSize}
                        key={resetKey}
                        sortBy={sortBy}
                        sortOrder={sortOrder}
                        hasEditAccess={hasEditAccess}
                        hasDeleteAccess={hasDeleteAccess}
                    />
                </Spin>
            </Dashboard>
            <Modal
                title="Edit"
                visible={isEditVisible}
                onOk={onEditClick}
                onCancel={closeEditModal}
                okText='Save'
                okButtonProps={{
                    loading: isUpdating
                }}
                destroyOnClose
            >
                <EditForm
                    form={editForm[0]}
                    organisationId={organisationId}
                    skill={editDifficultyLevel}
                    onUpdateAsync={onUpdateAsync}
                />
            </Modal>
            <Modal
                title="New"
                open={isCreateVisible}
                onOk={onCreateClick}
                onCancel={closeCreateModal}
                okText='Save'
                okButtonProps={{
                    loading: isCreating
                }}
                destroyOnClose
            >
                <CreateForm
                    form={createForm[0]}
                    organisationId={organisationId}
                    onCreateCallback={onCreateAsync}
                />
            </Modal>
        </React.Fragment>
    )

}