import { VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { Button, Image, List, Switch, Tag, Typography } from "antd";
import { useMemo } from "react";

import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';

const { Text } = Typography;

const itemContainerStyle = {
    position: 'relative',
}


const ItemCard = (props) => {

    const {
        item,
        showEditModal,
        hasEditAccess,
        hasDeleteAccess,
        onDelete,
        showBorder = false,
        disableSort = false,
        toggleVisibility,
        visible,
        toggleDateRange,
        isDateRangeEnabled,
        showDateRangeModal
    } = props

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useSortable({
        id: item.id,
        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });

    const borderStyle = useMemo(() => {

        return showBorder ? {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            borderColor: '#f0f0f0',
        } : { }

    }, [showBorder])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: 12,
        backgroundColor: '#fff',
        ...borderStyle
    };

    const dragHandlerProps = disableSort ? {} : listeners

    const startDate = getAppZoneMomentFromUTC(item?.startDate)
    const endDate = getAppZoneMomentFromUTC(item?.endDate)

    const datesInvalid = !startDate || !endDate || !startDate?.isValid() || !endDate?.isValid()

    return (
        <List.Item className='item-card' style={itemContainerStyle} ref={setNodeRef} >
            <div style={style} {...attributes}>
                <div className='item-content'>
                    <div className='item-container'>
                        <div className='drag-handle'>
                            {
                                !showBorder ?
                                    (
                                        <Button
                                            type="secondary"
                                            shape='circle'
                                            disabled={disableSort}
                                            ref={setActivatorNodeRef}
                                            {...dragHandlerProps}
                                            //{...listeners}
                                            icon={<VerticalAlignMiddleOutlined />}
                                        />
                                    )
                                    : null
                            }
                        </div>
                        <div>
                            <List.Item.Meta title={item.title} />
                            <div className='image-container'>
                                <Image className='theme-image' alt={item.title} src={item.imageUrl} height={160} />
                            </div>
                            <div className='bottom-row'>
                                <div className='actions'>
                                    <a
                                        onClick={() => {
                                            if (hasEditAccess && item.canEdit && typeof showEditModal === 'function') {
                                                showEditModal({
                                                    id: item.id,
                                                    title: item.title,
                                                    imageId: item.imageId,
                                                    imageUrl: item.imageUrl,
                                                    thumbImageId: item.thumbId,
                                                    thumbImageUrl: item.thumbImageUrl,
                                                })
                                            }
                                        }}
                                        className={`edit ${hasEditAccess && item.canEdit ? '' : 'disable'}`}>Edit</a>
                                    <a
                                        onClick={() => {
                                            hasDeleteAccess && item.canDelete && onDelete && onDelete(item.id, item.title)
                                        }}
                                        className={`${hasEditAccess && item.canEdit ? '' : 'disable'}`}
                                    >Delete</a>
                                </div>
                                {typeof item.color === 'string' ? <div><Tag className='color-tag' color={item.color}>{item.color}</Tag></div> : null}
                            </div>
                        </div>
                    </div>
                    <div className='item-right'>
                        <div className='visiblility-status'>
                            <Text type="secondary">Visibility</Text>
                            <Switch 
                                size='default' 
                                className='toggle-switch'
                                checked={visible}
                                onChange={checked => {
                                    toggleVisibility(item.id, checked)
                                }}
                             />
                        </div>
                        <div className='date-range-toggle'>
                            <div>
                            <Text type="secondary">Enable date range</Text>
                            {
                                isDateRangeEnabled && datesInvalid ? <Text className='invalid-date-warning' type="warning">Start or End date is invalid!</Text>
                                : null
                            }
                            </div>
                            <Switch 
                                size='default' 
                                className='toggle-switch'
                                checked={isDateRangeEnabled}
                                onChange={checked => {
                                    toggleDateRange(item.id, checked)
                                }} 
                                />
                        </div>
                        <div className='date-range-section'>
                            <div className='date-item'>
                                <Text type="secondary">Start Date</Text>
                                <Text className='date-text'>{startDate?.isValid() ? startDate.format('Do MMMM, YYYY') : ' - '}</Text>
                            </div>
                            <div className='date-item'>
                                <Text type="secondary">End date</Text>
                                <Text className='date-text'>{endDate?.isValid() ? endDate.format('Do MMMM, YYYY') : ' - '}</Text>
                            </div>
                        </div>
                        <a
                            className={'date-range-edit'}
                            onClick={() => {
                                showDateRangeModal({
                                    id: item.id,
                                    title: item.title,
                                    imageId: item.imageId,
                                    imageUrl: item.imageUrl,
                                    thumbImageId: item.thumbId,
                                    thumbImageUrl: item.thumbImageUrl,
                                    startDate: item.startDate,
                                    endDate: item.endDate,
                                })
                            }}
                        >Edit dates</a>
                    </div>
                </div>
            </div>
        </List.Item>
    )

}

export default ItemCard;