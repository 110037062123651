import React from 'react';
import { useQuery } from 'react-query';
import { PieChart, Pie, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import { appLinks, queryKeys } from '../../../config/constants';
import useHttpHelper from '../../../common/hooks/useHttpHelper';

const Label = (props) => {
    // console.log('props', props)
    const { value, name, fill, x, y, textAnchor } = props
    return (
        <text x={x} y={y} fill={fill} textAnchor={textAnchor} dominantBaseline="central">
            {`${name} - ${value}`}
        </text>

    )
}

const CustomLegand = (props) => {

    const { payload, title } = props;

    return (
        <div className='custom-legand'>
        <ul class="recharts-default-legend"
            style={{
                padding: 0,
                margin: 0,
                textAlign: 'center'
            }}>
            {
                payload?.map(e => {

                    console.log('payload items', e)

                    return (
                        <li class="recharts-legend-item legend-item-0"
                            style={{
                                display: 'inline-block',
                                marginRight: 10
                            }}>
                            <svg class="recharts-surface" width="14" height="14" viewBox="0 0 32 32"
                                style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginRight: 4
                                }}>
                                <path fill="#8884d8" cx="16" cy="16" class="recharts-symbols" transform="translate(16, 16)" d="M16,0A16,16,0,1,1,-16,0A16,16,0,1,1,16,0"></path>
                            </svg>
                            <span class="recharts-legend-item-text" style={{
                                color: e?.color ?? 'rgb(136, 132, 216)'
                            }}>{e.value}</span>
                        </li>
                    )

                })
            }
        </ul>
        <div className='footer'>
            <span>{title}</span>
        </div>
        </div>
    )


}



const UserStatus = () => {

    const { httpPostAsync } = useHttpHelper();

    const fetchOverViewDetailsAsync = async () => {
        try {

            const link = appLinks.userTypeStats

            const response = await httpPostAsync(link)

            console.log('user type', response?.data)

            return response?.data ?? []

        } catch (error) {
            console.log('error fetching user status')
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.userStatusStats],
        fetchOverViewDetailsAsync,
        {
            refetchOnWindowFocus: false,
        });

    const organisation1 = data?.[0]
    const organisation2 = data?.[1]

    return (
        <div className='user-chart-container'>
            <div className='title-container'>
                <span className='title'>User Status Overview</span>
            </div>
            <div className='user-pie-chart'>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={300}>
                        <Pie
                            data={organisation1?.data ?? []}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={Label}
                        />
                        <Tooltip />
                        <Legend
                            key={2}
                            layout='horizontal'
                            align='center'
                            verticalAlign="bottom"
                            iconType='circle'
                            content={<CustomLegand title={organisation1?.organisation} />}
                        />
                    </PieChart>

                </ResponsiveContainer>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={300}>
                        <Pie
                             data={organisation2?.data ?? []}
                            dataKey="value"
                            cx="50%"
                            cy="50%"
                            innerRadius={70}
                            outerRadius={90}
                            fill="#82ca9d"
                            label={Label}
                        />
                        <Tooltip />
                        <Legend
                            key={2}
                            layout='horizontal'
                            align='center'
                            verticalAlign="bottom"
                            iconType='circle'
                            content={<CustomLegand title={organisation2?.organisation} />}
                        />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    )

}

export default UserStatus;