import { Col, Row, Segmented } from "antd";
import { debounce, every, get } from "lodash";
import { useCallback, useMemo, useState } from "react";
import empty from "../../../common/components/Empty";
import LookupSelect from "../../../common/components/lookupSelect";
import useHttpHelper from "../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../config/constants";

const defaultItemPropMapper = ({ value, label, email }) => ({ value, label, key: `${value}.${email}`, children: label, email });

const filterOptions = [ 'All', 'Active', 'Invited', 'Deactivated', 'Leads']

const UsersLookups = (props) => {

    const { 
        organisationId, 
        setEmailListType,
        ...rest
    } = props;

    const { httpPostAsync } = useHttpHelper();

    const [searchParams, setSearchParams] = useState({ 
        query: '', 
        organisations: [organisationId], 
        itemsPerPage: 20,
        status:  filterOptions[0]
    });

    const { 
        query = '',
        status, 
    } = searchParams;

    const usersQueryParams = useMemo(() => ({ 
        organisations: [organisationId], 
        query, 
        status 
    }), [organisationId, query, status]);

    const fetchUsersAsync =
        useCallback(async (params = {}) => {

            //console.log('fetch users with params', params);

            const response = await httpPostAsync(appLinks.oneUserLookups, { ...params });

            //console.log('users response', response)

            return get(response, 'data') || [];
        }, [httpPostAsync, organisationId]);

    const usersQueryKeyFn =
        useCallback(({ organisations = [], status, query }) => [queryKeys.oneUserLookups, organisations, query, status], []);

    const enableUsersFetchFn = useCallback(({ organisations = [] }) => {
        return every(organisations, id => id > 0)
    }, []);

    const deboundedSearch = useCallback(debounce(q => setSearchParams(p => ({ ...p, query: q })), 300), [])

    const onSearch = (e) => deboundedSearch(e)

    const onBlur = (e) => deboundedSearch()

    const onSegmentChange = (status) => {
        setSearchParams(p => ({ ...p, status}))
        setEmailListType(status)
    }

    return (
        <div className="select-receipients-container">
            <div className="segmented-filters">
                <Segmented 
                    options={filterOptions} 
                    defaultValue={filterOptions[0]}
                    onChange={onSegmentChange}
                />
            </div>
            <Row style={{ paddingTop: 8 }} >
                <Col>
                    <LookupSelect
                        placeholder="Add Emails"
                        queryParams={usersQueryParams}
                        fetchAsync={fetchUsersAsync}
                        queryKeyFn={usersQueryKeyFn}
                        enabledFn={enableUsersFetchFn}
                        lookupId='users lookups'
                        //allowClear
                        showSearch
                        onSearch={onSearch}
                        filterOption={false}
                        mode='multiple'
                        labelInValue
                        tagRender={empty}
                        onBlur={onBlur}
                        style={{ width: 240 }}
                        //onChange={onChangeCallback}
                        itemPropsMapperFn={defaultItemPropMapper}
                        {...rest}
                    />
                </Col>
            </Row>
        </div>
    )

}

export default UsersLookups;
