import { useCallback, useMemo, useState } from "react";
import { Row, Col, Form, Card } from "antd";
import LookupSelect from "../../../common/components/lookupSelect";
import useHttpHelper from "../../../common/hooks/useHttpHelper";
import { debounce, every, get, map } from "lodash";
import { appLinks, queryKeys } from "../../../config/constants";
import empty from "../../../common/components/Empty";
import ReceipientTags from "./ReceipientTags";

const defaultItemPropMapper = ({ value, label, email }) => ({ value, label, key: `${value}.${email}`, children: label, email });

const shouldUpdateReceipients = () => (prev, cur) => prev.organisationItems !== cur.organisationItems;

export default (props) => {

    const { addRecipients, organisationId, recipients = [], onRemoveRecipient } = props;

    // const onCheck = (e) => {
    //     try {
    //         //console.log('on check', e.checked)
    //         onSendAllCheck(e.target.checked)
    //     } catch (error) {
    //         console.log('error on send all check', error);
    //     }

    // }

    const { httpPostAsync } = useHttpHelper();

    const [searchParams, setSearchParams] = useState({ query: '', organisations: [organisationId], itemsPerPage: 20 });

    const { query = '' } = searchParams;

    const usersQueryParams = useMemo(() => ({ organisations: [organisationId], query }), [organisationId, query]);

    const fetchUsersAsync =
        useCallback(async (params = {}) => {

            //console.log('fetch users with params', params);

            const response = await httpPostAsync(appLinks.userLookups, { ...params });

            //console.log('users response', response)

            return get(response, 'data') || [];
        }, [httpPostAsync, organisationId]);

    const usersQueryKeyFn =
        useCallback(({ organisations = [], organisationId, query }) => [queryKeys.userLookups, organisations, query], []);

    const enableUsersFetchFn = useCallback(({ organisations = [] }) => {
        return every(organisations, id => id > 0)
    }, []);

    const deboundedSearch = useCallback(debounce(q => setSearchParams(p => ({ ...p, query: q })), 300), [])

    const onSearch = (e) => {
        // console.log('on search', e);
        deboundedSearch(e);
    }

    const onBlur = (e) => {
        //console.log('on select', e)
        deboundedSearch();
    }

    const onChange = (e) => {
       // console.log('on change', e);
        const mappedUsers = map(e, ({ key, value, label }) => {
            try {

                const indexOfDot = key.indexOf('.');
                const email = key.substring(indexOfDot + 1);

                return {
                    key,
                    value,
                    label,
                    email,
                }

            } catch (error) {
                console.log('error mapping user', error);
                return {
                    key,
                    value,
                    label,
                }
            }
        });

        console.log('mapped users', mappedUsers);

        //const recipients = map(a, i => ({ value: i.value, key: i.key, label: i.label, email: i.email }));
        addRecipients && addRecipients(mappedUsers)
    }

    return (
        <div >
            {/* <Row>
                <Col>
                    <Checkbox onChange={onCheck} checked={checked} style={{ paddingBottom: 12 }}>Send all users</Checkbox>
                </Col>
                <Col>
                    <Checkbox onChange={onCheck} checked={checked} style={{ paddingBottom: 12 }}>Send push notifications</Checkbox>
                </Col>
            </Row> */}
            <Card>
            <Row style={{paddingTop:8}} >
                <Col>
                    <LookupSelect
                        placeholder="Select Recipients"
                        queryParams={usersQueryParams}
                        fetchAsync={fetchUsersAsync}
                        queryKeyFn={usersQueryKeyFn}
                        enabledFn={enableUsersFetchFn}
                        lookupId='users lookups'
                        //allowClear
                        showSearch
                        onSearch={onSearch}
                        filterOption={false}
                        mode='multiple'
                        labelInValue
                        tagRender={empty}
                        onBlur={onBlur}
                        style={{ width: 240 }}
                        onChange={onChange}
                        itemPropsMapperFn={defaultItemPropMapper}
                        value={recipients}
                    />
                </Col>
            </Row>
            <Row>
                <Col style={{paddingTop:12}}>
                    <Form.Item shouldUpdate={shouldUpdateReceipients} style={{marginBottom:0}}>
                        {(props) => <ReceipientTags {...props} organisationId={organisationId} onRemoveRecipient={onRemoveRecipient} />}
                    </Form.Item>
                </Col>
            </Row>
            </Card>
        </div>
    )

}
