import React from 'react';
import TagChip from '../tagchip';


export default (props) => {

    const { items = [], getColor, closable } = props

    return (
        <React.Fragment>
            {items?.map(item => {
                const color = typeof getColor === 'function' && getColor(item)
                return (
                    <TagChip
                        color={color}
                        key={item.value}
                        closable={closable} 
                        >
                        {item.label}
                    </TagChip>
                )
            })}
        </React.Fragment>
    )

}