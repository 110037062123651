import { get } from "lodash";
import { createContext, useContext, useMemo, useReducer } from "react";

const initialValue = {
    permissions: {},
}

export const parentPermissions = {
    user: 'users',
    challenges: 'challenges',
    creators: 'creators',
    posts: 'posts',
    organisations: 'organisations',
    clubs: 'clubs',
    settings: 'settings',
}

export const settingsChildPermissions = {
    themes: 'themes',
    skills: 'skills',
    durations: 'durations',
    difficulty: 'difficulty',
    rolePermissions: 'rolePermissions',
    age: 'ages',
    materials: 'materials'
}

export const childPermission = {
    list: 'list',
    details: 'details',
    create: 'create',
    edit: 'edit',
    delete: 'delete',
    editRoles: 'editRoles',
    editOrganisations: 'editOrganisations',
    oneKidsPosts: 'oneKidsPosts',
    calendar: 'calendar'
}

export const challengesPermission = {
    createEditBanners: 'createEditBanners',
    viewBanners: 'viewBanners',
    location: 'location',
    generate: 'generate'
}

// const childPermissions = {
//     [parentPermissions.challenges]: {
//         list: 'list',
//         details: 'details',
//         create: 'create',
//         edit: 'edit',
//         delete: 'delete',
//     },
//     [parentPermissions.clubs]: {
//         list: 'list',
//         details: 'details',
//         create: 'create',
//         edit: 'edit',
//         delete: 'delete',
//     },
// }

export const permissionValues = {
    yes: 'Yes',
    no: 'No',
    own: 'Own',
}

const PermissionContext = createContext({ ...initialValue });

export function usePermssionContext() {

    const { setPermissions } = useContext(PermissionContext);

    return { setPermissions }

}

export const useMenuPermissionsContext = () => {

    const { permissions = {} } = useContext(PermissionContext);

    //console.log('permission menu contex in menu permissions', permissions['settings.themes'])

    const challengesListKey = `${parentPermissions.challenges}.${childPermission.list}`;
    const hasChallengesMenuAccess = get(permissions, challengesListKey, permissionValues.no) !== permissionValues.no;

    const organisationsListKey = `${parentPermissions.organisations}.${childPermission.list}`;
    const hasOrganisationsMenuAccess = get(permissions, organisationsListKey, permissionValues.no) !== permissionValues.no;

    const hasSettingsMenuAccess = get(permissions, `${parentPermissions.settings}`) === permissionValues.yes;

    const themesListKey = `${parentPermissions.settings}.${settingsChildPermissions.themes}.${childPermission.list}`;
    const hasThemeSubmenuAccess = hasSettingsMenuAccess &&
        get(permissions, themesListKey, permissionValues.no) !== permissionValues.no;

    const skillsListKey = `${parentPermissions.settings}.${settingsChildPermissions.skills}.${childPermission.list}`;
    const hasSkillsSubmenuAccess = hasSettingsMenuAccess &&
        get(permissions, skillsListKey, permissionValues.no) !== permissionValues.no;

    const difficultyListKey = `${parentPermissions.settings}.${settingsChildPermissions.difficulty}.${childPermission.list}`;
    const hasDifficultySubmenuAccess = hasSettingsMenuAccess &&
        get(permissions, difficultyListKey, permissionValues.no) !== permissionValues.no;

    // const durationsList = `${parentPermissions.settings}.${settingsChildPermissions.durations}.${childPermission.list}`;
    // const hasDurationSubmenuAccess = hasSettingsMenuAccess &&
    //     get(permissions, durationsList, permissionValues.no) !== permissionValues.no;

    const hasRolePermissionKey = `${parentPermissions.settings}.${settingsChildPermissions.rolePermissions}`;
    const hasRolePermission = get(permissions, hasRolePermissionKey, permissionValues.no) !== permissionValues.no;
    const rolePermissionListKey = `${parentPermissions.settings}.${settingsChildPermissions.rolePermissions}.${childPermission.list}`;
    const hasRolePermissionsSubmenuAccess = hasSettingsMenuAccess &&
        hasRolePermission &&
        get(permissions, rolePermissionListKey, permissionValues.no) !== permissionValues.no;

    const clubKey = parentPermissions.clubs;
    const hasClubAccess = get(permissions, clubKey) === permissionValues.yes;
    const clubListKey = `${clubKey}.${childPermission.list}`;
    const hasClubsMenuAccess = hasClubAccess &&
        get(permissions, clubListKey, permissionValues.no) !== permissionValues.no;


    const usersKey = parentPermissions.user;
    const hasUserAccess = get(permissions, usersKey) === permissionValues.yes;
    const userListKey = `${usersKey}.${childPermission.list}`;
    const hasUsersMenuAccess = hasUserAccess &&
        get(permissions, userListKey, permissionValues.no) !== permissionValues.no;

    //console.log('has user menu access', hasUserAccess, hasUsersMenuAccess)

    // const creatorKey = parentPermissions.creators;
    // const hasCreatorsAccess = get(permissions, creatorKey) === permissionValues.yes;
    // const creatorListKey = `${creatorKey}.${childPermission.list}`;
    // const hasCreatorsMenuAccess = hasCreatorsAccess &&
    //     get(permissions, creatorListKey, permissionValues.no) !== permissionValues.no;


    const postsKey = parentPermissions.posts;
    const hasPostsAccess = get(permissions, postsKey) === permissionValues.yes;
    const postsListKey = `${postsKey}.${childPermission.list}`;
    const hasPostsMenuAccess = hasPostsAccess &&
        get(permissions, postsListKey, permissionValues.no) !== permissionValues.no;


    const hasAgesPermission = hasSettingsMenuAccess &&
        permissions[`${parentPermissions.settings}.${settingsChildPermissions.age}`] === permissionValues.yes

    const hasMaterialsPermission = hasSettingsMenuAccess &&
        permissions[`${parentPermissions.settings}.${settingsChildPermissions.materials}`] === permissionValues.yes


    const hasOneKidsMenuAccess = permissions[`${parentPermissions.posts}.${childPermission.oneKidsPosts}`] === permissionValues.yes

    const hasCalendarPermission = permissions[`${parentPermissions.challenges}.${childPermission.calendar}`] === permissionValues.yes

    const hasDashboardAccess = permissions[`dashboard.home`] === permissionValues.yes
    //dashboard.home

    return {
        hasUsersMenuAccess,
        hasChallengesMenuAccess,
        //hasCreatorsMenuAccess,
        hasOrganisationsMenuAccess,
        hasClubsMenuAccess,
        hasSettingsMenuAccess,
        hasThemeSubmenuAccess,
        hasSkillsSubmenuAccess,
        hasDifficultySubmenuAccess,
        // hasDurationSubmenuAccess,
        hasRolePermissionsSubmenuAccess,
        hasPostsMenuAccess,
        hasAgesPermission,
        hasMaterialsPermission,
        hasOneKidsMenuAccess,
        hasCalendarPermission,
        hasDashboardAccess,
    }

}

export const useChallengesPermissionContext = () => {

    const { permissions = {} } = useContext(PermissionContext);

    console.log('permission challenges contex: challenges', permissions)

    let hasListAccess = get(permissions, `${parentPermissions.challenges}.${childPermission.list}`) !== permissionValues.no;

    const hasChallengesMenuAccess = hasListAccess;

    let hasEditAccess = get(permissions, `${parentPermissions.challenges}.${childPermission.edit}`) !== permissionValues.no;

    let hasDeleteAccess = get(permissions, `${parentPermissions.challenges}.${childPermission.delete}`) !== permissionValues.no;

    let hasCreateAccess = get(permissions, `${parentPermissions.challenges}.${childPermission.create}`) === permissionValues.yes;

    let hasDetailAccess = get(permissions, `${parentPermissions.challenges}.${childPermission.details}`) !== permissionValues.no;

    let hasCreateEditBannersAccess =  permissions?.[`${parentPermissions.challenges}.${challengesPermission.createEditBanners}`] === permissionValues.yes;
    let hasViewBannersAccess =  permissions?.[`${parentPermissions.challenges}.${challengesPermission.viewBanners}`] === permissionValues.yes;

    let hasLocationAccess = permissions?.[`${parentPermissions.challenges}.${challengesPermission.location}`] === permissionValues.yes;

    let hasGenerateChallengeAccess = permissions?.[`${parentPermissions.challenges}.${challengesPermission.generate}`] === permissionValues.yes;

    return {
        hasChallengesMenuAccess,
        hasListAccess,
        hasDetailAccess,
        hasCreateAccess,
        hasEditAccess,
        hasDeleteAccess,
        hasCreateEditBannersAccess,
        hasViewBannersAccess,
        hasLocationAccess,
        hasGenerateChallengeAccess,
    }

}

export const useOrganisationPermissionContext = () => {

    const { permissions = {} } = useContext(PermissionContext);

    console.log('organisation challenges contex: challenges', permissions)

    const parentPerm = parentPermissions.organisations;

    let hasListAccess = get(permissions, `${parentPerm}.${childPermission.list}`) !== permissionValues.no;

    const hasChallengesMenuAccess = hasListAccess;

    let hasEditAccess = get(permissions, `${parentPerm}.${childPermission.edit}`) !== permissionValues.no;

    let hasDeleteAccess = get(permissions, `${parentPerm}.${childPermission.delete}`) !== permissionValues.no;

    let hasCreateAccess = get(permissions, `${parentPerm}.${childPermission.create}`) === permissionValues.yes;

    let hasDetailAccess = get(permissions, `${parentPerm}.${childPermission.details}`) !== permissionValues.no;

    return {
        hasChallengesMenuAccess,
        hasListAccess,
        hasDetailAccess,
        hasCreateAccess,
        hasEditAccess,
        hasDeleteAccess,
    }

}

function reducer(state, action) {

    //console.log('filter state action', action);

    switch (action.type) {
        case 'set':

            //console.log('set permissions')

            return { permissions: action.permissions }

        case 'reset':

            return { ...initialValue }

        default:
            throw new Error();
    }
}

export const WithPermissionContext = (props) => {

    const [state, dispatch] = useReducer(reducer, { ...initialValue });

    const setPermissions = (permissions = {}) => dispatch({ type: 'set', permissions })

    const resetPermissions = () => dispatch({ type: 'reset' })

    const contextValue = useMemo(() => {

        return {
            ...state,
            setPermissions,
            resetPermissions,
        }

    }, [state]);

    return (<PermissionContext.Provider {...props} value={contextValue} />)

}

export default PermissionContext;
