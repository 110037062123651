


const EditItemTag = (props) => {

    const {
        children,
        className,
    } = props;

    return (
        <div className={className}>
            <div>{children}</div>
        </div>
    )


}

export default EditItemTag