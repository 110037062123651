import { Col, Divider, Image, Row, Typography } from "antd";
import { get } from "lodash";
import { useMemo } from "react";
import { ReactComponent as ProfileImageDefault } from '../../../../assets/userProfile.svg';
import { getYoutubeVideoId } from "../../../../helpers/utils";

const { Text } = Typography;

const ThumbImageComponent = (props) => {

    const { thumbUrl } = props;

    return (
        thumbUrl ?
            <Image
                height={145}
                width={100}
                src={thumbUrl}

            //fallback={defaultPlaceholderImage}
            />
            : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
    )

}

const YoutubeListComponent = (props) => {

    const { videoLink } = props;

    const videoId = getYoutubeVideoId(videoLink)

    console.log('youtube video link', videoLink)

    return (
        videoLink ?
            <Image
                height={145}
                width={100}
                src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
            //fallback={defaultPlaceholderImage}
            />
            : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
    )

}

const listImageComponentMap = {
    'image': ThumbImageComponent,
    'youtubeVideo': YoutubeListComponent,
}

export default ({ label, listImageType, videoLink, imageUrl, onClose, index }) => {

    const ListComponent = useMemo(() => {

        const ViewComponent = get(listImageComponentMap, listImageType) || listImageComponentMap.image;

        return ViewComponent;

    }, [listImageType])

    return (
        <div >
            {index > 0 ? (<Divider />) : null}
            <Row justify='space-between'>
                <Col span={12}>
                    <div style={{ marginBottom: 12 }}> 
                        <Text type="secondary" >{label}</Text>
                    </div>
                    <ListComponent videoLink={videoLink} thumbUrl={imageUrl} />
                </Col>
                {/* <Col>
                    <Button
                        //type='primary'
                        shape='circle'
                        onClick={() => {

                            onClose(value, label)
                        }}
                        danger
                        icon={<DeleteOutlined />}
                    />
                </Col> */}
            </Row>
        </div>
    )

}