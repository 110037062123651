import { Badge, Form, Input, List, Modal, Select, Tag, Typography, notification } from "antd";
import { useState } from "react";
import OrganisationSelect from "./OrganisationSelect";
import { RelatedToActivitiesFieldName, RelatedToListFieldName } from ".";
import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../../config/constants";
import { useQuery } from "react-query";
import EmptyDisplayComponent from "../../../../../common/components/Empty";

import styles from './RelatedTo.module.scss'
import TagChip from "../../../../../common/components/tagchip";

const { Option } = Select;


const RelatedActivities = (props) => {

    const {
        organisationId,
        form
    } = props;

    const [selectedOrganisation, setSelectedOrganisation] = useState()

    const [selectedActivities, setSelectedActivities] = useState([])

    const { httpPostAsync } = useHttpHelper();

    const fetchChallengesAsync = async (params = {}) => {

        const link = appLinks.challengesActivitiesLookup.replace('{organisationId}', selectedOrganisation?.value);
        const response = await httpPostAsync(link, { ...params });

        console.log('activities lookup:', response)

        const activities = response?.data ?? []

        return activities;

    }

    const challengesQueryKey = [queryKeys.challengesLookup, selectedOrganisation?.value];

    const {
        data: activities = [],
        isLoading: isLoadingActivities,
    } = useQuery(
        challengesQueryKey,
        fetchChallengesAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0 && selectedOrganisation?.value > 0
        });


    const updateRelatedto = (newItems = []) => {
        try {
            //update list
            form.setFieldsValue({ [RelatedToListFieldName]: [...newItems] })

            // update activity select
            const filteredItems = newItems?.filter(e => e.organisationId === selectedOrganisation?.value) ?? []
            form.setFieldsValue({ [RelatedToActivitiesFieldName]: [...filteredItems] })

        } catch (error) {
            console.log(' error updateRelatedto', error)
        }
    }


    const [api, contextHolder] = notification.useNotification();

    return (
        <div>
            {contextHolder}
            <Typography.Title level={5}>
                Related Activities
            </Typography.Title>
            <div className={styles.activitiesSectionsContainer}>
                <div className={styles.selectActivitiesSection}>
                    <div className={styles.primaryOrganisationSelect}>
                        <p>Organisation</p>
                        <div>
                            <OrganisationSelect
                                organisationId={organisationId}
                                value={selectedOrganisation}
                                onSelect={(item) => {
                                    console.log('selected organisations', item)
                                    setSelectedOrganisation({
                                        ...item
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Select
                            loading={isLoadingActivities}
                            labelInValue
                            className={styles.organisationSelect}
                            showSearch
                            tagRender={EmptyDisplayComponent}
                            mode='multiple'
                            optionLabelProp="children"
                            value={selectedActivities}
                            onDeselect={(item, param) => {
                                try {
                                    console.log('on deselect', item, param)
                                    // return false;

                                    const allRelatedActivities = form.getFieldValue(RelatedToListFieldName)

                                    const activityId = param?.activityId
                                    if (activityId) {
                                        const updatedList = allRelatedActivities?.filter(e => e.activityId !== activityId) ?? []
                                        form.setFieldsValue({ [RelatedToListFieldName]: updatedList })

                                        setSelectedActivities(items => [...items?.filter(e => e.value !== activityId) ?? []])
                                    }

                                } catch (error) {
                                    console.log('error on deselect', error)
                                }
                            }}
                            onSelect={async (item, param) => {
                                console.log('on select', item, param)
                                try {
                                    const allRelatedActivities = form.getFieldValue(RelatedToListFieldName)

                                    // check if more then one entry exists for an organisations
                                    const selectedActivityOrganisationId = param.organisationId;
                                    if (organisationId) {

                                        const activityForSelectedOrganisation = allRelatedActivities?.find(e => e.organisationId === selectedActivityOrganisationId)
                                        if (!!activityForSelectedOrganisation) {
                                            api.info({
                                                message: 'Cannot Add',
                                                description: 'Multiple Activities added for the organisation',
                                                placement: 'bottomRight'
                                            });
                                            return;

                                        }
                                    }


                                    const activityId = param?.activityId
                                    if (activityId) {
                                        const containsActivity = allRelatedActivities?.find(e => e.activityId === activityId)
                                        if (!containsActivity) {
                                            const updatedList = [...allRelatedActivities ?? []]
                                            updatedList.push({
                                                relationId: param.relationId,
                                                activityId: param.activityId,
                                                title: param.title,
                                                organisation: param.organisation,
                                                organisationId: param.organisationId,
                                                isPrimary: param.isPrimary,
                                                parentActivity: param.parentActivity,
                                                parentActivityId: param.parentActivityId,
                                                disabled: undefined,
                                                value: param.value,
                                                label: param.label,
                                            })

                                            form.setFieldsValue({ [RelatedToListFieldName]: updatedList })
                                            setSelectedActivities(items => [item, ...items])
                                        }
                                    }


                                } catch (error) {
                                    console.log('error on selecting activity', error)
                                }
                            }}
                        >
                            {
                                activities?.map((item) => {
                                    return (
                                        <Option
                                            relationId={item.relationId}
                                            activityId={item.activityId}
                                            title={item.title}
                                            organisation={item.organisation}
                                            organisationId={item.organisationId}
                                            isPrimary={item.isPrimary}
                                            parentActivity={item.parentActivity}
                                            parentActivityId={item.parentActivityId}
                                            key={item.value}
                                            value={item.value}
                                            label={item.label}
                                            disabled={item.disabled}
                                        >
                                            {item.label}
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                        {/* </Form.Item> */}
                    </div>
                </div>
                <div className={styles.selectActivitiesSection} >
                    <Form.Item name="relatedToList" hidden>
                        <Input type="hidden" />
                    </Form.Item>
                    <p>Activitie(s)</p>
                    <div>
                        <Form.Item shouldUpdate={(prev, cur) => prev[RelatedToListFieldName] !== cur[RelatedToListFieldName]}>
                            {(props) => {

                                const activities = props.getFieldValue(RelatedToListFieldName) ?? []
                                const challengeId = props.getFieldValue('challengeId')

                                const onRemove = (itemId, label) => {

                                    Modal.confirm({
                                        title: `Are you sure you want to remove ${label}`,
                                        okText: 'Remove',
                                        closable: true,
                                        onOk: () => {
                                            console.log('on delete click');

                                            let filtered = activities?.filter(o => o.value !== itemId) ?? [];

                                            updateRelatedto(filtered)

                                        }

                                    });

                                }

                                return (
                                    <div>
                                        <List
                                            dataSource={activities}
                                            renderItem={(item) => {

                                                //const isPrimary = !!item.isPrimary;
                                                const isCurrentActivity = item.activityId === challengeId;

                                                return (
                                                    <List.Item
                                                        actions={[
                                                            !isCurrentActivity ?
                                                                (
                                                                    <a
                                                                        key="list-loadmore-edit"
                                                                        onClick={() => onRemove(item.value, item.label)}
                                                                    >
                                                                        Remove
                                                                    </a>
                                                                ) : null
                                                        ]}
                                                    >
                                                        <List.Item.Meta
                                                            title={<span>
                                                                <p>{item?.title}
                                                                    {item.activityId === challengeId ?
                                                                        (
                                                                            <span className={styles.currentBadge}><Badge status="success" text="Current" /></span>
                                                                        ) : null}
                                                                </p>
                                                            </span>}
                                                            description={
                                                                <div>
                                                                    <div className={styles.itemPrimaryContainer}>
                                                                        <p>{item.organisation}</p>
                                                                        <p>{item.isPrimary ? <Tag color="orange">Primary</Tag> : null}</p>


                                                                    </div>
                                                                    <p>{item.parentActivityId && item.parentActivityId !== challengeId ?
                                                                        (
                                                                            <div className={styles.associatedTag}>
                                                                                <Typography.Text>Associated with</Typography.Text>
                                                                                <TagChip>
                                                                                    {item.parentActivity}
                                                                                </TagChip>
                                                                            </div>
                                                                        ) : null}</p>
                                                                </div>
                                                            }
                                                        />
                                                    </List.Item>
                                                )
                                            }}
                                        />
                                    </div>
                                )
                            }}
                        </Form.Item>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default RelatedActivities