import { Button, Col, Row, Table } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../config/constants';


const SessionLogTable = props => {

    const {
        logs,
        onPagniationChange,
        onSortChange,
        hasMore = false,
        onLoadMoreClick,
        isFetchingNextPage,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Date',
            dataIndex: 'Timestamp',
            key: 'Timestamp',
            //sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {
                return (
                    <a
                        onClick={() => {
                            //console.log('on click', record)
                            navigate(`/${appRoutes.dashboard}/${appRoutes.userLogs}/${record.Id}`)
                        }}
                    >
                        {record.Timestamp}
                    </a>
                )

            },
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'Account',
            dataIndex: 'AccountType',
            key: 'AccountType',
        },
        {
            title: 'Device',
            dataIndex: 'Device',
            key: 'Device',
        },
        {
            title: 'Ip',
            dataIndex: 'Ip',
            key: 'Ip',
        },
        {
            title: 'Duration',
            dataIndex: 'Elapsed',
            key: 'Elapsed',
        },
        {
            title: 'Type',
            dataIndex: 'SessionType',
            key: 'SessionType',
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={logs}
                        onChange={onChangeTable}
                        rowKey={'Id'}
                        pagination={false}
                    />
                </Col>
            </Row>
            {
                hasMore ? (
                    <div className='load-more-container'>
                        <Button
                            loading={isFetchingNextPage}
                            onClick={onLoadMoreClick}
                        >
                            Load More
                        </Button>
                    </div>
                ) : null
            }
        </div>
    );

}


export default SessionLogTable;