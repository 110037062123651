import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Row, Col, Input, Typography, Form, Checkbox, Alert } from "antd"
import { useAppContext } from "../../common/components/appContext";
import { UserOutlined, KeyOutlined } from '@ant-design/icons';
import { ReactComponent as OneAppLogo } from '../../assets/adminLoginBeta.svg';
import { useEffect, useRef, useState } from "react";
import { appRoutes } from "../../config/constants";


const { Text } = Typography;


export default ({ loginAsync }) => {

    let navigate = useNavigate();
    let location = useLocation();

    const [showLoginError, setShowLoginError] = useState(false);

    const { isAuthenticated } = useAppContext();

    //console.log('Login', value);
    //let from = location.state?.from?.pathname || `/${appRoutes.users}`;

    const manualLoginRef = useRef(false);

    const onFinish = async (values) => {
       // console.log('Success:', values);
        const { password, username, remember = false } = values;

        const loginResponse = await loginAsync(username, password, remember);

       console.log('login response', loginResponse);

        if (loginResponse && loginResponse.code === 0) {
            let from = `/${appRoutes.challenges}`;

            if (location.state?.from?.pathname) {
                from = `${location.state?.from?.pathname}${location.state?.from?.search}`
            }
            console.log('naviage to challenges')
            navigate(from, { replace: true });
            setShowLoginError(false);
        } else {
            setShowLoginError(true);
        }


        // await loginAsync(username, password, remember)
    };

    // const onFinishFailed = (errorInfo) => {
    //     console.log('Failed:', errorInfo);
    // };


    useEffect(() => {

        console.log('is auth: 18:22', isAuthenticated)

        if (isAuthenticated && !manualLoginRef.current) {

            let from = `/${appRoutes.challenges}`;

            if (location.state?.from?.pathname) {
                from = `${location.state?.from?.pathname}${location.state?.from?.search}`
            }

            console.log(' no manual login click, so redirect', from);
            manualLoginRef.current = false;
            navigate(from, { replace: true });
        }

    }, [isAuthenticated])


    return (
        <div style={{ backgroundColor: '#8039B1' }}>
            <Row justify="center" style={{ height: '100vh' }} align="middle" >
                <Col span={8} align="middle">
                    <Row justify="center" >
                        <OneAppLogo height="140px" width="140px" fill="#fff" />
                        <br /> <br /> <br />
                    </Row>
                    <Row >
                        <Card style={{ width: '100%', backgroundColor: '#F4EEEE', borderRadius: '12px' }}>
                            <Text type='secondary' >Admin Login</Text>
                            <br /> <br />
                            <Form
                                name="basic"
                                //labelCol={{ span: 12, }}
                                // wrapperCol={{ span: 16, }}
                                initialValues={{
                                    //username: 'stephen.oneapp@gmail.com',
                                    //password: 'one@pass',
                                    remember: true,
                                }}
                                onFinish={onFinish}
                               // onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    //label="Username"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your username!',
                                        },
                                    ]}
                                >
                                    <Input
                                        size="large"
                                        placeholder="User name"
                                        data-testid='login-email'
                                        prefix={<UserOutlined />}
                                        style={{ borderRadius: '8px' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    // label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your password!',
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        size="large"
                                        placeholder="Password"
                                        data-testid='login-password'
                                        prefix={<KeyOutlined />}
                                        style={{ borderRadius: '8px' }}
                                    />
                                </Form.Item>
                                <Row>
                                    {
                                        showLoginError
                                            ? (
                                                <Alert
                                                    message="Username/password could be wrong, or you need permissions"
                                                    //banner
                                                    type="warning"
                                                />)
                                            : null
                                    }
                                </Row>
                                <Row justify="space-between" >
                                    <Col>
                                        <Form.Item
                                            name="remember"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Button type="link">Forgot Password ?</Button>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        size="large"
                                        data-testid='login-submit'
                                        style={{ borderRadius: '4px' }}
                                    >
                                        Login
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}