import { Col, Row, Table, Button } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


const UserLogTable = props => {

    const {
        logs,
        onPagniationChange,
        onSortChange,
        hasMore = false,
        onLoadMoreClick,
        isFetchingNextPage,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Date',
            dataIndex: 'Timestamp',
            key: 'Timestamp',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
        },
        {
            title: 'Device',
            dataIndex: 'Device',
            key: 'Device',
        },
        {
            title: 'Ip',
            dataIndex: 'Ip',
            key: 'Ip',
        },
        {
            title: 'Duration',
            dataIndex: 'Elapsed',
            key: 'Elapsed',
        },
        {
            title: 'Data',
            dataIndex: 'Data',
            key: 'Data',
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={logs}
                        onChange={onChangeTable}
                        rowKey={'Id'}
                        pagination={false}
                    />
                </Col>
            </Row>
            {
                hasMore ? (
                    <div className='load-more-container'>
                        <Button
                            loading={isFetchingNextPage}
                            onClick={onLoadMoreClick}
                        >
                            Load More
                        </Button>
                    </div>
                ) : null
            }
        </div>
    );

}


export default UserLogTable;