import { Col, Row, Table, Tooltip, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';


const { Text } = Typography

const NpsResponseTable = props => {

    const {
        logs,
        onPagniationChange,
        onSortChange,
        defaultPageSize,
        pageNo,
        total,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Organisation',
            dataIndex: 'organisation',
            key: 'organisation',
        },
        {
            title: 'Score',
            dataIndex: 'score',
            key: 'score',
            sorter: true,
        },
        {
            title: 'Feedback',
            dataIndex: 'feedback',
            key: 'feedback',
            render: (text, record) => {

                return (
                    <div style={{ maxWidth: 300 }}>
                        {
                            typeof record.feedback === 'string' && record.feedback.trim().length > 1 ?
                                <Tooltip title={record.feedback}>
                                    <Text ellipsis={{
                                        rows: 1,
                                        expandable: false,
                                    }}>
                                        {record.feedback}
                                    </Text>
                                </Tooltip>
                                :
                                '-'
                        }
                    </div>
                )

            }
        },
        {
            title: 'CreatedAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={logs}
                        onChange={onChangeTable}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                            pageSizeOptions: []
                        }}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default NpsResponseTable;