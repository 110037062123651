import { map } from "lodash"
import Item from './item';


export default ({ pinned = [] }) => {

    return (
        <div>
            {
                map(pinned, (item, index) => {
                    //console.log('powered by item', index, item);
                    return (
                        <Item key={item.value} {...item} index={index} />
                    )
                })
            }

        </div>
    )

}