import { Button, Col, Input, Modal, Row, Select, Spin, message } from 'antd';
import { debounce, get, join, map } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import EmptyDisplayComponent from '../../../common/components/Empty';
import OneOrganisationsLookup from '../../../common/components/lookupSelect/OneOrganisationsLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import useUsersPermissionContext from '../../../common/components/permissionContext/useUsersPermissions';
import UnauthorizedComponent from '../../../common/components/unauthorized/UnauthorizedComponent';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import FilterTags from '../../challenge/list/FilterTags';
import UsersTable from './table';
import useUsersFilterContext from './useUsersFilterContext';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';

const { Option } = Select;
const { Search } = Input;

const pageSize = 10;

const userStatuses = [
  { key: 'active', label: 'Active' },
  { key: 'Inactive', label: 'Inactive' },
]

export const userTypesList = [
  { key: 'admin', label: 'Admin' },
  { key: 'admin_user', label: 'Back office user' },
  { key: 'youth', label: 'Youth' },
]

export default () => {

  let navigate = useNavigate();

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useUsersFilterContext();

  const {
    pageNo,
    query,
    organisations = [],
    types = [],
    statuses = [],
    sortBy,
    sortOrder,
  } = searchParams;

  const [searchValue, setSearchValue] = useState(query);

  const {
    httpPostAsync,
    httpDeleteAsync,
    httpGetAsync
  } = useHttpHelper();

  const fetchUsersAsync = async () => {
    // console.log('call api:page param', pageParam, searchCriteria);

    const response = await httpPostAsync(
      appLinks.users,
      {
        itemPerPage: pageSize,
        query,
        pageNo,
        types: map(types, s => s.value),
        organisations: map(organisations, org => org.value),
        statuses: map(statuses, s => s.value),
        sortBy,
        sortOrder
      }
    );

   

    const data = response?.data ?? []

    //const momentNow = moment()

    const mappedData = data.map(e => {

      let appZoneLastActive = undefined
      if (typeof e.lastOpened === 'string') {
        appZoneLastActive = getAppZoneMomentFromUTC(e.lastOpened)
      }

      if (!appZoneLastActive?.isValid()) {
        appZoneLastActive = getAppZoneMomentFromUTC(e.lastLoggedIn)
      }

      let fromNow = ''
      let lastActive = ''
      if (appZoneLastActive?.isValid()) {

        lastActive = appZoneLastActive.format('Do MMMM, YYYY HH:mm')

        //const diffDays = momentNow.diff(appZoneLastActive, 'days')

       // if (diffDays < 1) {
          fromNow = appZoneLastActive.fromNow(false)
       // } else  {
         // fromNow = `${diffDays} ${diffDays > 1 ? 'days' : 'day'} ago` 
        //}

      
      }

      const appZoneRegisteredAt = getAppZoneMomentFromUTC(e.registeredAt, 'YYYY-MM-DD HH:mm:ss')

      let registeredAtDate = ''
      if (appZoneRegisteredAt?.isValid())  {
        registeredAtDate = appZoneRegisteredAt.format('Do MMMM, YYYY HH:mm')
      }
      
      return {
        ...e,
        lastActive,
        fromNow,
        registeredAtDate,
      }

    })

    console.log('users', mappedData)

    return {
      ...(response ?? {}),
      data: mappedData,
    };
  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.users, pageNo, query, sortBy, sortOrder]

    if (organisations.length) {
      const organisationsValues = map(organisations, s => s.value);
      const organisationsList = join(organisationsValues, '.');
      key.push(organisationsList)
    }

    if (types.length) {
      const typesValues = map(types, s => s.value);
      const typesList = join(typesValues, '.');
      key.push(typesList)
    }

    if (statuses.length) {

      const statusValues = map(statuses, s => s.value);
      const statusList = join(statusValues, '.');
      key.push(statusList)

    }

    console.log('filter key', key)

    return key;

  }, [pageNo, query, organisations, types, statuses, sortBy, sortOrder])

  const {
    isLoading,
    //isError,
    //error,
    data = {},
    isFetching,
    //isPreviousData,
    refetch,
  } = useQuery(
    queryKey,
    fetchUsersAsync,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })


  let { data: users = [], total = 0, } = data ?? {}

  const navigateToCreateNew = () => {
    try {

      navigate(`/${appRoutes.dashboard}/${appRoutes.newUser}`)

    } catch (error) {
      console.log('error navigating to create new user', error);
    }
  }

  const onSearch = async (text) => {
    try {

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }));

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onSearch);
  searchUsersRef.current = onSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));


    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  //#region filters

  const onOrganisationChange = (values) => {
    try {

      console.log('values', values)

      setSearchParams(p => ({ ...p, organisations: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on organisation chamge', error)
    }
  }


  const onStatusChange = (values) => {
    try {

      console.log('statuses', values)

      setSearchParams(p => ({ ...p, statuses: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on status change', error);
    }
  }

  //#endregion

  //#region delete

  const [deleteUserId, setDeleteUserId] = useState();

  const mutationQueryKey = [queryKeys.deleteUser, deleteUserId]

  const deleteUserAsync = async (useId) => {
    try {

      const link = appLinks.deleteUser.replace('{userId}', useId);
      const deleteResponse = await httpDeleteAsync(link);

      console.log('delete response', deleteResponse);

      return deleteResponse;

    } catch (error) {
      console.log('error deleting user', error);
    }
  }


  const {
    // data: updatePersonResponse,
    isLoading: isDeleting,
    mutateAsync: onDeleteUserAsync,
  } = useMutation(
    deleteUserAsync,
    { mutationKey: mutationQueryKey, }
  );


  const onDelete = (userId, name) => {
    try {

      console.log('delete user', userId);
      Promise.resolve(setDeleteUserId(userId))

      Modal.confirm({
        title: `Are you sure you want to delete ${name}`,
        okText: 'Delete',
        closable: true,
        okButtonProps: {
          loading: isDeleting,
          disabled: isDeleting,
        },
        onOk: async () => {
          console.log('on delete click');

          const deleteResponse = await onDeleteUserAsync(userId);

          if (deleteResponse && deleteResponse.code === 0) {
            message.success('User was deleted successfully');
          } else {
            message.error('We are facing some issues, user could not be deleted');
          }

          await refetch();

        }

      });

    } catch (error) {
      console.log('error deleting creator', error);
    }
  }

  //#endregion

  const {
    hasListAccess = false,
    //hasDetailAccess = false,
    hasCreateAccess = false,
    hasEditAccess = false,
    hasDeleteAccess = false,
  } = useUsersPermissionContext();

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);
      //searchFieldRef.current.input.value = '';
      // console.log('search ref', searchFieldRef.current)

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  const exportUserResultsAsync = async () => {
    try {

      const {
        //pageNo,
        query,
        organisations = [],
        types = [],
        statuses = [],
        sortBy,
        sortOrder,
      } = searchParams;

      const exportLink = appLinks.downloadUsersList

      const payload = {}

      const getQueryConfig = {
        responseType: 'blob',
        params: {
          query,
          types: types?.map(s => s.value),
          organisations: [...(organisations?.map(org => org.value) ?? [])],
          statuses: statuses?.map(s => s.value),
          sortBy,
          sortOrder
        }
      }

      const response = await httpGetAsync(
        exportLink,
        payload,
        getQueryConfig
      )

      //console.log('download results response', response)

      // create file link in browser's memory
      const href = URL.createObjectURL(response);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'exported-Users.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    } catch (error) {
      console.log('error on export user results', error)
    }
  }

  const onRemoveHOF = (filterProp, filterKey = 'key') => (tag) => {
    try {

      setSearchParams(params => ({
        ...params,
        [filterProp]: params[filterProp]?.filter(e => e[filterKey] !== tag[filterKey]) ?? [],
      }))

    } catch (error) {
      console.log('error removing fitler tag')
    }
  }

  const filterTagItems = useMemo(() => {

    return [
      {
        tags: organisations,
        filterProp: 'organisations'
      },
      {
        tags: types,
        filterProp: 'types'
      },
      {
        tags: statuses,
        filterProp: 'statuses'
      },
    ]

  }, [organisations, types, statuses])

  return (
    <UnauthorizedComponent isAuthorized={hasListAccess}>
      <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
        <Col>
          <Row>
            <Col style={{ paddingRight: 15 }}>
              <OneOrganisationsLookup
                onChange={onOrganisationChange}
                value={mapKeyValues(organisations)}
                style={{ width: 200 }}
                placeholder="Organisation filter"
              />
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Select
                mode="multiple"
                style={{ width: 200 }}
                placeholder="Status filter"
                onChange={onStatusChange}
                labelInValue
                value={mapKeyValues(statuses)}
                optionFilterProp='value'
                tagRender={EmptyDisplayComponent}
              >
                {
                  userStatuses.map(s => {
                    return (
                      <Option key={s.key} value={s.key}>{s.label}</Option>
                    )
                  })
                }
              </Select>
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Search
                placeholder="Search users"
                value={searchValue}
                onChange={onSearchChange}
                allowClear
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            data-testid='create-user'
            disabled={!hasCreateAccess}
            block
            onClick={navigateToCreateNew}
          >
            New User
          </Button>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Button
            type="link"
            size='small'
            //disabled={!hasCreateAccess}
            // block
            onClick={exportUserResultsAsync}
          >
            {`Export sheet ${data?.totalPages > 0 ? '( All pages )' : ''}`}
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <div style={{ marginBottom: 8 }}>
            <FilterTags
              onRemoveHOF={onRemoveHOF}
              items={filterTagItems}
            />
          </div>
        </Col>
        <Col>
          {
            isSearchActive
              ? (
                <Button type="link" size='small' onClick={onReset}>
                  Reset
                </Button>
              )
              : null
          }
        </Col>
      </Row>
      <Spin spinning={isLoading || isFetching || isDeleting}>
        <UsersTable
          key={resetKey}
          users={users}
          onSearch={debouncedSearch}
          onPagniationChange={onPagniationChange}
          onSortChange={onSortChange}
          onCreateNew={navigateToCreateNew}
          onDelete={onDelete}
          pageNo={pageNo}
          total={total}
          defaultPageSize={pageSize}
          hasEditAccess={hasEditAccess}
          hasDeleteAccess={hasDeleteAccess}
        />
      </Spin>
    </UnauthorizedComponent>
  );

}