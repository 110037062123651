import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const challengesFilterConfig = {
    key: 'challenges',
    isActiveProp: 'isChallengesSearchActive',
    setAction: 'challenges',
    resetAction: 'reset-challenges',
    setFilterProp: 'setChallengesFilters',
    resetFilterProp: 'resetChallengesFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        organisations: [],
        difficultyLevels: [],
        durations: [],
        types: [],
        statuses: [],
        themes: [],
        skills: [],
        clubs: [],
        sortBy: null,
        sortOrder: null,
        created: [],
    }
}

const useChallengesFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, challengesFilterConfig)
}

export default useChallengesFilterContext;
