import {
    Collapse,
    Divider,
    Form, Input,
    Modal,
    Select,
    Typography
} from 'antd';
import React, { useEffect } from 'react';
import { HexColorPicker } from 'react-colorful';
import { imageUrls } from '..';
import LinkItems from './LinkItems';
import ParagraphItems from './ParagraphItems';


const { Text } = Typography
const { Option } = Select
const { Panel } = Collapse

const SectionItem = (props) => {

    const { 
        field,
        onSelectUmageUrl,
        getParagraphs,
        getLinks,
        form,
        viewMode,
    } = props;

    const onImageSelect = (e) => {
        console.log('select image')
        onSelectUmageUrl(e.value)
    }


    return  (
        <div  className='section-item-container'>
             <Form.Item
                    {...field}
                    name={[field.name, "imageId"]}
                >
                    <Input type="hidden"/>
                </Form.Item>
            <div>
                <Form.Item
                    label='Title'
                    {...field}
                    name={[field.name, "title"]}
                    //fieldKey={[field.key, 'title']}
                    rules={[{ required: true, message: 'title are required' }]}
                >
                    <Input
                        placeholder="Title"
                    />
                </Form.Item>
            </div>
            <div className='section-image-select-container'>
            <Form.Item
                    {...field}
                    name={[field.name, "imageInfo"]}
                    label="Image Url"
                >
                <Select
                    placeholder="Select Image Url"
                    //mode="multiple"
                    labelInValue
                    //tagRender={Empty}
                    //loading={isLoadingSkills}
                    //disabled={disableSkills}
                    className='section-image-url-select'
                    optionLabelProp="children"
                    onSelect={onImageSelect}
                //allowClear
                >
                    {
                        imageUrls?.map(item => {
                            return (
                                <Option 
                                    key={item.id}
                                    id={item.id}
                                    value={item.value}
                                    >{item.label}</Option>
                            )
                        })
                    }
                </Select>
                </Form.Item>
            </div>
            <div>
                <Form.Item
                    {...field}
                    name={[field.name, "imageUrl"]}
                >
                    <Input
                        placeholder="Image url"
                    />
                </Form.Item>
            </div>
            <Collapse defaultActiveKey={['1']}>
                <Panel header="Text, Links and Background color" key="1">
                <div>
                <ParagraphItems
                    getParagraphs={getParagraphs}
                    field={field}
                    form={form}
                />
            </div>
            <Divider />
            <div>
                <LinkItems
                    getLinks={getLinks}
                    field={field}
                    form={form}
                />
            </div>
            <div className='select-color-container'>
                <Form.Item
                    label="Background Color"
                    {...field}
                    name={[field.name, "color"]}
                    //wrapperCol={{ span: 22 }}
                    valuePropName="color"
                    //getValueFromEvent={normFile}
                    rules={[{ required: true, message: 'Please choose an color' }]}
                >
                  <ColorPicker viewMode={viewMode} />
                </Form.Item>
                <div className='text-input'>
                <Form.Item
                 {...field}
                 name={[field.name, "color"]}
                >
                <Input placeholder="Background Color" />
                </Form.Item>
                </div>
            </div>
                </Panel>
            </Collapse>
        </div>
    )

}

const ColorPicker = (props) => {

    const {
        viewMode,
        color,
        ...rest
    } = props;

    return !viewMode ?
    (
        <HexColorPicker 
            color={color}
            {...rest}
        /> 
    ) 
    :
    (
        <div
           className='color-picker-placeholder'
           style={{
            backgroundColor: color
           }}
        ></div>
    )
    

}



export default SectionItem