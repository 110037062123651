import { Breadcrumb, Button, Row, Spin, message } from 'antd';
import { get } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import CreateEdit from '../common/createEdit';

import { ArrowLeftOutlined } from '@ant-design/icons';
import Dashboard from '../../../common/components/dashboard';


const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;


export default () => {

    let { organisationId } = useParams();

    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useQueryParam('tab', withDefault(StringParam, 'pinned'));

    //console.log('params', params);

    const { httpGetAsync } = useHttpHelper();

    const fetchOrganisationDetailsAsync = async () => {
        try {

            const organisation = await httpGetAsync(appLinks.organisationDetails.replace('{organisationId}', organisationId));

            console.log('organisation details', organisation);

            return organisation;

        } catch (error) {
            console.log('error fetching organisation details', error);
        }
    }

    const setTabParam = (key) => {
        key && setCurrentTab(key)
    }


    const {
        data = {},
        isLoading,
        isSuccess
    } = useQuery(
        [queryKeys.organisationDetailsView, organisationId],
        fetchOrganisationDetailsAsync,
        {
            enabled: organisationId > 0,
            refetchOnWindowFocus: false
        }
    );

    const initialValues = useMemo(() => {

        const organisationId = get(data, 'parentOrganisationId');
        const name = get(data, 'name');
        const type = get(data, 'type');
        const languages = get(data, 'languages');
        const email = get(data, 'email');
        const website = get(data, 'website');
        const isActive = get(data, 'isActive');

        const iconId = get(data, 'logoImageId');
        const iconImageUrl = get(data, 'logoLink');
        const coverImageId = get(data, 'coverImageId');
        const coverImageUrl = get(data, 'imageUrl')

        let iconFiles = [];

        if (iconId && iconImageUrl) {
            iconFiles.push({
                uid: iconId,
                name: 'iconimage',
                status: 'done',
                url: iconImageUrl,
            })
        }

        let coverImageFiles = [];

        if (coverImageId && coverImageUrl) {
            coverImageFiles.push({
                uid: coverImageId,
                name: 'coverImage',
                status: 'done',
                url: coverImageUrl,
            })
        }

        const pinned = get(data, 'pinned') || [];

        const { showPinned = false, showPopular = false } = data;

        return {
            organisationId,
            name,
            type,
            languages,
            email,
            website,
            isActive,
            iconFiles,
            coverImageFiles,
            pinned,
            showPinned,
            showPopular,
        }

    }, [data]);

    const iconId = get(data, 'logoImageId');
    const iconImageUrl = get(data, 'logoLink');
    const coverImageId = get(data, 'coverImageId');
    const coverImageUrl = get(data, 'imageUrl')

    // useEffect(() => {

    //     console.log('creators initialValues', isSuccess, initialValues);

    // }, [initialValues, isSuccess]);

    const { httpPutAsync } = useHttpHelper();

    const udpateOrganisationAsync = async (data) => {
        const link = appLinks.updateOrganisation.replace('{organisationId}', organisationId)
        // console.log('accept challenge link', link);
        console.log('udpate post data', data);
        const organisationData = { ...data };
        const response = await httpPutAsync(link, organisationData);
        console.log('update organisation async', response);

        return response;
    };

    const mutationQueryKey = [queryKeys.updateOrgansation, organisationId]

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateOrganisationAsync,
    } = useMutation(
        udpateOrganisationAsync,
        { mutationKey: mutationQueryKey, }
    );

    const onSubmit = async (postData, redirect = false) => {
        try {

            console.log('on submit', postData);

             const response = await onUpdateOrganisationAsync(postData);

             console.log('response', response);

            if (response && response.code === 0) {
                message.success('Organisation was updated successfully');

                if (redirect) {
                    // save and continue, => redirect to challenges listing
                    navigate(`/${appRoutes.organisations}`);
                } 

            } else if (response && response.code === 12) {
                message.error('Organisation with this email id already exists')
            } else if (response && response.code !== 0) {
                message.error('Organisation could not be updated')
            }

        } catch (error) {
            console.log(' on submit', error);
        }
    }

    const onBack = () => {
        try {

            navigate(`/${appRoutes.organisations}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const name = get(data, 'name');

    return (
        <Dashboard subrouting={false} activeItem='org'>
            <Spin spinning={isLoading || isUpdating}>
                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Organisations</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <ContainerDiv className='form-container-one'>
                    <CreateEdit
                        formValues={initialValues}
                        iconId={iconId}
                        iconImageUrl={iconImageUrl}
                        coverImageId={coverImageId}
                        coverImageUrl={coverImageUrl}
                        onSubmit={onSubmit}
                        submitTitle='Save' 
                        activeTab={currentTab} 
                        setActiveTabParam={setTabParam}
                        id={organisationId}
                    />
                </ContainerDiv>
            </Spin>
        </Dashboard>
    );

}