import CreatorView from './creator';
import OrganisationView from './organisation';
import ClubView from './club';
import TextView from './text';
import { useMemo } from 'react';
import { get } from 'lodash';
import empty from '../../../../../../common/components/Empty';
import { creatorTypes } from '../../../../../../config/constants';


const viewFactory = {
    [creatorTypes.creator]: CreatorView,
    [creatorTypes.organisation]: OrganisationView,
    [creatorTypes.club]: ClubView,
    [creatorTypes.text]: TextView,
}

export default ({ type, ...rest }) => {

    const CreatorComponent = useMemo(() => {
        return get(viewFactory, type, empty);
    }, [type])

    return (<CreatorComponent {...rest} />);

}