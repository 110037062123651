import React, { useEffect } from 'react';
import { get } from 'lodash';
import CreateEdit from '../common/createEdit';


export default ({
    skill = {},
    organisationId,
    form,
    onUpdateAsync
}) => {

    useEffect(() => {

        let value = { title: get(skill, 'title') };

        form.setFieldsValue(value);

    }, [skill]);

    return (
        <CreateEdit
            form={form}
            onSubmitCallback={onUpdateAsync}
            organisationId={organisationId}
        />
    )

}