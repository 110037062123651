import starPanda from '../../../assets/characters/star.svg';
import bunny from '../../../assets/characters/bunny.svg';
import tiger from '../../../assets/characters/tiger.svg';
import racoon from '../../../assets/characters/racoon.svg';
import elephant from '../../../assets/characters/elephant.svg';
import EmptyDisplayComponent from '../Empty';

const StarPanda = (props) => {
    return (
        <img {...props} src={starPanda}/>
    )
};

const Bunny = (props) => {
    return (
        <img {...props} src={bunny}/>
    )
};

const Tiger = (props) => {
    return (
        <img {...props} src={tiger}/>
    )
};

const Racoon = (props) => {
    return (
        <img {...props} src={racoon}/>
    )
};

const Elephant = (props) => {
    return (
        <img {...props} src={elephant}/>
    )
};

const CharacterComponentMap = {
  star: StarPanda,  
  bunny: Bunny,
  tiger: Tiger,
  racoon: Racoon,
  elephant: Elephant,
  default: EmptyDisplayComponent
}

export default CharacterComponentMap;

export { StarPanda };