import { Descriptions, Form, Input, Segmented, Select, Space, Typography } from "antd";
import EmptyDisplayComponent from "../../../../../common/components/Empty";
import SelectTag from "../selectTag";
import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../../config/constants";
import { useQuery } from "react-query";
const { TextArea } = Input
const { Option } = Select
const { Text } = Typography

const ageGroups = [
    { label: '3 - 4', value: '3 to 4' },
    { label: '5 - 6', value: ' 5 to 6' },
    { label: '7 - 8', value: ' 7 to 8' },
]


const shouldUpdate = (prev, cur) => prev.promptAgeGroups !== cur.promptAgeGroups || 
                                    prev.promptMaterials !== cur.promptMaterials || 
                                    prev.prompt !== cur.prompt

const PromptInput = (props) => {

    const {
        materialLookupItems,
        skillLookupItems,
        organisationId,
    } = props;

    const { httpPostAsync } = useHttpHelper()

    // const fetchAreasDataAsync = async (params = {}) => {

    //     const link = appLinks.areasLookups.replace('{organisationId}', organisationId);
    //     const response = await httpPostAsync(link, { ...params });

    //     console.log('areas response', response)

    //     return response?.data ?? [];

    // }

    // const areasOfDevelopmentKeys = [queryKeys.areasLookups, organisationId, undefined];

    // const {
    //     data: areasOfDevelopment = [],
    //     isLoading: isAreasLoading,
    // } = useQuery(
    //     areasOfDevelopmentKeys,
    //     fetchAreasDataAsync,
    //     {
    //         refetchOnWindowFocus: false,
    //         enabled: organisationId > 0
    //     });

    return (
        <div className="prompt-materials-container">
            {/* <Collapse
                // defaultActiveKey={['context']}
                className='prompt-collapse'
            >
                <Panel key="context" header="Prompt context (Work in progress)" >
                    <Descriptions
                        //column={1}
                        title="Prompt Context"
                        bordered
                    >
                        <Descriptions.Item
                            span={3}
                            label="Request context">
                            <Space direction="vertical">
                                <Text type="secondary">You are a child development professional.</Text>
                                <Text type="secondary">You will give parents, profesional advice.</Text>
                                <Text type="secondary">You can use common house hold items and props. (when no materials are provided)</Text>
                                <Text type="secondary">Instruct parents to do a fun activity to do with their children.</Text>
                                <Text type="secondary">Give an introduction for the activity based on the theme provided by the parent, in 30 words.</Text>
                                <Text type="secondary">Give a description of the activity based on the theme provided by the parent,  in about 50 words.</Text>
                                <Text type="secondary">Give a list of steps needed to perform this activity.</Text>
                                <Text type="secondary">Give a list of materials needed for the activity.</Text>
                                <Text type="secondary">Give a list of skills the child will develop from the activity.</Text>
                                <Text type="secondary">Give a list of human values the child will learn from the activity.</Text>
                                <Text type="secondary">Give a list of child developmental areas, the child will develop from the activity.</Text>
                                <Text type="secondary">Explain how the child will learn the chosen values in the activity, include it in response field chosen_values.</Text>
                                <Text type="secondary">Explain how the child will learn the chosen skills in the activity, include it in response field chosen_skills.</Text>
                                <Text type="secondary">Suggest 5 titles for the activity.</Text>
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>
                </Panel>
            </Collapse> */}

            <Form.Item name="prompt">
                <TextArea rows={6} placeholder={`User prompt`} />
            </Form.Item>
            <div className="merge-fields-container">
                <Space direction="horizontal">
                    <Text>merge Fields</Text>
                    <Text type="secondary" copyable>{`{materials}`}</Text>
                    <Text type="secondary" copyable className="agegroup-mergefield">{`{ageGroup}`}</Text>
                </Space>
            </div>
            {/* <Form.Item name="promptSteps">
                <InputNumber min={0} max={20} placeholder="Step count" style={{ minWidth: 120 }} />
            </Form.Item> */}
            <div className="agegroups-container">
                <Form.Item
                    label="Age group"
                    name="promptAgeGroups"
                    wrapperCol={{ span: 16 }}
                >
                    <Segmented options={ageGroups} />
                </Form.Item>
                {/* <SelectTag field='promptSkills' /> */}
            </div>
            <div className="prompt-materials-container">
                <Form.Item
                    label="Suggest Materials"
                    name="promptMaterials"
                    wrapperCol={{ span: 16 }}
                >
                    <Select
                        placeholder={'Select Materials'}
                        labelInValue
                        mode="multiple"
                        data-testid='materials-select'
                        className='prompt-materials-dropdown'
                        optionLabelProp="children"
                        tagRender={EmptyDisplayComponent}
                    >
                        {
                            materialLookupItems?.map(item => {

                                // const isExisting = itemValuesRef.current?.includes(item.value)

                                return (
                                    <Option
                                        key={item.value}
                                        value={item.value}
                                    // disabled={isExisting}
                                    >
                                        {item.label}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <SelectTag field='promptMaterials' />
            </div>
            <div>
            <Text>Final prompt</Text>
            <Form.Item shouldUpdate={shouldUpdate}>
            {(props) => {

                let prompt = props.getFieldValue('prompt') ?? ''
                let materials = props.getFieldValue('promptMaterials') ?? []
                materials = materials.map(e => e.label).join(', ') 
                let ageGroups = props.getFieldValue('promptAgeGroups') ?? ''
                //ageGroups = ageGroups.join(' to ')
                prompt = prompt.replace('{materials}', materials).replace('{ageGroup}', ageGroups) 

                return (
                   <Text type="secondary">{prompt}</Text>
                );
            }}
        </Form.Item>
            </div>
            {/* <div className="prompt-skills-container">
                <Form.Item
                    label="Suggest Skills"
                    name="promptSkills"
                    //rules={[{ required: true, message: 'Please choose an Type.' }]}
                    wrapperCol={{ span: 16 }}
                >
                    <Select
                        placeholder={'Select Skills'}
                        labelInValue
                        mode="multiple"
                        //value={selectedItem}
                        //loading={isLoadingMaterials}
                        //data-testid='materials-select'
                        className='prompt-skills-dropdown'
                        optionLabelProp="children"
                        tagRender={EmptyDisplayComponent}
                    //onSelect={setSelectedItem}
                    >
                        {
                            skillLookupItems?.map(item => {

                                // const isExisting = itemValuesRef.current?.includes(item.value)

                                return (
                                    <Option
                                        key={item.value}
                                        value={item.value}
                                    // disabled={isExisting}
                                    >
                                        {item.label}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <SelectTag field='promptSkills' />
            </div>
            <div className="prompt-areas-container">
                <Form.Item
                    label="Suggest Areas of development"
                    name="promptAreasOfDevelopment"
                    //rules={[{ required: true, message: 'Please choose an Type.' }]}
                    wrapperCol={{ span: 16 }}
                >
                    <Select
                        placeholder={'Select Areas of development'}
                        labelInValue
                        mode="multiple"
                        //value={selectedItem}
                        //loading={isLoadingMaterials}
                        //data-testid='materials-select'
                        className='prompt-areas-dropdown'
                        optionLabelProp="children"
                        tagRender={EmptyDisplayComponent}
                    //onSelect={setSelectedItem}
                    >
                        {
                            areasOfDevelopment?.map(item => {

                                // const isExisting = itemValuesRef.current?.includes(item.value)

                                return (
                                    <Option
                                        key={item.value}
                                        value={item.value}
                                    // disabled={isExisting}
                                    >
                                        {item.label}
                                    </Option>
                                )
                            })
                        }
                    </Select>
                </Form.Item>
                <SelectTag field='promptAreasOfDevelopment' />
            </div> */}
        </div>
    )

}

export default PromptInput;