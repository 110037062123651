
import React from 'react';
import { Col, Row, Space, Avatar, List, Typography, Collapse, Carousel, Image } from 'antd';
import { get, map } from 'lodash';
import { HeartOutlined } from '@ant-design/icons';
import ItemTitle from '../../../common/components/itemTitle';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import config from '../../../config/config';
import UploadItem from '../../reportedItems/List/cards/PostUploads/factory';

const { Text } = Typography;
const { Panel } = Collapse;

const IconText = ({ icon, text }) => (
    <Space>
        {icon && React.createElement(icon)}
        {text}
    </Space>
);

const sharedStatusMap = {
    'shared': 'Shared'
}

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}


export default (props) => {

    const { item = {}, navigateToUser } = props;

    const {
        id,
        title,
        description,
        likes = 0,
        sharedStatus,
        createdAt,
        organisation,
        totalUploads = 0,
        images = [],
        uploads = [],
        userDetails = {},
        activityDetails = {},
        adults,
        children,
    } = item;

    console.log('userDetails', userDetails)

    const createdMomentDate = getAppZoneMomentFromUTC(createdAt);

    const showAdults = !isNaN(adults) && typeof adults === 'number' && adults > 0;
    const showChildren = !isNaN(children) && typeof children === 'number' && children > 0;

    return (
        <List.Item
            key={props.id}
            // actions={[
            //     // <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
            //     <IconText icon={HeartOutlined} text={shares} key="list-vertical-like-o" />,
            //     <IconText text={get(sharedStatusMap, sharedStatus, 'Private')} key="list-vertical-message" />,
            // ]}
            extra={
                <React.Fragment>
                    <Image
                        style={{
                            borderRadius: 4,
                            marginBottom: 12
                        }}
                        width={272}
                        alt={activityDetails.title}
                        src={activityDetails.imageUrl}
                    />
                    <div>
                        <Text>{activityDetails.title}</Text>
                    </div>
                </React.Fragment>
            }
        >
            <List.Item.Meta
                avatar={<Avatar src={userDetails.profileImageUrl} />}
                title={<a nClick={(e) => {
                    e.preventDefault()
                    console.log('test', userDetails)
                    typeof navigateToUser === 'function' && navigateToUser(userDetails.id)
                }}>{userDetails.name}</a>}
            />
            <List.Item.Meta
                title={title}
                description={<Text type="secondary" >{description}</Text>}
            />
            <Row style={{ paddingBottom: 12 }} justify="start">
                <Col style={styles.statItem}>
                    <ItemTitle title="Created" value={createdMomentDate && createdMomentDate.isValid() && createdMomentDate.format(config.displayFormat)} />
                </Col>
                <Col style={styles.statItem}>
                    <ItemTitle title="Organisation" value={organisation} />
                </Col>
            </Row>
            {
                (showChildren || showAdults) ?
                    (
                        <Row style={{ paddingBottom: 12 }} justify="start">
                            <Col style={styles.statItem}>
                                <ItemTitle title="Adults" value={adults ?? ''} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Children" value={children ?? ''} />
                            </Col>
                        </Row>
                    )
                    : null
            }
            <Row>
                <Col style={styles.statItem}>
                    <IconText icon={HeartOutlined} text={likes} key="list-vertical-like-o" />
                </Col>
                <Col style={styles.statItem}>
                    <IconText text={get(sharedStatusMap, sharedStatus, 'Private')} key="list-vertical-message" />
                </Col>
            </Row>
            {
                totalUploads > 0
                    ?
                    <Collapse ghost class="custom-collapse" >
                        <Panel header={`Uploads (${totalUploads})`} key="1">
                            <Carousel>
                                {
                                    map(images, image => {

                                        return (
                                            <Image alt={image.name} src={image.imageUrl} height={240} />
                                        )

                                    })
                                }
                            </Carousel>
                            <Row style={{ paddingTop: 16 }}>
                                <Col>
                                    {
                                        map(uploads, upload => (<UploadItem {...upload} />))
                                    }
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    : null
            }
        </List.Item>
    )


}