import { Form, Input } from "antd"


export default () => {

    return (
        <Form.Item
            name="creatorText"
            label="Creator description"
            //rules={[{ required: true, message: 'Creator desciption required.' }]}
            // /wrapperCol={{ span: 16 }}
        >
          <Input.TextArea showCount maxLength={200} placeholder="Creator description" rows={4} />
        </Form.Item>
    )

}