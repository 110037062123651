import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const skillsFilterConfig = {
    key: 'skills',
    isActiveProp: 'isSkillsSearchActive',
    setAction: 'skills',
    resetAction: 'reset-skills',
    setFilterProp: 'setSkillFilters',
    resetFilterProp: 'resetSkillFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useSkillFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, skillsFilterConfig)
}

export default useSkillFilterContext;
