import { Select } from "antd";

import { appLinks, organisationTypes, queryKeys } from "../../../config/constants";
import EmptyDisplayComponent from "../Empty";
import useLookupsSearch from "./useLookupsSearch";

const { Option } = Select


const OrganisationsLookup = (props) => {

    const { 
        data = [],
        isLoading,
        onSearch,
        onBlur,
    } = useLookupsSearch(
        appLinks.adminOrganisationsV2,
        [queryKeys.foundationOrganisations, organisationTypes.foundation],
        { type: organisationTypes.foundation }
    )

    return (
        <Select
            mode="multiple"
            labelInValue
            tagRender={EmptyDisplayComponent}
            loading={isLoading}
            // search related
            filterOption={false} // needed to pick items updated after search
            showSearch
            showArrow
            onSearch={onSearch}
            onBlur={onBlur}
            {...props}
        >
            {
                data?.map(item => (<Option key={item.value} value={item.value}>{item.label}</Option>))
            }
        </Select>
    )

}

export default OrganisationsLookup;