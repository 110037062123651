import { useMemo } from "react"
import Empty from "../../../../common/components/Empty"
import PostCard from "./PostCard"
import UserCard from "./UserCard"

const reportedType = {
    user: 'user',
    post: 'post'
}

export const cardFactory = {
    [reportedType.post]: PostCard,
    [reportedType.user]: UserCard,
}

export default function CardSelector({item = {}}) {

    const { type } = item;

    const CardComponent = useMemo(() =>  cardFactory[type] ?? Empty, [type]);

    return (CardComponent !== undefined) ? (<CardComponent item={item} />) : null;

}