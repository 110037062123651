import { Breadcrumb, Button, message, Row, Spin } from 'antd';
import { get } from 'lodash';
import React from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useUsersPermissionContext from '../../../common/components/permissionContext/useUsersPermissions';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import CreateView from './CreateView';

import { appLinks, appRoutes, queryKeys } from '../../../config/constants';

import { ArrowLeftOutlined } from '@ant-design/icons';
import UnauthorizedComponent from '../../../common/components/unauthorized/UnauthorizedComponent';


const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;


export default (props) => {

    const navigate = useNavigate();

    const onBack = () => {
        try {

            navigate(`/${appRoutes.dashboard}/${appRoutes.users}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const { hasCreateAccess = false } = useUsersPermissionContext();

    const { httpPostAsync } = useHttpHelper();

    const onCreateUserAsync = async (data) => {
        try {

            const link = appLinks.createUser
            // console.log('accept challenge link', link);
            console.log('udpate post data', data);
            const createPersonData = { ...data };
            const response = await httpPostAsync(link, createPersonData);
            console.log('update user async', response);

            return response;

        } catch (error) {
            console.log('error creating user', error);
        }
    }

    const mutationQueryKey = [queryKeys.createUser]

    const {
        //data: createPersonResponse,
        isLoading: isCreatingPerson,
        mutateAsync: onCreateUser,
    } = useMutation(
        onCreateUserAsync,
        { mutationKey: mutationQueryKey, }
    );


    const onSubmit = async (postData, redirect = false) => {
        try {

            console.log('on submit', postData);

            const response = await onCreateUser(postData);

            if (response && response.code === 0) {
                message.success('User was created successfully');

                if (redirect) {
                    // save and continue, => redirect to challenges listing
                    navigate(`/${appRoutes.dashboard}/${appRoutes.users}`);
                } else {
                    //save/create new => created new and redirect to edit
                    const userId = get(response, 'data.id');
                    if (userId > 0) {
                        navigate(`/${appRoutes.dashboard}/${appRoutes.editUser}/${userId}`)

                    }
                }


            } else if (response && response.code === '400.1.9') {
                message.warning('User with this email already exits');
            } else if (response && response.code !== 0) {
                message.error('User could not be created');
            }

        } catch (error) {
            console.log('error on submit', error);
        }
    }

    return (
        <UnauthorizedComponent isAuthorized={hasCreateAccess}>
            <Row align="middle" style={{ paddingBottom: 8 }}>
                <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                    <Breadcrumb.Item><a onClick={onBack}>Users</a></Breadcrumb.Item>
                    <Breadcrumb.Item>New</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <ContainerDiv className='form-container-one'>
                <Spin spinning={isCreatingPerson}>
                    <CreateView
                        //formValues={initialValues}
                        onSubmit={onSubmit}
                        submitTitle='Save' />
                </Spin>
            </ContainerDiv>
        </UnauthorizedComponent>
    )

}