import { useMemo } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
const { Text } = Typography;


const ListTable = props => {

    const {
        items,
        onPagniationChange,
        onSortChange,
        pageNo,
        total,
        defaultPageSize,
        sortBy,
        sortOrder,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => {

        console.log('col', sortBy, sortOrder)

        let sentOnCol = { defaultSortOrder: null };

        const defaultSortOrder = sortOrder === 'ascend' ? 'ascend' : 'descend';

        if (sortBy === 'createdAt') {
            sentOnCol = { defaultSortOrder }
        }

        return [
            {
                title: 'Sent on',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                ...sentOnCol,
            },
            {
                title: 'User',
                dataIndex: 'user',
                key: 'user',
                // sorter: true,
            },
            {
                title: 'Organisation',
                dataIndex: 'organisation',
                key: 'organisation',
                // sorter: true,
                // ...organistionCol,
            },
            {
                title: 'Message',
                dataIndex: 'message',
                key: 'message',
                ellipsis: true,
            },
        ]
    }, [navigate, sortBy, sortOrder]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }

    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={items}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            total,
                        }}
                        onChange={onChangeTable}
                        rowKey={item => `${item.id}`}
                        expandable={{
                            expandedRowRender: record => {
                                return (
                                    <div>
                                        <Text style={{ margin: 0 }}>{record.createdAt}</Text>
                                        <p style={{ margin: 0 }}>{record.message}</p>
                                    </div>
                                )
                            },
                            rowExpandable: record => !!record.id,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default ListTable;