import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const oneKidsFilterConfig = {
    key: 'onekidsPosts',
    isActiveProp: 'isOneKidsPostsSearchActive',
    setAction: 'onekids-posts',
    resetAction: 'reset-onekids-posts',
    setFilterProp: 'setOneKidsPostsFilters',
    resetFilterProp: 'resetOneKidsPostsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const usePostFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, oneKidsFilterConfig)
}

export default usePostFilterContext;
