import { Col, Row, Spin, Input, Button, Select, Modal, message } from 'antd';
import { debounce, filter, get, join, map } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AppLayout from '../../../common/components/layout';
import useClubPermissionContext from '../../../common/components/permissionContext/useClubsPermission';
import TagChip from '../../../common/components/tagchip';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import OrganisationsTable from './clubTable';
import useClubsFilterContext from './useFilterContext';
import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import Dashboard from '../../../common/components/dashboard';


const { Option } = Select;
const { Search } = Input;

const pageSize = 10;

const clubStatuses = [
  { key: 'inactive', label: 'Inactive' },
  { key: 'active', label: 'Active' },
]

export default () => {

  let navigate = useNavigate();

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useClubsFilterContext();

  const {
    pageNo,
    query,
    organisations = [],
    statuses = [],
    sortBy,
    sortOrder,
  } = searchParams;

  const [searchValue, setSearchValue] = useState(query);

  const { httpPostAsync, httpDeleteAsync } = useHttpHelper();

  const fetchClubs = async () => {
    // console.log('call api:page param', pageParam, searchCriteria);

    const response = await httpPostAsync(
      appLinks.clubs,
      {
        itemPerPage: pageSize,
        query,
        pageNo,
        organisationIds: map(organisations, s => s.value),
        statuses: map(statuses, s => s.value),
        sortBy,
        sortOrder
      }
    );

    return response;
  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.creatorsList, pageNo, query, sortBy, sortOrder]

    if (organisations.length) {
      const organisationValues = map(organisations, s => s.value);
      const organisationList = join(organisationValues, '.');
      key.push(organisationList)
    }


    if (statuses.length) {

      const statusValues = map(statuses, s => s.value);
      const statusList = join(statusValues, '.');
      key.push(statusList)

    }

    console.log('filter key', key)

    return key;

  }, [pageNo, query, organisations, statuses, sortBy, sortOrder])

  const {
    isLoading,
    //isError,
    //error,
    data = {},
    isFetching,
    isPreviousData,
    refetch,
  } = useQuery(
    queryKey,
    fetchClubs,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })



  let { data: clubs = [], total = 0, } = data

  const navigateToCreateNew = () => {
    try {

      navigate(`/${appRoutes.newClub}`)

    } catch (error) {
      console.log('error navigating new club', error);
    }
  }

  const onSearch = async (text) => {
    try {

      //console.log('search users', text);

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }))

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onSearch);
  searchUsersRef.current = onSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));


    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  //#region filters

  const onOrganisationChange = (values) => {
    try {

      console.log('values', values)

      setSearchParams(p => ({ ...p, organisations: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on organisation chamge', error)
    }
  }


  const onStatusChange = (values) => {
    try {

      console.log('statuses', values)

      setSearchParams(p => ({ ...p, statuses: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on status change', error);
    }
  }


  //#endregion

  //#region delete

  const [deleteClubId, setDeleteClubId] = useState();

  const mutationQueryKey = [queryKeys.deleteClub, deleteClubId]

  const deleteClubAsync = async (creatorId) => {
    try {

      const link = appLinks.deleteClub.replace('{clubId}', creatorId);
      const deleteResponse = await httpDeleteAsync(link);

      console.log('delete response', deleteResponse);

      return deleteResponse;

    } catch (error) {
      console.log('error deleting club', error);
    }
  }

  const {
    // data: updatePersonResponse,
    isLoading: isDeleting,
    mutateAsync: onDeleteClubAsync,
  } = useMutation(
    deleteClubAsync,
    { mutationKey: mutationQueryKey, }
  );


  const onDelete = (clubId, name) => {
    try {

      console.log('delete club', clubId);
      Promise.resolve(setDeleteClubId(clubId))

      Modal.confirm({
        title: `Are you sure you want to delete ${name}`,
        okText: 'Delete',
        closable: true,
        okButtonProps: {
          loading: isDeleting,
          disabled: isDeleting,
        },
        onOk: async () => {
          console.log('on delete click');

          const deleteResponse = await onDeleteClubAsync(clubId);

          if (deleteResponse && deleteResponse.code === 0) {
            message.success('Club was deleted successfully');
          } else {
            message.error('We are facing some issues, club could not be deleted');
          }

          await refetch();

        }

      });

    } catch (error) {
      console.log('error deleting club', error);
    }
  }

  //#endregion

  const {
    //hasListAccess = false,
    //hasDetailAccess = false,
    hasCreateAccess = false,
    //hasEditAccess = false,
    //hasDeleteAccess = false,
  } = useClubPermissionContext();

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  const [tagsVisible, setTagsVisible] = useState(false);

  useEffect(() => {

    const searchTagsLen = organisations.length + statuses.length;

    setTagsVisible(searchTagsLen > 0)

  }, [organisations, statuses])

  const styles = useMemo(() => ({
    itemStyle: {
      paddingRight: 16,
      paddingBottom: 18,
    },
    tagsContainer: {
      paddingTop: tagsVisible ? 16 : 0,
      paddingBottom: tagsVisible ? 8 : 0

    },
    challengeTableContainer: {
      paddingTop: tagsVisible ? 0 : 17
    }
  }), [tagsVisible])

  return (
    <Dashboard subrouting={false} activeItem='clubs'>
      <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
        <Col>
          <Row>
            <Col style={{ paddingRight: 15 }}>
              <OrganisationsLookup
                onChange={onOrganisationChange}
                value={mapKeyValues(organisations)}
                style={{ width: 200 }}
                placeholder="Organisation filter"
              />
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Select
                mode="multiple"
                //allowClear
                style={{ width: 200 }}
                placeholder="Status filter"
                onChange={onStatusChange}
                labelInValue
                value={map(statuses, s => ({ value: s.value, label: s.label }))}
                tagRender={() => null}
                optionFilterProp='label'
              >
                {
                  map(clubStatuses, s => {
                    return (
                      <Option key={s.key} value={s.key} label={s.label}>{s.label}</Option>
                    )
                  })
                }
              </Select>
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Search
                placeholder="Search clubs"
                onChange={onSearchChange}
                value={searchValue}
                allowClear
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type="primary"
            block
            data-testid='create-club'
            disabled={!hasCreateAccess}
            onClick={navigateToCreateNew}
          >
            New Club
          </Button>
        </Col>
      </Row>
      <Row style={styles.tagsContainer} justify="space-between">
        <Col>
          <div style={{ marginBottom: 8 }}>
            {map(organisations, org => {

              return (
                <TagChip key={org.key} closable onClose={() => {
                  setSearchParams(p => {

                    //console.log('key, organisations', org.key, typeof org.key, p.organisations)

                    const filtered = filter(p.organisations, o => o.key !== org.key);

                    //console.log('filtered organisations', filtered)

                    return {
                      ...p,
                      organisations: filtered,
                    }

                  })
                }}>
                  {org.label}
                </TagChip>
              )

            })}
            {
              map(statuses, status => {

                return (
                  <TagChip key={status.key} closable onClose={() => {
                    setSearchParams(p => {

                      const filtered = filter(p.statuses, s => s.key !== status.key);

                      console.log('filtered statuses', filtered)

                      return {
                        ...p,
                        statuses: filtered,
                      }

                    })
                  }}>
                    {status.label}
                  </TagChip>
                )

              })
            }
          </div>
        </Col>
        <Col>
          {
            isSearchActive
              ? (
                <Button type="link" size='small' onClick={onReset}>
                  Reset
                </Button>
              )
              : null
          }
        </Col>
      </Row>
      <Spin spinning={isLoading || isFetching || isDeleting}>
        <OrganisationsTable
          key={resetKey}
          clubs={clubs}
          onSearch={debouncedSearch}
          onPagniationChange={onPagniationChange}
          onSortChange={onSortChange}
          onCreateNew={navigateToCreateNew}
          onDelete={onDelete}
          pageNo={pageNo}
          total={total}
          defaultPageSize={pageSize}
        />
      </Spin>
    </Dashboard>
  );

}