import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Upload, Switch, Progress, Tabs } from 'antd';
import { appLinks, queryKeys, organisationTypes } from '../../../config/constants';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import axios from 'axios';
import { useQuery } from 'react-query';
import { map } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import { ReactComponent as UserProfileDefault } from '../../../assets/userProfile.svg';
import { organisationTypesList } from '../list';
import Pinned from './Pinned';


const { Option } = Select;
const { TabPane } = Tabs;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const languages = [
    { key: 'en', label: 'English' },
    { key: 'ro', label: 'Romanian' },
]

const styles = {
    formRow: { paddingRight: 24 }
}

export default (props) => {

    const {
        onSubmit,
        formValues = {},
        submitTitle = '',
        iconId: passedIconId,
        iconImageUrl: passedIconImageUrl,
        coverImageId: passedCoverImageId,
        coverImageUrl: passedCoverImageUrl,
        disableEmail = false,
        activeTab = 'pinned',
        setActiveTabParam,
        id,
    } = props;

    //const navigate = useNavigate();

    const [form] = Form.useForm();

    const shouldRedirectOnSubmitRef = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    const { httpPostAsync } = useHttpHelper();

    //#region  fetch organisations list
    const fetchOrganisationsAsync = async () => {
        try {

            const organisations = await httpPostAsync(appLinks.adminOrganisationsV2, { type: organisationTypes.foundation });

            console.log('organisations', organisations);


            return organisations;


        } catch (error) {
            console.log('error fetching organisations', error);
        }
    }

    const queryKey = [queryKeys.foundationOrganisations, organisationTypes.foundation]

    const {
        data: organisations = [],
        isLoading: isLoadingOrganisations,
    } = useQuery(queryKey, fetchOrganisationsAsync, { refetchOnWindowFocus: false });
    //#endregion

    //#region upload files

    //const [iconFileList, setIconFileList] = useState([]);
    const [isIconUploading, setIsIconUploading] = useState();
    const [iconUploadProgress, setIconUploadProgress] = useState(0);
    const [iconId, setIconId] = useState();
    const [iconImageUrl, setIconImageUrl] = useState();


    const [isCoverImageUploading, setIsCoverImageUploading] = useState();
    const [coverImageProgress, setCoverImageProgress] = useState(0);
    const [coverImageId, setCoverImageId] = useState();
    const [coverImageUrl, setCoverImageUrl] = useState();


    const normFile = (e) => {

        console.log('Upload event:', e);

        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList;
    };
    //#region 

    const [pinnedCount, setPinnedCount] = useState(0);

    useEffect(() => {

        console.log('set field values', formValues);

        form.setFieldsValue(formValues);

        if (formValues.pinned) {
            setPinnedCount(formValues.pinned.length || 0)
        }

    }, [formValues])

    useEffect(() => {

        setIconId(passedIconId);
        setIconImageUrl(passedIconImageUrl);
        setCoverImageId(passedCoverImageId);
        setCoverImageUrl(passedCoverImageUrl);

        console.log('setting image urls')

    }, [passedIconId, passedIconImageUrl, passedCoverImageId, passedCoverImageUrl])


    const onSubmitHandler = async (values) => {
        try {

            console.log('on submit values', values);


            if (typeof onSubmit !== 'function') {
                return;
            }

            const {
                email,
                name,
                isActive,
                organisationId,
                showInListing,
                website,
                type,
                languages,
                showPinned,
                pinned = [],
                showPopular,
            } = values;



            let orderedPinned = []

            for (let i = 0; i < pinned.length; i++) {
                orderedPinned.push({
                    value: pinned[i].value,
                    sortOrder: i,
                    id: pinned[i].id,
                })
            }

            console.log('pinned', pinned, 'orderedPinned', orderedPinned);

            const postData = {
                email,
                name,
                isActive,
                organisationId,
                website,
                showInListing,
                type,
                languages: map(languages, language => language.value),
                logoImageId: iconId,
                coverImageId,
                showPinned,
                pinned: orderedPinned,
                showPopular,
            }

            await Promise.resolve(setIsSaving(true));

            await onSubmit(postData, shouldRedirectOnSubmitRef.current);

            setIsSaving(false);

        } catch (error) {
            console.log('error submiting', error);
        }
    }


    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}
                >
                    <Form
                        layout='vertical'
                        form={form}
                        //initialValues={initialValues}
                        //onValuesChange={onFormLayoutChange}
                        scrollToFirstError
                        //onFinishFailed={onSubmitError}
                        onFinish={onSubmitHandler}
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Logo approx width: 1092 to 3000 X height: 900"
                                    name="iconFiles"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    wrapperCol={{ span: 22 }}
                                    rules={[{ required: true, message: 'Please choose an logo' }]}
                                >
                                    <Upload
                                        name="avatar"
                                        //listType="picture-card"
                                        className="avatar-uploader"
                                        showUploadList={false}
                                        //action={appLinks.uploadOrganisationImagesUrl}
                                        customRequest={async (e) => {
                                            console.log('custom upload request', e);

                                            const fileInfo = {
                                                //name, size, mimeType
                                            }

                                            const uploadLinkResponse = await httpPostAsync(appLinks.uploadOrganisationImagesUrl)

                                            console.log('get upload link response', uploadLinkResponse, uploadLinkResponse.uploadUrl);

                                            // const { uploadUrl } = uploadLinkResponse | {};

                                            if (!uploadLinkResponse.uploadUrl) {
                                                console.log('no upload rul')
                                                return;
                                            }


                                            const iconUrl = await getBase64(e.file);
                                            //e.file.thumbUrl = iconUrl;
                                            //console.log('icon url', iconUrl);
                                            setIconImageUrl(iconUrl);
                                            setIsIconUploading(true);
                                            setIconUploadProgress(0);

                                            await axios.put(
                                                uploadLinkResponse.uploadUrl,
                                                e.file,
                                                {
                                                    //headers: { ContentType: imageBlob.type },
                                                    transformRequest: (d) => d,
                                                    onUploadProgress: progressEvent => {
                                                        console.log('progress init')


                                                        const progressPercent = Math.round(
                                                            (progressEvent.loaded / progressEvent.total) * 100
                                                        );
                                                        const isUploaded = progressEvent.loaded === progressEvent.total;

                                                        //console.log('uplaod progress', progressPercent, isUploaded)
                                                        console.log('progress percentage parameters', progressEvent.loaded, progressEvent.total)

                                                        setIconUploadProgress(progressPercent);

                                                        if (isUploaded) {
                                                            const { documentId } = uploadLinkResponse;
                                                            //console.log('on success', e.data)
                                                            setIconId(documentId)
                                                            setIsIconUploading(false)
                                                            e.onSuccess(e.data)
                                                        }


                                                    },
                                                }
                                            );

                                        }}
                                    //beforeUpload={beforeUpload}
                                    //onChange={this.handleChange}
                                    >
                                        {
                                            (iconImageUrl && !isIconUploading)
                                                ? <React.Fragment>
                                                    <div>
                                                        <Row style={{ paddingBottom: '15px' }} >
                                                            <img src={iconImageUrl} alt="avatar" style={{ width: 120, height: 130, borderRadius: '8px' }} />
                                                        </Row>
                                                        <Row >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Row>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                (
                                                    <div>
                                                        {
                                                            isIconUploading
                                                                ? <React.Fragment><Progress size="small" type="circle" width={40} percent={iconUploadProgress} /><div>...</div></React.Fragment>
                                                                : (<React.Fragment>
                                                                    <Row justify="start" style={{ paddingBottom: 12 }}>
                                                                        <UserProfileDefault height={130} width={120} style={{ borderRadius: 8 }} />
                                                                    </Row>
                                                                    <Row>
                                                                        <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                                    </Row>
                                                                </React.Fragment>
                                                                )
                                                        }
                                                    </div>
                                                )
                                        }
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="organisationId"
                                    label="Parent Organisation"
                                    //rules={[{ required: true, message: 'Please choose an organisation.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select Organisation"
                                        //onChange={onGenderChange}
                                        loading={isLoadingOrganisations}
                                        allowClear
                                    >
                                        {
                                            map(organisations, org => {
                                                return (
                                                    <Option key={org.value} value={org.value}>{org.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Name is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{ type: 'email', message: 'Email seems to be invalid' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input disabled={disableEmail} placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    label="Website"
                                    name="website"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Website" />
                                </Form.Item>
                                <Form.Item
                                    label="Active"
                                    name="isActive"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch />
                                </Form.Item>
                                <Form.Item
                                    label="Show popular list"
                                    name="showPopular"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch />
                                </Form.Item>
                                {/* <Form.Item
                                    label="Show in Listing"
                                    name="showInListing"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch disabled />
                                </Form.Item> */}
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Cover Image (1920 X 1080)"
                                    name="coverImageFiles"
                                    wrapperCol={{ span: 22 }}
                                    rules={[{ required: true, message: 'Please choose an cover image' }]}
                                >
                                    <Upload
                                        name="coverimage"
                                        //listType="picture-card"
                                        className="avatar-uploader"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}

                                        showUploadList={false}
                                        //action={appLinks.uploadOrganisationImagesUrl}
                                        customRequest={async (e) => {
                                            console.log('custom upload request', e);

                                            //const fileInfo = {
                                            //name, size, mimeType
                                            //}
                                            // todo need to pass file upload info, like size, name and mime type
                                            const uploadLinkResponse = await httpPostAsync(appLinks.uploadOrganisationImagesUrl)

                                            console.log('get upload link response', uploadLinkResponse, uploadLinkResponse.uploadUrl);

                                            // const { uploadUrl } = uploadLinkResponse | {};

                                            if (!uploadLinkResponse.uploadUrl) {
                                                console.log('no upload rul')
                                                return;
                                            }

                                            const iconUrl = await getBase64(e.file);
                                            setCoverImageUrl(iconUrl);
                                            setIsCoverImageUploading(true)
                                            setCoverImageProgress(0)

                                            await axios.put(
                                                uploadLinkResponse.uploadUrl,
                                                e.file,
                                                {
                                                    //headers: { ContentType: imageBlob.type },
                                                    transformRequest: (d) => d,
                                                    onUploadProgress: progressEvent => {
                                                        console.log('progress init')


                                                        const progressPercent = Math.round(
                                                            (progressEvent.loaded / progressEvent.total) * 100
                                                        );
                                                        const isUploaded = progressEvent.loaded === progressEvent.total;

                                                        //console.log('uplaod progress', progressPercent, isUploaded)
                                                        console.log('progress percentage parameters', progressEvent.loaded, progressEvent.total)
                                                        setCoverImageProgress(progressPercent)
                                                        if (isUploaded) {
                                                            const { documentId } = uploadLinkResponse;
                                                            //console.log('on success', e.data)
                                                            setCoverImageId(documentId);
                                                            setIsCoverImageUploading(false);
                                                            e.onSuccess(e.data)
                                                        }


                                                    },
                                                }
                                            );

                                        }}
                                    >
                                        {
                                            (coverImageUrl && !isCoverImageUploading)
                                                ? <React.Fragment>
                                                    <div>
                                                        <Row style={{ paddingBottom: '15px' }} >
                                                            <img src={coverImageUrl} alt="avatar" style={{ width: 280, height: 180, borderRadius: '8px' }} />
                                                        </Row>
                                                        <Row >
                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                        </Row>
                                                    </div>
                                                </React.Fragment>
                                                :
                                                (
                                                    <div>
                                                        {
                                                            isCoverImageUploading
                                                                ? <React.Fragment><Progress size="small" type="circle" width={40} percent={coverImageProgress} /><div>...</div></React.Fragment>
                                                                : (<React.Fragment>
                                                                    <div>
                                                                        <Row style={{ paddingBottom: '15px' }}>
                                                                            <CoverImageDefault height={180} width={280} style={{ borderRadius: 8 }} />
                                                                        </Row>
                                                                        <Row justify="start">
                                                                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                                                                        </Row>
                                                                    </div>
                                                                </React.Fragment>)
                                                        }
                                                    </div>
                                                )
                                        }
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    label="Type"
                                    name="type"
                                    rules={[{ required: true, message: 'Please choose an Type.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select Type"
                                        //onChange={onGenderChange}
                                        loading={isLoadingOrganisations}
                                        allowClear
                                    >
                                        {
                                            map(organisationTypesList, s => {
                                                return (
                                                    <Option key={s.key} value={s.key}>{s.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                {/* <Form.Item
                                    label="Languages"
                                    name="languages"
                                    //rules={[{ required: true, message: 'Please choose an Type.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select Languages"
                                        mode="multiple"
                                        labelInValue
                                        //onChange={onGenderChange}
                                        loading={isLoadingOrganisations}
                                        allowClear
                                    >
                                        {
                                            map(languages, s => {
                                                return (
                                                    <Option key={s.key} value={s.key}>{s.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item> */}
                            </Col>
                        </Row>
                        {
                            (id && id > 0)
                                ? (
                                    <Row style={styles.formRow} >
                                        <Col span={24}>
                                            <Tabs
                                                defaultActiveKey={activeTab}
                                                onChange={key => {
                                                    setActiveTabParam && setActiveTabParam(key);
                                                }}
                                                activeKey={activeTab}
                                            >
                                                <TabPane
                                                    tab={`Pinned ${pinnedCount > 0 ? ` (${pinnedCount})` : ''}`}
                                                    key="pinned"
                                                    forceRender
                                                >
                                                    <Pinned
                                                        form={form}
                                                        setPrequisiteCount={setPinnedCount}
                                                        organisationId={id}
                                                    />
                                                </TabPane>
                                            </Tabs>
                                        </Col>
                                    </Row>
                                )
                                : null
                        }
                        <Row>
                            <Col span={2}>
                                <Form.Item >
                                    <Button
                                        type="primary"
                                        data-testid="organisation-save"
                                        disabled={isSaving}
                                        loading={isSaving}
                                        onClick={() => {
                                            try {

                                                shouldRedirectOnSubmitRef.current = false;
                                                form.submit();

                                            } catch (error) {
                                                console.log('error on form save', error);
                                            }
                                        }}
                                    >{submitTitle}</Button>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item >
                                    <Button
                                        type="primary"
                                        data-testid="organisation-save-continue"
                                        disabled={isSaving}
                                        loading={isSaving}
                                        onClick={() => {
                                            try {

                                                shouldRedirectOnSubmitRef.current = true;
                                                form.submit();

                                            } catch (error) {
                                                console.log('error on form save', error);
                                            }
                                        }}
                                    >Save and continue</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );

}