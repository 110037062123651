import { Col, Row, Table, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../config/constants';

const { Text } = Typography;

// const statusToColorMapper = {
//     active: 'green',
//     default: '#d9d9d9'
// }

// const statusToTooltipMapper = {
//     active: 'Active',
//     default: 'Not active'
// }


const UsersTable = props => {

    const {
        users,
        //onSearch,
        onPagniationChange,
        onSortChange,
        //onCreateNew,
        pageNo,
        total,
        defaultPageSize,
        onDelete,
        hasEditAccess = false,
        hasDeleteAccess = false,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                if (record.canView) {
                    return (
                        <a
                            onClick={() => {
                                //console.log('on click', record)
                                navigate(`/${appRoutes.dashboard}/${appRoutes.userView}/${record.id}`)
                            }}
                        >
                            {record.name}
                        </a>
                    )
                } else {
                    return (
                        <Text>
                            {record.name}
                        </Text>
                    )
                }
            },
        },
        {
            title: 'Email address',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: 'Organisation',
            dataIndex: 'organisationText',
            key: 'organisationText',
        },
        {
            title: 'Type',
            dataIndex: 'accountType',
            key: 'accountType',
        },
        {
            title: 'Registered On',
            dataIndex: 'registeredAt',
            key: 'registeredAt',
            sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, { registeredAtDate, id }) => {


                return (
                    <Text>
                        {registeredAtDate}
                    </Text>)


            },
        },
        {
            title: 'Last active',
            dataIndex: 'lastActive',
            key: 'lastActive',
            //sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, { lastActive, id }) => {


                return (
                    <Text>
                        {lastActive}
                    </Text>)


            },
        },
        {
            title: 'Since',
            dataIndex: 'fromNow',
            key: 'fromNow',
            //sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, { fromNow, id }) => {


                return (
                    <Text>
                        {fromNow}
                    </Text>)


            },
        },
        //  {
        //     title: 'organisation',
        //     dataIndex: 'organisations',
        //     key: 'organisations',
        //     render: (text, record) => {

        //         return (
        //             <div>
        //                 {
        //                     map(record.organisations, org => {

        //                         return (
        //                             <Tooltip title={get(statusToTooltipMapper, org.status, statusToTooltipMapper.default)}>
        //                                 <Text>
        //                                     {org.name}
        //                                 </Text>
        //                                 <Badge color={get(statusToColorMapper, org.status, statusToColorMapper.default)} />
        //                             </Tooltip>
        //                         )

        //                     })
        //                 }
                  
        //             </div>
        //         )
        //     },
        // },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {

                return (
                    <Text>
                        {record.status === 'active' ? 'Active' : 'Inactive'}
                    </Text>
                )
            },
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return (
                    <a 
                    data-testid='edit-user'
                    style={hasEditAccess && record.canEdit  ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                    //style={{ color: 'grey', cursor: 'not-allowed'}} 
                    data-can-edit={hasEditAccess && record.canEdit ? 'yes' : ''}
                    onClick={() => {
                        console.log('on click', record)
                        hasEditAccess && record.canEdit && navigate(`/${appRoutes.dashboard}/${appRoutes.editUser}/${record.id}`)
                    }}
                    >
                        Edit
                    </a>
                )
            },
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return (
                    <a 
                    data-testid='delete-user'
                    style={hasDeleteAccess && record.canDelete ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                    //style={{ color: 'grey', cursor: 'not-allowed'}} 
                    data-can-delete={hasDeleteAccess && record.canDelete ? 'yes' : ''} 
                    onClick={() => {
                        //console.log('on click', record)
                        //navigate(`/${appRoutes.creatorPersonEdit}/${record.id}`)
                        hasDeleteAccess && record.canDelete && onDelete && onDelete(record.id, record.name)
                    }}>
                        Delete
                    </a>
                )
            },
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

          // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={users}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                            pageSizeOptions: []
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default UsersTable;