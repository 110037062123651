import { useCallback, useMemo } from "react";
import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../../config/constants";


export default function useCreators(organisationId, includeInfo = false) {

    const { httpPostAsync } = useHttpHelper();

    const creatorsParams = useMemo(() => {

        let params = {};

        if (organisationId && organisationId > 0) {
            params = { ...params, organisationId }
        }

        if (!!includeInfo) {
            params = { ...params, includeInfo: true }
        }

        return params;

    }, [organisationId, includeInfo])

    const fetchCreatorsAsync =
        useCallback(async (params = {}) => await httpPostAsync(appLinks.creatorsLookups, { ...params }), [httpPostAsync]);

    const enableCretorsFetchFn = useCallback(({ organisationId = 0 }) => organisationId > 0, []);

        const creatorsQueryKeyFn = useCallback(({ organisationId, includeInfo}) => {

            let key = [queryKeys.creatorLookup, organisationId, includeInfo ];

            console.log('creator persons query key ', key)
    
            return key
        }, []);

    return {
        creatorsParams,
        fetchCreatorsAsync,
        enableCretorsFetchFn,
        creatorsQueryKeyFn
    }

}