import { useMemo } from "react";
import { find, get, map } from "lodash";
import TagChip from "../../../common/components/tagchip";


export default (props) => {

    const { organisationId, onRemoveRecipient } = props;

    const organisationItems = props.getFieldValue('organisationItems');

    const receipients = useMemo(() => {
        try {

            const item = find(organisationItems, o => o.value === organisationId);

            return get(item, 'receipients') || [];

        } catch (error) {
            console.log('error getting receipients list', error);
            return []
        }

    }, [organisationId, organisationItems]);


    return (
        <div>
            {
                map(receipients, item => {
                    return (
                        <TagChip
                            key={item.value}
                            closable
                            onClose={() => onRemoveRecipient(item.value)}>
                            {item.label}
                        </TagChip>
                    )
                })
            }
        </div>)
}