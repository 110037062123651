import { useCallback, useRef, useState } from "react";
import useHttpHelper from "../../hooks/useHttpHelper";
import { useQuery } from "react-query";
import { debounce } from "lodash";

const useLookupsSearch = (
    link, 
    queryKeyItems = [],
    extraParams = {}, 
    dataMapper,
    enabled = true,
    ) => {

    const [queryFilter, setQueryFilter] = useState({ query: '' })

    const { httpPostAsync } = useHttpHelper();

    const fetchItemsAsync = async () => {
        try {

            const queryParams = {
               ...extraParams,
                ...(queryFilter ?? {})
            }

            let items = await httpPostAsync(link, queryParams);

            items = typeof dataMapper === 'function' ? dataMapper(items) : items

            return Array.isArray(items) ? items : []

        } catch (error) {
            console.log('error fetching organisations', error);
        }
    }

    const queryKey = [queryFilter.query, ...queryKeyItems]

    const {
        data = [],
        isLoading,
    } = useQuery(
        queryKey, 
        fetchItemsAsync, 
        { 
            refetchOnWindowFocus: false,
            enabled
        });

    const queryFilterRef = useRef(queryFilter)
    queryFilterRef.current = queryFilter

    // organisation dropdown search 
    const onSearch = useCallback((query) => debounce(setQueryFilter, 200)({ query }), [])

    const onBlur = () => setQueryFilter({ query: '' })

    return {
        isLoading,
        data,
        onSearch,
        onBlur
    }

}

export default useLookupsSearch