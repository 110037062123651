import Main from '../src/main'
import { QueryClient, QueryClientProvider } from 'react-query';
import { WithAppContext } from './common/components/appContext';
import FilterStoreContext from './common/components/filterStoreContext';
import { WithPermissionContext } from './common/components/permissionContext';

console.log(process.env)

const queryClient = new QueryClient({ refetchOnWindowFocus: false });

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <WithAppContext>
        <WithPermissionContext>
          <FilterStoreContext>
            <Main />
          </FilterStoreContext>
        </WithPermissionContext>
      </WithAppContext>
    </QueryClientProvider>
  );
}

export default App;
