import { Form, InputNumber } from 'antd';
import React from 'react';


export default ({ form, onSubmitCallback }) => {

    const onSubmit = (values) => {
        try {

            let postData = {
                age: values.age,
            }

            onSubmitCallback && onSubmitCallback(postData);

        } catch (error) {
            console.log('error in edit skill submit form', error);
        }
    }

    const ageValidator = (rule, value, callback) => {
        try {

            if (value < 0) {
                callback('Age should be between 0 and hundred')
            }

            if (value > 100) {
                callback('Age should be between 0 and hundred')
            }

            return callback();

        } catch (error) {
            console.log('error validating duration', error)
            callback('Age should be between 0 and hundred')
        }
    }

    return (
        <Form
            layout='vertical'
            form={form}
            scrollToFirstError
            name='create-edit-age'
            //initialValues={initialValues}
            onFinish={onSubmit}
        >
            <Form.Item
                label="Age"
                name="age"
                rules={[
                    { required: true, message: 'Age is required.' },
                    { validator: ageValidator, validateTrigger: ['onValueChange'] }
                ]}
                wrapperCol={{ span: 16 }}
            >
                <InputNumber placeholder="age"  />
            </Form.Item>
        </Form>
    )

}