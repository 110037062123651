import React, { useState } from "react";
import { Button, Progress, Upload } from "antd"
import axios from "axios";
import { getBase64 } from "../../../../helpers/image";
import { UploadOutlined } from '@ant-design/icons';
import { get } from "lodash";


export default (props) => {

    const {
        previewComponent = null,
        getUploadUrlAsync,
        onGetPreviewUrl,
        onUploadSuccess,
        ...rest
    } = props;

    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const customUploadRequest = async e => {
        try {

            console.log('custom upload params', e);

            const fileName = get(e, 'file.name');
            const mimeType = get(e, 'file.type');
            const fileSize = get(e, 'file.size');

            const uploadFile = {
                fileName,
                mimeType,
                fileSize,
            }

            const uploadUrl = await getUploadUrlAsync(uploadFile);

            if (!uploadUrl) {
                console.log('no upload rul')
                return;
            }

            const iconUrl = await getBase64(e.file);

            onGetPreviewUrl && onGetPreviewUrl(iconUrl);

            setIsUploading(true);

            await axios.put(
                uploadUrl,
                e.file,
                {
                    //headers: { ContentType: imageBlob.type },
                    transformRequest: (d) => d,
                    onUploadProgress: progressEvent => {
                        console.log('progress init')


                        const progressPercent = Math.round(
                            (progressEvent.loaded / progressEvent.total) * 100
                        );
                        const isUploaded = progressEvent.loaded === progressEvent.total;

                        //console.log('uplaod progress', progressPercent, isUploaded)
                        console.log('progress percentage parameters', progressEvent.loaded, progressEvent.total)

                        setUploadProgress(progressPercent);

                        if (isUploaded) {
                            setIsUploading(false);
                            e.onSuccess(e.data);
                            onUploadSuccess && onUploadSuccess({ 
                                url: iconUrl,
                                fileName,
                                mimeType,
                                fileSize,
                            });
                        }

                    },
                }
            );


        } catch (error) {
            console.log('error in custom form upload component', error);
        }
    }

    return (
        <React.Fragment>
            {previewComponent}
            <Upload
                showUploadList={false}
                customRequest={customUploadRequest}
                {...rest}
            >
                {
                    isUploading
                        ? (
                            <React.Fragment>
                                <Progress size="small" type="circle" width={40} percent={uploadProgress} />
                                <div>...</div>
                            </React.Fragment>)
                        :
                        (<Button icon={<UploadOutlined />}>Click to upload</Button>)
                }
            </Upload>
        </React.Fragment>
    )


}