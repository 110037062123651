import { Button, Card, Col, Form, Input, Mentions, Modal, Row, Select, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { imageUrls, langaugesItems } from ".";
import LookupSelect from "../../../common/components/lookupSelect";
import useHttpHelper from "../../../common/hooks/useHttpHelper";
import { appLinks, organisationTypes } from "../../../config/constants";
import useOrganisationsLookups from "../../challenge/common/hooks/useOrganisationsLookups";
import UserSelect from "./UserSelect";
import EmailSections from "./sections/EmailSections";

const { Option } = Mentions;
const { TextArea } = Input


const previewStates = {
    default: 'default',
    previewOnly: 'previewOnly',
    previewSend: 'previewSend',
}

export const selectedOrganisationFieldName = 'organisation'
export const selectedUsersFieldName = 'selectedUsers'

const oneOrganisations = true

const mapUsersToEmails = (users) => {

    const emails = users?.map(({ key }) => {
        try {

            const indexOfDot = key.indexOf('.');
            const email = key.substring(indexOfDot + 1);

            return email;

        } catch (error) {
            console.log('error mapping user', error);
        }
    })?.filter(Boolean) ?? []

    return emails
}

export default (props) => {

    const {
        formValues,
        navigateToDetails,
        sentId,
        viewMode,
        reloadDetails,
    } = props;

    const [form] = Form.useForm();

    useEffect(() => {

        try {

            if (typeof formValues === 'object' && formValues !== null) {
                form.setFieldsValue({
                    ...formValues
                })
            }

        } catch (error) {
            console.log('error setting form values', error)
        }

    }, [formValues])

    const [isLoadingPreview, setIsLoadingPreview] = useState(false)
    const [previewState, setPreviewState] = useState(previewStates.default)
    const [previewHtml, setPrevewHtml] = useState()
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [testEmail, setTestEmails] = useState('')

    const {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    } = useOrganisationsLookups(undefined, organisationTypes.foundation, false, oneOrganisations);

    const onSubmitHandler = async (values) => {
        try {

            console.log('on submit', values);

            const {
                //organisationItems = [],
                organisation,
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageUrl,
                bannerImageInfo,
                emails,
                emailSections,
                subject,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            } = values;

            // const orgId = organisation.value;

            // const organisationItem = find(organisationItems, org => org.value === orgId);

            // if (!organisationItem) {
            //     console.log('on organisation item')
            //     return;
            // }

            const emailPayload = {
                sendEmails: true,
                organisationId: organisation?.value,
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageInfo,
                bannerImageUrl,
                emails,
                emailSections,
                subject,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            };

            setPreviewState(previewStates.previewSend)

            const previewLink = appLinks.getEmailTemplate;

            const template = await httpPostAsync(previewLink, emailPayload)

            console.log('email templae response', typeof template)

            if (typeof template === 'string' && template?.trim()?.length > 0) {
                setPrevewHtml(template)
                setIsModalVisible(true)
            } else {
                setPrevewHtml(null)
            }


        } catch (error) {
            console.log('error on submit', error);
            setPreviewState(previewStates.default)
        }
    };

    const previousSelectedUserEmailsRef = useRef([])
    const userSelectOptionsRef = useRef([])

    const setCurrentSelectItems = (value) => userSelectOptionsRef.current = value

    const onValuesChange = (values) => {
        try {

            console.log('onValuesChange', values);

            let selectedUsers = values?.[selectedUsersFieldName]

            if (Array.isArray(selectedUsers)) {

                const newSelectedEmails = mapUsersToEmails(selectedUsers)

                const previousSelectedEmails = previousSelectedUserEmailsRef.current ?? []

                // check email removed from select
                // this keeps the track of previously selected users in useRef variable
                const removedEmails = previousSelectedEmails?.filter(e => !newSelectedEmails.includes(e))

                // update emails text
                // find emails from selected users
                // find emails from comma delimited text
                // combine them, remove duplicates and form new comma delimited email list
                const delimitedEmailsText = form.getFieldValue('emails')
                const emailsList = delimitedEmailsText?.trim()?.split(',')?.map(e => e.trim()) ?? []

                const combinedSet = new Set(newSelectedEmails.concat(emailsList)) // set remove duplicates

                // remove unselected emails
                // as unselected email should not be considered as user entered email
                for (const email of removedEmails) {
                    combinedSet.delete(email)
                }

                //TODO select emails from text field present in select list
                const selectOptions = userSelectOptionsRef.current ?? []
                const selectEmails = selectOptions.filter(e => combinedSet.has(e.email) && !newSelectedEmails.includes(e.email))
                // for (const item of selectOptions) {
                //     const email = item.email
                //     // needs to be selected as it is present in email list text
                //     if (combinedSet.has(email) && !newSelectedEmails.includes(email)) {
                //         selectEmails.push(email)
                //     }
                // }
                if (selectEmails.length) {
                    console.log('need to set selected emails')
                    selectedUsers = selectedUsers.concat(selectEmails)
                    form.setFieldsValue({ [selectedUsersFieldName]: selectedUsers });
                }

                const combinedEmails = [...combinedSet]

                form.setFieldsValue({ emails: combinedEmails?.join(', ') });

                // current emails becomes the new previous
                previousSelectedUserEmailsRef.current = newSelectedEmails

            }

        } catch (error) {
            console.log('error handling on values change', error);
        }
    }

    const onPreviewOnly = async () => {
        try {

            const values = form.getFieldsValue()

            console.log('values', values)

            const {
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageUrl,
                emails,
                emailSections,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            } = values;

            const emailPayload = {
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageUrl,
                emails,
                emailSections,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            };

            const previewLink = appLinks.getEmailTemplate;

            setPreviewState(previewStates.previewOnly)

            const template = await httpPostAsync(previewLink, emailPayload)

            if (typeof template === 'string' && template?.trim()?.length > 0) {
                setPrevewHtml(template)
                setIsModalVisible(true)
            } else {
                setPrevewHtml(null)
            }

        } catch (error) {
            console.log('error on preview only click', error);
            setPreviewState(previewStates.default)
            setPrevewHtml(null)
        }

    }

    const onPreviewSend = async () => {
        try {

            const organisation = form.getFieldValue('organisation');

            if (!organisation) {
                await form.validateFields();
                console.log('validate and return');
                return;
            }

            const emails = form.getFieldValue('emails')

            console.log('typeof', typeof emails)

            if (typeof emails === 'string' && emails.trim().length === 0) {
                form.setFields([{
                    name: 'emails',
                    errors: ['need some recipients']
                }])
                return;
            }

            const emailList = typeof emails === 'string' ? emails.split(',') : []

            if (emailList.length === 0) {
                form.setFields([{
                    name: 'emails',
                    errors: ['need some recipients']
                }])
                return;
            }


            form.submit();


        } catch (error) {
            console.log('error on send click', error);
        }

    }

    const onSendEmailAsync = async (sendEmails) => {
        // will save data and send emails if sendEmails parameter is set
        try {

            const values = form.getFieldsValue()

            console.log('values', values)

            const {
                //organisationItems = [],
                organisation,
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageUrl,
                bannerImageInfo,
                emails,
                emailSections,
                subject,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            } = values;


            const emailPayload = {
                id: sentId,
                sendEmails: !!sendEmails,  // dont sent emails, only save entry as draft
                organisationId: organisation?.value,
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageInfo,
                bannerImageUrl,
                emails,
                emailSections,
                subject,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            };

            const saveLink = appLinks.saveAndSendEmails;

            const response = await httpPostAsync(saveLink, emailPayload)

            // message that draft is saved

            console.log('response', response)

            //const id = response?.data?.id

            return response;

        } catch (error) {
            console.log('error on send click', error);
        }

    }

    const { httpPostAsync } = useHttpHelper();

    //#region preview

    const sendTestEmailAsyn = async () => {
        try {

            if (typeof testEmail !== 'string' || testEmail.trim().length === 0) {
                message.warn('Please enter email address')
            }

            console.log('send test email')

            const values = form.getFieldsValue()

            console.log('values', values)

            const {
                //organisationItems = [],
                organisation,
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageUrl,
                bannerImageInfo,
                //emails,
                emailSections,
                subject,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            } = values;


            const postData = {
                id: sentId,
                sendEmails: false,  // dont sent emails, only save entry as draft
                organisationId: organisation?.value,
                title,
                subtitle,
                headerText,
                bannerText,
                bannerTag,
                bannerImageInfo,
                bannerImageUrl,
                emails: testEmail,
                emailSections,
                subject,
                footerTitle,
                footerSubtitle,
                copyright,
                unsubscribe,
            };

            const sendTestEmailLink = appLinks.sentTestEmail

            const response = await httpPostAsync(sendTestEmailLink, postData)

            const responseCode = response.code

            console.log('response', response)

            if (responseCode === '0') {
                message.success('Test email sent')
            } else if (responseCode === '400.1.15' || responseCode === '400.1.16') {
                const invalidEmails = response.data?.emails ?? ''
                message.warn(`Emails not passed or invalid ${invalidEmails ? `, (${invalidEmails})` : ''}`)
            }


        } catch (error) {
            console.log('error sending test emails', error)
        }
    }

    const handleOk = async () => {
        //onReplaceItem(replaceItemValue, selectedItem)

        //setSelectedItem(undefined)
        if (previewState === previewStates.previewSend) {

            const shouldSendEmails = true;
            const response = await onSendEmailAsync(shouldSendEmails)
            const code = response?.code

            if (code === '0') {
                // email sent
                message.success('Emails sent!');
                setIsModalVisible(false)
                setPreviewState(previewStates.default)
                const id = response?.data?.id
                navigateToDetails(id)
                typeof reloadDetails === 'function' && reloadDetails()
            } else if (code === '400.1.15' || code === '400.1.16') {
                const invalidEmails = response.data?.emails ?? ''
                message.warn(`Emails not passed or invalid ${invalidEmails ? `, (${invalidEmails})` : ''}`)
            } else {
                // error sending emails
                message.warn('Emails could not be sent!');
                setIsModalVisible(false)
                setPreviewState(previewStates.default)
            }

        } else {
            setIsModalVisible(false)
            setPreviewState(previewStates.default)
        }

    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setPreviewState(previewStates.default)
    }

    const onPreviewTemplate = async () => {
        try {

            const link = appLinks.getEmailTemplate

            setPreviewState(previewStates.default)
            setIsLoadingPreview(true)
            const template = await httpPostAsync(link, { isPreview: true })

            if (typeof template === 'string' && template?.trim()?.length > 0) {
                setPrevewHtml(template)
                setIsModalVisible(true)
            } else {
                setPrevewHtml(null)
            }

        } catch (error) {
            console.log('error on preview', error)
        }
        setIsLoadingPreview(false)
    }
    //#endregion

    const onEmailTextChange = async (e) => {
        try {

            // this can be a super set or selected emails but not a subset
            // selected items will contains items from all status and organisations
            const emailTextList = e.target.value?.trim()?.split(',')?.map(e => e?.trim()) ?? []

            const selectedRecipients = form.getFieldValue(selectedUsersFieldName) ?? []

            // if some emails are edited out then, unselect them
            let selectedItems = selectedRecipients?.filter(e => {
                const indexOfDot = e.key.indexOf('.');
                const email = e.key.substring(indexOfDot + 1);
                return emailTextList.includes(email)
            }) ?? []

            const selectOptions = userSelectOptionsRef.current ?? []
            const newSelectedEmails = mapUsersToEmails(selectedRecipients)
            const selectUserItems = selectOptions.filter(e => emailTextList.includes(e.email) && !newSelectedEmails.includes(e.email))
            if (selectUserItems.length) {
                console.log('need to set selected emails')
                selectedItems = selectedRecipients.concat(selectUserItems)
                form.setFieldsValue({ [selectedUsersFieldName]: selectedItems });
            }

            form.setFieldsValue({ [selectedUsersFieldName]: selectedItems })

        } catch (error) {
            console.log('error on email text change', error);
        }
    }

    const onSelectUmageUrl = (e) => {
        try {

            //console.log('image url', e, item.type)
            // setting the text value of the url as it can be chnaged to any url
            form.setFieldsValue({
                bannerImageUrl: e?.value,
            })

        } catch (error) {
            console.log('error selectin banner image url', error)
        }
    }

    const emailListType = useRef()

    const setEmailListType = (type) => {
        console.log('email list type', type)
        emailListType.current = type
    }

    const onAddEntireList = async () => {
        try {

            const selectedOrganisation = form.getFieldValue('organisation')

            const params = {
                organisations: [selectedOrganisation?.value],
                status: emailListType.current,
                all: true
            }

            const usersResponse = await httpPostAsync(appLinks.oneUserLookups, params);

            const allUsersResponse = usersResponse?.data ?? []

            // getting selected email users

            let selectedRecipients = form.getFieldValue(selectedUsersFieldName) ?? []
            selectedRecipients = selectedRecipients?.filter(e => !!e.key)

            let selectedItems = selectedRecipients
                ?.map(e => {
                    const indexOfDot = e?.key?.indexOf('.');
                    const email = e?.key?.substring(indexOfDot + 1);
                    return {
                        ...e,
                        email,
                    }
                }) ?? []

            const selectedEmailList = mapUsersToEmails(selectedItems)

            const emailText = form.getFieldValue('emails')

            const emailTextList = emailText?.trim()?.split(',')?.map(e => e?.trim())?.filter(Boolean) ?? []

            const selectedEmails = selectedEmailList?.concat(emailTextList)

            const difference = allUsersResponse?.filter(e => !selectedEmails.includes(e.email))

            const emailsUnionSet = [...selectedItems, ...difference]

            const delimitedEmails = emailsUnionSet?.map(e => e.email)

            for (let i = 0; i < emailTextList?.length; i++) {
                if (!delimitedEmails.includes(emailTextList[i])) {
                    delimitedEmails.push(emailTextList[i])
                }
            }

            const emailsTextAreaText = delimitedEmails?.join(', ')

            form.setFieldsValue({ [selectedUsersFieldName]: emailsUnionSet })
            form.setFieldsValue({ emails: emailsTextAreaText })

        } catch (error) {
            console.log('on add entire list', error)
        }
    }

    const onClearList = () => {
        try {

            form.setFieldsValue({ [selectedUsersFieldName]: [] })
            form.setFieldsValue({ emails: '' })

        } catch (error) {
            console.log('error on clear list', error)
        }
    }

    const onSelectLangauge = (selectedItem) => {
        try {


            const item = langaugesItems.find(e => e.value === selectedItem.value)
            //console.log('select language', selectedItem, item)
            // setting the text value of the url as it can be chnaged to any url
            form.setFieldsValue({
                footerTitle: item?.title,
                footerSubtitle: item.subTitle,
                copyright: item.copyright,
                unsubscribe: item.unsubscribe
            })

        } catch (error) {
            console.log('error selectin footer language', error)
        }
    }

    return (
        <div className="send-email-form-container">
            <Card>
                <Form
                    layout='vertical'
                    form={form}
                    scrollToFirstError
                    onFinish={onSubmitHandler}
                    onValuesChange={onValuesChange}
                    name='send-app-emails'
                    disabled={viewMode}
                >
                    <Form.Item name="organisationItems" hidden>
                        <Input type="hidden" />
                    </Form.Item>
                    <Row>
                        <Col span={12} style={{ paddingRight: 24, paddingTop: 54 }}>
                            <Row>
                                <Col span={24}>
                                    <Card title='Header'>
                                        <Row>
                                            <Col>
                                                <Form.Item
                                                    label='Title'
                                                    name='title'
                                                    rules={[{ required: true, message: 'Title are required' }]}
                                                >
                                                    <Input
                                                        placeholder="Title"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Subtitle'
                                                    name='subtitle'
                                                    rules={[{ required: true, message: 'Subtitle are required' }]}
                                                >
                                                    <Input.TextArea
                                                        placeholder="subtitle"
                                                        rows={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='Header Text'
                                                    name='headerText'
                                                >
                                                    <Input.TextArea
                                                        placeholder="Header text"
                                                        rows={3}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>

                                <Col span={24}>
                                    <Card title="Banner">
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label='text'
                                                    name='bannerText'
                                                >
                                                    <Input.TextArea
                                                        placeholder="Text"
                                                        rows={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Item
                                                    label='Hash Tag'
                                                    name='bannerTag'
                                                >
                                                    <Input
                                                        placeholder="# Tag"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <div className='banner-image-select-container'>
                                            <Form.Item
                                                name={"bannerImageInfo"}
                                                label="Image Url"
                                            >
                                                <Select
                                                    placeholder="Select Image Url"
                                                    labelInValue
                                                    className="banner-image-url-select"
                                                    optionLabelProp="children"
                                                    onSelect={onSelectUmageUrl}
                                                >
                                                    {
                                                        imageUrls?.map(item => {
                                                            return (
                                                                <Option
                                                                    key={item.id}
                                                                    value={item.value}
                                                                >{item.label}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                            <div>
                                                <Form.Item name={"bannerImageUrl"} >
                                                    <Input placeholder="Image url" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Card title="Footer">
                                        <Form.Item
                                            name={"footerLangauge"}
                                            label="Footer language"
                                            required
                                            rules={[{ required: true, message: 'Footer langauge is required' }]}
                                        >
                                            <Select
                                                placeholder="Select footer langauge"
                                                labelInValue
                                                className="language-select"
                                                optionLabelProp="children"
                                                onSelect={onSelectLangauge}
                                                //defaultValue={langaugesItems[0]}
                                            >
                                                {
                                                    langaugesItems?.map(item => {
                                                        return (
                                                            <Option
                                                                key={item.id}
                                                                value={item.value}
                                                            >{item.label}</Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item name={"footerTitle"}  >
                                            <Input placeholder="Title"  />
                                        </Form.Item>
                                        <Form.Item name={"footerSubtitle"} >
                                            <Input placeholder="Sub-title" />
                                        </Form.Item>
                                        <Form.Item name={"copyright"} >
                                            <Input placeholder="Copyright"  />
                                        </Form.Item>
                                        <Form.Item name={"unsubscribe"} >
                                            <Input placeholder="Unsubscribe" />
                                        </Form.Item>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="email-subject-div">
                                <Col span={12}>
                                    <Form.Item
                                        name="subject"
                                        label='Email Subject'
                                        rules={[{ required: true, message: 'Subject are required' }]}
                                    >
                                        <Input placeholder="Email subject" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        label="Organisation"
                                        name="organisation"
                                    >
                                        <LookupSelect
                                            placeholder="Select Organisation"
                                            data-testid="notification-org-select"
                                            queryParams={organisationParams}
                                            fetchAsync={fetchOrganisationsAsync}
                                            queryKeyFn={organisationQueryKeyFn}
                                            lookupId='organisation'
                                            labelInValue
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name={selectedUsersFieldName}
                                    >
                                        <UserSelect
                                            form={form}
                                            setCurrentSelectItems={setCurrentSelectItems}
                                            setEmailListType={setEmailListType}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="recepient-action">
                                <div className="add-entire-list" >
                                    <Button onClick={onAddEntireList} >Add Entire List</Button>
                                </div>
                                <div>
                                    <Button onClick={onClearList}>Clear List</Button>
                                </div>
                            </div>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={`Recipients (Email will only be sent to recipients in the field below)`}
                                        name="emails"
                                    // help="Help"
                                    >
                                        <TextArea
                                            rows={12}
                                            onChange={onEmailTextChange}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className="btn-container">
                                <div className="preview-only">
                                    <Button
                                        onClick={onPreviewOnly}
                                        disabled={false}
                                    >Preview Only</Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={onPreviewSend}
                                    >Preview and Send</Button>
                                </div>
                                <Button
                                    onClick={async () => {
                                        const shouldSendEmails = false;
                                        const response = await onSendEmailAsync(shouldSendEmails)
                                        const code = response?.code
                                        if (code === '0') {
                                            message.success('Email Draft saved')
                                            console.log('id', response?.data)
                                            const id = response?.data?.id
                                            navigateToDetails(id)
                                        } else {
                                            message.warn('Email draft could not be saved')
                                        }

                                    }}
                                    className="save-draft-btn"
                                >Save Draft</Button>
                            </div>
                        </Col>
                        <Col span={12}>
                            <Row justify="end">
                                <Col>
                                    <Button
                                        onClick={onPreviewTemplate}
                                        loading={isLoadingPreview}
                                        disabled={false}
                                    >Preview Sample</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ paddingTop: 24 }}>
                                    <Card title="Sections">
                                        <EmailSections form={form} viewMode={viewMode} />
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>

            </Card>
            <Modal
                title="Email Preview"
                open={isModalVisible}
                onOk={handleOk}
                okText={previewState === previewStates.previewSend ? 'Send' : 'Ok'}
                onCancel={handleCancel}
                className="Html preview"
                width={1000}
            >
                <div>
                    <div dangerouslySetInnerHTML={{ __html: previewHtml }} />
                    <div style={{
                        position: 'relative'
                    }}>
                        {
                            previewState === previewStates.previewOnly ?
                                (
                                    <div className="send-test-email">
                                        <Input
                                            placeholder="Email"
                                            value={testEmail}
                                            onChange={(e) => setTestEmails(e.target.value)}
                                        />
                                        <Button
                                            onClick={sendTestEmailAsyn}
                                        >Test Send</Button>
                                    </div>
                                ) : null
                        }
                    </div>
                </div>
            </Modal>
        </div>
    )

}

