
export const storeDataAsync = async (key, value) => {
    try {
        return Promise.resolve(localStorage.setItem(key, value));
    } catch (e) {
        console.log('error storing data', e);
    }
}

export const storeJsonDataAsync = async (key, value) => {
    try {

        const stringValue = JSON.stringify(value);
        return await storeDataAsync(key, stringValue);
       
    } catch (e) {
        console.log('error storing json data as string', e);
    }
}

export const getStoredDataAsync = async (key) => {
    try {
        const value =  localStorage.getItem(key);
        return Promise.resolve(value);
    } catch (e) {
        console.log('error getting data from storage', e)
    }
}

export const getStoredJsonDataAsync = async (key) => {
    try {
        const value =  localStorage.getItem(key);
        return Promise.resolve(JSON.parse(value));
    } catch (e) {
        console.log('error getting json data from storage', e)
    }
}


export const removeStoredValueAsync = async (key) => {
    try {
        Promise.resolve(localStorage.removeItem(key))
    } catch (e) {
        console.log('error removing stored value', e);
    }
}