import { VerticalAlignTopOutlined } from '@ant-design/icons';
import { BackTop, Button, Col, Input, List, Row, Select, Spin } from 'antd';
import { debounce, filter, flatMap, get, join, map } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import AppLayout from '../../../common/components/layout';
import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import TagChip from '../../../common/components/tagchip';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys, reportedPostsType } from '../../../config/constants';
import CardSelector from './cards/CardFactory';
import useReportedItemsFilterContext from './useReportedItemsFilterContext';
import Dashboard from '../../../common/components/dashboard';

const { Search } = Input;
const { Option } = Select;

const pageSize = 10;

export const reportedEntityTypes = [
    { value: 'user', label: 'Users' },
    { value: 'post', label: 'Posts' },
]


const filteredReportedTypes = filter(reportedPostsType, t => t.value !== 'all')

console.log('filteredReportedTypes', filteredReportedTypes)

export default function ReportedItems() {

    const { httpPostAsync } = useHttpHelper();

    const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useReportedItemsFilterContext();

    const {
        //pageNo = 0,  // page no is handled by useInfiniteQuery, rest of the parameter needs to be in query key
        query = '',
        types = [],
        reportedTypes = [],
        organisations = [],
        sortBy = null,
        sortOrder = null,
    } = searchParams

    const fetchReportedItemsAsync = async (fetchParams = {}) => {

        const { pageParam = 0 } = fetchParams

        let searchData = {
            query,
            itemPerPage: pageSize,
            sortBy,
            sortOrder,
            pageNo: pageParam,
            reportedTypes: map(reportedTypes, s => s.value),
            organisations: map(organisations, s => s.value),
            types: map(types, s => s.value),
        }

        console.log('fetch reported items params:', fetchParams, searchData);

        const response = await httpPostAsync(appLinks.reportedItems, searchData);

        console.log('fetch reported items', response);

        return response;
    };

    const queryKey = useMemo(() => {

        const key = [queryKeys.reportedItems, query, sortBy, sortOrder]

        let organisationsList = '0';
        if (organisations.length) {
            const organisationsValues = map(organisations, s => s.value);
            organisationsList = join(organisationsValues, '.');
        }
        key.push(organisationsList)


        let typesList = '0';
        if (types.length) {
            const typeValues = map(types, s => s.value);
            typesList = join(typeValues, '.');
        }
        key.push(typesList)

        let reportedTypesList = '0';
        if (reportedTypes.length) {
            const reportedTypesListValues = map(reportedTypes, s => s.value);
            reportedTypesList = join(reportedTypesListValues, '.');
        }
        key.push(reportedTypesList)

        console.log('filter query key', key)

        return key;

    }, [query, sortBy, sortOrder, types, reportedTypes])

    const {
        data: postsPages,
        //  error,
        fetchNextPage,
        hasNextPage,
        isFetching: isLoadingPosts,
        isLoading,
        //   isFetchingNextPage,
        //  status,
    } = useInfiniteQuery(queryKey, fetchReportedItemsAsync, {
        refetchOnWindowFocus: false,
        getNextPageParam: (lastPage, pages) => {

            if (lastPage && lastPage.nextPage) {
                console.log('getNextPageParam: has next page', lastPage)
                return lastPage.nextPage;
            }

        },
    })

    const postList = useMemo(() => {

        const pages = get(postsPages, 'pages');

        const list = flatMap(pages, p => [...(get(p, 'data') || [])]);

        return list;

    }, [postsPages])

    const [tagsVisible, setTagsVisible] = useState(false);

    const styles = useMemo(() => ({
        itemStyle: {
            paddingRight: 16,
            paddingBottom: 18,
        },
        tagsContainer: {
            paddingTop: tagsVisible ? 16 : 0,
            paddingBottom: tagsVisible ? 8 : 0

        },
        challengeTableContainer: {
            paddingTop: tagsVisible ? 0 : 17
        }
    }), [tagsVisible])

    const [resetKey, setResetKey] = useState(0);

    //#region search and filters
    const [searchValue, setSearchValue] = useState(query);

    const searchPosts = async (text) => {
        try {

            setSearchParams(p => ({ ...p, query: text }));

        } catch (error) {
            console.log('error searching', error);
        }
    }

    const searchUsersRef = useRef(searchPosts);
    searchUsersRef.current = searchPosts;

    const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

    const onSearchChange = async (e) => {
        setSearchValue(e.target.value)
        debouncedSearch(e.target.value)
    }

    const onReportTypeChange = (values, props) => {
        try {

            // console.log('report types', values, props)

            const mappedItems = map(values, p => ({ key: p.key, value: p.value, label: p.label }));

            //console.log('mapped report types', mappedItems);

            setSearchParams(p => ({ ...p, reportedTypes: [...mappedItems], pageNo: 0 }))

        } catch (error) {
            console.log(`error in ${onReportTypeChange.name}`, error)
        }
    }

    const onEntityTypeChange = (values, props) => {
        try {

            // console.log('report types', values, props)

            const mappedItems = map(values, p => ({ key: p.key, value: p.value, label: p.label }));

            //console.log('mapped report types', mappedItems);

            setSearchParams(p => ({ ...p, types: [...mappedItems], pageNo: 0 }))

        } catch (error) {
            console.log(`error in ${onEntityTypeChange.name}`, error)
        }
    }

    const onReset = () => {
        try {

            resetSearchParams();
            setSearchValue('');
            setResetKey(k => k + 1);
            //searchFieldRef.current.input.value = '';
            // console.log('search ref', searchFieldRef.current)

        } catch (error) {
            console.log('error on resetting filters', error);
        }
    }

    const onOrganisationChange = (values) => {
        try {

            console.log('values', values)

            setSearchParams(p => ({ ...p, organisations: [...values], pageNo: 0 }))

        } catch (error) {
            console.log('error on organisation chamge', error)
        }
    }

    //#endregion

    const loadMore =
        (hasNextPage) ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button
                    loading={isLoadingPosts}
                    onClick={() => {
                        console.log('on next click', hasNextPage);
                        if (hasNextPage) {
                            //setSearchParams(p => ({ ...p, pageNo: p.pageNo + 1 }));
                            fetchNextPage()
                        }

                    }}>load more</Button>
            </div>
        ) : null;

    return (
        <Dashboard subrouting={false} activeItem='reportedItems'>
            <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
                <Col>
                    <Row>
                        <Col style={styles.itemStyle}>
                            <Search
                                placeholder="Search"
                                value={searchValue}
                                onChange={onSearchChange}
                                allowClear
                                style={{ width: 200 }}
                                key={resetKey}
                            />
                        </Col>
                        <Col style={{ paddingRight: 15 }}>
                            <OrganisationsLookup
                                onChange={onOrganisationChange}
                                value={mapKeyValues(organisations)}
                                style={{ width: 200 }}
                                placeholder="Organisation filter"
                            />
                        </Col>
                        <Col style={styles.itemStyle} >
                            <Select
                                mode="multiple"
                                style={{ width: 200 }}
                                placeholder="Reported type"
                                onChange={onEntityTypeChange}
                                labelInValue
                                // value={reportedTypes}
                                value={map(types, s => ({ value: s.value, label: s.label }))}
                                tagRender={() => null}
                                optionFilterProp='label'
                            >  {
                                    map(reportedEntityTypes, s => {
                                        return (
                                            <Option key={s.value} value={s.value} label={s.label} >{s.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                        <Col style={styles.itemStyle} >
                            <Select
                                mode="multiple"
                                style={{ width: 200 }}
                                placeholder="Reported posts"
                                onChange={onReportTypeChange}
                                labelInValue
                                //value={reportedTypes}
                                value={map(reportedTypes, s => ({ value: s.value, label: s.label }))}
                                tagRender={() => null}
                                optionFilterProp='label'
                            >  {
                                    map(filteredReportedTypes, s => {
                                        return (
                                            <Option key={s.value} value={s.value} label={s.label}>{s.label}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={styles.tagsContainer} justify="space-between">
                <Col>
                    <div style={{ marginBottom: 8 }}>
                        {map(organisations, org => {

                            console.log('organisation tag', org)

                            return (
                                <TagChip key={org.key}
                                    closable
                                    //style={styles.searchTags}
                                    onClose={() => {
                                        setSearchParams(p => {

                                            const filtered = filter(p.organisations, o => o.key !== org.key);

                                            console.log('filtered organisations', filtered)

                                            return {
                                                ...p,
                                                organisations: filtered,
                                            }

                                        })
                                    }}>
                                    {org.label}
                                </TagChip>
                            )

                        })}
                        {map(types, org => {

                            //  console.log('reportedTypes tag', org)

                            return (
                                <TagChip key={org.key}
                                    closable
                                    //style={styles.searchTags}
                                    onClose={() => {
                                        setSearchParams(p => {

                                            // console.log('remove', p, org)

                                            const filtered = filter(p.types, o => o.key !== org.key);

                                            //console.log('filtered reportTypes', filtered)

                                            return {
                                                ...p,
                                                types: filtered,
                                            }

                                        })
                                    }}>
                                    {org.label}
                                </TagChip>
                            )

                        })}
                        {map(reportedTypes, org => {

                            //  console.log('reportedTypes tag', org)

                            return (
                                <TagChip key={org.key}
                                    closable
                                    //style={styles.searchTags}
                                    onClose={() => {
                                        setSearchParams(p => {

                                            console.log('remove', p, org)

                                            const filtered = filter(p.reportedTypes, o => o.key !== org.key);

                                            console.log('filtered reportTypes', filtered)

                                            return {
                                                ...p,
                                                reportedTypes: filtered,
                                            }

                                        })
                                    }}>
                                    {org.label}
                                </TagChip>
                            )

                        })}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col push={23}>
                    {
                        isSearchActive
                            ? (
                                <Button type="link" size='small' onClick={onReset} style={{ marginBottom: 8 }} >
                                    Reset
                                </Button>
                            )
                            : null
                    }
                </Col>
            </Row>
            <Spin spinning={isLoading || isLoadingPosts}>
                <List
                    key={resetKey}
                    style={{
                        backgroundColor: '#fff',
                        paddingBottom: 16,
                    }}
                    bordered
                    dataSource={postList}
                    itemLayout="vertical"
                    size="large"
                    loading={isLoadingPosts}
                    loadMore={loadMore}
                    //  pagination={{
                    //     current: pageNo + 1,
                    //     defaultPageSize: pageSize,
                    //     //hideOnSinglePage: true,
                    //     total,
                    // }}
                    rowKey={item => item.id}
                    renderItem={item => <CardSelector key={item.id} item={item} />}
                />
                <BackTop>
                    <div >
                        <Button type="primary" shape="circle" icon={<VerticalAlignTopOutlined />} size='large' />
                    </div>
                </BackTop>
            </Spin>
        </Dashboard>
    )

}