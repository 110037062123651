import React from 'react';
import { Row, Col, Image, Card, Typography, Badge, Tabs, Divider } from 'antd';
import styled from 'styled-components';
import { get, map } from 'lodash';
import CreatorProfile from './creator';
import ItemTags from '../../../common/components/itemTag';
import ItemTitle from '../../../common/components/itemTitle';

import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import { ReactComponent as ProfileImageDefault } from '../../../assets/userProfile.svg';

const { Meta } = Card;
const TitleComponent = styled.span`
font-size: 16px;
font-weight: bold;
`

const TitleDiv = styled.div`
padding-top: 8px
`;

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}

const { Text, Title } = Typography;

const { TabPane } = Tabs;

const userStatusColorMap = {
    active: 'green',
    default: 'yellow',
}

const StatusTextMap = {
    active: 'Active',
}

export default (props) => {

    const {
        name,
        status,
        email,
        roles,
        clubs,
        organisations,
        accountType,
        //creator info
        creatorName,
        creatorShortBio,
        creatorTitle,
        creatorWebsite,
        creatorProfileImageUrl,
        creatorCoverImageUrl,
    } = props;


    return (
        <div>
            <Card>
                <Row>
                    <Col>
                        <Row align='bottom' >
                            {/* <Col 
                            //xs={8} sm={8} md={8} lg={4} xl={4} xxl={4}
                            >
                                        <Card
                                            //hoverable
                                            style={{ width: '100%', textAlign: 'center' }}
                                            cover={
                                                thumbUrl ?
                                                    <Image
                                                        height={200}
                                                        width={138}
                                                        src={thumbUrl}
                                                      
                                                    //fallback={defaultPlaceholderImage}
                                                    />
                                                    : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
                                            }
                                        >
                                        </Card>
                            </Col> */}
                        </Row>
                        <Row style={{ marginTop: 12 }}>
                            <Col>
                                <TitleComponent>{name}</TitleComponent>
                            </Col>
                            <Col>
                                <Badge
                                    style={{ paddingLeft: 32 }}
                                    color={get(userStatusColorMap, status, userStatusColorMap.default)}
                                    text={get(StatusTextMap, status, status)} />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 12 }} justify="start">
                            <Col style={styles.statItem}>
                                <ItemTitle title="Email" value={email} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Account Type" value={accountType} />
                            </Col>
                            {/* <Col style={styles.statItem}>
                                <ItemTitle title="Difficulty" value={difficulty} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Duration" value={duration} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Xp" value={xp} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Facilitation Needed" value={isFacilitationNeeded ? 'Yes' : 'No'} />
                            </Col> */}
                        </Row>
                        <Row style={{ paddingTop: 18 }}>
                            <Col>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Roles</Title>
                                    </div>
                                    {
                                        map(roles, (item, index) => {

                                            const userRoles = get(item, 'roles') || [];
                                            const organisation = get(item, 'organisation') || ''

                                            console.log('item', item)

                                            return (
                                                <div>
                                                    {index > 0 ? <div><Divider/> </div> : null}
                                                    <Text type="secondary">{organisation}</Text>
                                                    <div style={{ marginTop: 4 }}>
                                                        <ItemTags items={userRoles} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </TitleDiv>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 8 }}>
                            <Col>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Organisations</Title>
                                    </div>
                                    <ItemTags items={organisations} />
                                </TitleDiv>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 8 }}>
                            <Col>
                            <TitleDiv>
                            <div>
                                        <Title level={5} >Clubs</Title>
                                    </div>
                                    {
                                        map(clubs, (item, index) => {

                                            const userClubs = get(item, 'clubs') || [];
                                            const organisation = get(item, 'organisation') || ''

                                            console.log('club', item)

                                            return (
                                                <div>
                                                    {index > 0 ? <div><Divider/> </div> : null}
                                                    <Text type="secondary">{organisation}</Text>
                                                    <div style={{ marginTop: 4 }}>
                                                        <ItemTags items={userClubs} />
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </TitleDiv>
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                    </Col>
                </Row>
                <Row style={styles.formRow} >
                            <Col span={24}>
                                <Tabs defaultActiveKey="creator" >
                                    <TabPane
                                        tab={`Creator Profile`}
                                        key="creator"
                                        style={{ paddingTop: 18 }}
                                        //forceRender
                                    >
                                        <CreatorProfile 
                                                            creatorName={creatorName}
                                                            creatorShortBio={creatorShortBio}
                                                            creatorTitle={creatorTitle}
                                                            creatorWebsite={creatorWebsite}
                                                            creatorProfileImageUrl={creatorProfileImageUrl}
                                                            creatorCoverImageUrl={creatorCoverImageUrl}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
            </Card>
        </div>
    )
}