import { Card, Col, Image, Row } from "antd"
import Meta from "antd/lib/card/Meta";
import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import { ReactComponent as ProfileImageDefault } from '../../../assets/userProfile.svg';
import ItemTitle from "../../../common/components/itemTitle";

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}

export default (props) => {

    const {
        creatorName,
        creatorShortBio,
        creatorTitle,
        creatorWebsite,
        creatorProfileImageUrl,
        creatorCoverImageUrl,
    } = props;

    return (
        <div>
            <Row>
                <Col span={12}
                    // xs={24} sm={24} md={24} lg={11} xl={11} xxl={11} 
                    style={{ paddingRight: 24 }}>
                    <Row>
                        <Card
                            //hoverable
                            style={{ textAlign: 'center' }}
                            cover={
                                creatorProfileImageUrl ?
                                    <Image src={creatorProfileImageUrl} height={120} width={120} />
                                    : <ProfileImageDefault height={120} width={120} style={{ borderRadius: 12 }} />
                            }
                        >
                            <Meta title="Profile Image" />
                        </Card>
                    </Row>
                    <Row>
                        <Col style={styles.statItem}>
                            <ItemTitle title="Display name" value={creatorName} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={styles.statItem}>
                            <ItemTitle title="title" value={creatorTitle} />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={styles.statItem}>
                            <ItemTitle title="Website" value={creatorWebsite} />
                        </Col>
                    </Row>
                    <Row justify="start">
                        <Col style={styles.statItem}>
                            <ItemTitle title="Short bio" value={creatorShortBio} />
                        </Col>
                    </Row>
                </Col>
                <Col span={12}
                    // xs={24} sm={24} md={24} lg={11} xl={11} xxl={11} 
                    style={{ paddingRight: 24 }}>
                    <Row>
                        <Card
                            //hoverable
                            style={{ textAlign: 'center' }}
                            cover={
                                creatorCoverImageUrl ?
                                    <Image src={creatorCoverImageUrl} width={280} height={158} />
                                    : <CoverImageDefault width={280} height={158} style={{ borderRadius: 12 }} />
                            }
                        >
                            <Meta title="Cover Image" />
                        </Card>
                    </Row>
                </Col>
            </Row>

        </div>
    )

}