import { Popconfirm, Typography, Modal, Select, Tooltip, Form } from "antd";
import { DeleteOutlined, SwapOutlined, EditOutlined } from '@ant-design/icons'
import EditItemTag from ".";
import { useCallback, useMemo, useRef, useState } from "react";
//import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
const { Text } = Typography;

const { Option } = Select


const EditItem = (props) => {

    const {
        isNew,
        value,
        label,
        onTextUpdate,
        onRemoveClick,
        onShowModal,
        setSelectedValue
    } = props;

    const containerClass = isNew ? 'ant-tag-blue' : 'ant-tag'
    const textClass = isNew ? 'edit-item-text' : ''

    //const [text, setText] = useState(label)

    const textRef = useRef()

    const onMaterialTextChangeRef = useRef()
    onMaterialTextChangeRef.current = () => onTextUpdate(value, textRef.current)

    const editable = useMemo(() => {

        return isNew ? ({
            icon: <span className="edit-icon-container"><EditOutlined /></span>,
            onEnd: onMaterialTextChangeRef.current,
            onChange: (e) => {
                console.log('on change', e)
                textRef.current = e
            }
        }) : false

    }, [isNew])

    const onRemove = () => onRemoveClick(value, isNew)

    return (
        <EditItemTag
            key={value}
            className={`edit-item-tag ${containerClass}`}
        >
            <Text
                editable={editable}
                className={`${textClass}`}
            >
                {label}
            </Text>
            {
                isNew ?
                    (
                        <Tooltip title="Replace">
                            <a
                                className="replace-icon-container"
                                onClick={e => {
                                    //console.log('on replace')
                                    e.preventDefault()
                                    onShowModal()
                                    setSelectedValue(value)
                                }}
                            >
                                <SwapOutlined rotate={90} />
                            </a>
                        </Tooltip>
                    )
                    : null
            }
            <Popconfirm
                placement="topRight"
                title={`Are you sure you want to remove this "${label}"`}
                onConfirm={onRemove}
                okText="Yes"
                cancelText="No"
            >
                <Tooltip title="Remove">
                    <a className="delete-icon-container">
                        <DeleteOutlined />
                    </a>
                </Tooltip>
            </Popconfirm>
        </EditItemTag>)

}


const EditTagContainer = (props) => {

    const {
        items = [],
        formFieldName,
        onTextUpdate,
        onRemoveClick,
        onReplaceItem,
        lookupItems,
        modalTitle,
        selectLabel,
        selectPlaceholder,
    } = props;

    const shouldUpdate = useCallback((prev, cur) => prev[formFieldName] !== cur[formFieldName], [formFieldName])

    //const { httpPostAsync } = useHttpHelper();

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [replaceItemValue, setReplaceItemValue] = useState()
    const [selectedItem, setSelectedItem] = useState()

    const onShowModal = () => setIsModalVisible(true)
    const setSelectedValue = (value) => setReplaceItemValue(value)


    const handleOk = () => {
        onReplaceItem(replaceItemValue, selectedItem)
        setIsModalVisible(false)
        setSelectedItem(undefined)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setReplaceItemValue(-1)
        setSelectedItem(undefined)
    }

    const mappedMaterials = useMemo(() => {

        const values = items?.filter(e => !e.isNew)?.map(e => e.value) ?? []

        const mappedValues = lookupItems?.map(e => ({
            ...e,
            disabled: !!values?.includes(e.value)
        }))

        return mappedValues

    }, [items, lookupItems])

    const itemValuesRef = useRef([])

    return (
        <div>
            <Form.Item shouldUpdate={shouldUpdate}>
                {(props) => {

                    const tagItems = props.getFieldValue(formFieldName);

                    itemValuesRef.current = tagItems?.map(e => e.value)


                    return (
                        <div>
                            <div className="edit-item-tag-container">
                                {
                                    tagItems?.map((e, idx) => {
                                        return (
                                            <EditItem
                                                key={`${e.value}-${idx}`}
                                                {...e}
                                                idx={idx}
                                                onTextUpdate={onTextUpdate}
                                                onRemoveClick={onRemoveClick}
                                                onShowModal={onShowModal}
                                                setSelectedValue={setSelectedValue}
                                            />)
                                    })
                                }
                            </div>
                            <Modal
                                title={modalTitle}
                                visible={isModalVisible}
                                onOk={handleOk}
                                onCancel={handleCancel}
                                className="replace-material-modal"
                            >
                                <div>
                                    <span className="materials-select-label">{selectLabel}</span>
                                    <Select
                                        placeholder={selectPlaceholder}
                                        labelInValue
                                        value={selectedItem}
                                        //loading={isLoadingMaterials}
                                        data-testid='materials-select'
                                        className='replace-materials-dropdown'
                                        optionLabelProp="children"
                                        onSelect={setSelectedItem}
                                    >
                                        {
                                            mappedMaterials?.map(item => {

                                                const isExisting = itemValuesRef.current?.includes(item.value)

                                                return (
                                                    <Option
                                                        key={item.value}
                                                        value={item.value}
                                                        disabled={isExisting}
                                                    >
                                                        {item.label}
                                                    </Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </div>
                            </Modal>
                        </div>
                    )

                }}
            </Form.Item>

        </div>
    )
}

export default EditTagContainer;