import React, { PureComponent } from 'react';
import {
    ComposedChart,
    Line,
    Area,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';


const CustomTooltip = ({ active, payload, label, ...rest }) => {
    //console.log('payload,', payload, label, rest)
    if (active && payload && payload.length) {
        const users = payload[0].value
        const totalUser = payload[1].value
        return (
            <div className="user-tooltip">
                <div className="intro">{label}</div>
                <div className="label-user" style={{
                    color: payload[0].color
                }}>{`Users : ${users}`}</div>
                <div className="label-total"  style={{
                    color: payload[1].color
                }}>{`Cumulative users: ${totalUser}`}</div>
            </div>
        );
    }

    return null;
};


const UsersChart = () => {

    const { httpPostAsync } = useHttpHelper();

    let navigate = useNavigate();

    const fetchStatsAsync = async () => {
        try {

            const link = appLinks.registeredOneUsersStats

            const response = await httpPostAsync(link)

            console.log('registered one users', response?.data)

            const data = response?.data ?? {}

            const items = data.items?.map(e => {

                const createdAt = getAppZoneMomentFromUTC(e.created)

                let createdText = e.created;

                if (createdAt.isValid()) {
                    createdText = createdAt.format('MMMM, YYYY')
                }

                return {
                    ...e,
                    createdText
                }

            }) ?? []

            console.log('registered one : items', items)

            return {
                ...data,
                items,

            }

        } catch (error) {
            console.log('error fetching registered one users stats')
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.registeredOneUserStats],
        fetchStatsAsync,
        {
            refetchOnWindowFocus: false,
        });

    const items = data?.items ?? []

    const navigateToUser = (e) =>  {
        e.preventDefault()
        //navigate(`/${appRoutes.dashboard}/${appRoutes.userActivities}`)
        navigate(`/${appRoutes.dashboard}/${appRoutes.userActivities}`);
    }

    return (
        <div className="users-chart-container">
            <div className='title-container'>
                <span className='title'>One Users Overview</span><span className='user-link'><a onClick={navigateToUser}>View List</a></span>
            </div>
            <div className='composite-chart'>
                <ResponsiveContainer width="100%" height="100%">
                    <ComposedChart
                        width={500}
                        height={400}
                        data={items}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        }}
                    >
                        <CartesianGrid stroke="#f5f5f5" />
                        <XAxis dataKey="createdText" scale="band" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar dataKey="users" barSize={20} fill="#413ea0" />
                        <Line type="monotone" dataKey="cumulativeUser" stroke="#ff7300" />
                    </ComposedChart>
                </ResponsiveContainer>
            </div>
        </div>
    )


}

export default UsersChart