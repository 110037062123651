import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const durationsFilterConfig = {
    key: 'durations',
    isActiveProp: 'isDurationsSearchActive',
    setAction: 'durations',
    resetAction: 'reset-durations',
    setFilterProp: 'setDurationsFilters',
    resetFilterProp: 'resetDurationsFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useDurationFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, durationsFilterConfig)
}

export default useDurationFilterContext;
