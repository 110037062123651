import React, { useEffect } from 'react';
import CreateEdit from '../common/createEdit';



export default ({ form, onCreateCallback, organisationId }) => {

    useEffect(() => {

        console.log('on create rest initial form values');

        form.setFieldsValue({ title: undefined });

    }, [])

    return (
        <CreateEdit
            form={form}
            onSubmitCallback={onCreateCallback}
            organisationId={organisationId}
        />
    )

}