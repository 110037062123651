import { useMemo } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { appRoutes, publishStatusMap } from "../../../config/constants";
const { Text } = Typography;

//const antIcon = <LoadingOutlined style={{ fontSize: 16 }} spin />;

// const userTypeMapper = {
//     SA: 'Super Admin',
//     Admin: 'Admin',
//     Youth: 'Youth'
// }

const CreatorsTable = props => {

    const {
        challenges,
        onPagniationChange,
        onSortChange,
        pageNo,
        total,
        defaultPageSize,
        sortBy,
        sortOrder,
        onDelete,
        // hasEditAccess = false,
        // hasDeleteAccess = false,
        // hasDetailAccess = false,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => {

        console.log('col', sortBy, sortOrder)

        let organistionCol = { defaultSortOrder: null };

        let titleCol = {  defaultSortOrder: undefined}

        const defaultSortOrder = sortOrder === 'ascend' ? 'ascend' : 'descend';

        if (sortBy === 'organisation') {
            organistionCol = { defaultSortOrder }
        } else if (sortBy === 'title') {
            titleCol = { defaultSortOrder }
        } 
        
        return [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: true,
            ...titleCol,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return (
                    !record.canView
                    ? (<Text>{record.title}</Text>)
                    : (<a onClick={() => {
                            navigate(`/${appRoutes.challengeView}/${record.id}`)
                        //console.log('on click', record)
                    }}>
                        {record.title}
                    </a>)
                )
                
            },
        },
        {
            title: 'Organisation',
            dataIndex: 'organisation',
            key: 'organisation',
            sorter: true,
            ...organistionCol,
        },
        {
            title: 'Difficulty',
            dataIndex: 'difficuty',
            key: 'difficuty',
            //sorter: true,
        },
        {
            title: 'Duration',
            dataIndex: 'duration',
            key: 'duration',
            //sorter: true,
        },
        {
            title: 'XP',
            dataIndex: 'xp',
            key: 'xp',
            //sorter: true,
        },
        {
            title: 'Publish',
            dataIndex: 'listStatus',
            key: 'listStatus',
            render: (text, record) => {

                const publishStatus = get(publishStatusMap, text) || '';

                return (<Text>{publishStatus}</Text>)

            }
            //sorter: true,
        },
        {
            title: 'Creator',
            dataIndex: 'creator',
            key: 'creator',
            //sorter: true,
        },
        {
            title: 'Powered By',
            dataIndex: 'poweredBy',
            key: 'poweredBy',
            //sorter: true,
        },
        {
            title: 'Enrolled',
            dataIndex: 'enrolled',
            key: 'enrolled',
            //sorter: true,
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                let extraParams = {};

                if (record.ownerUserId) {
                    extraParams = {
                        'creator_user_id': record.ownerUserId
                    }
                }

                return (
                    <a 
                    { ...extraParams }
                    data-testid='edit-challenge'
                    style={record.canEdit ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                    //className={hasEditAccess && record.canEdit ? 'enabled' : ''}
                    data-can-edit={record.canEdit ? 'yes' : ''}
                    onClick={() => {
                        console.log('on click', record)
                        record.canEdit && navigate(`/${appRoutes.editChallenge}/${record.id}`)
                    }}>
                        Edit
                    </a>
                )
            },
        },
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                return (
                    <a 
                    data-testid='delete-challenge'
                    style={record.canDelete ? {} : { color: 'grey', cursor: 'not-allowed'}} 
                    //className={hasDeleteAccess && record.canDelete ? 'enabled' : ''}
                    data-can-delete={record.canDelete ? 'yes' : ''}
                    onClick={() => {
                        //console.log('on click', record)
                        //navigate(`/${appRoutes.creatorPersonEdit}/${record.id}`)
                        record.canDelete  && onDelete && onDelete(record.id, record.title)
                    }}>
                        Delete
                    </a>
                )
            },
        },
    ]}, [navigate, sortBy, sortOrder ]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

          // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={challenges}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                            pageSizeOptions: []
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default CreatorsTable;