import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { Spin } from 'antd';
import {
  Login,
  Home,
  OrganisationsList,
  ClubList,
  Challenges,
  ChallengeView,
  OrganisationView,
  NewOrganisation,
  EditOrganisation,
  ClubView,
  NewClub,
  EditClub,
  UsersList,
  EditChallenge,
  CreateChallenge,
  Themes,
  Skills,
  Difficulty,
  RolePermissions,
  EditRolePermisson,
  EditUser,
  UserView,
  NewUser,
  Posts,
  Notifications,
  NotificationSend,
  NotificationDetails,
  UserMessages,
  ReportedItems,
  AgesItems,
  MaterialsItems,
  OneKidsPosts,
  EmailList,
  SendEmails,
  SendEmailDetails,
  UnsubscribedEmails,
  AdminLayout,
  PlayMomentCalendar,
  Dashboard,
  DashboardHome,
  UserActivities,
  SessionLogs,
  UserLogs,
  NpsResponses,
} from "../screens";
import useAppSetup from "./useAppSetup";
import { appRoutes } from "../config/constants";


const Main = () => {

  const { isSettingUp, loginAsync } = useAppSetup();

  return (
    <div className="one-admin">
      <Spin spinning={isSettingUp}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            <Routes>
              {
                isSettingUp
                  ? (<Route path={`/${appRoutes.login}`} element={<Login loginAsync={loginAsync} />} />)
                  :
                  <React.Fragment>
                    <Route path="/" element={<Challenges />} />
                    <Route path={`/${appRoutes.login}`} element={<Login loginAsync={loginAsync} />} />
                    <Route path={`/${appRoutes.home}`} element={<Home />} />
                    <Route path={`/${appRoutes.organisations}`} element={<OrganisationsList />} />
                    <Route path={`/${appRoutes.clubs}`} element={<ClubList />} />
                    <Route path={`/${appRoutes.challenges}`} element={<Challenges />} />
                    <Route path={`/${appRoutes.challengeView}/:challengeId`} element={<ChallengeView />} />
                    <Route path={`/${appRoutes.editChallenge}/:challengeId`} element={<EditChallenge />} />
                    <Route path={`/${appRoutes.createChallenge}`} element={<CreateChallenge />} />
                    <Route path={`/${appRoutes.dashboard}`} element={<Dashboard activeItem="users" />} >
                        <Route path={appRoutes.users} element={<UsersList />} />
                        <Route path={appRoutes.userActivities} element={<UserActivities />} />
                        <Route path={`${appRoutes.editUser}/:userId`} element={<EditUser />} />
                        <Route path={`${appRoutes.userView}/:userId`} element={<UserView />} />
                        <Route path={`${appRoutes.newUser}`} element={<NewUser />} />
                    </Route>
                    <Route path={`/${appRoutes.organisations}/:organisationId`} element={<OrganisationView />} />
                    <Route path={`/${appRoutes.newOrganisation}`} element={<NewOrganisation />} />
                    <Route path={`/${appRoutes.editOrganisation}/:organisationId`} element={<EditOrganisation />} />
                    <Route path={`/${appRoutes.clubView}/:clubId`} element={<ClubView />} />
                    <Route path={`/${appRoutes.newClub}`} element={<NewClub />} />
                    <Route path={`/${appRoutes.editClub}/:clubId`} element={<EditClub />} />
                    <Route path={`/${appRoutes.themes}`} element={<Themes />} />
                    <Route path={`/${appRoutes.skills}`} element={<Skills />} />
                    <Route path={`/${appRoutes.difficulty}`} element={<Difficulty />} />
                    <Route path={`/${appRoutes.rolePermissions}`} element={<RolePermissions />} />
                    <Route path={`/${appRoutes.editRolePermissions}/:roleId`} element={<EditRolePermisson />} />
                    <Route path={`/${appRoutes.posts}`} element={<Posts />} />
                    <Route path={`/${appRoutes.notifications}`} element={<Notifications />} />
                    <Route path={`/${appRoutes.notificationSend}`} element={<NotificationSend />} />
                    <Route path={`/${appRoutes.notificationDetails}/:notificationId`} element={<NotificationDetails />} />
                    <Route path={`/${appRoutes.messages}`} element={<UserMessages />} />
                    <Route path={`/${appRoutes.reported}`} element={<ReportedItems />} />
                    <Route path={`/${appRoutes.ages}`} element={<AgesItems />} />
                    <Route path={`/${appRoutes.materials}`} element={<MaterialsItems />} />
                    <Route path={`/${appRoutes.oneKidsPosts}`} element={<OneKidsPosts />} />
                    <Route path={`/${appRoutes.emails}`} element={<EmailList />} />
                    <Route path={`/${appRoutes.sendEmails}`} element={<SendEmails />} />
                    <Route path={`/${appRoutes.sendEmailDetails}/:sentId`} element={<SendEmailDetails />} />
                    <Route path={`/${appRoutes.unsubscribedEmails}`} element={<UnsubscribedEmails />} />
                    <Route path={`/${appRoutes.adminArea}`} element={<Dashboard activeItem="calender" />} >
                        <Route path={`${appRoutes.calender}`}  element={<PlayMomentCalendar />} />
                    </Route>
                    <Route path={`/${appRoutes.dashboard}`} element={<Dashboard activeItem="dashboard" />} >
                        <Route path={appRoutes.dashHome} element={<DashboardHome />} />
                    </Route>
                    <Route path={`/${appRoutes.dashboard}`} element={<Dashboard activeItem="sessionLogs" />} >
                        <Route path={appRoutes.sessionLogs} element={<SessionLogs />} />
                        <Route path={`${appRoutes.userLogs}/:sessionId`} element={<UserLogs />} />
                    </Route>
                    <Route path={`/${appRoutes.dashboard}`} element={<Dashboard activeItem="npsresponses" />} >
                        <Route path={appRoutes.npsResponses} element={<NpsResponses />} />
                    </Route>
                  </React.Fragment>
              }
            </Routes>
          </QueryParamProvider>
        </BrowserRouter>
      </Spin>
    </div>
  )

}


export default Main