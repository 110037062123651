import React from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../../config/constants';
import { useQuery } from 'react-query';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';



const colors ={
    Sent: '#8884d8',
    Delivered: '#82ca9d',
    Opened: '#0088FE',
    Bounced: '#EC407A',
    Complaint: '#252D5C',
  }

const SentEmailBarChart = () => {

    const { httpPostAsync } = useHttpHelper();

    const fetchStatsAsync = async () => {
        try {

            const link = appLinks.sentEmailStats

            const response = await httpPostAsync(link)

            console.log('sent email stats', response?.data)

            const data = response?.data ?? {}

             const items = data.items?.map(e => {

                return {
                    ...e,
                    sentAt: getAppZoneMomentFromUTC(e.sentAt)
                }

             }) ?? []

             return {
                ...data,
                items,

             }
            
        } catch (error) {
            console.log('error fetching emails overview data')
        }
    }

    const {
        data,
        isLoading,
    } = useQuery(
        [queryKeys.sentEmailStats],
        fetchStatsAsync,
        {
            refetchOnWindowFocus: false,
        });

    const items = data?.items ?? []

    let width = '100%'

    if (items?.length <= 3) {
        width = '50%'
    }

    return (
        <div className='sent-emails-bar-chart-container'>
             <div className='title-container'>
                <span className='title'>Sent Emails by Title</span>
            </div>
            <div className='email-bar-chart'>
            <ResponsiveContainer width={width} height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={items}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend  iconType='circle'/>
                    <Bar dataKey="sent" stackId="sent" fill={data?.colors?.Sent ?? colors.Sent} />
                    <Bar dataKey="delivered" stackId="stats" fill={data?.colors?.Delivered ?? colors.Delivered} />
                    <Bar dataKey="opened" stackId="stats" fill={data?.colors?.Opened ?? colors.Opened} />
                    <Bar dataKey="bounced" stackId="stats" fill={data?.colors?.Bounced ?? colors.Bounced} />
                    <Bar dataKey="complaint" stackId="stats" fill={data?.colors?.Complaint ?? colors.Complaint} />
                </BarChart>
            </ResponsiveContainer>
            </div>
        </div>
    )

}

export default SentEmailBarChart