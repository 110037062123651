

export const updateThemeListHOF = (from, to) => (previous) => {
    try {

        //console.log('update info', from, to)

        // const schema = {
        //     pages: [
        //         {
        //             data: [
        //                 {id: 1}
        //             ]
        //         }
        //     ]
        // }

        const pages = previous?.pages ?? [];

        console.log('pages ', pages);

        for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {

            const fromItemIndex = previous.pages[pageIndex].data?.findIndex(e => e.id === from.id)
            const toItemIndex = previous.pages[pageIndex].data?.findIndex(e => e.id === to.id)

            previous.pages[pageIndex].data[fromItemIndex] = to
            previous.pages[pageIndex].data[toItemIndex] = from
            //previous.pages[pageIndex].data = [...previous.pages[pageIndex].data]

        }

        return previous

    } catch (error) {
        console.log('error updating data in the list', error);
        return previous;
    }
}

