import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const postsFilterConfig = {
    key: 'posts',
    isActiveProp: 'isPostsSearchActive',
    setAction: 'posts',
    resetAction: 'reset-posts',
    setFilterProp: 'setPostsFilters',
    resetFilterProp: 'resetPostsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const usePostFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, postsFilterConfig)
}

export default usePostFilterContext;
