import React from 'react';
import { Form, Input } from 'antd';


export default (props) => {

    const { index } = props;

    return (
        <Form.Item
            name={[index, "link"]}
            label='Youtube link'
            rules={[{ required: true, message: 'Youtube link is required.' }]}
        >
            <Input placeholder='Link' />
        </Form.Item>
    )
}