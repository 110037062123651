import React, { useEffect } from 'react';
import { get } from 'lodash';
import CreateEdit from '../common/createEdit';


export default ({
    age,
    organisationId,
    form,
    onUpdateAsync
}) => {

    useEffect(() => {

        let value = { age };

        form.setFieldsValue(value);

    }, [age]);

    return (
        <CreateEdit
            form={form}
            onSubmitCallback={onUpdateAsync}
            organisationId={organisationId}
        />
    )

}