import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const userMessagesFilterConfig = {
    key: 'userMessages',
    isActiveProp: 'isuserMessagesSearchActive',
    setAction: 'user-messages',
    resetAction: 'reset-user-messages',
    setFilterProp: 'setUserMessagesFilters',
    resetFilterProp: 'resetUserMessagesFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        organisations: [],
        statuses: [],
        sortBy: null,
        sortOrder: null,
    }
}

const useUserMessagesFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, userMessagesFilterConfig)
}

export default useUserMessagesFilterContext;
