import appConfig from "./config";


export const appLinks = {
    login: `${appConfig.baseUrl}api/admin/auth/login`,
    refreshTokenLogin: `${appConfig.baseUrl}api/admin/auth/token/refresh`,
    loggedInUserInfo: `${appConfig.baseUrl}api/admin/profile/info`,   
    tokenRefresh: `${appConfig.baseUrl}api/admin/profile/refresh`,   
    users: `${appConfig.baseUrl}api/admin/account/users`,   
    usersLastActivityList: `${appConfig.baseUrl}api/admin/account/users/last-activity`,
    usersDetails: `${appConfig.baseUrl}api/admin/account/users/{userId}`,   
    updateUser: `${appConfig.baseUrl}api/admin/account/users/{userId}`,   
    createUser: `${appConfig.baseUrl}api/admin/account/user`,
    deleteUser: `${appConfig.baseUrl}api/admin/account/users/{userId}`,   
    userRoles: `${appConfig.baseUrl}api/admin/permissions/roles/list`,
    checkCreator: `${appConfig.baseUrl}api/admin/account/users/creator-check/{organisationId}`,
    creatorPersons: `${appConfig.baseUrl}api/admin/creator-person/list`,  
    getCreatorPersonUploadImageUrl: `${appConfig.baseUrl}api/admin/creator-person/get-icon-upload-url`, 
    adminOrganisations: `${appConfig.baseUrl}api/admin/lookups/oranisations`, 
    adminOrganisationsV2: `${appConfig.baseUrl}api/admin/lookups/v2/oranisations`, 
    createNewPerons: `${appConfig.baseUrl}api/admin/creator-person`, 
    creatorDetails: `${appConfig.baseUrl}api/admin/creator-person/{creatorId}`, 
    updateCreatorPerson: `${appConfig.baseUrl}api/admin/creator-person/{creatorId}`, 
    deleteCreator: `${appConfig.baseUrl}api/admin/creator-person/{creatorId}`,
    organistions: `${appConfig.baseUrl}api/admin/organisation/list`,  
    clubs: `${appConfig.baseUrl}api/admin/club/list`,  
    challenges: `${appConfig.baseUrl}api/admin/challenge/list`,  
    difficultyLevelsV1: `${appConfig.baseUrl}api/admin/lookups/v1/difficulty-levels`,
    difficultyLevels: `${appConfig.baseUrl}api/admin/lookups/difficulty-levels`,
    durationsV1: `${appConfig.baseUrl}api/admin/lookups/v1/durations`,
    durations: `${appConfig.baseUrl}api/admin/lookups/durations`,
    themesV1: `${appConfig.baseUrl}api/admin/lookups/v1/themes`,
    themes: `${appConfig.baseUrl}api/admin/lookups/themes`,
    skillsV1: `${appConfig.baseUrl}api/admin/lookups/v1/skills`,
    skills: `${appConfig.baseUrl}api/admin/lookups/skills`,
    organisationRoles: `${appConfig.baseUrl}api/admin/lookups/roles/{organisationId}`,
    introTemplates: `${appConfig.baseUrl}api/admin/lookups/intro-templates`,
    challengeDetails: `${appConfig.baseUrl}api/admin/challenge/{challengeId}`,
    organisationDetails: `${appConfig.baseUrl}api/admin/organisation/{organisationId}`,
    deleteOrganisation: `${appConfig.baseUrl}api/admin/organisation/{organisationId}`,
    createNewOranisation: `${appConfig.baseUrl}api/admin/organisation`,
    uploadOrganisationImagesUrl: `${appConfig.baseUrl}api/admin/organisation/get-temp-upload-url`, 
    updateOrganisation: `${appConfig.baseUrl}api/admin/organisation/{organisationId}`, 
    clubDetails: `${appConfig.baseUrl}api/admin/club/{clubId}`, 
    createClub: `${appConfig.baseUrl}api/admin/club`, 
    updateClub: `${appConfig.baseUrl}api/admin/club/{clubId}`, 
    deleteClub: `${appConfig.baseUrl}api/admin/club/{clubId}`, 
    updateChallenge: `${appConfig.baseUrl}api/admin/challenge/{challengeId}`,
    uploadResourceUrl: `${appConfig.baseUrl}api/admin/challenge/get-temp-upload-url`,
    creatorsLookups: `${appConfig.baseUrl}api/admin/lookups/creators`,
    clubsLookups: `${appConfig.baseUrl}api/admin/lookups/clubs`,
    uploadChallengeThumb: `${appConfig.baseUrl}api/admin/challenge/get-thumb-temp-upload-url`,
    uploadChallengeCoverImage: `${appConfig.baseUrl}api/admin/challenge/get-image-temp-upload-url`,
    createChallenge: `${appConfig.baseUrl}api/admin/challenge`,
    deleteChallenge: `${appConfig.baseUrl}api/admin/challenge/{challengeId}`,
    adminThemes: `${appConfig.baseUrl}api/admin/static/themes/list`,
    toggleThemeVisibilityStatus: `${appConfig.baseUrl}api/admin/static/themes/toggle-visibility/{themeId}`,
    toggleThemeDateRange: `${appConfig.baseUrl}api/admin/static/themes/toggle-enable-date-range/{themeId}`,
    themeUpdateDateRange: `${appConfig.baseUrl}api/admin/static/themes/update-date-range/{themeId}`,
    themeCoverImageUploadUrl: `${appConfig.baseUrl}api/admin/static/themes/get-temp-upload-url`,
    updateTheme: `${appConfig.baseUrl}api/admin/static/themes/{themeId}`,
    createTheme: `${appConfig.baseUrl}api/admin/static/themes`,
    deleteTheme: `${appConfig.baseUrl}api/admin/static/themes/{themeId}`,
    updateThemeSort: `${appConfig.baseUrl}api/admin/static/themes/update-sort`,
    adminSkills: `${appConfig.baseUrl}api/admin/static/skills/list`,
    updateSkill: `${appConfig.baseUrl}api/admin/static/skills/{skillId}`,
    createSkill: `${appConfig.baseUrl}api/admin/static/skills`,
    deleteSkill: `${appConfig.baseUrl}api/admin/static/skills/{skillId}`,
    adminDifficulty: `${appConfig.baseUrl}api//admin/static/difficulty/list`,
    updateDifficulty: `${appConfig.baseUrl}api/admin/static/difficulty/{difficultyId}`,
    createDifficulty: `${appConfig.baseUrl}api/admin/static/difficulty`,
    deleteDifficulty: `${appConfig.baseUrl}api/admin/static/difficulty/{difficultyId}`,
    adminDurations: `${appConfig.baseUrl}api/admin/static/durations/list`,
    updateDuration: `${appConfig.baseUrl}api/admin/static/durations/{durationId}`,
    createDuration: `${appConfig.baseUrl}api/admin/static/durations`,
    deleteDuration: `${appConfig.baseUrl}api/admin/static/durations/{durationId}`,
    rolePermissions:  `${appConfig.baseUrl}api/admin/permissions/roles/{roleId}`,
    updateRolePermissions:  `${appConfig.baseUrl}api/admin/permissions/roles/{roleId}`,
    checkExistingUserWithEmail: `${appConfig.baseUrl}api/admin/account/users/existing-user`,
    challengePosts: `${appConfig.baseUrl}api/admin/posts`,
    changeChallengeListingStatus: `${appConfig.baseUrl}api/admin/challenge/change-listing-status`,
    toggleDailyActivity: `${appConfig.baseUrl}api/admin/challenge/toggle-daily-activity/{challengeId}`,
    challengeEnrollmentCount: `${appConfig.baseUrl}api/admin/challenge/get-enrollment-count/{challengeId}`,
    challengeWeb: `${appConfig.webUrl}challenge/{challengeId}`,
    challengesLookup: `${appConfig.baseUrl}api/admin/lookups/challenges/{organisationId}`,
    sentAppNotifications: `${appConfig.baseUrl}api/admin/notification`,
    userLookups: `${appConfig.baseUrl}api/admin/lookups/users`,
    oneUserLookups: `${appConfig.baseUrl}api/admin/lookups/users-list`,
    getRecipientsFromEmail:  `${appConfig.baseUrl}api/admin/get-recipients/{organisationId}`,
    sentNotifications:  `${appConfig.baseUrl}api/admin/sent-notifications`,
    sentNotificationDetails: `${appConfig.baseUrl}api/admin/notification-details/{notificationId}`,
    userMessages: `${appConfig.baseUrl}api/admin/user/messages`,
    updateShowPinned: `${appConfig.baseUrl}api/admin/organisation/upadate-show-pinned/{organisationId}`,
    updateShowPopular: `${appConfig.baseUrl}api/admin/organisation/upadate-show-popular/{organisationId}`,
    reportedItems: `${appConfig.baseUrl}api/admin/reported`,
    agesList: `${appConfig.baseUrl}api/admin/static/ages/list`,
    createAge: `${appConfig.baseUrl}api/admin/static/ages`,
    updateAge: `${appConfig.baseUrl}api/admin/static/ages/{ageId}`,
    deleteAge: `${appConfig.baseUrl}api/admin/static/ages/{ageId}`,
    materialsList: `${appConfig.baseUrl}api//admin/static/materials/list`,
    createMaterials: `${appConfig.baseUrl}api/admin/static/materials`,
    updateMaterials: `${appConfig.baseUrl}api/admin/static/materials/{materialId}`,
    deleteMaterials: `${appConfig.baseUrl}api/admin/static/materials/{materialId}`,
    agesOrganisationLookups: `${appConfig.baseUrl}api/admin/lookups/ages`,
    materialsOrganisationLookups: `${appConfig.baseUrl}api/admin/lookups/materials`,
    oneValuesLookups: `${appConfig.baseUrl}api/admin/lookups/one-values/{organisationId}`,
    areasLookups: `${appConfig.baseUrl}api/admin/lookups/areas/{organisationId}`,
    locations: `${appConfig.baseUrl}api/admin/lookups/locations`,
    oneKidsPosts: `${appConfig.baseUrl}api/admin/onekids-posts`,
    challengesActivitiesLookup: `${appConfig.baseUrl}api/admin/challenge/lookups/get-activities/{organisationId}`,
    generateChallenge: `${appConfig.baseUrl}api/admin/challenge/generate`,
    getEmailTemplate: `${appConfig.baseUrl}api/email/manage/email-template`,
    saveAndSendEmails: `${appConfig.baseUrl}api/email/manage/save-entry-send`,
    sentEmailsDetails: `${appConfig.baseUrl}api/email/manage/entry/{id}`,
    sentEmails: `${appConfig.baseUrl}api/email/manage/entries/list`,
    sentTestEmail: `${appConfig.baseUrl}api/email/manage/sent-test-email`,
    unsubscribedEmailList: `${appConfig.baseUrl}api/email/manage/entries/unsubscribed/list`,
    addPlaymomentScheduleEntry: `${appConfig.baseUrl}api/admin/challenge/schedule`,
    getPlaymomentScheduleEntries: `${appConfig.baseUrl}api/admin/challenge/schedule/entries`,
    updatePlaymomentScheduleEntry: `${appConfig.baseUrl}api/admin/challenge/schedule/update`,
    removePlaymomentEntry: `${appConfig.baseUrl}api/admin/challenge/schedule/delete/{id}`,
    allSentEmailStats: `${appConfig.baseUrl}api/email/stats/all-sent-emails`,
    sentEmailStats: `${appConfig.baseUrl}api/email/stats/sent-emails`,
    registeredOneUsersStats: `${appConfig.baseUrl}api/admin/user/one-user-stats`,
    downloadUsersList: `${appConfig.baseUrl}api/admin/account/users/download`,
    downloadLastActivtyUsersList: `${appConfig.baseUrl}api/admin/account/users/last-activity/download`,
    userTypeStats: `${appConfig.baseUrl}api/admin/account/users/type-stats`,
    sessionLogs: `${appConfig.baseUrl}api/admin/logs/session`,
    userLogs: `${appConfig.baseUrl}api/admin/logs`,
    npsReponses: `${appConfig.baseUrl}api/admin/account/users/nps-responses`,
    seasonsLookups: `${appConfig.baseUrl}api/admin/lookups/seasons`
}

export const appRoutes = {
    dashboard: 'dashboard',
    login: 'login',
    home: 'home',
    creatorPersons: 'creatorPersons',
    newCreatorPerson: 'newcreator',
    creatorPersonView: 'viewcreator',
    creatorPersonEdit: 'editcreator',
    organisations: 'organisations',
    newOrganisation: 'neworganisation',
    editOrganisation: 'editorganisation',
    clubs: 'clubs',
    challenges: 'challenges',
    challengeView: 'challenges',
    editChallenge: 'editchallenge',
    clubView: 'clubs',
    newClub: 'newclub',
    editClub: 'edit-user',
    users: 'users',
    userActivities: 'user-activities',
    usersView: 'users',
    editUser: 'editUser',
    newUser: 'new-user',
    userView: 'user-details',
    createChallenge: 'createChallenge',
    themes: 'themes',
    skills: 'skills',
    difficulty: 'difficulty',
    durations: 'durations',
    rolePermissions: 'role-permissions',
    editRolePermissions: 'edit-role-permissions',
    posts: 'posts',
    notifications: 'notifications',
    notificationSend: 'notification',
    notificationDetails: 'notification-details',
    messages: 'messages',
    reported: 'reported',
    ages: 'ages',
    materials: 'materials',
    oneKidsPosts: 'onekids-posts',
    emails: 'emails',
    sendEmails: 'send-email',
    sendEmailDetails: 'send-email-details',
    unsubscribedEmails: 'unsubscribed-emails',
    adminArea: 'admin',
    calender: 'calender',
    dashHome: 'dash-home',
    sessionLogs: 'session-logs',
    userLogs: 'user-logs',
    npsResponses: 'nps-responses'
}

export const queryKeys = {
    foundationOrganisations: 'foundationOrganisations',
    organisations: 'organisations',
    creatorPersonDetails: 'creatorPersonDetails',
    creatorDetailsView: 'creatorDetailsView',
    updateCreatorPerson: 'updateCreatorPerson',
    creatorsList: 'creatorsList',
    deleteCreator: 'deleteCreator',
    difficultyLevels: 'difficultyLevel',
    durations: 'durations',
    themes: 'themes',
    skills: 'skills',
    introTemplates: 'introTemplates',
    challenges: 'challenges',
    challengeDetailsView: 'challengeDetailsView',
    organisationDetailsView: 'organisationDetailsView',
    createOrganisation: 'createOrganisation',
    deleteOrganisation: 'deleteOrganisation',
    createNewPerson: 'createNewPerson',
    updateOrgansation: 'updateOrgansation',
    clubDetailsView: 'clubDetailsView',
    createNewClub: 'createNewClub',
    updateClub: 'updateClub',
    deleteClub: 'deleteClub',
    users: 'users',
    usersLastActivtyList: 'usersLastActivtyList',
    userRoles: 'userRoles',
    userDetails: 'userDetails',
    userIsCreator: 'userIsCreator',
    updateUser: 'updateUser',
    deleteUser: 'deleteUser',
    createUser: 'createUser',
    updateChallenge: 'updateChallenge',
    creatorLookup: 'creatorLookup',
    clubLookups: 'clubLookups',
    createChallenge: 'createChallenge',
    deleteChallenge: 'deleteChallenge',
    settingsThemesList: 'settingsThemesList',
    updateTheme: 'updateTheme',
    createTheme: 'createTheme',
    deleteTheme: 'deleteTheme',
    updateThemeSort: 'updateThemeSort',
    settingsSkillsList: 'settingsSkillsList',
    updateSkill: 'updateSkill',
    createSkill: 'createSkill',
    deleteSkill: 'deleteSkill',
    settingsDifficultyList: 'settingsDifficultyList',
    updateDifficulty: 'updateDifficulty',
    createDifficulty: 'createDifficulty',
    deleteDifficulty: 'deleteDifficulty',
    settingsDurationsList: 'settingsDurationsList',
    updateDuration: 'updateDurations',
    createDuration: 'createDuration',
    deleteDuration: 'deleteDuration',
    rolePermissions: 'rolePermissions',
    updateRolePermissions: 'updateRolePermissions',
    organisationRoles: 'organisationRoles',
    challengePosts: 'challengePosts',
    organisationClubs: 'organisationClubs',
    challengesLookup: 'challengesLookup',
    userLookups: 'userLookups',
    oneUserLookups: 'oneUserLookups',
    userEmails: 'userEmails',
    sentNotifications: 'sentNotifications',
    notificationDetails: 'notificationDetails',
    userMessages: 'userMessages',
    reportedItems: 'reportedItems',
    clubLookupsFilter: 'clubLookupsFilter',
    settingsAgesList: 'settingsAgesList',
    createAge: 'createAge',
    updateAge: 'updateAge',
    deleteAge: 'deleteAge',
    settingsMaterialsList: 'settingsMaterialsList',
    createMaterials: 'createMaterials',
    updateMaterials: 'updateMaterials',
    deleteMaterials: 'deleteMaterials',
    agesOrganisationsLookups: 'agesOrganisationsLookups',
    materialsOrganisationsLookups: 'materialsOrganisationsLookups',
    oneValuesLookups: 'oneValuesLookups',
    areasLookups: 'areasLookups',
    locations: 'locations',
    oneKidsPosts: 'oneKidsPosts',
    oneOrganisations: 'oneOrganisations',
    sentEmailDetails: 'sentEmailDetails',
    sentEmailsList: 'sentEmailsList',
    unsubscribedEmailList: 'unsubscribedEmailList',
    playmomentCalendar: 'playmomentCalendar',
    emailsOverview: 'emailsOverview',
    sentEmailStats: 'sentEmailStats',
    registeredOneUserStats: 'registeredOneUserStats',
    userStatusStats: 'userStatusStats',
    sessionLogs: 'sessionLogs',
    userLogs: 'userLogs',
    seasonsLookups: 'seasonsLookups',
}

export const organisationTypes = {
    organisation: 'organisation',
    foundation: 'foundation',
    sponsor: 'sponsor'
}

export const resourceType = {
    video: 'video',
    youtubeVideo: 'youtube_video',
    link: 'link',
    image: 'image',
    pdf: 'pdf',
    file: 'file',
    viewDownload: 'view_download',
}


export const imageFilesExenstions = ['.jpg', '.jpeg', '.png', '.svg', '.bmp']

export const creatorTypes = {
    creator: 'creator',
    organisation: 'organisation',
    club: 'club',
    text: 'text',
}

export const publishStatusMap = {
    listed: 'Published',
    unlisted: 'Not published'
}

export const reportedPostsType = [
    {  value: 'all', label: 'All Reported' },
    {  value: 'violence', label: 'Violence'  },
    {  value: 'offensive', label: 'Offensive' },
    {  value: 'threat', label: 'Threat' },
    {  value: 'other', label: 'Other' },
  ]


  export const uploadFileTypes = {
    image: 'image',
    video: 'video',
    audio: 'audio',
    pdf: 'pdf',
    other: 'other',
}
