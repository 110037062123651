import { useContext } from "react";
import { get } from "lodash";
import PermissionContext, { childPermission, parentPermissions, permissionValues, settingsChildPermissions } from ".";


export default function useThemesPermissionsContext() {

    const { permissions = {} } = useContext(PermissionContext);

    const hasThemesAccess = get(permissions, `${parentPermissions.settings}.${settingsChildPermissions.themes}`) === permissionValues.yes;

    const themesCreate = `${parentPermissions.settings}.${settingsChildPermissions.themes}.${childPermission.create}`;
    const hasThemesCreateAccess = hasThemesAccess &&
        get(permissions, themesCreate, permissionValues.no) !== permissionValues.no;

    const themesEdit = `${parentPermissions.settings}.${settingsChildPermissions.themes}.${childPermission.edit}`;
    const hasThemesEditAccess = hasThemesAccess &&
        get(permissions, themesEdit, permissionValues.no) !== permissionValues.no;

    const themesDelete = `${parentPermissions.settings}.${settingsChildPermissions.themes}.${childPermission.delete}`;
    const hasThemesDeleteAccess = hasThemesAccess &&
        get(permissions, themesDelete, permissionValues.no) !== permissionValues.no;

    return {
        hasThemesCreateAccess,
        hasThemesEditAccess,
        hasThemesDeleteAccess
    }

}