import { includes } from "lodash";
import { useCallback, useMemo } from "react";
import useHttpHelper from "../../../../common/hooks/useHttpHelper";
import { appLinks, organisationTypes, queryKeys } from "../../../../config/constants";


const defaultParams = {};

export default function useOrganisationsLookups(organisationId, type, includeInfo, oneOrganisations) {

    const { httpPostAsync } = useHttpHelper();

    const organisationParams = useMemo(() => {

        let params = defaultParams;

        if (organisationId && organisationId > 0) {
            params = { ...params, organisationId }
        }

        if (type && includes(organisationTypes, type)) {
            params = { ...params, type }
        }

        if (!!includeInfo) {
            params = { ...params, includeInfo: true }
        }

        if (!!oneOrganisations) {
            params = { ...params, oneOrganisations: true }
        }

        return params;

    }, [organisationId, type, includeInfo, oneOrganisations])

    const fetchOrganisationsAsync = useCallback(async (params = {}) => {

        console.log('fetch organisations params', params)

        return await httpPostAsync(appLinks.adminOrganisationsV2, params)
    }, []);
    

    const organisationQueryKeyFn = useCallback(({ organisationId, type, includeInfo, oneOrganisations}) => {

        let key = [queryKeys.organisations, organisationId, type, includeInfo, oneOrganisations];

        console.log('organisation, query key', key)

        return key
    }, []);

    return {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    }

}


export function useOrganisationsLookupsV2(type, query) {

    const { httpPostAsync } = useHttpHelper();

    const organisationParams = useMemo(() => {

        let params = defaultParams;

        if (type && includes(organisationTypes, type)) {
            params = { ...params, type }
        }

        if (typeof query === 'string') {
            params = { ...params, query }
        }

        return params;

    }, [type, query])

    const fetchOrganisationsAsync = useCallback(async (params = {}) => {

        console.log('fetch organisations params', params)

        return await httpPostAsync(appLinks.adminOrganisationsV2, params)
    }, []);
    

    const organisationQueryKeyFn = useCallback(({ type, query }) => {

        let key = [queryKeys.organisations, type, query];

        console.log('organisation, query key', key)

        return key

    }, []);

    return {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    }

}