import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";



export const notificationsFilterConfig = {
    key: 'notifications',
    isActiveProp: 'isNotificationSearchActive',
    setAction: 'notifications',
    resetAction: 'reset-notifications',
    setFilterProp: 'setNotificationsFilters',
    resetFilterProp: 'resetNotificationsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        sortBy: null,
        sortOrder: null,
        organisations: [],
    }
}

const useNotificationsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, notificationsFilterConfig)
}

export default useNotificationsFilterContext;
