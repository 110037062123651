import { get } from 'lodash';

const commonConfig = {
    apiDateFormat: 'YYYY-MM-DD HH:mm:ss',
    displayFormat: 'Do MMMM, YYYY',
    appTimeZone: 'Europe/London',
  };


const config = {
    development: {
        ...commonConfig,
        //baseUrl: 'https://pt5ltc93i1.execute-api.eu-central-1.amazonaws.com/pro/',
        baseUrl: 'http://127.0.0.1:3000/',
        webUrl: 'https://playmoments.app/',
        refreshTokenStorageKey: 'one.dev.ref',
    }, 
    staging: {
        ...commonConfig,
        baseUrl: 'https://oneapi.burrow.link/',
        webUrl: 'http://playmoments.com/',
        refreshTokenStorageKey: 'one.staging.ref',
    },
    production: {
        ...commonConfig,
        //baseUrl: 'https://laoexuci5b.execute-api.eu-central-1.amazonaws.com/production/',
        baseUrl: 'https://api.playmoments.app/',
        webUrl: 'https://playmoments.app/',
        refreshTokenStorageKey: 'one.prod.ref',
        chapgptUrl: 'https://zqczdse7rfneidbmjejvhavdzq0lhyhr.lambda-url.eu-central-1.on.aws',
    }
}

const environment = process.env.REACT_APP_ENVIRONMENT;

const appConfig = get(config, environment, config.development);


export default appConfig;

