import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { Breadcrumb, Button, Row, Spin, message } from 'antd';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import AppLayout from '../../../common/components/layout';
import ChallengeForm from '../common/createEdit';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { filter, find, get, isEmpty } from 'lodash';
import { useChallengesPermissionContext, useMenuPermissionsContext } from '../../../common/components/permissionContext';

const defaultValues = {
  xp: 100,
  isFacilitationNeeded: false,
  creatorType: 'creator',
  coverImageType: 'image',
  listImageType: 'image',
  duration: 5,
  promptAgeGroups: '3 to 4',
  prompt: `
  You are an early childhood professional.
  Create an activity for parents and kids, age {ageGroup}, to do at home using a {materials}. 
  The activity should include intro, description, list of steps, list of materials, list of skills, list of values, list of areas of development. 
  Provide the output in json format with keys: intro, description, steps, skills, materials, values, areasOfDevelopment
  `
}

const ContainerDiv = styled.div`
background-color: white;
padding-left: 24px;
padding-top: 24px;
`;

export default (props) => {

  const navigate = useNavigate();

  let location = useLocation();

  // move to a hook
  const [currentTab, setCurrentTab] = useQueryParam('tab', withDefault(StringParam, 'intro'));

  const setTabParam = (key) => {
    key && setCurrentTab(key)
  }

  const organisationId = get(location, 'state.organisationId');

  if (!organisationId || !organisationId > 0) {
    console.log('organisation id not supplied', organisationId)
    return <Navigate to={`/${appRoutes.challenges}`} state={{ from: location }} replace />;
  }

  const { httpPostAsync } = useHttpHelper();

  const checkIsCreatorAsync = async () => {
    try {

      const response = await httpPostAsync(appLinks.checkCreator.replace('{organisationId}', organisationId));

      console.log('is creator', response);

      return response;

    } catch (error) {
      console.log('error checking if user is creator', error);
    }
  }

  const {
    data,
    isLoading,
    isSuccess
  } = useQuery(
    [queryKeys.userIsCreator, organisationId],
    checkIsCreatorAsync,
    {
      enabled: organisationId > 0,
      refetchOnWindowFocus: false
    }
  );

  const initialValues = useMemo(() => {

    const creatorPerson = get(data, 'data');

    console.log('cretor person', creatorPerson);

    let extraData = {};

    if (!isEmpty(creatorPerson)) {

      const { id, name, title, website, logoLink } = creatorPerson;

      extraData = {
        creatorId: id,
        creatorPerson: {
          name,
          title,
          website,
          logoLink,
        }
      }
    }

    return {
      ...defaultValues,
      organisationId,
      creatorPerson,
      // duration: moment().startOf('day').add(5, 'minutes'),
      // publish: true,
      ...extraData,
      // isActive,
    }

  }, [organisationId, data]);


  const createChallengeAsync = async (data) => {
    //const link = appLinks.createNewPerons
    // console.log('accept challenge link', link);
    console.log('challenge post data', data);
    const createChallengeData = { ...data };
    const response = await httpPostAsync(appLinks.createChallenge, createChallengeData);
    console.log('create challenge async', response);

    return response;
  };

  const mutationQueryKey = [queryKeys.createChallenge]

  const {
    //data: createPersonResponse,
    isLoading: isCreating,
    mutateAsync: onCreateChallengeAsync,
  } = useMutation(
    createChallengeAsync,
    { mutationKey: mutationQueryKey, }
  );

  const onSubmit = async (postData, redirect = false) => {
    try {

      console.log('postData', postData);

      const response = await onCreateChallengeAsync(postData);
      //const response = { code: '500' }

      console.log('create challenge response', response);

      if (response && response.code === 0) {
        message.success('Challenge was created successfully');

        if (redirect) {
          // save and continue, => redirect to challenges listing
          navigate(`/${appRoutes.challenges}`);
        } else {
          //save/create new => created new and redirect to edit
          const createdChallengeId = get(response, 'data.id');
          if (createdChallengeId > 0) {
            let params = { pathname: `/${appRoutes.editChallenge}/${createdChallengeId}` };

            if (!isEmpty(currentTab)) {
              params = { ...params, search: `tab=${currentTab}`, };
            };

            navigate(params)

          }
        }

      } else if (response && response.code === 0) {
        message.error('Challenge could not be created')
      }

    } catch (error) {
      console.log('error submitting', error);
    }
  }

  const onBack = () => {
    try {

      navigate(`/${appRoutes.challenges}`);

    } catch (error) {
      console.log('error on back click', error);
    }
  }

  const [organisationState, setOrganisationState] = useState([]);
  const [challengeOrganistionId, setChallengeOrganisationId] = useState(organisationId);

  const updateValuesOnOrganisationChange = (e, values) => {
    try {

      console.log('on organisation change', e, values);


      setChallengeOrganisationId(e);

      if (challengeOrganistionId && challengeOrganistionId !== e) {

        const { difficultyId, durationId, skills, themes, introId } = values;

        console.log('orgnisation state', organisationState)

        setOrganisationState(s => {

          const orgValue = {
            id: challengeOrganistionId,
            difficultyId,
            durationId,
            skills,
            themes,
            introId
          }

          const filteredOrganisationValues = filter(s, o => o.id !== challengeOrganistionId);

          return [orgValue, ...filteredOrganisationValues]

        })

        const organisationData = find(organisationState, o => o.id === e) || {}

        return {
          difficultyId: undefined,
          durationId: undefined,
          skills: undefined,
          themes: undefined,
          introId: undefined,
          ...organisationData
        }

      }



    } catch (error) {
      console.log('error on organisation change', error);
    }
  }

  const {
    hasAgesPermission = false,
    hasMaterialsPermission = false
  } = useMenuPermissionsContext();

  const {
    hasCreateEditBannersAccess,
    hasLocationAccess,
    hasGenerateChallengeAccess,
  } = useChallengesPermissionContext()

  const postDataMapper = (formValues) => {
    try {

      console.log('override post data')

      const {
        materials = [],
        skills = [],
        prompt,
        promptResponse,
      } = formValues

      return {
        materials: materials?.filter(e => !e.isNew)?.map(e => e.value),
        skills: skills?.filter(e => !e.isNew)?.map(e => e.value),
        newNaterials: materials?.filter(e => !!e.isNew),
        newSkills: skills?.filter(e => !!e.isNew),
        prompt,
        promptResponse,
      }

    } catch (error) {
      console.log('error overriding post data', error)
    }
  }

  return (
    <AppLayout activeItem='challenges'>
      <Spin spinning={isCreating}>
        <Row align="middle" style={{ paddingBottom: 8 }} >
          <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
          <Breadcrumb style={{ margin: 8, marginLeft: 12 }} >
            <Breadcrumb.Item><a onClick={onBack}>Challenges</a></Breadcrumb.Item>
            <Breadcrumb.Item>New Challenge</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <ContainerDiv>
          <ChallengeForm
            formValues={initialValues}
            isDataLoaded
            organisationId={challengeOrganistionId}
            organisationsDisabled={true}
            disableThemes={!challengeOrganistionId}
            disableSkills={!challengeOrganistionId}
            disableDurationDisplay={!challengeOrganistionId}
            disableDifficultyLevel={!challengeOrganistionId}
            updateValuesOnOrganisationChange={updateValuesOnOrganisationChange}
            //themesLabel={"Themes (select organisation to load)"}
            onSubmit={onSubmit}
            submitTitle='Save'
            showPublish
            activeTab={currentTab}
            setActiveTabParam={setTabParam}
            showAges={hasAgesPermission}
            showMaterials={hasMaterialsPermission}
            showBannersTab={hasCreateEditBannersAccess}
            hasLocationAccess={hasLocationAccess}
            showRelatedToTab
            postDataMapper={postDataMapper}
            showGeneratePrompt={hasGenerateChallengeAccess}
          />
        </ContainerDiv>
      </Spin>
    </AppLayout>
  );


}