import { Modal, Spin, message } from 'antd';
import { debounce, map } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Dashboard from '../../../common/components/dashboard';
import { useChallengesPermissionContext } from '../../../common/components/permissionContext';
import UnauthorizedComponent from '../../../common/components/unauthorized/UnauthorizedComponent';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import CreateChallengeModal from './CreateChallengeModal';
import Header from './Header';
import ChallengesTable from './challenges';
import useChallengesFilterContext from './useChallengesFilterContext';


const pageSize = 10;

// const publishStatuses = [
//   { key: 'published', label: 'Published' },
//   { key: 'unpublished', label: 'Unpublished' },
// ]


const durationLevels = [
  { value: 'short', label: 'Short (below 2 hours)', start: 0, end: 2 * 60 },
  { value: 'medium', label: 'Medium (2 to 4 hours)', start: 2 * 60, end: 4 * 60 },
  { value: 'long', label: 'Long (4 hours +)', start: (4 * 60 + 1) },
]

const getDelimiatedValues = (items) => Array.isArray(items) ? items.map(e => e.value).join('.') ?? '-' : '-'

const mapValues = items => Array.isArray(items) ? items.map(e => e.value) : []


export default () => {

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useChallengesFilterContext();

  const tagsVisible = useMemo(() => {

    const searchTagsLen =
      searchParams.statuses.length ?? 0 + searchParams.themes.length ?? 0 +
      searchParams.types.length ?? 0 + searchParams.organisations.length ?? 0 +
      searchParams.difficultyLevels.length ?? 0 + searchParams.skills.length ?? 0 +
      searchParams.clubs?.length ?? 0 +
      searchParams.durations.length ?? 0 +
      searchParams.created.length ?? 0;

    return searchTagsLen > 0

  }, [searchParams])

  const styles = useMemo(() => ({
    itemStyle: {
      paddingRight: 16,
      paddingBottom: 18,
    },
    tagsContainer: {
      paddingTop: tagsVisible ? 16 : 0,
      paddingBottom: tagsVisible ? 8 : 0

    },
    challengeTableContainer: {
      paddingTop: tagsVisible ? 0 : 17
    }
  }), [tagsVisible])

  let navigate = useNavigate();

  const {
    pageNo = 0,
    query = '',
    organisations = [],
    difficultyLevels = [],
    durations = [],
    types = [],
    statuses = [],
    themes = [],
    skills = [],
    sortBy = null,
    sortOrder = null,
    clubs = [],
    created = []
  } = searchParams

  const { httpPostAsync, httpDeleteAsync } = useHttpHelper();

  const fetchChallengesAsync = async () => {

    const filterParams = {
      itemPerPage: pageSize,
      query,
      pageNo,
      organisations: mapValues(organisations),
      difficultyLevels: mapValues(difficultyLevels),
      durations: mapValues(durations),
      durationRange: Array.isArray(durations) ? durations.map(s => ({ start: s.start, end: s.end })) : [],
      themes: mapValues(themes),
      skills: mapValues(skills),
      clubs: mapValues(clubs),
      statuses: mapValues(statuses),
      sortBy,
      sortOrder,
      created: mapValues(created),
    }

    console.log('call api filter params:', filterParams);

    const response = await httpPostAsync(
      appLinks.challenges,
      filterParams
    );

    console.log('fetch challenges', response);

    return response;
  };


  const queryKey = useMemo(() => {

    const key = [queryKeys.challenges, pageNo, query, sortBy, sortOrder]

    key.push(getDelimiatedValues(organisations))
    key.push(getDelimiatedValues(difficultyLevels))
    key.push(getDelimiatedValues(durations))
    key.push(getDelimiatedValues(types))
    key.push(getDelimiatedValues(statuses))
    key.push(getDelimiatedValues(themes))
    key.push(getDelimiatedValues(skills))
    key.push(getDelimiatedValues(clubs))
    key.push(getDelimiatedValues(created))

    console.log('filter key', key)

    return key;

  }, [pageNo, query, organisations, difficultyLevels, durations, types, statuses, themes, skills, clubs, created, sortBy, sortOrder])

  const {
    isLoading,
    data = {},
    isFetching,
    // isPreviousData,
    refetch,
  } = useQuery(
    queryKey,
    fetchChallengesAsync,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })

  let { data: challenges = [], total = 0 } = data || {}

  const [searchValue, setSearchValue] = useState(query);

  const onChallengeSearch = async (text) => {
    try {

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }));

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onChallengeSearch);
  searchUsersRef.current = onChallengeSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = pagination?.current ?? 0
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));

    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {

      const { field, order, column } = sort ?? {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  //#region filters dropdowns

  const onFilterParamChangeHOF = (paramProp) => (values) => {
    try {
      setSearchParams(p => ({ ...p, [paramProp]: [...values], pageNo: 0 }))
    } catch (error) {
      console.log('error on filter param change', paramProp, error)
    }
  }

  const onDurationChange = (values, props) => {
    try {

      const mappedDurations = map(props, p => ({ key: p.key, value: p.value, label: p.text, end: p.end, start: p.start }));

      setSearchParams(p => ({ ...p, durations: [...mappedDurations], pageNo: 0 }))

    } catch (error) {
      console.log('error on duration chamge', error)
    }
  }


  //#endregion filter dropdowns

  //#region delete

  const [deleteChallengeId, setDeleteChallengeId] = useState();

  const mutationQueryKey = [queryKeys.deleteChallenge, deleteChallengeId]

  const deleteChallengeAsync = async (challengeId) => {
    try {

      const link = appLinks.deleteChallenge.replace('{challengeId}', challengeId);
      const deleteResponse = await httpDeleteAsync(link);

      return deleteResponse;

    } catch (error) {
      console.log('error deleting challenge', error);
    }
  }

  const {
    // data: updatePersonResponse,
    isLoading: isDeleting,
    mutateAsync: onDeleteChallengeAsync,
  } = useMutation(
    deleteChallengeAsync,
    { mutationKey: mutationQueryKey, }
  );

  const onDelete = async (challengeId, title) => {
    try {


      const enrollmentCount = await httpPostAsync(appLinks.challengeEnrollmentCount.replace('{challengeId}', challengeId));
      console.log('enrollment count', enrollmentCount)
      if (enrollmentCount &&
        enrollmentCount.code === 0 &&
        enrollmentCount.data &&
        enrollmentCount.data.enrollments > 0) {

        Modal.info({
          title: `Challenge has enrolled students, you can unpublish the challenge to remove it from listings`
        });

        return;
      }

      console.log('delete creator', challengeId);
      Promise.resolve(setDeleteChallengeId(challengeId))

      Modal.confirm({
        title: `Are you sure you want to delete ${title}`,
        okText: 'Delete',
        closable: true,
        okButtonProps: {
          loading: isDeleting,
          disabled: isDeleting,
        },
        onOk: async () => {
          console.log('on delete click');

          const deleteResponse = await onDeleteChallengeAsync(challengeId);

          console.log('delete response', deleteResponse);

          if (deleteResponse && deleteResponse.code === 0) {
            message.success('Challenge was deleted successfully');
          } else if (deleteResponse && deleteResponse.code === 403) {
            message.warning('Looks like you need permission to delete this challenge');
          } else {
            message.error('We are facing some issues, challenge could not be deleted');
          }

          await refetch();

        }

      });

    } catch (error) {
      console.log('error deleting challenge', error);
    }
  }

  //#endregion


  //#region create modal


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showCreateModal = () => setIsModalVisible(true);
  const hideCreateModal = () => setIsModalVisible(false);

  const onCreateChallengeSubmitHandler = (organisationId) => {
    try {

      console.log('create challenge', organisationId)

      navigate(`/${appRoutes.createChallenge}`, { state: { organisationId } })

    } catch (error) {
      console.log('error on create challenge modal submit', error);
    }
  }

  //#endregion create modal

  //#region Access/Permission

  const {
    hasListAccess,
    hasCreateAccess = false,
  } = useChallengesPermissionContext();

  //#endregion

  const onRemoveHOF = (filterProp, filterKey = 'key') => (tag) => {
    try {

      setSearchParams(params => ({
        ...params,
        [filterProp]: params[filterProp]?.filter(e => e[filterKey] !== tag[filterKey]) ?? [],
      }))

    } catch (error) {
      console.log('error removing fitler tag')
    }
  }

  const filterTagItems = useMemo(() => {

    return [
      {
        tags: organisations,
        filterProp: 'organisations'
      },
      {
        tags: difficultyLevels,
        filterProp: 'difficultyLevels'
      },
      {
        tags: durations,
        filterProp: 'durations'
      },
      // {
      //   tags: statuses,
      //   filterProp: 'statuses'
      // },
      {
        tags: themes,
        filterProp: 'themes'
      },
      {
        tags: skills,
        filterProp: 'skills'
      },
      {
        tags: clubs,
        filterProp: 'clubs'
      },
      {
        tags: created,
        filterProp: 'created'
      },
    ]

  }, [organisations, difficultyLevels, durations, themes, skills, clubs, created])

  return (
    <Dashboard activeItem='challenges' subrouting={false}>
      <UnauthorizedComponent isAuthorized={hasListAccess} >
        <Header
          styles={styles}
          onFilterParamChangeHOF={onFilterParamChangeHOF}
          onDurationChange={onDurationChange}
          organisations={organisations}
          difficultyLevels={difficultyLevels}
          durationLevels={durationLevels}
          durations={durations}
          themes={themes}
          skills={skills}
          clubs={clubs}
          created={created}
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          disableCreateChallenge={!hasCreateAccess}
          onCreateChallenge={showCreateModal}
          onRemoveHOF={onRemoveHOF}
          filterTagItems={filterTagItems}
          isSearchActive={isSearchActive}
          onReset={onReset}
        />
        <Spin spinning={isLoading || isFetching || isDeleting}>
          <div style={styles.challengeTableContainer}>
            <ChallengesTable
              key={resetKey}
              challenges={challenges}
              onPagniationChange={onPagniationChange}
              onSortChange={onSortChange}
              pageNo={pageNo}
              total={total}
              defaultPageSize={pageSize}
              sortBy={sortBy}
              sortOrder={sortOrder}
              onDelete={onDelete}
            />
          </div>
        </Spin>
        <CreateChallengeModal
          isModalVisible={isModalVisible}
          onCancel={hideCreateModal}
          onSubmit={onCreateChallengeSubmitHandler}
        />
      </UnauthorizedComponent>
    </Dashboard>
  );

}