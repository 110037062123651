import React, { useMemo } from 'react';
import { get } from 'lodash';
import creatorFactory from './creatorViews';
import PoweredBy from './poweredBy';
import empty from '../../../../common/components/Empty';
import styledComponents from 'styled-components';

const TitleComponent = styledComponents.span`
font-size: 16px;
font-weight: bold;
`

export default (props) => {

    const { creator = {}, poweredBy = [] } = props;

    const creatorType = get(creator, 'creatorType');

    const CreatorView = useMemo(() => {

        return get(creatorFactory, creatorType, empty);

    }, [creatorType]);

    return (
        <div>
            {/* {creator ? <TitleComponent>Creator</TitleComponent> : null}
            {creator ?
                <div style={{ marginTop: 16 }}>
                    <CreatorView {...creator} />
                </div>
                : null
            }
            <PoweredBy poweredBy={poweredBy} /> */}
        </div>)
}

