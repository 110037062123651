import { Form } from "antd";
import { useCallback, useMemo } from "react";
import empty from "../../../../../common/components/Empty";
import LookupSelect from "../../../../../common/components/lookupSelect";
import useHttpHelper from "../../../../../common/hooks/useHttpHelper";
import { appLinks, queryKeys } from "../../../../../config/constants";


function defaultItemPropMapper({ value, label, logoLink, website }) {
    return {
        key: value,
        value,
        link: logoLink,
        website,
        name: label,
        children: label,
    }
}

export default ({ organisationId, isEnabled = true, valueProp, form }) => {

    const { httpPostAsync } = useHttpHelper();

    const fetchOrganisationClubsAsync = useCallback(async (params = {}) => {

        console.log('fetch organisation clubs params', params)

        return await httpPostAsync(appLinks.clubsLookups, params)

    }, []);

    let queryKeyfunction = () => [queryKeys.clubLookups, organisationId];

    const clubParams = useMemo(() => {

        return { organisationId }

    }, [organisationId]);

    const enableFunction = () => organisationId > 0;

    return (
        <div>
            <Form.Item
                name="challengeClubs"
                label="Clubs"
                //rules={[{ required: true, message: 'Please choose organisations.' }]}
                wrapperCol={{ span: 16 }}
                // getValueFromEvent={(args, params) => {
                //     console.log('get value from event', args, params)

                //     const items = map(params, param => {

                //         return {
                //             disabled: undefined,
                //             key: param.key,
                //             label: param.name,
                //             value: param.value,
                //             name: param.name,
                //             logoLink: param.link,
                //             website: param.website,
                //         }

                //     });

                //     return items;
                // }}
            >
                <LookupSelect
                    placeholder="Select Clubs"
                    queryParams={clubParams}
                    fetchAsync={fetchOrganisationClubsAsync}
                    queryKeyFn={queryKeyfunction}
                    enabledFn={enableFunction}
                    lookupId='challenge-clubs'
                    mode="multiple"
                    labelInValue
                    tagRender={empty}
                    disabled={!isEnabled}
                    //itemPropsMapperFn={defaultItemPropMapper}
                  //  onChange={onChange}
                />
            </Form.Item>
        </div>
    )
}