import { useContext } from "react";
import { get } from "lodash";
import PermissionContext, { childPermission, parentPermissions, permissionValues, settingsChildPermissions } from ".";


export default function useSkillsPermissionsContext() {

    const { permissions = {} } = useContext(PermissionContext);

    const hasSkillsAccess = get(permissions, `${parentPermissions.settings}.${settingsChildPermissions.skills}`) === permissionValues.yes;

    const createPermssionKey = `${parentPermissions.settings}.${settingsChildPermissions.skills}.${childPermission.create}`;
    const hasSkillsCreateAccess = hasSkillsAccess &&
        get(permissions, createPermssionKey, permissionValues.no) !== permissionValues.no;

    const editPermissionKey = `${parentPermissions.settings}.${settingsChildPermissions.skills}.${childPermission.edit}`;
    const hasSkillEditAccess = hasSkillsAccess &&
        get(permissions, editPermissionKey, permissionValues.no) !== permissionValues.no;

    const deletePermissionKey = `${parentPermissions.settings}.${settingsChildPermissions.skills}.${childPermission.delete}`;
    const hasSkillsDeleteAccess = hasSkillsAccess &&
        get(permissions, deletePermissionKey, permissionValues.no) !== permissionValues.no;

    return {
        hasSkillsCreateAccess,
        hasSkillEditAccess,
        hasSkillsDeleteAccess
    }

}