import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const ageFilterConfig = {
    key: 'age',
    isActiveProp: 'isAgeSearchActive',
    setAction: 'age',
    resetAction: 'reset-age',
    setFilterProp: 'setAgeFilters',
    resetFilterProp: 'resetAgeFilters',
    initialValue: {
        organisationId: undefined,
        pageNo: 0,
        // query: '',
        sortBy: null,
        sortOrder: null,
    }
}

const useAgeFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, ageFilterConfig)
}

export default useAgeFilterContext;
