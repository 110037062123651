import { Badge, Button, Descriptions, Form, Popconfirm, Tooltip, Typography } from "antd";
import ItemTags from '../../../../../common/components/itemTag';
import { DeleteOutlined, VerticalAlignMiddleOutlined, EditOutlined } from '@ant-design/icons'
import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    rectSwappingStrategy,
    arrayMove,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useGenerateChallenge from "../useGenerateChallenge";
import EditTagContainer from "../EditItemTag/EditTagContainer";
import PromptInput from "./PromptInput";

const { Text } = Typography


const shouldUpdateSteps = () => (prev, cur) => prev.steps !== cur.steps

const shouldUpdateIntro = () => (prev, cur) => prev.customIntro !== cur.customIntro
const shouldUpdateDescription = () => (prev, cur) => prev.description !== cur.description

const materialsFieldName = 'materials'
const stepsFieldName = 'steps'
const skillsFieldName = 'skills'

const GenerateChallenge = (props) => {

    const {
        getFormValue,
        setFormValues,
        organisationId,
        materialLookupItems,
        skillLookupItems,
        setPromptError
    } = props

    //#region generate challenge
    const {
        fetchGptResponseAsync,
        promptState,
        promptResponse,
        isGptResponseFetched,
        hasNewMaterials,
        hasNewValues,
        hasNewAreas,
    } = useGenerateChallenge()

    const isLoadingResponse = promptState === 'loading'

    const onGenerateActivityClick = async () => {

        let prompt = getFormValue('prompt')

        if (typeof prompt !== 'string' && !prompt?.trim()?.length > 0) {
            setPromptError();
            return;
        }


        const promptMaterials = getFormValue('promptMaterials')
        const promptSkills = getFormValue('promptSkills')
        const propmptAreasOfDevelopment = getFormValue('promptAreasOfDevelopment')
     
        const materialsText = promptMaterials.map(e => e.label).join(', ') 
        let ageGroups = getFormValue('promptAgeGroups') ?? '3 to 4'
        //ageGroups = ageGroups.join(' to ')
        prompt = prompt.replace('{materials}', materialsText).replace('{ageGroup}', ageGroups) 

        const postData = {
            prompt,
            promptMaterials: promptMaterials?.map(e => e.label),
            promptSkills: promptSkills?.map(e => e.label),
            propmptAreasOfDevelopment: propmptAreasOfDevelopment?.map(e => e.label),
            organisationId,
        }

        const data = await fetchGptResponseAsync(postData)

        if (data === null || typeof data !== 'object') {
            return;
        }

        const {
            intro = '',
            description = '',
            steps = [],
            materials,
            skills,
            oneValues,
            oneAreas = []
        } = data
        // dont include new
        // materials, value and areas of development
        //const existingMaterials = materials?.filter(e => !e.isNew) ?? []
        const existingValues = oneValues?.filter(e => !e.isNew) ?? []
        const existingAreas = oneAreas?.filter(e => !e.isNew) ?? []


        //console.log('resposne data', data)

        setFormValues({
            customIntro: intro,
            description,
            steps,
            materials,
            skills,
            oneValues: existingValues,
            oneAreas: existingAreas,
            promptResponse: data,
        })
    }
    //#endregion generate challenge

    //#region helpers
    const replaceItem = (value, newItem, fieldName) => {

        const items = getFormValue(fieldName) ?? []

        for (let index = 0; index < items.length; index++) {
            const element = items[index];
            if (element.value === value) {
                items[index] = newItem
                break;
            }
        }

        setFormValues({ [fieldName]: [...items] })
    }

    const updateLabel = (value, label, fieldName) => {
        //console.log('onend ', value, label)
        //updateMaterialText(value, text)

        const items = getFormValue(fieldName) ?? []

        const updatedItems = items?.map(
            e => (e.value === value && e.isNew === true) ? ({ ...e, label }) : e
        )

        setFormValues({ [fieldName]: [...updatedItems] })

    }

    const onRemoveItem = (value, fieldName) => {

        const materials = getFormValue(fieldName) ?? []
        const filtered = materials?.filter(o => o.value !== value);
        setFormValues({ [fieldName]: filtered })
    }
    //#endregion helpers

    //#region materials actions
    const onMaterialTextChange = (value, label) => updateLabel(value, label, materialsFieldName)

    const onRemoveMaterial = (value) => onRemoveItem(value, materialsFieldName)

    const onReplaceMaterialClick = (value, newMaterial) => replaceItem(value, newMaterial, materialsFieldName)

    //#endregion materials actions

    //#region skills actions
    const onReplaceSkilllClick = (value, newSkill) => replaceItem(value, newSkill, skillsFieldName)

    const onSkillsTextChange = (value, label) => updateLabel(value, label, skillsFieldName)

    const onRemoveSkillClick = (value) => onRemoveItem(value, skillsFieldName)
    //#endregion skills actions

    //#region setps action
    const onRemoveStep = (uniqueId) => {
        try {

            const items = getFormValue(stepsFieldName) ?? []
            const filtered = items?.filter(o => o.uniqueId !== uniqueId);
            setFormValues({ [stepsFieldName]: filtered })
        } catch (error) {
            console.log('error removing step', error)
        }
    }


    const onStepTextUpdateEvent = (uniqueId, description) => {

        const items = getFormValue(stepsFieldName) ?? []

        const mappedSteps = items?.map(e => (e.uniqueId === uniqueId && e.isNew === true) ?
            ({ ...e, description }) : e)

        setFormValues({ [stepsFieldName]: [...mappedSteps] })

    }
    //#endregion step actions


    return (
        <div className="prompt-container">
            <PromptInput
                materialLookupItems={materialLookupItems}
                skillLookupItems={skillLookupItems}
                organisationId={organisationId}
            />
            <Button
                onClick={onGenerateActivityClick}
                loading={isLoadingResponse}
            >
                Generate and fill response
            </Button>
            {
                isGptResponseFetched ?
                    (
                        <div className='generated-challenge-details'>
                            <Descriptions
                                //column={1}
                                title="Generated Challenge Details"
                                bordered
                            >
                                <Descriptions.Item
                                    span={3}
                                    label="intro">
                                    <Form.Item shouldUpdate={shouldUpdateIntro}>
                                        {(props) => {
                                            const intro = props.getFieldValue('customIntro');
                                            return intro
                                        }}
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={3}
                                    label="Description"
                                >
                                    <Form.Item shouldUpdate={shouldUpdateDescription}>
                                        {(props) => {
                                            const description = props.getFieldValue('description');
                                            return description
                                        }}
                                    </Form.Item>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label="Values"
                                    span={3}
                                >
                                    <div>
                                        <ItemTags
                                            items={promptResponse?.oneValues}
                                            getColor={item => !!item.isNew ? 'blue' : undefined}
                                        />
                                        {
                                            hasNewValues ?
                                                (
                                                    <div>
                                                        <Badge color="blue" text="New Values" />
                                                    </div>
                                                )
                                                : null
                                        }
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label="Area of Development"
                                    span={3}
                                >
                                    <div>
                                        <ItemTags
                                            items={promptResponse?.oneAreas}
                                            getColor={item => !!item.isNew ? 'blue' : undefined}
                                        />
                                        {
                                            hasNewAreas ?
                                                (
                                                    <div>
                                                        <Badge color="blue" text="New Areas" />
                                                    </div>
                                                )
                                                : null
                                        }
                                        {/* <List
                                            grid={{ gutter: 16 }}
                                            dataSource={oneAreas}
                                            renderItem={item => (
                                                <List.Item>
                                                    <Card title={item.label}>Choose image to save</Card>
                                                </List.Item>
                                            )}
                                        /> */}
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={3}
                                    label="Skills"
                                >
                                    <div>
                                        <EditTagContainer
                                            items={promptResponse?.skills}
                                            formFieldName={skillsFieldName}
                                            onTextUpdate={onSkillsTextChange}
                                            onRemoveClick={onRemoveSkillClick}
                                            organisationId={organisationId}
                                            onReplaceItem={onReplaceSkilllClick}
                                            lookupItems={skillLookupItems}
                                            modalTitle="Replace skill with"
                                            selectLabel="Skills"
                                            selectPlaceholder="Select Skill"
                                        />
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    span={3}
                                    label="Materials"
                                >
                                    <div>
                                        <EditTagContainer
                                            items={promptResponse?.materials}
                                            formFieldName={materialsFieldName}
                                            onTextUpdate={onMaterialTextChange}
                                            onRemoveClick={onRemoveMaterial}
                                            organisationId={organisationId}
                                            onReplaceItem={onReplaceMaterialClick}
                                            lookupItems={materialLookupItems}
                                            modalTitle="Replace material with"
                                            selectLabel="Materials"
                                            selectPlaceholder="Select Material"
                                        />
                                        {
                                            hasNewMaterials ?
                                                (
                                                    <div>
                                                        <Badge color="blue" text="New Materials" />
                                                    </div>
                                                )
                                                : null
                                        }
                                    </div>
                                </Descriptions.Item>
                                <Descriptions.Item
                                    label="Steps"
                                    span={3}
                                >
                                    <div className="steps-container">
                                        <ul>
                                            <Form.Item shouldUpdate={shouldUpdateSteps}>
                                                {(props) => {

                                                    const steps = props.getFieldValue('steps');

                                                    //const onEditEnd = (uniqueId) => onStepTextChangeRef.current(uniqueId)

                                                    const updateSteps = (updatedSteps) => {
                                                        if (Array.isArray(updatedSteps) && updateSteps.length) {

                                                            props.setFieldsValue({ 'steps': updatedSteps })

                                                        }

                                                    }

                                                    return (
                                                        <SortableSteps
                                                            steps={steps}
                                                            //onDescriptionChange={onDescriptionChange}
                                                            onStepTextUpdate={onStepTextUpdateEvent}
                                                            onRemoveStep={onRemoveStep}
                                                            updateSteps={updateSteps}
                                                        />
                                                    )

                                                }}
                                            </Form.Item>
                                        </ul>
                                    </div>
                                </Descriptions.Item>
                                {
                                    promptResponse?.extraItems?.map(e => {
                                        return (
                                            <Descriptions.Item
                                                label={e.key}
                                                span={3}
                                            >
                                                {JSON.stringify(e.value)}
                                            </Descriptions.Item>
                                        )
                                    })
                                }
                            </Descriptions>
                        </div>
                    )
                    : null
            }
        </div>
    )

}

const StepItem = (props) => {

    const {
        uniqueId,
        description,
        onDescriptionChange,
        onEditEnd,
        onRemoveStep,
        showBorder,
    } = props;

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useSortable({
        id: uniqueId,

        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });

    const borderStyle = useMemo(() => {

        return showBorder ? {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            borderColor: '#1890ff',
            // width: 500,
        } : {

            borderColor: 'rgb(243 243 243)',
            borderStyle: 'solid',
            borderRadius: 8,
            borderWidth: 1
        }

    }, [showBorder])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: 12,
        backgroundColor: '#fff',

        ...borderStyle
    };

    const itemContainerStyle = {
        position: 'relative',
        //opacity:`${showBorder ? '0.8' : '1'}`

    }

    return (
        <li key={uniqueId} >
            <div style={itemContainerStyle} ref={setNodeRef}  >
                <a
                    className="step-drag-handle"
                    href="#"
                    {...listeners}
                    ref={setActivatorNodeRef}
                >
                    <VerticalAlignMiddleOutlined />
                </a>
                <Text
                    editable={{
                        icon: <span className="edit-icon-container"><EditOutlined /></span>,
                        onEnd: onEditEnd,
                        onChange: onDescriptionChange
                    }}
                //className={`${textClass}`}
                >
                    {description}
                </Text>
                <Popconfirm
                    placement="topRight"
                    title={"Are you sure you want to remove this step"}
                    onConfirm={() => onRemoveStep(uniqueId)}
                    okText="Yes"
                    cancelText="No"
                >
                    <Tooltip title="Remove">
                        <a className="delete-icon-container">
                            <DeleteOutlined />
                        </a>
                    </Tooltip>
                </Popconfirm>
            </div>
        </li>
    )

}


const SortableSteps = ({
    steps,
    updateSteps,
    onStepTextUpdate,
    onRemoveStep,
}) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const textRef = useRef()

    const onDescriptionChange = (e) => {
        //console.log('on change', e)
        //setText(e)
        textRef.current = e
    }

    const [activeId, setActiveId] = useState(null);
    const [activeItem, setActiveItem] = useState({});


    const getItemByStepId = useCallback((stepId) => steps?.find(s => s.uniqueId === stepId), [steps])

    const onSwap = (activeId, overId) => {

        //const items = props.getFieldValue('oneValues');

        const oldIndex = steps?.findIndex(o => o.uniqueId === activeId)
        const newIndex = steps?.findIndex(o => o.uniqueId === overId)

        let updatedItems = arrayMove(steps, oldIndex, newIndex);

        updateSteps(updatedItems)
    }


    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
        >
            <SortableContext
                items={steps ?? []}
                strategy={rectSwappingStrategy}
            >
                {
                    steps?.map((e, idx) => {

                        //const onEditEnd = () => onStepTextChangeRef.current(e.uniqueId)

                        return (
                            <StepItem
                                uniqueId={e.uniqueId}
                                description={e.description}
                                onDescriptionChange={onDescriptionChange}
                                onEditEnd={() => onStepTextUpdate(e.uniqueId, textRef.current)}
                                onRemoveStep={onRemoveStep}
                            />
                        )
                    })
                }
            </SortableContext>
            <DragOverlay>
                {activeId ? (<StepItem id={activeId} {...activeItem} showBorder viewOnly />) : null}
            </DragOverlay>
        </DndContext>
    )


    function handleDragStart(event) {
        const { active } = event;

        //console.log('drag start', active);

        setActiveId(active.id)

        //setActiveId(active.id);
        const item = getItemByStepId(active.id) || {};
        const stepIndex = findIndexOf(steps, active.id);
        setActiveItem({ ...item, index: stepIndex });

    }

    function findIndexOf(items = 0, stepId) {
        try {

            let indexOfItem = -1;

            for (let i = 0; i < items.length; i++) {

                if (items[i].uniqueId === stepId) {
                    indexOfItem = i;
                    break;
                }

            }

            return indexOfItem;

        } catch (error) {
            console.log('error finding index of item', error);
            return -1
        }

    }

    function handleDragEnd(event) {

        const { active, over } = event;

        //console.log('drag end', event);

        if (active.id !== over.id) {
            // console.log('active item', activeItem)

            //const oldIndex = findIndexOf(steps, active.id);
            //const newIndex = findIndexOf(steps, over.id);
            //console.log('old index ', oldIndex, 'new index ', newIndex)
            onSwap(active.id, over.id);
        }

        setActiveId(null);
        setActiveItem({});
    }

}


export default GenerateChallenge;