import { Row, Col, TimePicker, Select, Input } from "antd";
import { map } from "lodash";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
const { Option } = Select;

const daysArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
    16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];

const daysOptions = map(daysArray, day => ({ value: day, label: day }));


const totalDayMins = 24 * 60;


export default ({ onValueChange, durationValue, ...rest }) => {

    const [days, setDays] = useState();
    const [momentTimeValue, setMomentTimeValue] = useState();

    useEffect(() => {
        try {

            let totalMinutes = durationValue || 0;

            let _days = Math.floor(totalMinutes / totalDayMins);
            totalMinutes -= _days * (totalDayMins);

            // if total mins less then zero then set zero as time cant be zero
            let timeMins = totalMinutes >= 0 ? totalMinutes : 0;
            _days = _days >= 0 ? _days : 0;

            setDays(_days);

            //var minutes = Math.floor(delta / 60) % 60;

            const duration = moment().startOf('day').add(timeMins, 'minutes');
            if (duration.isValid) {
                setMomentTimeValue(duration)
            } else {
                setMomentTimeValue();
            }
   
           console.log('set duration value', durationValue, _days);
            
        } catch (error) {
            console.log('error setting duration value', error);
        }
    }, [durationValue])

    const onDayChange = (e) => {
        try {

            const dayMins = (e || 0) * totalDayMins;

            // need to add the hours and minutes from moment duration
    
            let totalMinutes = dayMins;
    
            if (momentTimeValue && momentTimeValue.isValid()) {
                totalMinutes += moment.duration(momentTimeValue.diff(moment().startOf('day'))).asMinutes();
            }
    
            //console.log('on day change', e, dayMins, totalMinutes);
    
            onValueChange && onValueChange(totalMinutes);
            
        } catch (error) {
            console.log('on day change error', error);
        }

      

    }

    const onTimeChange = (e) => {
        try {

            console.log('on time change', e);

            let minutes = 0;

            if (e) {
                minutes = moment.duration(e.diff(moment().startOf('day'))).asMinutes();
            }

            const totalMins = (days || 0) * totalDayMins + Math.floor(minutes); 

            //console.log('on time select', minutes, totalMins);

            onValueChange && onValueChange(totalMins);

            setMomentTimeValue(e);

            
        } catch (error) {
            console.log('error on time change', error);
        }
    }

    return (
        <div>
            <Row>
                <Input.Group compact>
                    <Select
                        placeholder="Days"
                        allowClear
                        options={daysOptions}
                        onChange={onDayChange}
                        value={days}
                        style={{ width: 100 }}
                    />
                    <TimePicker
                        format='HH:mm'
                        allowClear
                        minuteStep={5}
                        showNow={false}
                        hideDisabledOptions
                        inputReadOnly
                        popupClassName='challenge-duration-picker-popup'
                        data-testid="challenge-duration"
                        //{...rest}
                        value={momentTimeValue}
                        onChange={onTimeChange}
                        onSelect={onTimeChange}
                    />
                </Input.Group>
            </Row>
        </div>)

}