import { useContext } from "react";
import { get } from "lodash";
import PermissionContext, { childPermission, parentPermissions, permissionValues, settingsChildPermissions } from ".";


export default function useDifficultyPermissionsContext() {

    const { permissions = {} } = useContext(PermissionContext);

    const difficultyParentKey = `${parentPermissions.settings}.${settingsChildPermissions.difficulty}`;

    const hasDifficultyAccess = get(permissions, difficultyParentKey) === permissionValues.yes;

    const createKey = `${difficultyParentKey}.${childPermission.create}`;
    const hasCreateAccess = hasDifficultyAccess &&
        get(permissions, createKey, permissionValues.no) !== permissionValues.no;

    const editKey = `${difficultyParentKey}.${childPermission.edit}`;
    const hasEditAccess = hasDifficultyAccess &&
        get(permissions, editKey, permissionValues.no) !== permissionValues.no;

    const deleteKey = `${difficultyParentKey}.${childPermission.delete}`;
    const hasDeleteAccess = hasDifficultyAccess &&
        get(permissions, deleteKey, permissionValues.no) !== permissionValues.no;

    return {
        hasCreateAccess,
        hasEditAccess,
        hasDeleteAccess
    }

}