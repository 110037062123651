import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Row, Col, Image, Card, Typography, Badge, Tabs, Button, message, Modal, Switch } from 'antd';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import Sections from '../common/sections';
import ItemTags from '../../../common/components/itemTag';
import Resources from '../common/resources';
import Creators from '../common/creators';
import ItemTitle from '../../../common/components/itemTitle';

import { ReactComponent as CoverImageDefault } from '../../../assets/coverPlaceholder.svg';
import { ReactComponent as ProfileImageDefault } from '../../../assets/userProfile.svg';
import { debounce, get } from 'lodash';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, publishStatusMap } from '../../../config/constants';
import { getYoutubeVideoId } from '../../../helpers/utils';
import Prerequisites from '../common/prerequisites';
import BannerView from './bannerView';
import RelatedToView from './RelatedToView';

const { Meta } = Card;

const TitleComponent = styled.span`
font-size: 16px;
font-weight: bold;
`

const TitleDiv = styled.div`
padding-top: 8px
`;

const styles = {
    statisticValueStyle: {
        fontSize: '14px',
        marginBottom: '12px'
    },
    statsContainer: {
        marginBottom: '24px'
    },
    statItem: {
        marginRight: 40
    }
}

const { Text, Title, Link } = Typography;

const { TabPane } = Tabs;

const publishColorMap = {
    listed: 'green',
    unlisted: '#d9d9d9'
}

const publishButtonTextMap = {
    listed: 'Unpublish',
    unlisted: 'Publish'
}

// const publishStateChange = {
//     listed: 'Unpublish',
//     unlisted: 'Publish'
// }

const CoverImageComponent = (props) => {

    const { imageUrl } = props;

    return (
        imageUrl ?
            <Image src={imageUrl} height={270} width={480} />
            : <CoverImageDefault height={270} width={480} style={{ borderRadius: 12 }} />
    )

}

const YoutubeCoverComponent = (props) => {

    const { videoLink } = props;

    const videoId = getYoutubeVideoId(videoLink)

    console.log('youtube video link', videoLink)

    return (
        videoLink ?
            <YouTube videoId={videoId} height={270} width={480} />
            : <CoverImageDefault height={270} width={480} style={{ borderRadius: 12 }} />
    )

}


const coverImageComponentMap = {
    'image': CoverImageComponent,
    'youtubeVideo': YoutubeCoverComponent,
}

const ThumbImageComponent = (props) => {

    const { thumbUrl } = props;

    return (
        thumbUrl ?
            <Image
                height={200}
                width={138}
                src={thumbUrl}
            />
            : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
    )

}

const YoutubeListComponent = (props) => {

    const { videoLink } = props;

    const videoId = getYoutubeVideoId(videoLink)

    console.log('youtube video link', videoLink)

    return (
        videoLink ?
            <Image
                height={200}
                width={138}
                src={`https://img.youtube.com/vi/${videoId}/mqdefault.jpg`}
            />
            : <ProfileImageDefault height={160} width={111} style={{ borderRadius: 8 }} />
    )

}




const listImageComponentMap = {
    'image': ThumbImageComponent,
    'youtubeVideo': YoutubeListComponent,
}


export default (props) => {

    const {
        challengeId,
        title,
        listingStatus,
        organisation,
        difficulty,
        duration,
        xp,
        intro,
        description,
        isFacilitationNeeded,
        themes = [],
        skills = [],
        sections = [],
        resources = [],
        thumbUrl,
        imageUrl,
        creators = {},
        reloadDetails,
        coverImageType,
        listImageType,
        videoLink,
        clubs = [],
        isClubLevelVisibility,
        prerequisites = [],
        ages = [],
        materials = [],
        showAges = false,
        showMaterials = false,
        oneValues,
        areas,
        showBannersTab = false,
        hasLocationAccess = false,
        location,
        showRelatedToTab,
        primaryRelatedActivity,
        relatedActivities,
        dailyActivity,
        seasons
    } = props;

    const stepsCount = get(sections, '[0].items.length') ?? 0;
    const resourcesCount = resources?.length ?? 0;
    const prequisiteCount = prerequisites?.length ?? 0;

    const {
        publishStatus,
        publishBadgeColor,
        listingButtonText,
        isPublished,
    } = useMemo(() => {

        const defaultListStatus = 'unlisted';

        const publishStatus = get(publishStatusMap, listingStatus, publishStatusMap[defaultListStatus]);

        const publishBadgeColor = get(publishColorMap, listingStatus, publishColorMap[defaultListStatus]);

        const listingButtonText = get(publishButtonTextMap, listingStatus, publishButtonTextMap[defaultListStatus])

        return {
            publishStatus,
            publishBadgeColor,
            listingButtonText,
            isPublished: listingStatus === 'listed',
        }

    }, [listingStatus]);

    const { httpPostAsync } = useHttpHelper();

    const [isBusy, setIsBusy] = useState(false);

    const changePublishStatusCall = async (payload) => {

        const response = await httpPostAsync(appLinks.changeChallengeListingStatus, payload);

        const operation = payload.list ? 'Published' : 'Unpublished';

        if (response && response.code === 0) {
            message.success(`Challenge was ${operation} `);
        } else {
            message.error(`Challenge could not be ${operation}`);
        }

        console.log('publish response', response);

        await reloadDetails();
    }


    const togglePublishStatus = async () => {
        try {

            // check if students are enrolled with an api call
            // show confirmation, saying enrolled students wont be able to see the challenge

            setIsBusy(true);

            let payload = { challengeId };

            if (listingStatus === 'listed') {
                payload.list = false;
            } else {
                payload.list = true;
            }

            if (payload.list === false) {

                const enrollmentCount = await httpPostAsync(appLinks.challengeEnrollmentCount.replace('{challengeId}', challengeId));
                console.log('enrollment count', enrollmentCount)
                if (enrollmentCount &&
                    enrollmentCount.code === 0 &&
                    enrollmentCount.data &&
                    enrollmentCount.data.enrollments > 0) {

                    Modal.confirm({
                        title: `Are you sure you want to unpublish "${title}", users will not be able to view it`,
                        okText: 'Unpublish',
                        closable: true,
                        okButtonProps: {
                            loading: isBusy,
                            disabled: isBusy,
                        },
                        onOk: async () => {
                            await changePublishStatusCall(payload);
                            setIsBusy(false);
                        }
                    });

                    setIsBusy(false);
                    return;

                }

            }

            await changePublishStatusCall(payload);
            setIsBusy(false);


        } catch (error) {
            console.log('change publish status', error);
        }
    }

    const togglePublishRef = useRef(togglePublishStatus);
    togglePublishRef.current = togglePublishStatus;

    const debouncedToggle = useCallback(debounce(togglePublishRef.current, 300), [listingStatus, isBusy])

    //#region toggle daily activity

    const toggleDailyActiivty = async () => {
        try {

            const response = await httpPostAsync(appLinks.toggleDailyActivity.replace('{challengeId}', challengeId));
  
            if (response.code === '0') {
                //const operation = response?.data?.dailyActivity === true ? 'daily '
                message.success(`Challenge daily activity status updated`);
            } else {
                message.error(`Challenge daily activity status could not be changed`);
            }
    
            console.log('publish response', response);
    
            await reloadDetails();


        } catch (error) {
            console.log('change publish status', error);
        }
    }

    const toggleDailyActivityRef = useRef(toggleDailyActiivty);
    toggleDailyActivityRef.current = toggleDailyActiivty;

    const debouncedToggleDailyActivity = useCallback(debounce(toggleDailyActivityRef.current, 300), [])

    //#endregion

    const CoverComponent = useMemo(() => {

        const ViewComponent = get(coverImageComponentMap, coverImageType) || coverImageComponentMap.image;

        return ViewComponent;

    }, [coverImageType])

    const ListComponent = useMemo(() => {

        const ViewComponent = get(listImageComponentMap, listImageType) || listImageComponentMap.image;

        return ViewComponent;

    }, [listImageType])

    const webLink = appLinks.challengeWeb.replace('{challengeId}', challengeId);

    const copyConfig = useMemo(() => ({
        tooltips: 'Copy link',
        text: webLink
    }), [webLink])

    const [ activeTab, setActiveTabParam] = useState(1)

    return (
        <div className='challenge-details-section'>
            <Card>
                <Row>
                    <Col>
                        <Row align='bottom' >
                            <Col
                                style={{ paddingRight: 24 }}>
                                <Card
                                    style={{ width: '100%' }}
                                    cover={<CoverComponent imageUrl={imageUrl} videoLink={videoLink} />}
                                >
                                    <Meta title="Cover Image" />
                                </Card>
                            </Col>
                            <Col
                            >
                                <Card
                                    style={{ width: '100%', textAlign: 'center' }}
                                    cover={<ListComponent thumbUrl={thumbUrl} videoLink={videoLink} />}
                                >
                                    <Meta title="Listing Image" />
                                </Card>
                            </Col>
                        </Row>
                        <div className='title-section'>
                            <TitleComponent>{title}</TitleComponent>
                        </div>
                        <div className='publish-section'>
                            <div className='publish'>
                            <Badge
                                //style={{ paddingLeft: 32 }}
                                color={publishBadgeColor}
                                text={publishStatus}
                            />
                            <Button
                                type="primary"
                                className='toggle-publish'
                                block
                                data-testid='change-publish-status'
                                loading={isBusy}
                                disabled={isBusy}
                                onClick={debouncedToggle}
                                style={{ width: 120 }}
                            >
                                {listingButtonText}
                            </Button>
                            </div>
                            {
                                isPublished
                                    ? (
                                        <div className='webview'>
                                            <Button type="link" size='small' href={webLink} target="_blank">
                                                Web view
                                            </Button>
                                            <Link
                                                copyable={copyConfig}>
                                            </Link>
                                        </div>
                                    )
                                    : null
                            }
                        </div>
                        <div className='daily-activity-section'>
                            <Text type="secondary">Is daily activity</Text>
                            <Switch 
                                size='default' 
                                className='toggle-switch'
                                checked={dailyActivity}
                                onChange={debouncedToggleDailyActivity}
                             />
                        </div>
                        <Row style={{ paddingTop: 12 }} justify="start">
                            <Col style={styles.statItem}>
                                <ItemTitle title="Organisation" value={organisation} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Difficulty" value={difficulty} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Duration" value={duration} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Xp" value={xp} />
                            </Col>
                            <Col style={styles.statItem}>
                                <ItemTitle title="Facilitation Needed" value={isFacilitationNeeded ? 'Yes' : 'No'} />
                            </Col>
                            {
                                hasLocationAccess ?
                                    (
                                        <Col style={styles.statItem}>
                                            <ItemTitle title="Location" value={location} />
                                        </Col>
                                    ) : null
                            }
                        </Row>
                        <Row style={{ paddingTop: 18 }}>
                            <Col>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Themes</Title>
                                    </div>
                                    <ItemTags items={themes} />
                                </TitleDiv>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 8 }}>
                            <Col>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Skills</Title>
                                    </div>
                                    <ItemTags items={skills} />
                                </TitleDiv>
                            </Col>
                        </Row>
                        {
                            showAges ?
                                (
                                    <Row style={{ paddingTop: 8 }}>
                                        <Col>
                                            <TitleDiv>
                                                <div>
                                                    <Title level={5}>Ages</Title>
                                                </div>
                                                <ItemTags items={ages} />
                                            </TitleDiv>
                                        </Col>
                                    </Row>
                                )
                                : null
                        }
                        {
                            showMaterials ?
                                (
                                    <Row style={{ paddingTop: 8 }}>
                                        <Col>
                                            <TitleDiv>
                                                <div>
                                                    <Title level={5}>Materials</Title>
                                                </div>
                                                <ItemTags items={materials} />
                                            </TitleDiv>
                                        </Col>
                                    </Row>
                                )
                                : null
                        }
                        {
                            isClubLevelVisibility && clubs && clubs.length
                                ? (
                                    <Row style={{ paddingTop: 18 }}>
                                        <Col>
                                            <TitleDiv>
                                                <div>
                                                    <Title level={5} >Visible to user of club(s)</Title>
                                                </div>
                                                <ItemTags items={clubs} />
                                            </TitleDiv>
                                        </Col>
                                    </Row>
                                ) : null
                        }
                         <Row style={{ paddingTop: 8 }}>
                            <Col>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Seasons</Title>
                                    </div>
                                    <ItemTags items={seasons} />
                                </TitleDiv>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 18 }}>
                            <Col span={24}>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Intro</Title>
                                    </div>
                                    <Text>{intro}</Text>
                                </TitleDiv>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 24 }}>
                            <Col span={24}>
                                <TitleDiv>
                                    <div>
                                        <Title level={5} >Description</Title>
                                    </div>
                                    <Text>{description}</Text>
                                </TitleDiv>
                            </Col>
                        </Row>
                        <div>
                            {/* antd tabs flickering issue
                            .ant-tabs-nav-operations { display: none !important;} */}
                            <Tabs 
                                defaultActiveKey={[1]} 
                                style={{ minHeight: 300 }} 
                                
                            >
                                <TabPane forceRender tab={`Steps ${stepsCount > 0 ? ` (${stepsCount})` : ''}`} key={1}>
                                    <Sections sections={sections} />
                                </TabPane>
                                <TabPane forceRender tab={`Resources ${resourcesCount > 0 ? ` (${resourcesCount})` : ''}`} key={2}>
                                    <Resources resources={resources} />
                                </TabPane>
                                <TabPane forceRender tab="Creators & Powered by" key={3}>
                                    <Creators creator={creators.creator} poweredBy={creators.poweredBy} />
                                </TabPane>
                                <TabPane forceRender  tab={`Prerequisite activities ${prequisiteCount > 0 ? ` (${prequisiteCount})` : ''}`} key={4}>
                                    <Prerequisites prerequisites={prerequisites} />
                                </TabPane>
                                {
                                    showBannersTab ?
                                        (
                                            <TabPane forceRender tab="Banners" key={5}>
                                                <BannerView oneValues={oneValues} oneAreas={areas} title={title} />
                                            </TabPane>
                                        ) : null
                                }
                                {
                                    showRelatedToTab ?
                                        (
                                            <TabPane forceRender tab="Related To" key={6}>
                                                <RelatedToView
                                                    primaryRelatedActivity={primaryRelatedActivity}
                                                    relatedActivities={relatedActivities}
                                                />
                                            </TabPane>
                                        ) : null
                                }
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}