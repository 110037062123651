import { Form, Input } from 'antd';
import React from 'react';


export default ({ form, onSubmitCallback }) => {

    const onSubmit = (values) => {
        try {

            let postData = {
                title: values.title,
            }

            onSubmitCallback && onSubmitCallback(postData);

        } catch (error) {
            console.log('error in edit skill submit form', error);
        }
    }

    return (
        <Form
            layout='vertical'
            form={form}
            scrollToFirstError
            name='create-edit-theme'
            //initialValues={initialValues}
            onFinish={onSubmit}
        >
            <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: 'Title is required.' }]}
                wrapperCol={{ span: 16 }}
            >
                <Input placeholder="title" showCount maxLength={50} />
            </Form.Item>
        </Form>
    )

}