import { Col, Row, Table } from 'antd';
import { useMemo } from 'react';


export default (props) => {

    const {
        items = [],
        onPagniationChange,
        onSortChange,
        onEditClick,
        //onDelete,
        pageNo,
        total,
        defaultPageSize,
        //showEditModal,
        sortBy,
        sortOrder,
        hasEditAccess = false,
        //hasDeleteAccess = false,
    } = props;

    //let navigate = useNavigate();

    const columns = useMemo(() => {

        let titleCol = { defaultSortOrder: undefined }

        const defaultSortOrder = sortOrder === 'ascend' ? 'ascend' : 'descend';

        if (sortBy === 'role') {
            titleCol = { defaultSortOrder }
        }

        return [
            {
                title: 'Title',
                dataIndex: 'role',
                key: 'role',
                sorter: true,
                ...titleCol,
            },
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                //render: (text, record) => <span>{record.name}</span>
                render: (text, record) => {

                    return (
                        <a
                            data-testid='edit-user-role'
                            style={hasEditAccess && record.canEdit ? {} : { color: 'grey', cursor: 'not-allowed' }}
                            data-can-edit={hasEditAccess && record.canEdit ? 'yes' : ''}
                            onClick={() => {
                                hasEditAccess && record.canEdit && onEditClick(record.id);
                            }}>
                            Edit
                        </a>
                    )
                },
            },
            // {
            //     title: '',
            //     dataIndex: 'name',
            //     key: 'name',
            //     //render: (text, record) => <span>{record.name}</span>
            //     render: (text, record) => {

            //         return (
            //             <a
            //                 data-testid='delete-user-role'
            //                 style={hasDeleteAccess && record.canDelete ? {} : { color: 'grey', cursor: 'not-allowed' }}
            //                 data-can-delete={hasDeleteAccess && record.canDelete ? 'yes' : ''}
            //                 onClick={() => {
            //                     hasDeleteAccess && record.canDelete && onDelete && onDelete(record.id, record.title)
            //                 }}>
            //                 Delete
            //             </a>
            //         )
            //     },
            // },
        ]
    }, [sortBy, sortOrder]);


    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }

    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{
                    //padding: '12px',
                    paddingTop: '0px'
                }}>
                    <Table
                        columns={columns}
                        dataSource={items}
                        rowKey={'id'}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}