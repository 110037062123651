import React, { useMemo } from 'react';
import { get } from 'lodash';
import YoutubeResource from './youtubeResource';
import PDFResource from './pdfResource';
import LinkResource from './linkResource';
import ImageResource from './imageResource';
import viewDownloadResource from './viewDownloadResource';
import empty from '../../../../../../common/components/Empty';
import { resourceType } from '../../../../../../config/constants';


export const resourceViewFactory = {
    [resourceType.link]: LinkResource,
    [resourceType.youtubeVideo]: YoutubeResource,
    [resourceType.pdf]: PDFResource,
    [resourceType.image]: ImageResource,
    [resourceType.viewDownload]: viewDownloadResource,
}


const ResourceFactoryView = (props) => {

    const { type } = props;

    const ResourceComponent = useMemo(() => {

        return get(resourceViewFactory, type, empty)

    }, [type]);


    return (<ResourceComponent {...props} />)

}


export default ResourceFactoryView;