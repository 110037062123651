import { useQuery } from 'react-query';
import { Breadcrumb, Button, message, Modal, Row, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AppLayout from '../../../common/components/layout';
import OrganisationView from './view';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Dashboard from '../../../common/components/dashboard';


export default () => {

    let { organisationId } = useParams();

    //console.log('params', params);

    const navigate = useNavigate();

    const { httpGetAsync, httpPostAsync } = useHttpHelper();

    const fetchOrganisationAsync = async () => {
        try {

            const challenge = await httpGetAsync(appLinks.organisationDetails.replace('{organisationId}', organisationId));

            console.log('organisation details', challenge);

            return challenge;

        } catch (error) {
            console.log('error fetching organisation details', error);
        }
    }

    const {
        data,
        isLoading,
        refetch: reloadDetails,
    } = useQuery(
        [queryKeys.organisationDetailsView, organisationId],
        fetchOrganisationAsync,
        {
            enabled: organisationId > 0,
            refetchOnWindowFocus: false
        }
    );

    const {
        id,
        name,
        parentOrganisation,
        status,
        showInListing,
        supportedLanguages,
        typeText,
        email,
        website,
        logoLink,
        imageUrl,
        pinned = [],
        showPinned,
        showPopular,
    } = data || {}

    const onBack = () => {
        try {

            navigate(`/${appRoutes.organisations}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const [isBusy, setIsBusy] = useState(false);

    //#region toggle pinned list

    const toggleShowPinnedApiAsync = async (payload, operationTex) => {

        const link = appLinks.updateShowPinned.replace('{organisationId}', id)

        const response = await httpPostAsync(link, payload);

        if (response && response.code === 0) {
            message.success(`${operationTex} pinned list was successfull`);
        } else {
            message.error(`Could not ${operationTex} pinned list`);
        }

        console.log('pinned list response', response);

        await reloadDetails();
    }

    const onShowPinnedToggle = (show) => {
        try {

            console.log('Show pinned list', show)

            let payload = {};

            let messageText = '';
            let okButtonText = ''
            let operationText = '';

            if (!!showPinned) {
                payload.showPinned = false;
                messageText = `Are you sure you want to hide pinned list from "${name}" home screen`;
                okButtonText = 'Hide';
                operationText = 'Hide'
            } else {
                messageText = `Are you sure you want to show pinned list in "${name} home screen"`;
                okButtonText = 'Show'
                operationText = 'Show';
                payload.showPinned = true;
            }

            Modal.confirm({
                title: messageText,
                okText: okButtonText,
                closable: true,
                okButtonProps: {
                    loading: isBusy,
                    disabled: isBusy,
                },
                onOk: async () => {
                    setIsBusy(true);
                    await toggleShowPinnedApiAsync(payload, operationText);
                    setIsBusy(false);
                }
            });


        } catch (error) {
            console.error('error on show pinned toggle', error);
            setIsBusy(false);
        }
    }

    //#endregion

    //#region show popular challenges list in home

    const toggleShowPopularApiAsync = async (payload, operationTex) => {

        const link = appLinks.updateShowPopular.replace('{organisationId}', id)

        const response = await httpPostAsync(link, payload);

        if (response && response.code === 0) {
            message.success(`${operationTex} popular list was successfull`);
        } else {
            message.error(`Could not ${operationTex} popular list`);
        }

        console.log('popular list response', response);

        await reloadDetails();
    }

    const onShowPopularToggle = (show) => {
        try {

            console.log('Show popular list', show)

            let payload = {};

            let messageText = '';
            let okButtonText = ''
            let operationText = '';

            if (!!showPopular) {
                payload.showPopular = false;
                messageText = `Are you sure you want to hide popular list from "${name}" home screen`;
                okButtonText = 'Hide';
                operationText = 'Hide'
            } else {
                messageText = `Are you sure you want to show popular list in "${name} home screen"`;
                okButtonText = 'Show'
                operationText = 'Show';
                payload.showPopular = true;
            }

            Modal.confirm({
                title: messageText,
                okText: okButtonText,
                closable: true,
                okButtonProps: {
                    loading: isBusy,
                    disabled: isBusy,
                },
                onOk: async () => {
                    setIsBusy(true);
                    await toggleShowPopularApiAsync(payload, operationText);
                    setIsBusy(false);
                }
            });


        } catch (error) {
            console.error('error on show popular toggle', error);
            setIsBusy(false);
        }
    }

    //#endregion

    return (
        <Dashboard subrouting={false} activeItem='org'>
            <Spin spinning={isLoading || isBusy}>
                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Organisations</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <OrganisationView
                    name={name}
                    status={status}
                    showInListing={showInListing}
                    parentOrganisation={parentOrganisation}
                    supportedLanguages={supportedLanguages}
                    type={typeText}
                    email={email}
                    website={website}
                    logoLink={logoLink}
                    imageUrl={imageUrl}
                    pinned={pinned}
                    showPinned={showPinned}
                    onShowPinnedToggle={onShowPinnedToggle}
                    showPopular={showPopular}
                    onShowPopularToggle={onShowPopularToggle}
                />
            </Spin>
        </Dashboard>
    );

}