import React, { useCallback, useMemo, useState } from 'react';
import {
    Button, Col, Form, Input, Row, Typography, Modal, Space, Divider, Select
} from 'antd';
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { find, get, map, random, uniqueId } from "lodash";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragOverlay,
} from '@dnd-kit/core';
import {
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
    rectSwappingStrategy,
} from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HexColorPicker } from 'react-colorful';
import ParagraphItems, { paraFieldName } from './ParagraphItems';
import LinkItems, { linkFieldName } from './LinkItems';
import { imageUrls } from '..';
import SectionItemContainer from './SectionItemContainer';

const { confirm } = Modal
const { Text } = Typography
const { Option } = Select

export const sectionFieldName = 'emailSections'


const SortableSection = ({ fields, form, move, remove, viewMode }) => {

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [activeId, setActiveId] = useState(null);
    const [activeItem, setActiveItem] = useState({});

    const steps = form.getFieldValue(sectionFieldName) ?? [];

    const getItemByIndex = useCallback((index) => {

        //console.log('get unique id callback', get(steps, `[${index}]`))

        return get(steps, `[${index}]`) || {}

    }, [steps])

    const getItemByStepId = useCallback((stepId) => {

        //console.log('get unique id callback', get(steps, `[${index}]`))

        return find(steps, s => s.uniqueId === stepId)

    }, [steps])

    return (
        <div>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
            >
                <SortableContext
                    items={fields}
                    strategy={rectSwappingStrategy}
                >
                    {
                        fields?.map((field, index) => {

                            const onRemove = () => {
                                try {

                                    confirm({
                                        title: `Are you sure remove this section ${index + 1}?`,
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'This section will be removed permently after save.',
                                        okText: 'Remove',
                                        okType: 'danger',
                                        cancelText: 'No',
                                        onOk() {

                                            console.log('Remove', index,);
                                            remove(index);
                                        },
                                        onCancel() {
                                            console.log('Cancel');
                                        },
                                    });


                                } catch (error) {
                                    console.log('error removing step', error);
                                }
                            }

                            const item = getItemByIndex(index);

                            const getParagraphs = () => {
                                try {

                                    const item = getItemByIndex(index);

                                    console.log('items', item)

                                    return item?.[paraFieldName] ?? []

                                } catch (error) {
                                    console.log('error getting paragrpahs', error)
                                }
                            }

                            const setImageUrl = (id, imageUrl) => {
                                try {

                                    const sections = form.getFieldValue(sectionFieldName) ?? []
                                    //console.log('email sections', sections)
                                    if (!Array.isArray(sections)) {
                                        return;
                                    }

                                    const updateSections = sections?.map(e => {

                                        if (e.uniqueId === id) {

                                            return {
                                                ...e,
                                                imageUrl,
                                            }

                                        } 
                                        return e;

                                    }) 

                                    form.setFieldsValue({ [sectionFieldName]: updateSections })
                                    
                                } catch (error) {
                                    console.log('error setting section image url', error)
                                }
                            }

                            const getLinks = () => {
                                try {

                                    const item = getItemByIndex(index);

                                    console.log('items', item)

                                    return item?.[paraFieldName]?.[linkFieldName] ?? []

                                } catch (error) {
                                    console.log('error getting links', error)
                                }
                            }


                            return (
                                <SectionItemContainer
                                    key={`${item.uniqueId}.${index}`}
                                    field={field}
                                    {...item}
                                    index={index}
                                    onRemove={onRemove}
                                    getParagraphs={getParagraphs}
                                    setImageUrl={setImageUrl}
                                    getLinks={getLinks}
                                    form={form}
                                    viewMode={viewMode}
                                />
                            )
                        })
                    }
                </SortableContext>
                <DragOverlay>
                    {activeId ? (<SectionItemContainer id={activeId} {...activeItem} showBorder viewOnly />) : null}
                </DragOverlay>
            </DndContext>
        </div>
    )

    function handleDragStart(event) {
        const { active } = event;

        //console.log('drag start', active);

        setActiveId(active.id)

        //setActiveId(active.id);
        const item = getItemByStepId(active.id) || {};
        const stepIndex = findIndexOf(steps, active.id);
        setActiveItem({ ...item, index: stepIndex });

    }

    function findIndexOf(items = 0, stepId) {
        try {

            let indexOfItem = -1;

            for (let i = 0; i < items.length; i++) {

                if (items[i].uniqueId === stepId) {
                    indexOfItem = i;
                    break;
                }

            }

            return indexOfItem;

        } catch (error) {
            console.log('error finding index of item', error);
            return -1
        }

    }

    function handleDragEnd(event) {

        const { active, over } = event;

        //console.log('drag end', event);

        if (active.id !== over.id) {
            // console.log('active item', activeItem)

            const oldIndex = findIndexOf(steps, active.id);
            const newIndex = findIndexOf(steps, over.id);
            console.log('old index ', oldIndex, 'new index ', newIndex)
            move(oldIndex, newIndex);
        }

        setActiveId(null);
        setActiveItem({});
    }

}


const EmailSections = (props) => {

    const {
        form,
        viewMode
    } = props;

    //const [sections, setSections] = useState([])

    const getUniqueId = (steps) => {

        try {

            let stepUniqueId = uniqueId();

            let exists = find(steps, s => s.uniqueId === stepUniqueId);
            console.log('isUnique ', exists)

            let maxCount = steps.length;

            while (!!exists && maxCount < 1000) {
                exists = find(steps, s => s.uniqueId === stepUniqueId);
                let newId = uniqueId();
                stepUniqueId = `${stepUniqueId}.${newId}.${maxCount}`;
                maxCount++;
            }

            return stepUniqueId;

        } catch (error) {
            console.log('error getting unique if for setps', error);
            let stepUniqueId = uniqueId();
            return `${stepUniqueId}.${random(0, 999999)}`
        }
    }

    const getMaxSortOrder = (steps) => {
        try {

            let max = 0;

            if (!steps.length) {
                // of no steps return 0
                return max
            }

            for (let i = 0; i < steps.length; i++) {
                const sortOrder = get(steps[i], 'sortOrder');
                if (sortOrder >= max) {
                    max = sortOrder;
                }
            }

            return max + 1;

        } catch (error) {
            console.log('error gettin max sort order', error);
            return steps.length;
        }
    }

    return (
        <div>
            <Form.List name={sectionFieldName}>
                {(fields, { add, remove, move }) => {
                    return (
                        <div className='section-container' >
                            <div style={{ minHeight: 300 }}>
                                <SortableSection fields={fields} form={form} move={move} remove={remove} viewMode={viewMode}/>
                            </div>
                            <Row justify='center' align='bottom'>
                                <Button
                                    type="primary"
                                    className="add-section-btn"
                                    onClick={() => {

                                        const sectionItems = form.getFieldValue(sectionFieldName) ?? []
                                        const stepUniqueId = getUniqueId(sectionItems)
                                        let sortOrder = getMaxSortOrder(sectionItems)

                                        const timeStamp = new Date().getTime();

                                        add({
                                            sortOrder,
                                            uniqueId: stepUniqueId,
                                            [paraFieldName]: [{
                                                uid: timeStamp,
                                                para: 's'
                                            }]
                                        })

                                    }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Section
                                </Button>
                            </Row>
                        </div>
                    )
                }}
            </Form.List>
        </div>
    )

}

export default EmailSections;