import { useMemo } from 'react';
import { Col, Row, Table, Tooltip, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;


const UnsubscribeEmailsTable = props => {

    const {
        listItems,
        onPagniationChange,
        onSortChange,
        pageNo,
        total,
        defaultPageSize,
        navigateToDetails,
        navigateWithCopy,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Unsubscribed on',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, { appZoneCreatedAt, id }) => {

                //console.log('add created at', appZoneCreatedAt)

                return (
                    <Text>
                        {appZoneCreatedAt && appZoneCreatedAt.isValid() && appZoneCreatedAt.format('Do MMMM, YYYY HH:mm')}
                    </Text>)

                // return (
                //     <a
                //     onClick={() => {
                //         //console.log('on click', record)
                //         navigate(`/${appRoutes.organisations}/${record.id}`)
                //     }}>
                //         {record.name}
                //     </a>
                // )
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => {

                return (
                    <div style={{ maxWidth: 300 }}>
                        <Tooltip title={record.title}>
                            <Text ellipsis={{
                                rows: 1,
                                expandable: false,
                            }}>
                                {record.email}
                            </Text>
                        </Tooltip>
                    </div>
                )

            }
        },
    ], [navigate]);

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

            // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }

    return (
        <div className='send-email-list-table'>
            <Row>
                <Col justify='flex-start' span={24} style={{
                    //padding: '12px',
                    paddingTop: '0px'
                }}>
                    <Table
                        columns={columns}
                        dataSource={listItems}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}

export default UnsubscribeEmailsTable;