import React from 'react';
import { Divider } from 'antd';
import styledComponents from 'styled-components';
import { map } from 'lodash';
import { CreatorOrganisationView } from './creatorViews';


const TitleComponent = styledComponents.span`
font-size: 16px;
font-weight: bold;
`

export const PoweredByItem = (props) => {

    const { logoLink, website, name } = props;

    return (
        <div style={{ marginTop: 12 }}>
            <CreatorOrganisationView logoLink={logoLink} website={website} name={name} />
        </div>
    )
}

export default ({ poweredBy = [] }) => {

    return (
        <div>
            {
                poweredBy && poweredBy.length
                    ? (
                        <div style={{ marginTop: 16 }}>
                            <TitleComponent>Powered by</TitleComponent>
                            <div style={{ paddingBottom: 24 }} />
                            {
                                map(poweredBy, (item, index) => {

                                    return (
                                        <div key={item.id}>
                                             {index > 0 ? (<Divider />) : null}
                                            <PoweredByItem  {...item} />
                                        </div>
                                    )

                                })
                            }
                        </div>
                    )
                    : null
            }
        </div>
    )
}