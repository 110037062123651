import {
    Button,
    Card,
    Col,
    Collapse,
    Form, Input,
    InputNumber,
    Radio,
    Row, Select,
    Switch,
    Tabs,
    Upload,
    message,
} from 'antd';
import { get, includes, isEmpty, join, map, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import { ReactComponent as CoverImageDefault } from '../../../../assets/coverPlaceholder.svg';
import Empty from '../../../../common/components/Empty';
import LookupSelect from '../../../../common/components/lookupSelect';
import DifficultyLookup from '../../../../common/components/lookupSelect/DifficultyLookup.js';
import MaterialsLookup from '../../../../common/components/lookupSelect/MaterialsLookup.js';
import SkillsLookup from '../../../../common/components/lookupSelect/SkillsLookups.js';
import ThemesLookup from '../../../../common/components/lookupSelect/ThemesLookup.js';
import useHttpHelper from '../../../../common/hooks/useHttpHelper';
import { appLinks, creatorTypes, imageFilesExenstions, organisationTypes, queryKeys } from '../../../../config/constants';
import { isNullOrWhitespace } from '../../../../helpers/utils';
import FormUploader from '../formUploader';
import useOrganisationsLookups from '../hooks/useOrganisationsLookups';
import '../index.scss';
import GenerateChallenge from './GenerateChallenge';
import RelatedTo, { PrimaryRelatedToFieldName, RelatedToListFieldName } from './RelatedTo';
import Banner from './banner';
import Clubs from './clubs';
import Creators from './creators';
import Prerequisites from './prerequisites';
import Resources from './resources';
import SelectTag from './selectTag/index.js';
import Steps from './steps';
import CustomTimePicker from './timepicker';
import SeasonsLookup from '../../../../common/components/lookupSelect/SeasonsLookups.js';

const { Option } = Select
const { TabPane } = Tabs
const { Panel } = Collapse


function normFile(e) {

    console.log('Upload event:', e);

    if (Array.isArray(e)) {
        return e;
    }

    return e && e.fileList;
};

const mapValues = (items) => items?.map(e => e.value) 

export const languages = [
    { key: 'en', label: 'English' },
    { key: 'ro', label: 'Romanian' },
]

const coverImageOptions = [
    { label: 'Cover image', value: 'image' },
    { label: 'Youtube video', value: 'youtubeVideo' }
]

const listImageOptions = [
    { label: 'List image', value: 'image' },
    { label: 'Youtube video', value: 'youtubeVideo' }
]

export const ThumbImagePreview = (props) => {

    const { previewUrl } = props;

    return (
        previewUrl
            ? (
                <Row style={{ paddingBottom: '15px' }} >
                    <img src={previewUrl} alt="avatar" style={{ width: 120, height: 130, borderRadius: '8px' }} />
                </Row>
            )
            : (
                <Row justify="start" style={{ paddingBottom: 12 }}>
                    <CoverImageDefault height={130} width={120} style={{ borderRadius: 8 }} />
                </Row>
            )
    )

}

export const CoverImagePreview = (props) => {

    const { previewUrl } = props;

    return (
        previewUrl
            ? (
                <Row style={{ paddingBottom: '15px' }} >
                    <img src={previewUrl} alt="avatar" style={{ width: 280, height: 180, borderRadius: '8px' }} />
                </Row>
            )
            : (
                <Row style={{ paddingBottom: '15px' }}>
                    <CoverImageDefault height={180} width={280} style={{ borderRadius: 8 }} />
                </Row>
            )
    )
}

const styles = {
    formRow: { paddingRight: 24 }
}

const getItemTagColor = item => !!item.isNew ? 'blue' : undefined

export default (props) => {

    const {
        onSubmit,
        formValues = {},
        isDataLoaded,
        submitTitle = '',
        thumbId: passedIconId,
        thumbUrl: passedIconImageUrl,
        imageId: passedCoverImageId,
        imageUrl: passedCoverImageUrl,
        organisationId,
        introText,
        organisationsDisabled = true,
        disableThemes = false,
        disableSkills = false,
        disableDifficultyLevel = false,
        themesLabel = "Themes",
        updateValuesOnOrganisationChange,
        showPublish = false,
        activeTab = 'intro',
        setActiveTabParam,
        showAges = false,
        showMaterials = false,
        showBannersTab = false,
        hasLocationAccess = false,
        showRelatedToTab = false,
        postDataMapper,
        showGeneratePrompt = false
    } = props;

    const [form] = Form.useForm();

    const shouldRedirectOnSubmitRef = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    const { httpPostAsync } = useHttpHelper();

    //#region  dropdowns list
    const {
        organisationParams,
        fetchOrganisationsAsync,
        organisationQueryKeyFn,
    } = useOrganisationsLookups(undefined, organisationTypes.foundation, false);

    const onOrganisationChangeHandler = (e) => {
        try {

            if (typeof updateValuesOnOrganisationChange !== 'function') {
                return;
            }

            const values = form.getFieldsValue();
            const formValues = pick(
                values,
                ['organisationInfo', 'organisationId', 'skills', 'themes', 'difficultyId', 'durationId', 'introId'])

            const updatedValues = updateValuesOnOrganisationChange(e, formValues);

            console.log('updated values', updatedValues)

            if (!isEmpty(updatedValues)) {
                form.setFieldsValue({ ...updatedValues })
            }

        } catch (error) {
            console.log('error in on change organisations in create edit', error)
        }
    }

    // skills
    const fetchSkillsAsync = async () => {
        try {

            const items = await httpPostAsync(appLinks.skills, { organisationId });

            console.log('skills', items);

            return items;

        } catch (error) {
            console.log('error fetching skills', error);
        }
    }

    const skillsQueryKey = [queryKeys.skills, organisationId];

    const {
        data: skills = [],
        //isLoading: isLoadingSkills,
    } = useQuery(
        skillsQueryKey,
        fetchSkillsAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });

    // intro templates
    const fetchIntroTemplatesAsync = async () => {
        try {

            const items = await httpPostAsync(appLinks.introTemplates, { organisationId });

            console.log('intro templates', items);

            return items;

        } catch (error) {
            console.log('error fetching intro templates', error);
        }
    }

    const introTemplatesQueryKey = [queryKeys.introTemplates, organisationId];

    const {
        data: introTemplates = [],
        isLoading: isLoadingIntroTemplates,
    } = useQuery(
        introTemplatesQueryKey,
        fetchIntroTemplatesAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });

    const onIntroTemplateChange = (template, param) => {
        try {

            console.log('on intro template change', template, param);

            const intro = get(param, 'intro');

            setIntroTemplateText(intro);

        } catch (error) {
            console.log('error setting intro template', error);
        }
    }

    // ages
    const fetchAgesAsync = async () => {
        try {

            const items = await httpPostAsync(appLinks.agesOrganisationLookups, { organisationIds: [organisationId] });

            console.log('ages', items);

            return items;

        } catch (error) {
            console.log('error fetching ages', error);
        }
    }

    const agesQueryKey = [queryKeys.agesOrganisationsLookups, organisationId];

    const {
        data: ages = [],
        isLoading: isLoadingAges,
    } = useQuery(
        agesQueryKey,
        fetchAgesAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });


    // materials
    const fetchMaterialsAsync = async () => {
        try {

            const items = await httpPostAsync(appLinks.materialsOrganisationLookups, { organisationIds: [organisationId] });

            console.log('materials', items);

            return items;

        } catch (error) {
            console.log('error fetching materials', error);
        }
    }

    const materialsQueryKey = [queryKeys.materialsOrganisationsLookups, organisationId];

    const {
        data: materials = [],
        // isLoading: isLoadingMaterials,
    } = useQuery(
        materialsQueryKey,
        fetchMaterialsAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0,
            //placeholderData: [],
        })

    // location
    const locationParams = useMemo(() => ({ organisationId }), [organisationId]);

    const fetchLocationsAsync =
        useCallback(async (params = {}) => {
            const response = await httpPostAsync(appLinks.locations, { ...params })
            //console.log('locations', response)
            return response?.data ?? []
        }, [httpPostAsync]);

    const enableLocationsFetch = useCallback(({ organisationId = 0 }) => organisationId > 0, []);

    const locationsQueryKeyFn =
        useCallback(({ organisationId }) => [queryKeys.locations, organisationId], []);

    //#endregion dropdowns

    //#region upload files
    const [thumbId, setThumbId] = useState();
    const [thumbUrl, setThumbUrl] = useState();

    const [imageId, setImageId] = useState();
    const [imageUrl, setImageUrl] = useState();
    //#endregion 

    const [stepsCount, setStepCount] = useState(0);
    const [resourcesCount, setResourcesCount] = useState(0);
    const [prequisiteCount, setPrequisiteCount] = useState(0);
    const [hasCustomIntro, setHasCustomIntro] = useState(false);
    const [clubVisibility, setClubVisibility] = useState(false);
    const [clubCount, setClubCount] = useState(0);
    const [title, setTitle] = useState('');

    //#region form update helpers

    const getFormValue = (param) => {
        const allowedParams = [
            'prompt',
            'materials',
            'skills',
            'steps',
            'promptMaterials',
            'promptSkills',
            'promptAreasOfDevelopment',
            'promptAgeGroups'
        ]

        if (!allowedParams?.includes(param)) {
            return;
        }

        return form.getFieldValue(param)
    }

    const [extraPostData, setExtraPostData] = useState({})

    const setPromptError = () => {
        form.setFields([{
            name: 'prompt',
            errors: ['Prompt is required']
        }])
    }

    const setFormValues = (params) => {

        const allowedParams = [
            'customIntro',
            'description',
            'steps',
            'materials',
            'skills',
            'oneValues',
            'oneAreas',
            'promptResponse',
        ]

        let hasProp = false

        const formData = allowedParams?.reduce((prev, cur) => {

            const allowedProp = cur
            const value = params?.[allowedProp]
            if (value !== undefined) {

                prev = {
                    ...prev,
                    [allowedProp]: value
                }

                hasProp = true
            }

            return prev;

        }, {})

        if (hasProp) {
            form.setFieldsValue(formData)
        }

        if (params?.promptResponse) {
            setExtraPostData({ promptResponse: params?.promptResponse })
        }

    }

    //#endregion form update helpers

    useEffect(() => {

        //console.log('form values use effect', isDataLoaded, formValues);

        if (isDataLoaded) {
            console.log('set field values', formValues);

            form.setFieldsValue(formValues);

            if (!formValues) {
                return;
            }

            if (formValues.steps) {
                setStepCount(formValues.steps.length || 0)
            }

            if (formValues.resources) {
                setResourcesCount(formValues.resources.length || 0)
            }

            if (formValues.prerequisites) {
                setPrequisiteCount(formValues.prerequisites.length || 0)
            }

            setHasCustomIntro(!isNullOrWhitespace(formValues.customIntro));

            if (formValues.duration >= 0) {
                setDurationLabel(formValues.duration)
            }

            if (formValues.coverImageType === 'youtubeVideo') {
                setIsCoverImageRequired(false)

            }

            if (formValues.listImageType === 'youtubeVideo') {
                setIsListImageRequired(false)
            }

            if (formValues.isClubLevelVisibility) {
                setClubVisibility(!!formValues.isClubLevelVisibility)
            }

            if (formValues.challengeClubs) {
                const clubCount = formValues.challengeClubs && formValues.challengeClubs.length
                    ? formValues.challengeClubs.length
                    : 0;
                setClubCount(clubCount)
            }

            if (formValues.title) {
                setTitle(formValues.title)
            }

        }

    }, [formValues, isDataLoaded])

    const [introTemplateText, setIntroTemplateText] = useState();

    useEffect(() => {

        setThumbId(passedIconId);
        setThumbUrl(passedIconImageUrl);
        setImageId(passedCoverImageId);
        setImageUrl(passedCoverImageUrl);
        setIntroTemplateText(introText);

        console.log('setting image urls')

    }, [passedIconId, passedIconImageUrl, passedCoverImageId, passedCoverImageUrl, introText])


    const onSubmitHandler = async (values) => {
        try {

            //console.log('on submit values', values);

            if (typeof onSubmit !== 'function') {
                return;
            }

            const {
                title,
                xp,
                difficultyId,
                isFacilitationNeeded,
                customIntro,
                introId,
                description,
                themes = [],
                skills = [],
                steps,
                resources = [],
                creatorType,
                creatorText,
                poweredByOrganisations = [],
                organisationId,
                duration,
                publish,
                coverImageType,
                listImageType,
                videoLink,
                isClubLevelVisibility,
                challengeClubs = [],
                prerequisites = [],
                ages,
                materials,
                oneValues,
                oneAreas,
                locationId,
                dailyActivity,
                seasons,
            } = values;

            if (steps && steps.length) {
                for (let i = 0; i < steps.length; i++) {
                    steps[i].sortOrder = i;
                }
            }

            for (let i = 0; i < resources.length; i++) {

                resources[i] = {
                    sortOrder: i,
                    id: resources[i].id,
                    name: resources[i].name,
                    type: resources[i].type,
                    link: resources[i].link,
                    documentId: resources[i].documentId,
                    fileName: resources[i].fileName,
                }

            }

            let creatorId = undefined;

            if (creatorType === creatorTypes.creator) {
                creatorId = get(values, 'creatorId');
            } else if (creatorType === creatorTypes.organisation) {
                creatorId = get(values, 'creatorOrganisationId');
            } else if (creatorType === creatorTypes.club) {
                creatorId = get(values, 'creatorClubId');
            }

            let orderedPrerequisites = []

            for (let i = 0; i < prerequisites.length; i++) {
                orderedPrerequisites.push({
                    value: prerequisites[i].value,
                    sortOrder: i,
                    id: prerequisites[i].id,
                    completionLevel: prerequisites[i].completionLevel
                })
            }


            let calculatedDuration = 0;
            if (duration) {
                try {
                    // const momentDuration = moment.duration(duration.diff(moment().startOf('day')));
                    // if (momentDuration && momentDuration.isValid()) {
                    //    calculatedDuration = Math.abs(Math.floor(momentDuration.asMinutes()));
                    //}
                    calculatedDuration = Math.abs(Math.floor(duration));
                } catch (error) {
                    console.log('error calculating duration', error);
                }
            }

            let mappedOneValues;
            if (oneValues?.length) {
                mappedOneValues = []
                for (let i = 0; i < oneValues.length; i++) {
                    const oneValue = {
                        id: oneValues[i].value,
                        sortOrder: i,
                        score: oneValues[i].score,
                    }

                    mappedOneValues.push(oneValue)
                }
            }

            let mappedAreas;
            if (oneAreas?.length) {
                mappedAreas = [];
                for (let i = 0; i < oneAreas.length; i++) {
                    const oneArea = {
                        id: oneAreas[i].value,
                        sortOrder: i,
                        score: oneAreas[i].score,
                    }
                    mappedAreas.push(oneArea)
                }
            }

            let postData = {
                organisationId,
                title,
                thumbId,
                imageId,
                xp,
                difficultyLevelId: difficultyId,
                isFacilitationNeeded,
                customIntro,
                introId,
                description,
                themes: map(themes, item => item.value),
                skills: map(skills, item => item.value),
                steps,
                resources,
                creator: {
                    creatorType,
                    creatorId,
                    creatorText,
                },
                poweredBy: map(poweredByOrganisations, item => item.value),
                durationMins: calculatedDuration,
                publish,
                coverImageType,
                listImageType,
                videoLink,
                isClubLevelVisibility,
                clubs: map(challengeClubs, item => item.value),
                prerequisites: orderedPrerequisites,
                ages: ages?.map(item => item.value),
                materials: materials?.map(item => item.value),
                values: mappedOneValues,
                areas: mappedAreas,
                locationId,
                [PrimaryRelatedToFieldName]: values?.[PrimaryRelatedToFieldName],
                [RelatedToListFieldName]: values?.[RelatedToListFieldName],
                dailyActivity,
                seasons: mapValues(seasons),
            }

            if (typeof postDataMapper === 'function') {
                const overridePostData = postDataMapper({ ...values, ...extraPostData })
                if (overridePostData !== null && typeof postData === 'object') {
                    postData = {
                        ...postData,
                        ...overridePostData
                    }
                }

            }

            await Promise.resolve(setIsSaving(true));

            await onSubmit(postData, shouldRedirectOnSubmitRef.current);

            setIsSaving(false);

        } catch (error) {
            console.log('error submiting', error);
        }
    }

    const [durationText, setDurationText] = useState('');

    const setDurationLabel = (totalMins = 0) => {
        try {

            console.log('set duration label', totalMins)

            let delta = totalMins;

            let days = Math.floor(delta / (24 * 60));
            delta -= days * (24 * 60);


            let hours = Math.floor(delta / 60) % 24;
            delta -= hours * 60;

            let minutes = delta;

            console.log('days', days, hours, minutes);

            //let text = '';
            let durations = [];
            if (days > 0) {
                durations.push(`${days} day${days > 1 ? 's' : ''}`);
            }

            if (hours > 0) {
                durations.push(`${hours} hour${hours > 1 ? 's' : ''}`)
            }

            if (minutes > 0) {
                durations.push(`${minutes} minute${minutes > 1 ? 's' : ''}`)
            }

            setDurationText(join(durations, ', '))

        } catch (error) {
            console.log('error setting duration text', error);
        }
    }

    const onValuesChange = (values) => {
        try {

            if (!values) {
                return;
            }

            if (values.steps) {
                setStepCount(values.steps.length ?? 0)
                console.log('first', values?.steps?.length)
            }

            if (values.resources) {
                setResourcesCount(values.resources.length ?? 0)
            }

            if (values.prerequisites) {
                setPrequisiteCount(values.prerequisites.length ?? 0)
            }

            setHasCustomIntro(!isNullOrWhitespace(values.customIntro));

            if (values.isClubLevelVisibility) {
                setClubVisibility(!!values.isClubLevelVisibility)
            }

            if (values.challengeClubs) {
                // console.log('club visibility', formValues.isClubLevelVisibility)
                let clubCount = 0;

                if (clubVisibility && values.challengeClubs) {
                    clubCount = values.challengeClubs.length;
                }

                setClubCount(clubCount)
            }

            if (values.duration >= 0) {
                setDurationLabel(values.duration)
            }

            if (values.title) {
                setTitle(values.title)
            }

            console.log('onValuesChange', { ...values });

        } catch (error) {
            console.log('error handling on values change', error);
        }
    }

    //#region  uploads
    // thumb upload
    const getThumbUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.uploadChallengeThumb, { organisationId, ...UploadFile })

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload challenge thumb response', uploadLinkResponse)

            setThumbId(documentId)

            return uploadUrl;

        } catch (error) {
            console.log('error getting thumb upload url', error)
        }
    }

    const onThumbUploadSuccess = ({ url }) => {
        try {

            console.log('on file upload success', thumbId)

            //setThumbId(challengeThumbDocumentIdRef.current);
            setThumbUrl(url);
            //form.setFieldsValue({ thumbId: thumbId })


        } catch (error) {
            console.log('error in image resource upload success', error);
        }
    }

    const beforeImageUpload = (file) => {

        const extenstion = file.name.substring(file.name.lastIndexOf('.'), file.name.length)

        console.log('extension ', extenstion);

        const isImage = includes(imageFilesExenstions, extenstion);
        if (!isImage) {
            message.error(`Only image files can be uploaded`);
        }

        if (file.size > 3 * 1024 * 1024) {
            message.error(`File size needs to be less then 3MB`);
        }

        return isImage || Upload.LIST_IGNORE;
    }

    //cover image upload

    const getImageUploadUrlAsync = async (UploadFile = {}) => {
        try {

            const uploadLinkResponse = await httpPostAsync(appLinks.uploadChallengeCoverImage, { organisationId, ...UploadFile })

            const { uploadUrl, documentId } = uploadLinkResponse || {}

            console.log('upload challenge image response', uploadLinkResponse)

            setImageId(documentId)

            return uploadUrl;

        } catch (error) {
            console.log('error getting thumb upload url', error)
        }
    }

    const onImageUploadSuccess = ({ url }) => {
        try {

            console.log('on file upload success', thumbId)

            //setThumbId(challengeThumbDocumentIdRef.current);
            setImageUrl(url);
            //form.setFieldsValue({ thumbId: thumbId })



        } catch (error) {
            console.log('error in image resource upload success', error);
        }
    }
    //#endregion

    const onFinishFailed = (e) => {
        console.log('finish failed', e);
        try {

            const errors = e.errorFields;
            if (!errors || !errors.length) {
                //no error return
                return;
            }

            const firstError = errors[0];
            console.log('first error', firstError)

            if (get(firstError, 'name[0]') === 'steps') {
                //open steps tab
                console.log('open steps tab');
                setActiveTabParam('steps');
            } else if (get(firstError, 'name[0]') === 'resources') {
                console.log('open resources tab')
                setActiveTabParam('resources');
            }

        } catch (error) {
            console.log('error handling on submit error', error);
        }
    }

    const durationValdator = (rule, value, callback) => {
        try {

            console.log('duration validator', value)

            //const minutes = moment.duration(value.diff(moment().startOf('day'))).asMinutes()


            if (!value > 0) {
                callback('Please select a duration of more then zero minutes')
            }

            return callback();

        } catch (error) {
            console.log('error validating duration', error)
            callback('Could not validate duration')
        }
    }

    const [isCoverImageRequired, setIsCoverImageRequired] = useState(true);

    const onCoverImageOptionChange = (e) => {
        try {

            console.log('cover image', e);

            const value = e.target.value;

            setIsCoverImageRequired(value === 'image')

        } catch (error) {
            console.log(' on cover image/video options error', error)
        }
    }

    const [isListImageRequired, setIsListImageRequired] = useState(true);

    const onListImageOptionChange = (e) => {
        try {

            console.log('cover image', e);

            const value = e.target.value;

            setIsListImageRequired(value === 'image')

        } catch (error) {
            console.log(' on list image/video options error', error)
        }
    }

    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}
                >
                    <Form
                        layout='vertical'
                        form={form}
                        scrollToFirstError
                        onFinish={onSubmitHandler}
                        onValuesChange={onValuesChange}
                        onFinishFailed={onFinishFailed}
                        name='create-edit-challenge'
                    >
                        <Form.Item name="challengeId" hidden>
                            <Input type="hidden" />
                        </Form.Item>
                        {
                            showGeneratePrompt ?
                                (
                                    <Collapse
                                        //defaultActiveKey={['prompt']}
                                        className='prompt-collapse'
                                    >
                                        <Panel key="prompt" header="Chat GPT prompt" >
                                            <GenerateChallenge
                                                getFormValue={getFormValue}
                                                setFormValues={setFormValues}
                                                setPromptError={setPromptError}
                                                organisationId={organisationId}
                                                materialLookupItems={materials}
                                                skillLookupItems={skills}
                                            />
                                        </Panel>
                                    </Collapse>
                                )
                                : null
                        }
                        <Row align="bottom">
                            <Col span={12}>
                                <Form.Item
                                    label="Cover Image (1920 X 1080)"
                                    name="imageFile"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                //rules={[{ required: isCoverImageRequired, message: 'Please choose an cover image' }]}
                                >
                                    <FormUploader
                                        getUploadUrlAsync={getImageUploadUrlAsync}
                                        onUploadSuccess={onImageUploadSuccess}
                                        beforeUpload={beforeImageUpload}
                                        data-testid='cover-image-input'
                                        previewComponent={
                                            <Form.Item shouldUpdate={(prev, cur) => prev.listingImage !== cur.listingImage}>
                                                {() => {
                                                    return (<CoverImagePreview previewUrl={imageUrl} />)
                                                }}
                                            </Form.Item>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Listing image (960 X 1390)"
                                    name="thumbFile"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                //rules={[{ required: isListImageRequired, message: 'Please choose an listing image' }]}
                                >
                                    <FormUploader
                                        getUploadUrlAsync={getThumbUploadUrlAsync}
                                        onUploadSuccess={onThumbUploadSuccess}
                                        beforeUpload={beforeImageUpload}
                                        data-testid='list-image-input'
                                        previewComponent={
                                            <Form.Item shouldUpdate={(prev, cur) => prev.listingImage !== cur.listingImage}>
                                                {() => {
                                                    return (<ThumbImagePreview previewUrl={thumbUrl} />)
                                                }}
                                            </Form.Item>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: 24 }}>
                            <Col span={12}>
                                <Form.Item
                                    label="Youtube url (Challenge details will show Youtube video)"
                                    name="videoLink"
                                    rules={[{ required: !isCoverImageRequired, message: 'Youtube url is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input
                                        placeholder="Youtube url"
                                        disabled={isCoverImageRequired}
                                        showCount
                                        maxLength={200}
                                        data-testid="challenge-youtube-url" />
                                </Form.Item>
                                <Form.Item
                                    name="coverImageType"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Radio.Group
                                        options={coverImageOptions}
                                        onChange={onCoverImageOptionChange}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <span style={{ marginTop: 56 }}>
                                    <Form.Item shouldUpdate={(prev, cur) => prev.youtubeUrl !== cur.youtubeUrl}>
                                        {() => {
                                            return (!isListImageRequired
                                                ? (<div>
                                                    <span>List icon will show Youtube video image if available</span>
                                                </div>
                                                )
                                                : <div>
                                                </div>
                                            )
                                        }}
                                    </Form.Item>
                                </span>
                                <Form.Item
                                    name="listImageType"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Radio.Group
                                        options={listImageOptions}
                                        onChange={onListImageOptionChange}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="organisationId"
                                    label="Organisation"
                                    rules={[{ required: true, message: 'Please choose an organisation.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <LookupSelect
                                        placeholder="Select Organisation"
                                        disabled={organisationsDisabled}
                                        queryParams={organisationParams}
                                        fetchAsync={fetchOrganisationsAsync}
                                        queryKeyFn={organisationQueryKeyFn}
                                        lookupId='organisations'
                                        onChange={onOrganisationChangeHandler}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    rules={[{ required: true, message: 'Title is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="title" showCount maxLength={100} data-testid="challenge-title" />
                                </Form.Item>
                                <Form.Item
                                    label="XP"
                                    name="xp"
                                    //rules={[{ type: 'required', message: 'XP  is required' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <InputNumber min={0} step={10} addonAfter="XP" defaultValue={100} data-testid="challenge-xp" />
                                </Form.Item>
                                <Form.Item
                                    label="Difficulty"
                                    name="difficultyId"
                                    rules={[{ required: true, message: 'Please choose an difficulty level.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <DifficultyLookup
                                        mode="single"
                                        showSearch
                                        placeholder="Select Difficulty"
                                        labelInValue={false}
                                        disabled={disableDifficultyLevel}
                                        url={appLinks.difficultyLevels}
                                        organisationId={organisationId}
                                        dataMapper={(items) => items?.map(e => ({ key: e.value, value: e.label })) ?? []}
                                        popupClassName='difficulty-dropdown-menu'
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={`Duration ${durationText ? `(${durationText})` : ''}`}
                                    name="duration"
                                    rules={[
                                        { required: true, message: 'Please select an duration' },
                                        { validator: durationValdator, validateTrigger: ['onValueChange'] }
                                    ]}
                                    wrapperCol={{ span: 16 }}
                                    trigger='onValueChange'
                                    valuePropName='durationValue'
                                >
                                    <CustomTimePicker />
                                </Form.Item>
                                <Form.Item
                                    label="Facilitation needed"
                                    name="isFacilitationNeeded"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch />
                                </Form.Item>
                                {
                                    hasLocationAccess ?
                                        (
                                            <Form.Item
                                                label="Location"
                                                name="locationId"
                                                // rules={[{ required: true, message: 'Please choose an location' }]}
                                                wrapperCol={{ span: 16 }}
                                            >
                                                <LookupSelect
                                                    placeholder="Select Location"
                                                    disabled={false}
                                                    queryParams={locationParams}
                                                    fetchAsync={fetchLocationsAsync}
                                                    enabledFn={enableLocationsFetch}
                                                    queryKeyFn={locationsQueryKeyFn}
                                                    lookupId='location'
                                                    data-testid='location-select'
                                                    popupClassName='location-dropdown-menu'
                                                />
                                            </Form.Item>
                                        ) : null
                                }
                                {
                                    showPublish ?
                                        (
                                            <Form.Item
                                                label="Publish"
                                                name="publish"
                                                valuePropName="checked"
                                                wrapperCol={{ span: 16 }}
                                            >
                                                <Switch data-testid="publish-challenge" />
                                            </Form.Item>
                                        )
                                        : null
                                }
                                <Form.Item
                                    label="Is daily activity"
                                    name="dailyActivity"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={themesLabel}
                                    name="themes"
                                    //rules={[{ required: true, message: 'Please choose themes.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <ThemesLookup
                                        placeholder="Select Themes"
                                        disabled={disableThemes}
                                        organisationId={organisationId}
                                        popupClassName='themes-dropdown-menu'
                                    />
                                </Form.Item>
                                <SelectTag field='themes' />
                                <Form.Item
                                    label="Skills"
                                    name="skills"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <SkillsLookup
                                        placeholder="Select Skills"
                                        disabled={disableSkills}
                                        popupClassName='skills-dropdown-menu'
                                        organisationId={organisationId}
                                    />
                                </Form.Item>
                                <SelectTag
                                    field='skills'
                                    getColor={getItemTagColor}
                                />
                                {
                                    showAges ?
                                        (
                                            <React.Fragment>
                                                <Form.Item
                                                    label="Age"
                                                    name="ages"
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        placeholder="Select Ages"
                                                        mode="multiple"
                                                        labelInValue
                                                        tagRender={Empty}
                                                        loading={isLoadingAges}
                                                        data-testid='age-select'
                                                        popupClassName='age-dropdown-menu'
                                                        optionLabelProp="children"
                                                        optionFilterProp='label'
                                                    >
                                                        {
                                                            map(ages, item => {
                                                                return (
                                                                    <Option key={item.value} value={item.value} label={item.label}>{item.label}</Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                <SelectTag field='ages' />
                                            </React.Fragment>
                                        )
                                        : null
                                }
                                {
                                    showMaterials ?
                                        (
                                            <React.Fragment>
                                                <Form.Item
                                                    label="Materials"
                                                    name="materials"
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <MaterialsLookup
                                                        placeholder="Select Materials"
                                                        popupClassName='materials-dropdown-menu'
                                                        organisationId={organisationId}
                                                    />
                                                </Form.Item>
                                                <SelectTag
                                                    field='materials'
                                                    getColor={getItemTagColor}
                                                />
                                            </React.Fragment>
                                        )
                                        : null
                                }
                                <div>
                                    <Form.Item
                                        label="Seasons"
                                        name="seasons"
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <SeasonsLookup
                                            placeholder="Select Seasons"
                                            popupClassName='seasons-dropdown-menu'
                                            //organisationId={organisationId}
                                        />
                                    </Form.Item>
                                    <SelectTag
                                        field='seasons'
                                        getColor={getItemTagColor}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row style={styles.formRow}>
                            <Col span={24}>
                                <Tabs
                                    defaultActiveKey={activeTab}
                                    onChange={key => {
                                        setActiveTabParam && setActiveTabParam(key);
                                    }}
                                    activeKey={activeTab}
                                >
                                    <TabPane
                                        tab={`Intro & Description`}
                                        key="intro"
                                        style={{ paddingTop: 18 }}
                                        forceRender
                                    >
                                        <Row style={styles.formRow} >
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Custom Intro"
                                                    name="customIntro"
                                                    tooltip='When custom intro is set, intro template will not be used'
                                                >
                                                    <Input.TextArea
                                                        showCount
                                                        maxLength={2000}
                                                        placeholder="Custom intro"
                                                        rows={4}
                                                        data-testid="custom-intro"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={styles.formRow} >
                                            <Col span={12}>
                                                <Form.Item
                                                    label="Intro Template"
                                                    name="introId"
                                                    tooltip='Will be disabled if custom intro is set'
                                                    wrapperCol={{ span: 16 }}
                                                >
                                                    <Select
                                                        placeholder="Select Intro Template"
                                                        onChange={onIntroTemplateChange}
                                                        disabled={hasCustomIntro}
                                                        loading={isLoadingIntroTemplates}
                                                        allowClear
                                                        optionLabelProp="children"
                                                    >
                                                        {
                                                            map(introTemplates, item => {
                                                                return (
                                                                    <Option
                                                                        key={item.value}
                                                                        value={item.value}
                                                                        intro={item.intro}
                                                                    >
                                                                        {item.label}
                                                                    </Option>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={23}>
                                                <Card>
                                                    <p style={hasCustomIntro ? { color: 'grey' } : {}} >{introTemplateText}</p>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row style={styles.formRow} >
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Description"
                                                    name="description"
                                                >
                                                    <Input.TextArea
                                                        showCount maxLength={2000}
                                                        placeholder="Description"
                                                        rows={8}
                                                        data-testid="challenge-description"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    <TabPane
                                        tab={<div id='challenge-steps-tab'>{`Steps ${stepsCount > 0 ? ` (${stepsCount})` : ''}`}</div>}
                                        key="steps"
                                        style={{ paddingTop: 18 }}
                                        forceRender
                                    >
                                        <Steps form={form} />
                                    </TabPane>
                                    <TabPane
                                        tab={`Resources ${resourcesCount > 0 ? ` (${resourcesCount})` : ''}`}
                                        key="resources"
                                        forceRender
                                    >
                                        <Resources form={form} />
                                    </TabPane>
                                    <TabPane tab="Creators and Powered by" key="creators" forceRender>
                                        <Creators form={form} organisationId={organisationId} />
                                    </TabPane>
                                    <TabPane tab={`Clubs ${clubCount > 0 ? ` (${clubCount})` : ''}`} key="clubs" forceRender>
                                        <Clubs form={form} setClubCount={setClubCount} organisationId={organisationId} />
                                    </TabPane>
                                    <TabPane
                                        tab={`Prerequisites ${prequisiteCount > 0 ? ` (${prequisiteCount})` : ''}`}
                                        key="prerequisite"
                                        forceRender
                                    >
                                        <Prerequisites form={form} setPrequisiteCount={setPrequisiteCount} organisationId={organisationId} />
                                    </TabPane>
                                    {
                                        showBannersTab ?
                                            (
                                                <TabPane tab={`Banners`} key='banner'>
                                                    <Banner form={form} organisationId={organisationId} title={title} />
                                                </TabPane>
                                            ) : null
                                    }
                                    {
                                        showRelatedToTab ?
                                            (
                                                <TabPane tab="Related to" key='relatedTo'>
                                                    <RelatedTo
                                                        form={form}
                                                        organisationId={organisationId}
                                                    />
                                                </TabPane>
                                            ) : null
                                    }
                                </Tabs>
                            </Col>
                        </Row>
                        <Row style={styles.formRow} >
                            <Col span={2} >
                                <Form.Item >
                                    <Button
                                        icon={Empty}
                                        type="primary"
                                        data-testid="challenge-save"
                                        disabled={isSaving}
                                        loading={isSaving}
                                        onClick={() => {
                                            try {

                                                shouldRedirectOnSubmitRef.current = false;
                                                form.submit();

                                            } catch (error) {
                                                console.log('error on form save', error);
                                            }
                                        }}
                                    >{submitTitle}</Button>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item>
                                    <Button
                                        icon={Empty}
                                        type="primary"
                                        //htmlType="submit"
                                        data-testid="challenge-save-continue"
                                        disabled={isSaving}
                                        loading={isSaving}
                                        onClick={() => {
                                            try {

                                                shouldRedirectOnSubmitRef.current = true;
                                                form.submit();

                                            } catch (error) {
                                                console.log('error on form save', error);
                                            }
                                        }}
                                    >Save and continue</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );

}