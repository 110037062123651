import { Select } from "antd";

import { appLinks, queryKeys } from "../../../config/constants";
import EmptyDisplayComponent from "../Empty";
import useLookupsSearch from "./useLookupsSearch";
import { useMemo } from "react";

const { Option } = Select

const DifficultyLookup = (props) => {

    const {
        url = appLinks.difficultyLevelsV1,
        organisationId,
        dataMapper,
        ...rest
    } = props

    const { 
        extraQueryParams, 
        queryKey 
    } = useMemo(() => {

        const queryKey = [queryKeys.difficultyLevel]

        let extraQueryParams = {}

       if (organisationId > 0) {
        queryKey.push(organisationId)
        extraQueryParams = {
            ...extraQueryParams,
            organisationId
        }
       }

       return {
        extraQueryParams,
        queryKey
       };

    }, [organisationId])
  
    const { 
        data = [],
        isLoading,
        onSearch,
        onBlur,
    } = useLookupsSearch(
        url,
        queryKey,
        extraQueryParams,
        dataMapper,
    )

    return (
        <Select
            mode="multiple"
            //style={{ width: 200 }}
            labelInValue
            tagRender={EmptyDisplayComponent}
            loading={isLoading}
            // search related
            filterOption={false} // needed to pick items updated after search
            showArrow
            onSearch={onSearch}
            onBlur={onBlur}
            {...rest}
        >
            {
                data?.map(item => (<Option key={item.key} value={item.key}>{item.value}</Option>))
            }
        </Select>
    )

}

export default DifficultyLookup;