import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Row } from 'antd';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import Dashboard from '../../../common/components/dashboard';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';
import SendView from '../send/SendView';


const SentEmailDetails = () => {

    let { sentId } = useParams();

    const navigate = useNavigate();

    const { httpPostAsync } = useHttpHelper();

    const fetchDetailsAsync = async () => {
        try {

            const response = await httpPostAsync(appLinks.sentEmailsDetails.replace('{id}', sentId))

            return response

        } catch (error) {
            console.log('error fetching sent emails details', error);
        }
    }

    const {
        data,
        isLoading,
        refetch,

    } = useQuery(
        [queryKeys.sentEmailDetails, sentId],
        fetchDetailsAsync,
        {
            enabled: sentId > 0,
            refetchOnWindowFocus: false
        }
    );

    const viewMode = !!data?.data?.viewMode

    const formValues = useMemo(() => {

        console.log('data', data)

        let values = {}

        if (data?.code === '0' && typeof data?.data === 'object' && data?.data != null) {
            values = {
                ...data.data
            }
        }

        return values;

    }, [data])

    const onBack = () => {
        try {

            navigate(`/${appRoutes.emails}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }


    const navigateToDetails = (id) => {
        try {

            if (typeof id === 'number' && id > 0) {
                navigate(`/${appRoutes.sendEmailDetails}/${id}`)
            } else {
                navigate(`/${appRoutes.emails}`)
            }



        } catch (error) {
            console.log('error navigating to notification details', error);
        }
    }

    const reloadDetails = async () => {
        console.log('refetch')
        refetch()
    }


    return (
        <Dashboard subrouting={false} activeItem='emails'>
            <Row align="middle" style={{ paddingBottom: 8 }}>
                <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                    <Breadcrumb.Item><a onClick={onBack}>Emails</a></Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <SendView
                formValues={formValues}
                sentId={sentId}
                viewMode={viewMode}
                navigateToDetails={navigateToDetails}
                reloadDetails={reloadDetails}
            />
        </Dashboard>
    )
}

export default SentEmailDetails