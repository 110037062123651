import React, { useMemo } from 'react';
import { Typography, List, ConfigProvider } from 'antd';
//import styled from 'styled-components';
import { get, map } from 'lodash';
import EmptyComponent from '../../../../common/components/Empty';
import EmptyResult from '../../../../common/components/EmptyResult';


//const { Text } = Typography;

// const TitleComponent = styled.span`
// font-size: 16px;
// font-weight: bold;
// `

// const sectionTypeMapper = {
//     'steps': 'Steps'
// }

const StepSection = (props) => {

    const { type, customTitle, items = [] } = props;

    console.log('sectoin step resources', type, customTitle)

    return (
        <ConfigProvider renderEmpty={() => <EmptyResult description='No steps' />}>
            <List
                //header={<TitleComponent>{customTitle || get(sectionTypeMapper, type, type)}</TitleComponent>}
                //bordered
                dataSource={items}
                renderItem={item => (
                    <List.Item>
                        <Typography.Text>{item.title}</Typography.Text>
                    </List.Item>
                )}
            />
        </ConfigProvider>
    )

}

const sectionFactory = {
    'steps': StepSection
}

const SectionElement = (props) => {

    const { type } = props;

    console.log('section elment resources', type)

    const SectionComponent = useMemo(() => {
        return get(sectionFactory, type, EmptyComponent)
    }, [type]);

    return (<SectionComponent {...props} />)
}

export default (props) => {

    const { sections = [] } = props;

    console.log('sections', sections);

    return (
        <div>
            {
                map(sections, section => (<SectionElement key={section.id} {...section} />))
            }
        </div>
    )
}


