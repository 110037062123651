import React from "react"
import TagChip from "../../../common/components/tagchip"


const FilterTags = (props) => {

    const { items, onRemoveHOF } = props

    return (
        <React.Fragment>
            {
                items?.map((item = {}) => {

                    const { tags = [], filterProp } = item

                    const onRemove = onRemoveHOF(filterProp)

                    return (<TagItems
                        onRemoveHOF={onRemoveHOF}
                        key={filterProp}
                        tags={tags}
                        onRemove={onRemove}
                    />)

                })
            }
        </React.Fragment>
    )

}

const TagItems = (props) => {

    const {
        tags = [],
        onRemove,
    } = props

    return (
        <React.Fragment>
            {
                tags?.map(tag => {

                  const onRemoveTag = () => onRemove(tag)

                    return (
                        <TagChip key={tag.key}
                            closable
                            onClose={onRemoveTag}
                        >
                            {tag.label}
                        </TagChip>
                    )

                })
            }
        </React.Fragment>
    )

}

export default FilterTags