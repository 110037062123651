import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Switch } from 'antd';
import { appLinks, queryKeys, organisationTypes } from '../../../config/constants';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { useQuery } from 'react-query';
import { map } from 'lodash';

const { Option } = Select;

export default (props) => {

    const {
        onSubmit,
        formValues = {},
        submitTitle = '',
    } = props;

    //const navigate = useNavigate();

    const [form] = Form.useForm();

    const shouldRedirectOnSubmitRef = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    const { httpPostAsync } = useHttpHelper();

    //#region  fetch organisations list
    const fetchOrganisationsAsync = async () => {
        try {

            const organisations = await httpPostAsync(appLinks.adminOrganisationsV2, { type: organisationTypes.foundation });

            console.log('organisations', organisations);


            return organisations;


        } catch (error) {
            console.log('error fetching organisations', error);
        }
    }

    const queryKey = [queryKeys.foundationOrganisations, organisationTypes.foundation]

    const {
        data: organisations = [],
        isLoading: isLoadingOrganisations,
    } = useQuery(queryKey, fetchOrganisationsAsync, { refetchOnWindowFocus: false });
    //#endregion

    useEffect(() => {

        console.log('set field values', formValues);

        form.setFieldsValue(formValues);

    }, [formValues])

    const onSubmitHandler = async (values) => {
        try {

            if (typeof onSubmit !== 'function') {
                return;
            }

            const { name, isActive, organisationId } = values;

            const postData = {
                name,
                organisationId,
                isActive,
            }

            await Promise.resolve(setIsSaving(true));

            await onSubmit(postData, shouldRedirectOnSubmitRef.current);

            setIsSaving(false);

        } catch (error) {
            console.log('error submiting', error);
        }
    }

    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}
                >
                    <Form
                        layout='vertical'
                        form={form}
                        scrollToFirstError
                        onFinish={onSubmitHandler}
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    name="organisationId"
                                    label="Organisation"
                                    rules={[{ required: true, message: 'Please choose an organisation.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select Organisation"
                                        //onChange={onGenderChange}
                                        loading={isLoadingOrganisations}
                                        allowClear
                                    >
                                        {
                                            map(organisations, org => {
                                                return (
                                                    <Option key={org.value} value={org.value}>{org.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[{ required: true, message: 'Name is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Name" />
                                </Form.Item>
                                <Form.Item
                                    label="Active"
                                    name="isActive"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                <Form.Item >
                                    <Button 
                                    type="primary" 
                                    data-testid="club-save"
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={() => {
                                        try {

                                            shouldRedirectOnSubmitRef.current = false;
                                            form.submit();
                                            
                                        } catch (error) {
                                            console.log('error on form save', error);
                                        }
                                    }}
                                    >{submitTitle}</Button>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item >
                                    <Button 
                                    type="primary" 
                                    data-testid="club-save-continue"
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={() => {
                                        try {

                                            shouldRedirectOnSubmitRef.current = true;
                                            form.submit();
                                            
                                        } catch (error) {
                                            console.log('error on form save', error);
                                        }
                                    }}
                                    >Save and continue</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );

}