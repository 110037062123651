import React, { useEffect, useState } from 'react';
import { Button, Collapse, Row, Spin, Breadcrumb, message, Col, Card } from 'antd';
//import styled from 'styled-components';
import { filter, find, get, map } from 'lodash';
import AppLayout from '../../../common/components/layout';
import { useNavigate, useParams } from 'react-router-dom';
import { appLinks, appRoutes, queryKeys } from '../../../config/constants';

import { ArrowLeftOutlined } from '@ant-design/icons';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { useMutation, useQuery } from 'react-query';
import PermissionView from './components';
import Empty from '../../../common/components/Empty';

const { Panel } = Collapse;

// const ContainerDiv = styled.div`
// background-color: white;
// padding-left: 24px;
// padding-top: 24px;
// `;

const styles = {
    formRow: { 
        paddingRight: 24,
        marginTop:24
     }
}

const excludeChildrenMappins = ['settings']

function setPermissionValue(_permissions = [], _permissionId, _value, _parent) {

    // console.log('calling set child value', _permissions)

    let isFound = false;

    const search = (permissions = [], permissionId, value, parent) => {
        try {

            console.log('calling search with parent', parent)

            if (isFound || !value || !permissionId) {
                console.log('skipping loop', parent)
                return permissions;
            }

            for (let i = 0; i < permissions.length; i++) {


                const permission = permissions[i];

                //console.log('looping', permission.permission)

                if (isFound) {
                    console.log('break for loop', permission.permission)
                    break;
                }

                if (permission.permission === permissionId) {
                    console.log('child permision found', permission);
                    permissions[i].value = value;
                    isFound = true;
                    break;
                }

                const children = get(permission, 'children') || [];
                const childrenCount = children.length;

                if (!!childrenCount) {
                    permissions[i].children = search(children, permissionId, value, _parent)
                }

                if (parent === permission.permission) {

                    // can update parent here
                    const yesCount = filter(children, c => c.value === 'Yes').length;
                    const noCount = filter(children, c => c.value === 'No').length

                    // if all are yes then checkAll == true
                    // if all are no then checkAll = false
                    // if there are some own then indeterminate = true;

                    if (!!yesCount && yesCount === childrenCount) {
                        permissions[i].checkAll = true;
                        permissions[i].indeterminate = false;
                        permissions[i].value = 'Yes';
                    } else if (!!noCount && noCount === childrenCount) {
                        permissions[i].checkAll = false;
                        permissions[i].indeterminate = false;
                        permissions[i].value = 'No';
                    } else {
                        permissions[i].checkAll = false;
                        permissions[i].indeterminate = true;
                        permissions[i].value = 'Yes';
                    }

                    console.log('yes no count', yesCount.length, noCount.length);
                    console.log('updated parent', permissions[i]);

                }

            }

            return permissions;

        } catch (error) {
            console.log('error setting child value', error);
        }
    }

    return search(_permissions, _permissionId, _value, _parent);
}

function checkUncheckParent(_permissions = [], _permissionId, _check) {

    // console.log('calling set child value', _permissions)

    let isFound = false;

    const search = (permissions = [], permissionId, check, parent) => {
        try {

            console.log('calling search  for parent', permissionId)

            if (isFound || !permissionId) {
                console.log('skipping loop with parent', permissionId)
                return permissions;
            }

            for (let i = 0; i < permissions.length; i++) {


                const permission = permissions[i];

                //console.log('looping', permission.permission)

                if (isFound) {
                    console.log('break for loop', permission.permission)
                    break;
                }

                const children = get(permission, 'children') || [];

                if (permission.permission === permissionId) {
                    console.log('permision found, parent', permission, parent);

                    isFound = true;

                    permissions[i].value = !!check ? 'Yes' : 'No';
                    permissions[i].indeterminate = false;
                    permissions[i].checkAll = !!check;

                    permissions[i].children = map(children, c => ({
                        ...c,
                        value: !!check ? 'Yes' : 'No',
                    }));

                    if (parent && permissions[i].children.length && find(permissions[i].children, c => c.value === 'Yes' || c.indeterminate === true )) {
                        console.log('set parent value to yes')
                        parent.value = 'Yes';
                    } else if (parent && permissions[i].children.length && !find(permissions[i].children, c => c.value === 'Yes'|| c.indeterminate === true )) {
                        console.log('set parent value to No')
                        parent.value = 'No';
                    }

                    break;
                }


                const childrenCount = children.length;

                if (!!childrenCount) {
                    permissions[i].children = search(children, permissionId, check, permissions[i])
                }

            }

            return permissions;

        } catch (error) {
            console.log('error setting child value', error);
        }
    }

    return search(_permissions, _permissionId, _check);
}

export default () => {

    let { roleId } = useParams();

    const navigate = useNavigate();

    const { httpGetAsync, httpPutAsync } = useHttpHelper();

    const onBack = () => {
        try {

            navigate(`/${appRoutes.rolePermissions}`);

        } catch (error) {
            console.log('error on back click', error);
        }
    }

    const fetchChallengeDetailsAsync = async () => {
        try {

            const creator = await httpGetAsync(appLinks.rolePermissions.replace('{roleId}', roleId));

            console.log('challenge details', creator);

            return creator;

        } catch (error) {
            console.log('error fetching challenge details', error);
        }
    }

    const {
        data,
        isLoading,
        isSuccess
    } = useQuery(
        [queryKeys.e, roleId],
        fetchChallengeDetailsAsync,
        {
            enabled: roleId > 0,
            refetchOnWindowFocus: false
        }
    );

    function setPermissionChecks(permisisons = [], permissionValue) {
        try {

            for (let i = 0; i < permisisons.length; i++) {

                const item = permisisons[i];

                const children = get(item, 'children') || [];
                //console.log('looping', item);
                if (item.value === 'No' && permissionValue === 'No' && !!children.length) {
                    permisisons[i].children = setPermissionChecks(children, 'No');
                    permisisons[i].selected = [];
                } else if (item.value === 'Yes' && !!children.length) {
                    const yesEntries = filter(children, child => child.value === 'Yes');
                    permisisons[i].selected = map(yesEntries, entry => entry.permission);

                    if (!!yesEntries.length && yesEntries.length < children.length) {
                        permisisons[i].indeterminate = true;
                    } else {
                        permisisons[i].checkAll = yesEntries.length === children.length;
                    }

                    permisisons[i].children = setPermissionChecks(children);

                }

            }

            return permisisons;

        } catch (error) {
            console.log('error setting permissions checks', error);
        }
    }

    const [permissions, setPermissions] = useState(data)

    useEffect(() => {

        const rolePermissions = get(data, 'data') || [];

        //console.log('role permissions', data);

        // if parent is no then uncheck parent
        // if all children are selected then check all parent
        // if yes and some children are selected then set interdiminate
        // and set the selected items

        const mappedPermissions = setPermissionChecks(rolePermissions);

        console.log('mapped permissions', mappedPermissions);

        if (rolePermissions && rolePermissions.length && rolePermissions[0].id) {
            setActivePanels(rolePermissions[0].id);
        }

        setPermissions(mappedPermissions);

    }, [data])


    // useEffect(() => {

    //     let value = { title: get(skill, 'title') };

    //     form.setFieldsValue(value);

    // }, [skill]);

    //const title = get(data, 'title');
    const title = get(data, 'role');

    const onAccordianChange = e => { setActivePanels(e); }


    const [activePanels, setActivePanels] = useState([]);


    const udpateRolePermissionsAsync = async (data) => {
        const link = appLinks.updateRolePermissions.replace('{roleId}', roleId)
        // console.log('accept challenge link', link);
        console.log('udpate post data', data);
        const postData = { ...data };
        const response = await httpPutAsync(link, postData);
        console.log('update role permissions async', response);

        return response;
    };

    const mutationQueryKey = [queryKeys.updateRolePermissions, roleId]

    const {
        // data: updatePersonResponse,
        isLoading: isUpdating,
        mutateAsync: onUpdateRolePermissionAsync,
    } = useMutation(
        udpateRolePermissionsAsync,
        { mutationKey: mutationQueryKey, }
    );

    const onSubmit = async () => {
        try {

            console.log('on submit', permissions);

            const response = await onUpdateRolePermissionAsync({ permissions });

            console.log('response', response);

            if (response && response.code === 0) {
                message.success('Role permission where updated successfully');
                navigate(`/${appRoutes.rolePermissions}`);
            } if (response && response.code !== 0) {
                message.error('Role permissions could not be updated')
            }

        } catch (error) {
            console.log(' on submit', error);
        }
    }

    // ant-collapse-extra class need to be modified
    //margin-left: 20px;

    return (
        <AppLayout openKeys={['settings']} activeItem='settings-role-permissions'>
            <Spin spinning={isLoading || isUpdating}>
                <Row align="middle" style={{ paddingBottom: 8 }}>
                    <Button onClick={onBack} shape="circle" icon={<ArrowLeftOutlined />} />
                    <Breadcrumb style={{ margin: 8, marginLeft: 8 }} >
                        <Breadcrumb.Item><a onClick={onBack}>Role permissions</a></Breadcrumb.Item>
                        <Breadcrumb.Item>{title}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
                <div className='form-container-one'>
                    <Card>
                    <Collapse
                        activeKey={activePanels}
                        //ghost 
                        //collapsible='header' 
                        onChange={onAccordianChange}
                    >
                        {
                            map(permissions, permission => {

                                const onPermissionSelect = (value, perm, parent) => {
                                    try {

                                        console.log('on permission select in main loop', value, perm, parent)

                                        // const udpatePermissionFunction = setChildValue();

                                        const updatedPermissions = setPermissionValue(permissions, perm, value, parent);

                                        console.log('updated permissions', updatedPermissions);

                                        setPermissions([...updatedPermissions]);

                                    } catch (error) {
                                        console.log('error in permission select', error);
                                    }
                                }

                                const onCheckUncheckAll = (check, permissionId) => {
                                    try {

                                        console.log('on check uncheck all', check, permissionId)
                                        let updatedPermissions = checkUncheckParent(permissions, permissionId, check);

                                        // updatedPermissions = map(updatedPermissions, p => {

                                        //     if (p.children && p.children.length && find(p.children, c => c.value === 'Yes')) {
                                        //         console.log('Has child with yes, setting parent value to Yes', p)
                                        //         p.value = 'Yes';
                                        //     } else if (p.children && p.children.length){
                                        //         p.value = 'No';
                                        //     }

                                        //     return p;

                                        // });


                                        console.log('updated permissions', updatedPermissions)

                                        setPermissions([...updatedPermissions]);

                                    } catch (error) {
                                        console.log('error on check all', error);
                                    }
                                }

                                // check count, recursive
                                return (
                                    <Panel
                                        header={`${permission.label}`}
                                        key={permission.id}
                                        test-dataid={permission.id}
                                    // extra={}
                                    >
                                        <PermissionView
                                            {...permission}
                                            onPermissionSelect={onPermissionSelect}
                                            onCheckUncheckAll={onCheckUncheckAll}
                                        />
                                    </Panel>
                                )

                            })
                        }
                        {/* <Panel
                            header='Check'
                            extra={
                                <div style={{ padding: 0, margin: 0, display: 'flex', justifyContent: 'flex-start' }}>
                                    <div>

                                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                                            Check all
                                        </Checkbox>

                                    </div>
                                </div>
                            } key="111">
                            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                        </Panel> */}
                    </Collapse>
                    <Row style={styles.formRow} >
                        <Col>
                            <Button onClick={onSubmit} icon={Empty} type="primary" htmlType="submit">Update</Button>
                        </Col>
                    </Row>
                    </Card>
                   
                </div>
            </Spin>
        </AppLayout>
    )

}