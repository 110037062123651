import { Button, Col, Input, Row, Spin } from 'antd';
import { debounce, get, join } from 'lodash';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';

import OrganisationsLookup from '../../../common/components/lookupSelect/OrganisationsLookup';
import { mapKeyValues } from '../../../common/components/lookupSelect/utils';
import UnauthorizedComponent from '../../../common/components/unauthorized/UnauthorizedComponent';
import useHttpHelper from '../../../common/hooks/useHttpHelper';
import { appLinks, queryKeys } from '../../../config/constants';
import { getAppZoneMomentFromUTC } from '../../../helpers/dateTimeHelpers';
import FilterTags from '../../challenge/list/FilterTags';
import UsersTable from './table';
import useUsersLastActivityFilterContext from './useUsersFilterContext';

const { Search } = Input;

const pageSize = 10;

const UserActivities = () => {

  const { searchParams, setSearchParams, isSearchActive, resetSearchParams } = useUsersLastActivityFilterContext();

  const {
    pageNo,
    query,
    organisations = [],
    types = [],
    statuses = [],
    sortBy,
    sortOrder,
  } = searchParams;

  const [searchValue, setSearchValue] = useState(query);

  const {
    httpPostAsync,
   //httpDeleteAsync,
    httpGetAsync
  } = useHttpHelper();

  const fetchUsersListAsync = async () => {
    // console.log('call api:page param', pageParam, searchCriteria);

    const response = await httpPostAsync(
      appLinks.usersLastActivityList,
      {
        itemPerPage: pageSize,
        query,
        pageNo,
        types: types?.map(s => s.value) ?? [],
        organisations: organisations?.map(org => org.value) ?? [],
        statuses: statuses?.map(s => s.value) ?? [],
        sortBy,
        sortOrder
      }
    );

    console.log('users', response)

    const respnseObj = response ?? {}

    //return response;

    const data = respnseObj?.data?.map(e => {

      return {
        ...e,
        lastLoggedInAt: getAppZoneMomentFromUTC(e.lastLoggedIn),
        lastOpenedAt: getAppZoneMomentFromUTC(e.lastOpened),
      }

    })

    return {
      ...respnseObj,
      data
    }

  };

  const queryKey = useMemo(() => {

    const key = [queryKeys.usersLastActivtyList, pageNo, query, sortBy, sortOrder]

    if (organisations.length) {
      const organisationsValues = organisations?.map(s => s.value) ?? [];
      const organisationsList = join(organisationsValues, '.');
      key.push(organisationsList)
    }



    console.log('filter key', key)

    return key;

  }, [pageNo, query, organisations, sortBy, sortOrder])

  const {
    isLoading,
    //isError,
    //error,
    data = {},
    isFetching,
    //isPreviousData,
    //refetch,
  } = useQuery(
    queryKey,
    fetchUsersListAsync,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false
    })


  let { data: users = [], total = 0, } = data ?? {}

  const onSearch = async (text) => {
    try {

      setSearchParams(p => ({ ...p, query: text, pageNo: 0 }));

    } catch (error) {
      console.log('error searching users', error);
    }
  }

  const searchUsersRef = useRef(onSearch);
  searchUsersRef.current = onSearch;

  const debouncedSearch = useCallback(debounce(searchUsersRef.current, 300), []);

  const onSearchChange = async (e) => {
    setSearchValue(e.target.value)
    debouncedSearch(e.target.value)
  }

  const onPagniationChange = (pagination) => {
    try {

      console.log('on change', pagination,);
      let currentPage = get(pagination, 'current');
      currentPage = currentPage > 0 ? currentPage - 1 : pageNo;
      setSearchParams(p => ({ ...p, pageNo: currentPage }));


    } catch (error) {
      console.log('error on table change', error);
    }
  }

  const onSortChange = (sort) => {
    try {


      console.log('sort', sort)

      const { field, order, column } = sort || {};

      if (column) {
        setSearchParams(p => ({ ...p, sortBy: field, sortOrder: order, pageNo: 0 }));
      } else {
        setSearchParams(p => ({ ...p, sortBy: null, sortOrder: null, pageNo: 0 }));
      }

    } catch (error) {
      console.log('error sorting', error);
    }
  }

  //#region filters

  const onOrganisationChange = (values) => {
    try {

      console.log('values', values)

      setSearchParams(p => ({ ...p, organisations: [...values], pageNo: 0 }))

    } catch (error) {
      console.log('error on organisation chamge', error)
    }
  }

  //#endregion

  const [resetKey, setResetKey] = useState(0);

  const onReset = () => {
    try {

      resetSearchParams();
      setSearchValue('');
      setResetKey(k => k + 1);
      //searchFieldRef.current.input.value = '';
      // console.log('search ref', searchFieldRef.current)

    } catch (error) {
      console.log('error on resetting filters', error);
    }
  }

  const exportUserResultsAsync = async () => {
    try {

      const {
        //pageNo,
        query,
        organisations = [],
        types = [],
        statuses = [],
        sortBy,
        sortOrder,
      } = searchParams;

      const exportLink = appLinks.downloadLastActivtyUsersList

      const payload = {}

      const getQueryConfig = {
        responseType: 'blob',
        params: {
          query,
          types: types?.map(s => s.value),
          organisations: [...(organisations?.map(org => org.value) ?? [])],
          statuses: statuses?.map(s => s.value),
          sortBy,
          sortOrder
        }
      }

      const response = await httpGetAsync(
        exportLink,
        payload,
        getQueryConfig
      )

      //console.log('download results response', response)

      // create file link in browser's memory
      const href = URL.createObjectURL(response);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'exported-Users.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

    } catch (error) {
      console.log('error on export user results', error)
    }
  }

  const onRemoveHOF = (filterProp, filterKey = 'key') => (tag) => {
    try {

      setSearchParams(params => ({
        ...params,
        [filterProp]: params[filterProp]?.filter(e => e[filterKey] !== tag[filterKey]) ?? [],
      }))

    } catch (error) {
      console.log('error removing fitler tag')
    }
  }

  const filterTagItems = useMemo(() => {

    return [
      {
        tags: organisations,
        filterProp: 'organisations'
      }
    ]

  }, [organisations])

  return (
    <UnauthorizedComponent isAuthorized={true}>
      <Row justify='space-between' style={{ paddingBottom: '18px' }} gutter={24}>
        <Col>
          <Row>
            <Col style={{ paddingRight: 15 }}>
              <OrganisationsLookup
                onChange={onOrganisationChange}
                value={mapKeyValues(organisations)}
                style={{ width: 200 }}
                placeholder="Organisation filter"
              />
            </Col>
            <Col style={{ paddingRight: 15 }} >
              <Search
                placeholder="Search users"
                value={searchValue}
                onChange={onSearchChange}
                allowClear
                style={{ width: 200 }}
              />
            </Col>
          </Row>
        </Col>
        <Col>
        </Col>
      </Row>
      <Row justify='end'>
        <Col>
          <Button
            type="link"
            size='small'
            //disabled={!hasCreateAccess}
            // block
            onClick={exportUserResultsAsync}
          >
            {`Export sheet ${data?.totalPages > 0 ? '( All pages )' : ''}`}
          </Button>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col>
          <div style={{ marginBottom: 8 }}>
            <FilterTags
              onRemoveHOF={onRemoveHOF}
              items={filterTagItems}
            />
          </div>
        </Col>
        <Col>
          {
            isSearchActive
              ? (
                <Button type="link" size='small' onClick={onReset}>
                  Reset
                </Button>
              )
              : null
          }
        </Col>
      </Row>
      <Spin spinning={isLoading || isFetching}>
        <UsersTable
          key={resetKey}
          users={users}
          onSearch={debouncedSearch}
          onPagniationChange={onPagniationChange}
          onSortChange={onSortChange}
          pageNo={pageNo}
          total={total}
          defaultPageSize={pageSize}
        />
      </Spin>
    </UnauthorizedComponent>
  );

}

export default UserActivities