import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Input, Row, Switch, Tabs } from 'antd';
import Roles from './roles';
import Organisations from './organisations';
import Clubs from './clubs';
import Creator from './creator';


const { TabPane } = Tabs;

const styles = {
    formRow: { paddingRight: 24 }
}


export default (props) => {

    const {
        onSubmit,
        formValues = {},
        submitTitle = '',
        showRolesTab = false,
        showOrganisationsTab = false,
        showClubsTab = true,
        creatorProfileImageId,
        creatorProfileImageUrl,
        creatorCoverImageId,
        creatorCoverImageUrl,
    } = props;

    //const navigate = useNavigate();

    const [form] = Form.useForm();


    const shouldRedirectOnSubmitRef = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    //const { httpPostAsync } = useHttpHelper();

    //#region  fetch organisations list
    // const fetchOrganisationsAsync = async () => {
    //     try {

    //         const organisations = await httpPostAsync(appLinks.adminOrganisations, { type: organisationTypes.foundation });

    //         console.log('organisations', organisations);


    //         return organisations;


    //     } catch (error) {
    //         console.log('error fetching organisations', error);
    //     }
    // }

    // const queryKey = [queryKeys.foundationOrganisations, organisationTypes.foundation]

    // const {
    //     data: organisations = [],
    //     isLoading: isLoadingOrganisations,
    // } = useQuery(queryKey, fetchOrganisationsAsync, { refetchOnWindowFocus: false });
    //#endregion

    useEffect(() => {

        console.log('set field values', formValues);

        form.setFieldsValue(formValues);

    }, [formValues]);

    const [creatorData, setCreatorsData] = useState({});

    const onSubmitHandler = async (values) => {
        try {

            console.log('on submit handler', values)

            if (typeof onSubmit !== 'function') {
                return;
            }

            const {
                firstName,
                lastName,
                roles,
                organisations,
                hasAdminAccess,
                clubs,
                //creator info
                creatorName,
                creatorShortBio,
                creatorTitle,
                creatorWebsite,
            } = values;

            console.log('on submit handler: values', values)

            const {
                creatorProfileImageId,
                creatorCoverImageId,
            } = creatorData

            const postData = {
                firstName,
                lastName,
                roles,
                clubs,
                organisations,
                hasAdminAccess,
                //creator info
                creatorProfileImageId,
                creatorCoverImageId,
                creatorName,
                creatorShortBio,
                creatorTitle,
                creatorWebsite,
            }

            await Promise.resolve(setIsSaving(true));

            await onSubmit(postData, shouldRedirectOnSubmitRef.current);

            setIsSaving(false);

        } catch (error) {
            console.log('error submiting', error);
        }
    }

    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}
                >
                    <Form
                        layout='vertical'
                        form={form}
                        scrollToFirstError
                        onFinish={onSubmitHandler}
                        name='user-details'
                    >
                        <Row>
                            <Col span={12}>
                                {/* <Form.Item
                                    name="organisationId"
                                    label="Organisation"
                                    rules={[{ required: true, message: 'Please choose an organisation.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select Organisation"
                                        //onChange={onGenderChange}
                                        loading={isLoadingOrganisations}
                                        allowClear
                                    >
                                        {
                                            map(organisations, org => {
                                                return (
                                                    <Option key={org.key} value={org.key}>{org.value}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item> */}
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'First name is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="First name" />
                                </Form.Item>
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    //rules={[{ required: true, message: 'First name is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Last name" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Email" disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Allow admin app access"
                                    name="hasAdminAccess"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch />
                                </Form.Item>
                                {/* <Form.Item
                                    label="Allow operations access"
                                    name="allowOperationsAccess"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch disabled />
                                </Form.Item> */}
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>
                        <Row style={styles.formRow} >
                            <Col span={24}>
                                <Tabs defaultActiveKey="roles" >
                                    {
                                        showRolesTab
                                            ? (
                                                <TabPane
                                                    tab={`Roles`}
                                                    key="roles"
                                                    style={{ paddingTop: 18 }}
                                                    forceRender
                                                >
                                                    <Roles form={form} />
                                                </TabPane>
                                            )
                                            : null
                                    }
                                    {
                                        showOrganisationsTab
                                            ? (
                                                <TabPane
                                                    tab={`Organisations`}
                                                    key="organisations"
                                                    style={{ paddingTop: 18 }}
                                                    forceRender
                                                >
                                                    <Organisations form={form} />
                                                </TabPane>
                                            )
                                            : null
                                    }
                                    {
                                        showClubsTab
                                            ? (
                                                <TabPane
                                                    tab={`Clubs`}
                                                    key="clubs"
                                                    style={{ paddingTop: 18 }}
                                                    forceRender
                                                >
                                                    <Clubs form={form} />
                                                </TabPane>
                                            )
                                            : null
                                    }
                                    <TabPane
                                        tab={`Creator Profile`}
                                        key="creator"
                                        style={{ paddingTop: 18 }}
                                        forceRender
                                    >
                                        <Creator
                                            form={form}
                                            creatorProfileImageId={creatorProfileImageId}
                                            creatorProfileImageUrl={creatorProfileImageUrl}
                                            creatorCoverImageId={creatorCoverImageId}
                                            creatorCoverImageUrl={creatorCoverImageUrl}
                                            setCreatorsData={setCreatorsData}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                <Form.Item >
                                    <Button 
                                    type="primary" 
                                    data-testid="organisation-save"
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={() => {
                                        try {

                                            shouldRedirectOnSubmitRef.current = false;
                                            form.submit();
                                            
                                        } catch (error) {
                                            console.log('error on form save', error);
                                        }
                                    }}
                                    >
                                        {submitTitle}</Button>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item >
                                    <Button 
                                    type="primary" 
                                    data-testid="organisation-save-continue"
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={() => {
                                        try {

                                            shouldRedirectOnSubmitRef.current = true;
                                            form.submit();
                                            
                                        } catch (error) {
                                            console.log('error on form save', error);
                                        }
                                    }}
                                    >Save and continue</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    );

}