import { DeleteOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
    Button, Col,
    Input,
    Row,
    Space,
    Typography
} from 'antd';
import React, { useMemo } from 'react';
import SectionItem from './SectionItem';


const { Text } = Typography


const SectionItemContainer = ({
    index,
    field,
    uniqueId,
    showBorder = false,
    onRemove,
    viewOnly = false,
    getParagraphs,
    getLinks,
    setImageUrl,
    form,
    viewMode,
}) => {

    // const [selectedImageUrl, setSelectedImageUrl] = useState()

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        setActivatorNodeRef,
    } = useSortable({
        id: uniqueId,

        transition: {
            duration: 150, // milliseconds
            easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
        },
    });

    const borderStyle = useMemo(() => {

        return showBorder ? {
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: 8,
            borderColor: '#1890ff',
            // width: 500,
        } : {

            borderColor: 'rgb(243 243 243)',
            borderStyle: 'solid',
            borderRadius: 8,
            borderWidth: 1
        }

    }, [showBorder])

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        padding: 12,
        backgroundColor: '#fff',

        ...borderStyle
    };

    const itemContainerStyle = {
        position: 'relative',
        //opacity:`${showBorder ? '0.8' : '1'}`

    }

    const onSelectUmageUrl = (imageUrl) => {
        try {

            //console.log('selected image', e)
            //setSelectedImageUrl(e.value)
            //form.setv
            setImageUrl(uniqueId, imageUrl)

        } catch (error) {
            console.log('error selecting image url', error)
        }
    }

    return (
        <div>
            {index > 0 ? (<div style={{ marginTop: 8 }} />) : null}
            <div style={itemContainerStyle} ref={setNodeRef}  >
                <Row>
                    <Col flex={1} span={24}>
                        <div style={style} {...attributes} >
                            <Row>
                                <Text style={{ paddingBottom: 12 }}>{`Section ${index + 1}`}</Text>
                            </Row>
                            <Row justify="space-between" >
                                <Col flex={1}>
                                    {
                                        !viewOnly
                                            ? (
                                                <SectionItem
                                                    field={field}
                                                    onSelectUmageUrl={onSelectUmageUrl}
                                                    getParagraphs={getParagraphs}
                                                    getLinks={getLinks}
                                                    form={form}
                                                    viewMode={viewMode}
                                                />
                                            )
                                            :
                                            (
                                                <Input value={`Section ${index + 1}`}
                                                    contentEditable={false}
                                                />
                                            )
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col>
                        <Space direction="vertical" size="middle" style={{
                            padding: 12,
                            height: '100%',
                            justifyContent: 'center'
                        }} >
                            <Button
                                //type='primary'
                                shape='circle'
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {
                                    !viewOnly && onRemove && onRemove();
                                    // onClose(value, label)
                                }}
                                danger
                                icon={<DeleteOutlined />}
                            />
                            <Button
                                type="secondary"
                                shape='circle'
                                ref={setActivatorNodeRef}
                                {...listeners}
                                //style={{ position: 'absolute', top: 8, right: 8 }}
                                onClick={() => {

                                    console.log('draging action')
                                }}
                                //danger
                                icon={<VerticalAlignMiddleOutlined />}
                            />
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )

}

export default SectionItemContainer;