import { Typography } from 'antd';
import React from 'react';
import { creatorTypes } from '../../../../config/constants';
import ItemTitle from '../../../../common/components/itemTitle';

const { Text } = Typography;


const CreatorClubView = ({ name }) => {
    return (<ItemTitle title='Club' value={name} />)
}

export const CreatorPersonView = ({ name, title, shortBio, website, logoLink, showBio = true }) => {
    return (
        <div>
            { logoLink ? <img src={logoLink} alt="avatar" style={{ height: 80, borderRadius: '8px' }} /> : null }
            <ItemTitle title='Creator' value={name} />
            { title ? <ItemTitle title='Title' value={title} /> : null }
            { showBio ? <ItemTitle title='Bio' value={shortBio} /> : null }
            { website ? <ItemTitle title='Website' value={website} /> : null }
        </div>
    )
}


export const CreatorOrganisationView = ({ name, logoLink, website }) => {
    return (
        <div>
            { logoLink ? <img src={logoLink} alt="avatar" style={{ height: 80, borderRadius: '8px' }} /> : null }
            { name ? <ItemTitle title='Organisation' value={name} /> : null }
            { website ? <ItemTitle title='Website' value={website} /> : null }
        </div>
    )
}

const CreatorTextView = ({ creatorText }) => {
    return (
        <div>
            <Text>{creatorText}</Text>
        </div>
    )
}

const creatorFactory = {
    [creatorTypes.club]: CreatorClubView,
    [creatorTypes.creator]: CreatorPersonView,
    [creatorTypes.organisation]: CreatorOrganisationView,
    [creatorTypes.text]: CreatorTextView,
}

export default creatorFactory