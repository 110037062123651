import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const usersLastActivityFilterConfig = {
    key: 'usersLastActivity',
    isActiveProp: 'isUsersLastActivitySearchActive',
    setAction: 'usersLastActivity',
    resetAction: 'reset-users-last-activity',
    setFilterProp: 'setUsersLastActivityFilters',
    resetFilterProp: 'resetUsersLastActivityFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        types: [],
        statuses: [],
        organisations: [],
        sortBy: null,
        sortOrder: null,
    }
}

const useUsersLastActivityFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, usersLastActivityFilterConfig)
}

export default useUsersLastActivityFilterContext;
