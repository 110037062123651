import moment from 'moment-timezone';
import config from '../config/config';

export function getAppZoneMomentFromUTC(formattedUtcDate, format = config.apiDateFormat) {
  try {
    const mUtcDate = moment.utc(formattedUtcDate, format);

    return mUtcDate.tz(config.appTimeZone);
    
  } catch (error) {
    console.log('error converting string to utc date', error);
  }
}