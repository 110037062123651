
import { useContext } from "react";
import { FilterStoreContext, getFilterContextValue } from "../../../common/components/filterStoreContext";


export const clubsFilterConfig = {
    key: 'clubs',
    isActiveProp: 'isClubsSearchActive',
    setAction: 'clubs',
    resetAction: 'reset-clubs',
    setFilterProp: 'setClubsFilters',
    resetFilterProp: 'resetClubsFilters',
    initialValue: {
        pageNo: 0,
        query: '',
        organisations: [],
        statuses: [],
        sortBy: null,
        sortOrder: null,
    }
}

const useClubsFilterContext = () => {

    const contextValue = useContext(FilterStoreContext);

    return getFilterContextValue(contextValue, clubsFilterConfig)
}

export default useClubsFilterContext;