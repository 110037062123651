import { DatePicker, Form } from "antd"
import { useEffect } from "react";
import moment from 'moment-timezone';


const EditDateRange = (props) => {

    const {
        form,
        theme,
        onUpdateAsync,
    } = props

    useEffect(() => {

        const startDate = theme?.startDate
        const endDate = theme?.endDate

        const momentStartDate = moment(startDate, 'YYYY-MM-DD')
        const momentEndDate = moment(endDate, 'YYYY-MM-DD')

        if (momentStartDate?.isValid() && momentEndDate?.isValid()) {

            const value = {
                startDate: momentStartDate,
                endDate: momentEndDate
            }

            form.setFieldsValue(value);
        }

    }, [theme]);


    const onSubmit = (values) => {
        try {

            //console.log('on submit date ranges', values)

            const startMDate = values?.startDate
            const endMDate = values?.endDate

            if (startMDate?.isValid() && endMDate?.isValid()) {

                let postData = {
                    startDate: startMDate.format('DD-MM-YYYY'),
                    endDate: endMDate.format('DD-MM-YYYY'),
                }

                typeof onUpdateAsync === 'function' && onUpdateAsync(postData)

            }

        } catch (error) {
            console.log('error in update date range form submit', error);
        }
    }


    return (
        <div>
            <Form
                layout='vertical'
                form={form}
                scrollToFirstError
                name='edit-date-range'
                //initialValues={initialValues}
                onFinish={onSubmit}
            >
                <Form.Item
                    label="Start date"
                    name="startDate"
                    rules={[
                        { required: true, message: 'Start date is required' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                //console.log('start date validator', value)
                                const endDateValue = getFieldValue('endDate')
                                if (endDateValue?.isValid() && value?.isValid() && endDateValue.isAfter(value)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(new Error('Start date should be after end date'));
                                }
                            },
                        }),
                    ]}
                    wrapperCol={{ span: 16 }}
                >
                    <DatePicker 
                        format={'DD-MM-YYYY'} 
                        allowClear={false}
                        placeholder="Start date" 
                        />
                </Form.Item>
                <Form.Item
                    label="End date"
                    name="endDate"
                    rules={[
                        { required: true, message: 'End date is required' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                //console.log('start date validator', value)
                                const startDateValue = getFieldValue('startDate')
                                if (startDateValue?.isValid() && value?.isValid() && value.isAfter(startDateValue)) {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject(new Error('End date should be before start date'));
                                }
                            },
                        }),
                    ]}
                    wrapperCol={{ span: 16 }}
                >
                    <DatePicker 
                        format={'DD-MM-YYYY'} 
                        allowClear={false}
                        placeholder="End Date"
                    />
                </Form.Item>
            </Form>
        </div>
    )

}

export default EditDateRange 