import { Col, Row, Table, Typography } from 'antd';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '../../../config/constants';

const { Text } = Typography;

// const statusToColorMapper = {
//     active: 'green',
//     default: '#d9d9d9'
// }

// const statusToTooltipMapper = {
//     active: 'Active',
//     default: 'Not active'
// }


const OrganisationsTable = props => {

    const {
        users,
        onPagniationChange,
        onSortChange,
        pageNo,
        total,
        defaultPageSize,
    } = props;

    let navigate = useNavigate();

    const columns = useMemo(() => [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            //render: (text, record) => <span>{record.name}</span>
            render: (text, record) => {

                    return (
                        <Text>
                            {record.name}
                        </Text>
                    )
                
            },
        },
        {
            title: 'Email address',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
        },
        {
            title: 'Organisation',
            dataIndex: 'organisationText',
            key: 'organisationText',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {

                return (
                    <Text>
                        {record.status === 'active' ? 'Active' : 'Inactive'}
                    </Text>
                )
            },
        },
        {
            title: 'One Status',
            dataIndex: 'oneKidsStatus',
            key: 'oneKidsStatus',
            render: (text, record) => {

                return (
                    <Text>
                        {record.oneKidsStatus}
                    </Text>
                )
            },
        },
        {
            title: 'Device',
            dataIndex: 'device',
            key: 'device',
            render: (text, record) => {

                return (
                    <Text>
                        {record.device}
                    </Text>
                )
            },
        },
        {
            title: 'Activities',
            dataIndex: 'activitiesOpened',
            key: 'activitiesOpened',
            render: (text, record) => {

                return (
                    <Text>
                        {record.activitiesOpened}
                    </Text>
                )
            },
        },
        {
            title: 'Last Opened',
            dataIndex: 'lastOpenedAt',
            key: 'lastOpenedAt',
            render: (text, record) => {

                const lastOpenedAt = record.lastOpenedAt

                return (
                    <Text>
                       {lastOpenedAt && lastOpenedAt.isValid() && lastOpenedAt.format('Do MMM, YY HH:mm')}
                    </Text>
                )
            },
        },
        {
            title: 'Last Logged in',
            dataIndex: 'lastLoggedInAt',
            key: 'lastLoggedInAt',
            render: (text, record) => {

                const lastLoggedInAt = record.lastLoggedInAt

                return (
                    <Text>
                       {lastLoggedInAt && lastLoggedInAt.isValid() && lastLoggedInAt.format('Do MMM, YY HH:mm')}
                    </Text>
                )
            },
        },
        {
            title: 'Posts',
            dataIndex: 'posts',
            key: 'posts',
            render: (text, record) => {

                return (
                    <Text>
                        {record.posts}
                    </Text>
                )
            },
        },
        {
            title: 'Deleted',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (text, record) => {

                return (
                    <Text>
                        {!!record.isDeleted ? 'Yes' : 'No'}
                    </Text>
                )
            },
        },

    ], [navigate]);

    // 

    const onChangeTable = (pagination, filters, sorter, extra) => {
        try {

          // console.log('on change', pagination, filters, sorter, extra);

            if (extra.action === 'paginate') {
                onPagniationChange && onPagniationChange(pagination);
            } else if (extra.action === 'sort') {
                onSortChange && onSortChange(sorter)
            }

        } catch (error) {
            console.log('error on table change', error);
        }
    }


    return (
        <div>
            <Row>
                <Col justify='flex-start' span={24} style={{ paddingTop: '0px' }}>
                    <Table
                        columns={columns}
                        dataSource={users}
                        pagination={{
                            current: pageNo + 1,
                            defaultPageSize,
                            //hideOnSinglePage: true,
                            total,
                        }}
                        onChange={onChangeTable}
                    />
                </Col>
            </Row>
        </div>
    );

}


export default OrganisationsTable;