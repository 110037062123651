
import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button, Col, Form, Input, Row, Select, Switch, Tabs } from "antd"
import { useQuery } from "react-query";
import { filter, map, debounce } from 'lodash';
import useHttpHelper from "../../../common/hooks/useHttpHelper";
import { useAppContext } from "../../../common/components/appContext";
import CreatorProfile from '../common/createEdit/creator';
import Empty from '../../../common/components/Empty';
import TagChip from "../../../common/components/tagchip";
import { passwordSchema, passwordErrorMessageMap } from "../../../helpers/passwordSchema";
import { appLinks, organisationTypes, queryKeys } from "../../../config/constants";

const { TabPane } = Tabs;
const { Option } = Select;

const styles = {
    formRow: { paddingRight: 24 }
}

const shouldUpdateRoles = () => (prev, cur) => prev.roles !== cur.roles;
const shouldUpdateClubs = () => (prev, cur) => prev.clubs !== cur.clubs;

export default (props) => {

    const { onSubmit, submitTitle = 'Save' } = props;

    const { user } = useAppContext();

    const [form] = Form.useForm();

    const shouldRedirectOnSubmitRef = useRef(false);
    const [isSaving, setIsSaving] = useState(false);

    const [organisationId, setOrganisationId] = useState(user.defaultOrganisationId);

    const { httpPostAsync } = useHttpHelper();

    //#region  fetch organisations list
    const fetchOrganisationsAsync = async () => {
        try {

            const organisations = await httpPostAsync(appLinks.adminOrganisationsV2, { type: organisationTypes.foundation });
            console.log('organisations', organisations);
            return organisations;

        } catch (error) {
            console.log('error fetching organisations', error);
        }
    }

    const queryKey = [queryKeys.foundationOrganisations, organisationTypes.foundation]

    const {
        data: organisations = [],
        isLoading: isLoadingOrganisations,
    } = useQuery(queryKey, fetchOrganisationsAsync, { refetchOnWindowFocus: false });
    //#endregion

    //#region  fetch roles
    const fetchRolesAsync = async () => {
        try {

            return await httpPostAsync(appLinks.organisationRoles.replace('{organisationId}', organisationId))
        } catch (error) {
            console.log('error getting user roles for organisations', error);
        }
    };

    const userRolesQueryKey = [queryKeys.organisationRoles, organisationId];

    const {
        data: roles = [],
        isLoading: isLoadingUserRoles,
        refetch: refetchUserRoles
    } = useQuery(
        userRolesQueryKey,
        fetchRolesAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });
    //#endregion


    //#region fetch clubs

    const fetchClubsAsync = async () => {
        try {

            return await httpPostAsync(appLinks.clubsLookups, { organisationId })
        } catch (error) {
            console.log('error getting user clubs for organisations', error);
        }
    };

    const userClubsQueryKey = [queryKeys.organisationClubs, organisationId];

    const {
        data: clubs = [],
        isLoading: isLoadingUserClubs,
        refetch: refetchUserClubs
    } = useQuery(
        userClubsQueryKey,
        fetchClubsAsync,
        {
            refetchOnWindowFocus: false,
            enabled: organisationId > 0
        });

    //#endregion

    useEffect(() => {

        refetchUserRoles();
        refetchUserClubs();

        form.setFieldsValue({ roles: [], clubs: [] });

    }, [organisationId])

    const onValuesChange = (values) => {
        try {

            if (!values) {
                return;
            }

            if (values.organisationId) {
                console.log('organisation id changed', values)
                setOrganisationId(values.organisationId)
            }

            console.log('onValuesChange', values);

        } catch (error) {
            console.log('error handling on values change', error);
        }
    }


    const onSubmitHandler = async (values) => {
        try {

            console.log('on submit handler', values)

            if (typeof onSubmit !== 'function') {
                return;
            }

            const {
                firstName,
                email,
                lastName,
                roles,
                clubs,
                organisationId,
                hasAdminAccess,
                password,
                //creator info
                creatorName,
                creatorShortBio,
                creatorTitle,
                creatorWebsite,
            } = values;

            const {
                creatorProfileImageId,
                creatorCoverImageId,
            } = creatorData

            const postData = {
                firstName,
                lastName,
                organisationId,
                email,
                roles: map(roles, r => r.value),
                clubs: map(clubs, r => r.value),
                hasAdminAccess,
                password,
                // creator info
                creatorProfileImageId,
                creatorCoverImageId,
                creatorName,
                creatorShortBio,
                creatorTitle,
                creatorWebsite,
            }

            await Promise.resolve(setIsSaving(true));

            await onSubmit(postData, shouldRedirectOnSubmitRef.current);

            setIsSaving(false);

           // onSubmit && onSubmit(postData)

        } catch (error) {
            console.log('error submiting', error);
        }
    }

    const checkExistingEmailAsync = async (email) => {
        try {

            console.log('check email with value', email)

            return await httpPostAsync(appLinks.checkExistingUserWithEmail, { email })

        } catch (error) {
            console.log('error checking existing email async', error);
        }
    }

    const checkExistingEmail = useCallback(debounce(checkExistingEmailAsync, 300, { trailing: true, leading: true }), []);

    const existingEmailValidatorAsync = async (rule, value, callback) => {
        try {
            const existinUserReponse = await checkExistingEmail(value)
            console.log('existing email', value, existinUserReponse)

            if (existinUserReponse && existinUserReponse.code === 0 && existinUserReponse.existingUserCount > 0) {
                console.log('user wil email already exists')
                callback('Email is already take, please try with a different one')
                return Promise.reject("Email is already take, please try with a different one")
            }

            return Promise.resolve(true);

        } catch (error) {
            console.log('error validating existing email', error);
        }
    }


    const passwordValidator = (rule, value, callback) => {
        try {

            const validateResult = passwordSchema.validate(value, { list: true });

            if (validateResult && validateResult.length) {
                const validationError = validateResult[0];
                const errorMessage = passwordErrorMessageMap[validationError];
                if (errorMessage) {
                    callback(errorMessage)
                }
            }

            console.log('validate password result', validateResult)

            return callback();

        } catch (error) {
            console.log('error validating password')
            // callback('Col')
        }
    }

    const [creatorData, setCreatorsData] = useState({});

    return (
        <div>
            <Row>
                <Col
                    justify='flex-start'
                    span={24}
                    style={{ paddingTop: '0px' }}
                >
                    <Form
                        layout='vertical'
                        form={form}
                        scrollToFirstError
                        onFinish={onSubmitHandler}
                        onValuesChange={onValuesChange}
                        onError={e => console.log('on submit error', e)}
                        onFinishFailed={e => console.log('onFinishFailed', e)}
                        name='new-user'
                        initialValues={{
                            organisationId: user.defaultOrganisationId
                        }}
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="First Name"
                                    name="firstName"
                                    rules={[{ required: true, message: 'First name is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="First name" />
                                </Form.Item>
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    //rules={[{ required: true, message: 'First name is required.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Last name" />
                                </Form.Item>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    validateFirst={true}
                                    rules={[
                                        { required: true, message: 'Email is required.' },
                                        { required: true, type: 'email', message: 'Email seems to be invalid' },
                                        { validator: existingEmailValidatorAsync }
                                    ]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    validateFirst={true}
                                    rules={[
                                        { required: true, message: 'Password is required' },
                                        { validator: passwordValidator }
                                    ]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                                <Form.Item
                                    name="organisationId"
                                    label="Organisation"
                                    rules={[{ required: true, message: 'Please choose an organisation.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select Organisation"
                                        //onChange={onGenderChange}
                                        loading={isLoadingOrganisations}

                                    // allowClear
                                    >
                                        {
                                            map(organisations, org => {
                                                return (
                                                    <Option key={org.value} value={org.value}>{org.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Clubs"
                                    name={"clubs"}
                                    //rules={[{ required: true, message: 'Please choose an Type.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select clubs"
                                        mode="multiple"
                                        labelInValue
                                        tagRender={Empty}
                                        //notFoundContent={e => Empty}
                                        loading={isLoadingUserClubs}
                                    // disabled={disableSkills}
                                    //allowClear
                                    >
                                        {
                                            map(clubs, item => {
                                                return (
                                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item shouldUpdate={shouldUpdateClubs}>
                                    {(props) => {

                                        // roles grouped by organisation
                                        const organisationClubs = props.getFieldValue('clubs');

                                        //const organisationRoles = get(items, `[${index}].roles`);

                                        //console.log('items', items);

                                        return (
                                            <React.Fragment>
                                                {map(organisationClubs, role => {

                                                    return (
                                                        <TagChip
                                                            key={role.value}
                                                            closable
                                                            onClose={() => {

                                                                const filtered = filter(organisationClubs, o => o.value !== role.value);

                                                                //     console.log('filtered roles', filtered)

                                                                //     console.log('filtered themes', filtered)

                                                                // items[index].roles = [...filtered ]

                                                                props.setFieldsValue({ clubs: filtered })

                                                            }}
                                                        >
                                                            {role.label}
                                                        </TagChip>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    }}
                                </Form.Item>
                                <Form.Item
                                    label="Roles"
                                    name={"roles"}
                                    //rules={[{ required: true, message: 'Please choose an Type.' }]}
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Select
                                        placeholder="Select roles"
                                        mode="multiple"
                                        labelInValue
                                        tagRender={Empty}
                                        //notFoundContent={e => Empty}
                                        loading={isLoadingUserRoles}
                                    // disabled={disableSkills}
                                    //allowClear
                                    >
                                        {
                                            map(roles, item => {
                                                return (
                                                    <Option key={item.value} value={item.value}>{item.label}</Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item shouldUpdate={shouldUpdateRoles}>
                                    {(props) => {

                                        // roles grouped by organisation
                                        const organisationRoles = props.getFieldValue('roles');

                                        //const organisationRoles = get(items, `[${index}].roles`);

                                        //console.log('items', items);

                                        return (
                                            <React.Fragment>
                                                {map(organisationRoles, role => {

                                                    return (
                                                        <TagChip
                                                            key={role.value}
                                                            closable
                                                            onClose={() => {

                                                                const filtered = filter(organisationRoles, o => o.value !== role.value);

                                                                //     console.log('filtered roles', filtered)

                                                                //     console.log('filtered themes', filtered)

                                                                // items[index].roles = [...filtered ]

                                                                props.setFieldsValue({ roles: filtered })

                                                            }}
                                                        >
                                                            {role.label}
                                                        </TagChip>
                                                    )
                                                })}
                                            </React.Fragment>
                                        )
                                    }}
                                </Form.Item>
                                <Form.Item
                                    label="Allow admin app access"
                                    name="hasAdminAccess"
                                    valuePropName="checked"
                                    wrapperCol={{ span: 16 }}
                                >
                                    <Switch />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>
                        <Row style={styles.formRow} >
                            <Col span={24}>
                                <Tabs defaultActiveKey="roles" >
                                    <TabPane
                                        tab={`Creator Profile`}
                                        key="creator"
                                        style={{ paddingTop: 18 }}
                                        forceRender
                                    >
                                        <CreatorProfile
                                            form={form}
                                            // creatorProfileImageId={creatorProfileImageId}
                                            // creatorProfileImageUrl={creatorProfileImageUrl}
                                            // creatorCoverImageId={creatorCoverImageId}
                                            // creatorCoverImageUrl={creatorCoverImageUrl}
                                            setCreatorsData={setCreatorsData}
                                        />
                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={2}>
                                <Form.Item >
                                    <Button 
                                    type="primary"
                                    data-testid="user-save"
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={() => {
                                        try {

                                            shouldRedirectOnSubmitRef.current = false;
                                            form.submit();
                                            
                                        } catch (error) {
                                            console.log('error on form save', error);
                                        }
                                    }}
                                    >{submitTitle}</Button>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item >
                                    <Button 
                                    type="primary"
                                    data-testid="user-save-continue"
                                    disabled={isSaving}
                                    loading={isSaving}
                                    onClick={() => {
                                        try {

                                            shouldRedirectOnSubmitRef.current = true;
                                            form.submit();
                                            
                                        } catch (error) {
                                            console.log('error on form save', error);
                                        }
                                    }}
                                    >Save and continue</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )



}